import API from "services/api";
import apiConfig from "config/api";

export type IndicatorsItem = {
  tickets: number;
  manualCompletions: number;
  users: number;
};

export const fetchIndicators = async () => {
  const { data: indicators } = await API.get<IndicatorsItem>(
    apiConfig.paths.authorized.pendingActions
  );
  return indicators;
};
