import * as React from "react";
import { FieldRenderProps } from "react-final-form/typescript";
import { FormControl, FormHelperText } from "@material-ui/core";

import { TextEditor } from "components";

type Props = FieldRenderProps<string, HTMLElement> & {
  placeholder?: string;
};

// @TODO @FIXME: backspace didn't work "monitorEvents(document.body, 'key')"
const TextEditorControl: React.FC<Props> = ({
  input: { value, onChange, onBlur },
  meta: { submitError, dirtySinceLastSubmit, error, touched },
  placeholder,
  ...rest
}) => {
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  return (
    <FormControl error={showError} {...rest}>
      <TextEditor
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />

      {showError && <FormHelperText>{error || submitError}</FormHelperText>}
    </FormControl>
  );
};

export default TextEditorControl;
