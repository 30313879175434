import * as React from "react";
import { Switch, Route, match } from "react-router-dom";

import ManualSubmissionsRouter from "./routers/manual-submissions";
import CoursesRouter from "./routers/courses";

type Props = {
  match: match;
};

const CoursesRootRouter: React.FC<Props> = ({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/manual-submissions`}
      component={ManualSubmissionsRouter}
    />
    <Route path={match.path} component={CoursesRouter} />
  </Switch>
);

export default CoursesRootRouter;
