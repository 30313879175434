import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  emptyPlaceholderWrap: {
    display: "flex",
    flexDirection: "column"
  },
  emptyPlaceholderButton: {
    margin: "0 auto"
  }
}));
