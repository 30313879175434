import * as React from "react";

import { AppLayout, ApiResultRenderer } from "components";
import useListFilters from "utils/hooks/useListFilters";
import {
  useMyCoursesHistoryList,
  MyCoursesHistoryListFilters
} from "modules/courses/provider/my-courses";
import { useModuleState, actionTypes } from "modules/courses/reducers/history";
import { LayoutType } from "components/layout-selector/layout-selector";
import { useAuth } from "services/auth-provider";
import useStateWithLocalStorage from "utils/hooks/useStateWithLocalStorage";

import MyCoursesList from "../components/course-list-my/course-list-my";

type Props = {};

const CoursesHistoryView: React.FC<Props> = () => {
  const { user } = useAuth();
  const [{ myCoursesHistoryFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters<MyCoursesHistoryListFilters>(
    myCoursesHistoryFilters
  );
  const myCoursesHistoryList = useMyCoursesHistoryList({
    variables: { listFilters }
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_MY_COURSES_HISTORY_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  const [layout, setLayout] = useStateWithLocalStorage<LayoutType>({
    localStorageKey: `coursesHistoryLayout-${user!.id}`,
    defaultValue: "grid"
  });

  return (
    <AppLayout viewTitle="History" hideSmUp>
      <ApiResultRenderer
        apiResult={myCoursesHistoryList}
        reloadsSection={false}
      >
        {({ data = [], meta, loading }) => (
          <MyCoursesList
            courses={data}
            meta={meta!}
            listFilters={listFilters}
            activeLayout={layout}
            setActiveLayout={setLayout}
            loading={loading}
            isHistoryView
          />
        )}
      </ApiResultRenderer>
    </AppLayout>
  );
};

export default CoursesHistoryView;
