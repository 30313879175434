import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
  gridItem: {
    [breakpoints.down("xs")]: {
      flexGrow: 1
    }
  },
  gridSearch: {
    order: -1,
    marginBottom: spacing(3),
    [breakpoints.up("sm")]: {
      order: 0,
      marginBottom: spacing(0)
    }
  },
  searchHeader: {
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  searchDiv: {
    width: "100%",
    marginBottom: spacing(3),
    [breakpoints.up("sm")]: {
      width: "70%",
      marginBottom: 0
    },
    [breakpoints.up("md")]: {
      display: "none"
    }
  },
  exportButton: {
    marginBottom: spacing(6)
  }
}));
