import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Link,
  Breadcrumbs as MuiBreadcrumbs
} from "@material-ui/core";
import { BreadcrumbsProps } from "@material-ui/core/Breadcrumbs";

type Route = {
  name: string;
  path?: string;
};

type OptionalRoute = Route | boolean | null | undefined;

type Props = BreadcrumbsProps & {
  routes: OptionalRoute[];
};

const Breadcrumbs: React.FC<Props> = ({ routes, ...breadcrumbsProps }) => {
  const filteredRoutes = routes.filter(Boolean) as Route[];

  return (
    <MuiBreadcrumbs separator="›" {...breadcrumbsProps}>
      {filteredRoutes.map((item, index) =>
        item.path ? (
          <Link
            key={`${item.name}-${index}`}
            component={RouterLink}
            to={`${item.path}`}
          >
            {item.name}
          </Link>
        ) : (
          <Typography key={`${item.name}-${index}`}>{item.name}</Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
