import * as React from "react";
import { Tooltip } from "@material-ui/core";

import useToast from "utils/hooks/useToast";
import { AutoGrid, Button } from "components";
import { useUsersListStatusUpdate } from "modules/users/provider";
import { IconInfo } from "assets/icons";

type UsersListSubActionsProps = {
  selectedUserIds: Array<string>;
  refetch: () => void;
  clearSelection: () => void;
};

const DirectUserListSubActions: React.FC<UsersListSubActionsProps> = ({
  selectedUserIds,
  refetch,
  clearSelection
}) => {
  const activateUsers = useUsersListStatusUpdate("activate");
  const deactivateUsers = useUsersListStatusUpdate("deactivate");
  const { showToast } = useToast();

  const [submitting, setSubmitting] = React.useState(false);

  const handleUsersStatusChange = (
    operationType: "activate" | "deactivate"
  ) => async () => {
    try {
      setSubmitting(true);
      await (operationType === "activate"
        ? activateUsers({ users: selectedUserIds })
        : deactivateUsers({ users: selectedUserIds }));
      clearSelection();
      refetch();
      showToast(
        `${selectedUserIds.length} direct user(s) were successfully ${operationType}d`,
        "success"
      );
    } catch (ex) {
      showToast(
        "Something went wrong, try again in a moment or report a problem",
        "error"
      );
    } finally {
      setSubmitting(false);
    }
  };

  const actionForbidden = selectedUserIds.length > 25;

  return (
    <AutoGrid showEmpty>
      {selectedUserIds.length > 0 && (
        <>
          <Button
            onClick={handleUsersStatusChange("deactivate")}
            color="hint"
            disabled={actionForbidden || submitting}
          >
            Deactivate users
          </Button>
          <Button
            onClick={handleUsersStatusChange("activate")}
            color="success"
            disabled={actionForbidden || submitting}
          >
            Activate users
          </Button>
        </>
      )}
      {actionForbidden && (
        <Tooltip
          title="You can't change status of more than 25 direct users at once."
          placement="bottom-start"
        >
          <IconInfo color="error" />
        </Tooltip>
      )}
    </AutoGrid>
  );
};

export default DirectUserListSubActions;
