import * as React from "react";

import useListFilters, { ListFiltersState } from "utils/hooks/useListFilters";
import { AutoGrid, Search, ListToolbar, SelectFilter } from "components";

import {
  statusSelectionOptions,
  typeSelectionOptions
} from "../problems.constants";
import { useAssigneesList } from "../provider";
import { actionTypes, useModuleState } from "../reducers/problems";

type Props = {
  listFilters: ListFiltersState;
};

const ProblemsListToolbar: React.FC<Props> = ({ listFilters }) => {
  const [{ assigneesFilters }, dispatch] = useModuleState();
  const assigneeslistFilters = useListFilters(assigneesFilters);
  const assigneesList = useAssigneesList({
    variables: { listFilters: assigneeslistFilters }
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_ASSIGNEES_FILTERS,
      payload: assigneeslistFilters
    });
  }, [dispatch, assigneeslistFilters]);

  const assigneesOptions = React.useMemo(
    () =>
      assigneesList.data
        ? assigneesList.data.map(assignee => ({
            value: assignee.id,
            label: `${assignee.firstName} ${assignee.lastName}`
          }))
        : [],
    [assigneesList.data]
  );

  const left = (
    <AutoGrid spacing={2}>
      <Search listFilters={listFilters} />
      <SelectFilter
        label="Status"
        listFilters={listFilters}
        name="status"
        options={statusSelectionOptions}
      />
      <SelectFilter
        label="Type"
        listFilters={listFilters}
        name="type"
        options={typeSelectionOptions}
      />
      <SelectFilter
        label="Assignee"
        listFilters={listFilters}
        name="assignee"
        options={assigneesOptions}
      />
    </AutoGrid>
  );

  return <ListToolbar left={left} />;
};

export default ProblemsListToolbar;
