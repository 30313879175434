import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

import { CustomTheme } from "config/theme";

const useStyles = makeStyles(({ palette, typography }: CustomTheme) => ({
  index: {
    flexGrow: 0.2,
    flexShrink: 0.5,
    color: palette.primary.main,
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeightRegular
  }
}));

type Props = {
  index: number;
};

const ChipIndex: React.FC<Props> = ({ index }) => {
  const classes = useStyles();
  return <Typography className={classes.index}>{index + 1}</Typography>;
};

export default ChipIndex;
