import React, { ReactElement } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Chip } from "@material-ui/core";
import clsx from "clsx";

import useTableStyles from "components/data-table/data-table.styles";
import { TooltipList } from "components";
import { pluralize } from "utils/strings";
import { statusVariants } from "components/chip/course-status-chip";

import { CourseStatus } from "../../../courses.constants";

import useStyles from "./course-chip.styles";

type Props = {
  label: string | string[] | ReactElement;
  avatar?: ReactElement;
  deleteIcon?: boolean;
  onDelete?: () => void;
  empty?: boolean;
  status?: CourseStatus;
};

const CourseChip: React.FC<Props> = ({
  label,
  avatar,
  deleteIcon,
  onDelete,
  empty,
  status
}) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const statusVariant = status ? statusVariants[status] : undefined;

  let labelElem: React.ReactElement | string;

  if (Array.isArray(label)) {
    labelElem = (
      <>
        <span className={tableClasses.ellipsis}>{label[0]}</span>
        {label.length > 1 ? (
          <div className={classes.group}>
            <span className={tableClasses.ellipsis}>{label[1]}</span>
            {label.length > 2 && (
              <TooltipList
                title={`${label.length - 1} ${pluralize(
                  "group",
                  label.length - 1
                )}`}
                list={label.slice(1)}
              />
            )}
          </div>
        ) : (
          "-"
        )}
      </>
    );
  } else {
    labelElem = label;
  }
  if (statusVariant) {
    labelElem = (
      <>
        {label}
        <div className={clsx(classes.status, classes[statusVariant.variant])}>
          {statusVariant.icon}
          <span
            className={clsx(classes.statusText, classes[statusVariant.variant])}
          >
            {statusVariant.defaultLabel}
          </span>
        </div>
      </>
    );
  }

  return (
    <Chip
      classes={{
        root: classes.chip,
        label: clsx(classes.label, tableClasses.ellipsis),
        deletable: classes.deletable,
        deleteIcon: classes.icon
      }}
      className={clsx(empty && classes.emptyChip)}
      avatar={avatar}
      label={labelElem}
      deleteIcon={deleteIcon ? <CloseIcon /> : undefined}
      onDelete={onDelete}
    />
  );
};

export default CourseChip;
