import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, typography, breakpoints, shadows }) => ({
  root: {
    padding: spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 220,
    minWidth: 200,
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    boxShadow: shadows[4],

    [breakpoints.up("sm")]: {
      minHeight: 270,
      padding: spacing(4, 5)
    },

    "&:hover": {
      transform: "scale(1.05)"
    }
  },
  contentContainer: {
    flexGrow: 1,
    marginBottom: spacing(2)
  },
  header: {
    display: "flex",
    flexWrap: "wrap"
  },
  name: {
    color: typography.body1.color,
    minWidth: "fit-content",
    marginRight: spacing(2),
    fontSize: "1.125rem",

    [breakpoints.up("sm")]: {
      fontSize: "1.5rem"
    }
  },
  status: {
    height: 32
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing(1),
    fontSize: "0.875rem"
  },
  icon: {
    fontSize: "1rem",
    marginRight: spacing(1)
  },
  courseId: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: typography.body2.color,
    fontSize: "1.125rem",

    [breakpoints.up("sm")]: {
      fontSize: "1.5rem"
    }
  }
}));
