import { makeStyles } from "@material-ui/styles";
import "react-quill/dist/quill.snow.css"; // @TODO: Would be better lazy load it?

export default makeStyles(({ palette }) => ({
  quill: {
    "& > div.ql-container": {
      height: 300,
      fontSize: "1rem",
      color: palette.text.default,
      borderBottom: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderLeft: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderRight: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderTop: "none !important",
      borderRadius: "0px 0px 5px 5px"
    },
    "& > div.ql-toolbar": {
      backgroundColor: palette.hint.light,
      borderTop: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderLeft: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderRight: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderBottom: "none !important",
      borderRadius: "5px 5px 0px 0px"
    }
  }
}));
