import * as React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

import Placeholder from "./placeholder";

type Props = {
  message?: React.ReactNode;
  inline?: boolean;
};

const LoadingPlaceholder: React.FC<Props> = ({
  message = "Loading...",
  inline = false
}) => (
  <Placeholder
    image={<CircularProgress size={inline ? 15 : 40} />}
    message={<Typography color="primary">{message}</Typography>}
    inline={inline}
  />
);

export default LoadingPlaceholder;
