import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, shadows }) => ({
  root: {
    transition:
      "opacity 306ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 204ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, top 0.3s ease !important",
    boxShadow: shadows[3]
  },
  paperRoot: {
    padding: spacing(4)
  },
  title: {
    marginBottom: spacing(4)
  }
}));
