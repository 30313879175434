import * as React from "react";

import commonReducer, {
  initialState as commonInitialState
} from "modules/common/reducers/common";
import myCoursesReducer, {
  initialState as myCoursesInitialState
} from "modules/courses/reducers/my-courses";
import availableCoursesReducer, {
  initialState as availableCoursesInitialState
} from "modules/courses/reducers/available-courses";
import coursesReducer, {
  initialState as coursesInitialState
} from "modules/courses/reducers/courses";
import historyReducer, {
  initialState as historyInitialState
} from "modules/courses/reducers/history";
import submissionsReducer, {
  initialState as submissionsInitialState
} from "modules/courses/reducers/submissions";
import loadedCoursesReducer, {
  initialState as loadedInitialState
} from "modules/courses/reducers/loaded-courses";
import learningPlansReducer, {
  initialState as learningPlansInitialState
} from "modules/courses/reducers/learning-plans";
import myLearningPlansReducer, {
  initialState as myLearningPlansInitialState
} from "modules/courses/reducers/my-learning-plans";
import companiesReducer, {
  initialState as companiesInitialState
} from "modules/companies/reducers/companies";
import usersReducer, {
  initialState as usersInitialState
} from "modules/users/reducers/users";
import problemsReducer, {
  initialState as problemsInitialState
} from "modules/problems/reducers/problems";
import tenantsReducer, {
  initialState as tenantsInitialState
} from "modules/tenants/reducers/tenants";

const initialState = {
  common: commonInitialState,
  myCourses: myCoursesInitialState,
  availableCourses: availableCoursesInitialState,
  courses: coursesInitialState,
  history: historyInitialState,
  submissions: submissionsInitialState,
  loadedCourses: loadedInitialState,
  learningPlans: learningPlansInitialState,
  myLearningPlans: myLearningPlansInitialState,
  companies: companiesInitialState,
  tenants: tenantsInitialState,
  users: usersInitialState,
  problems: problemsInitialState
};

export type State = typeof initialState;

const mainReducer = (
  {
    common,
    myCourses,
    availableCourses,
    courses,
    history,
    submissions,
    loadedCourses,
    learningPlans,
    myLearningPlans,
    companies,
    tenants,
    users,
    problems
  }: State,
  action: any
) => ({
  common: commonReducer(common, action),
  myCourses: myCoursesReducer(myCourses, action),
  availableCourses: availableCoursesReducer(availableCourses, action),
  courses: coursesReducer(courses, action),
  history: historyReducer(history, action),
  submissions: submissionsReducer(submissions, action),
  loadedCourses: loadedCoursesReducer(loadedCourses, action),
  learningPlans: learningPlansReducer(learningPlans, action),
  myLearningPlans: myLearningPlansReducer(myLearningPlans, action),
  companies: companiesReducer(companies, action),
  tenants: tenantsReducer(tenants, action),
  users: usersReducer(users, action),
  problems: problemsReducer(problems, action)
});

export const StateContext = React.createContext(null as any);

type StateProviderProps = {
  children: React.ReactElement;
};

const StateProvider: React.FC<StateProviderProps> = ({ children }) => (
  <StateContext.Provider value={React.useReducer(mainReducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export default StateProvider;
