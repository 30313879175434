export const downloadBlob = (blob: Blob, filename: string) => {
  // IE download
  if (window.navigator?.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }
  const invisibleLink = window.document.createElement("a");
  invisibleLink.style.display = "none";
  invisibleLink.href = window.URL.createObjectURL(blob);
  invisibleLink.download = filename;
  document.body.appendChild(invisibleLink);
  invisibleLink.click();
  document.body.removeChild(invisibleLink);
};
