import * as React from "react";
import { AxiosError } from "axios";

import { LoadingPlaceholder, ErrorPlaceholder } from "components";

interface ResultStatus {
  loading: boolean;
  error?: AxiosError<any>;
  data?: any;
}

type Props<T extends ResultStatus> = {
  apiResult: T;
  reloadsSection?: boolean;
  children: (result: T) => React.ReactNode;
};

const ApiResultRenderer = <T extends ResultStatus>({
  apiResult,
  children,
  reloadsSection = true
}: Props<T>) =>
  apiResult.error ? (
    <ErrorPlaceholder error={apiResult.error} />
  ) : reloadsSection ? (
    apiResult.loading ? (
      <LoadingPlaceholder />
    ) : !apiResult.data ? (
      <></>
    ) : (
      <>{children(apiResult)}</>
    )
  ) : (
    <>{children(apiResult)}</>
  );

export default ApiResultRenderer;
