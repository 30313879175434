import * as React from "react";
import { Form, FormSpy } from "react-final-form";
import { Box } from "@material-ui/core";
import { window } from "globalthis/implementation";

import { Button, AutoGrid } from "components";
import * as PythonApi from "services/api/python";
import { createValidator } from "utils/forms";
import { splitPhoneAndExtension } from "utils/strings";
import { UserItem, UserCreateUpdateVars } from "modules/users/provider/users";
import { UserRole, UserStatus } from "modules/users/users.constants";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";

import UserFormBody from "./user-form-body";

const validator = createValidator({
  firstName: { presence: { allowEmpty: false } },
  lastName: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false }, email: { message: "is invalid" } },
  username: {
    presence: { allowEmpty: false },
    format: {
      pattern: /\S*/,
      message: ` can't contain whitespaces`
    }
  },
  phone: (_: any, attributes: any) =>
    attributes.phone && {
      format: {
        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        message: "number is invalid"
      }
    },
  extension: (_: any, attributes: any) =>
    attributes.extension && {
      format: {
        pattern: /^\d+$/,
        message: "can contain only numbers"
      }
    },
  role: {
    presence: { allowEmpty: false }
  }
});

type UserRecordProps = {
  onSubmit: (user: UserCreateUpdateVars) => void;
  onCloseDrawer: () => void;
  user?: UserItem;
  setIsModalEnabled: (isEnabled: boolean) => void;
};

const UserRecord: React.FC<UserRecordProps> = ({
  onSubmit,
  onCloseDrawer,
  user,
  setIsModalEnabled
}) => {
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();
  const userId = React.useMemo(() => {
    if (user) {
      return user.id;
    }
    return null;
  }, [user]);

  const initialValues = React.useMemo(() => {
    if (user) {
      const phoneAndExtension = splitPhoneAndExtension(user.phone);
      return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        isAccepted: user.status === UserStatus.ACTIVE,
        groupId: user.groups && user.groups[0] ? user.groups[0].id : "",
        groupIds:
          user.groups && user.groups.length > 0
            ? user.groups.map(({ id }) => id)
            : [],
        workAddress: user.workAddress ? user.workAddress : "",
        phone: phoneAndExtension.phone,
        extension: phoneAndExtension.extension,
        username: user.username
      };
    } else {
      return {
        email: "",
        firstName: "",
        lastName: "",
        role: UserRole.STUDENT,
        isAccepted: false,
        groupId: "",
        groupIds: [],
        workAddress: "",
        phone: "",
        extension: "",
        username: ""
      };
    }
  }, [user]);

  const handleSubmit = React.useCallback(
    async values => {
      const {
        workAddress,
        groupId,
        groupIds,
        phone,
        extension,
        ...restValues
      } = values;

      try {
        await onSubmit({
          ...restValues,
          phone: phone && extension ? `${phone} x ${extension}` : phone || "",
          ...((restValues.role === UserRole.STUDENT ||
            restValues.role === UserRole.TENANT_ADMIN ||
            restValues.role === UserRole.INTERNAL_ADMIN) && {
            workAddress: workAddress || ""
          }),
          ...(restValues.role === UserRole.STUDENT && {
            groups: [{ id: groupId }],
            groupId: groupId,
            groupsIds: [groupId]
          }),
          ...((restValues.role === UserRole.TENANT_ADMIN ||
            restValues.role === UserRole.INTERNAL_ADMIN) && {
            groups: groupIds.map((id: string) => ({ id })),
            groupsIds: groupIds
          })
        } as UserCreateUpdateVars);
        setShouldRefetchIndicatorsToTrue();
      } catch (ex) {
        return PythonApi.getFormErrors(ex);
      }
      window.location.reload(); // this is a workaround for NPWT-199
    },
    [onSubmit, setShouldRefetchIndicatorsToTrue]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validator}
    >
      {({ handleSubmit, submitting, hasValidationErrors, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ pristine: true }}
            onChange={({ pristine }) => {
              setIsModalEnabled(!pristine);
            }}
          />
          <UserFormBody userId={userId} />
          <Box mt={8}>
            <AutoGrid justify="flex-end">
              <Button variant="text" color="error" onClick={onCloseDrawer}>
                Cancel
              </Button>

              <Button
                type="submit"
                color="success"
                disabled={submitting || hasValidationErrors || pristine}
              >
                {userId ? "Save" : "Add new user"}
              </Button>
            </AutoGrid>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default UserRecord;
