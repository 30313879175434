import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { useFetchMutation, useFetch, FetchOptions } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export type GroupCursesListFilters = {
  name?: string;
};

type GroupCursesVars = {
  id: string;
  listFilters?: ListFilters<GroupCursesListFilters>;
};

export type GroupCursesListItem = {
  id: string;
  createdAt: string;
  name: string;
  externalId: string;
  isPublished: boolean;
};

type CourseFromGroupDeleteVars = {
  groupId: string;
  courseId: string;
};

const createGroupCoursesListConfig = ({ id, listFilters }: GroupCursesVars) =>
  PythonApi.makeRequestForList<GroupCursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.assignedToGroup(id),
    params: {
      ...PythonApi.getParamsFromFilters(listFilters, {
        name: "contains"
      })
    }
  });

export const useGroupCoursesList = (options: FetchOptions<GroupCursesVars>) =>
  useFetch(createGroupCoursesListConfig(options.variables), options);

const createCourseFromGroupDeleteConfig = () =>
  PythonApi.makeMutation<CourseFromGroupDeleteVars>(
    ({ courseId, groupId }) => ({
      method: "DELETE",
      url: apiConfig.paths.authorized.course.unassignFromGroup(
        courseId,
        groupId
      )
    })
  );

export const useCourseFromGroupDelete = () =>
  useFetchMutation(createCourseFromGroupDeleteConfig());

export const useAssignCoursesToGroups = (courseId: string) => {
  type Vars = {
    groups: Array<string>;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.assignToGroup(courseId),
      data: variables
    }))
  );
};

export const useUnassignCoursesToGroups = (courseId: string) => {
  type Vars = {
    groups: Array<string>;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.unAssignToGroup(courseId),
      data: variables
    }))
  );
};

export const useAssignCourseToUsers = () => {
  type Vars = {
    course: Required<string>;
    users: Array<string>;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.assignToUser,
      data: variables
    }))
  );
};

export const useUnassignCourseToUsers = () => {
  type Vars = {
    course: Required<string>;
    users: Array<string>;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.unassignToUser,
      data: variables
    }))
  );
};
