import * as React from "react";
import { match } from "react-router";

import {
  Section,
  AppLayout,
  LoadingPlaceholder,
  ErrorPlaceholder
} from "components";
import { useMyCourseHistoryRead } from "modules/courses/provider/my-courses";

import { CourseInfoLearner } from "../components/course-info/learner";
import { CourseHeader } from "../components/course-info/common";

type Props = {
  match: match<{ courseId: string }>;
};

const CourseDetailsView: React.FC<Props> = ({ match }) => {
  const { courseId } = match.params;

  const { data: course, loading, error } = useMyCourseHistoryRead({
    variables: { courseId }
  });

  if (loading || error || !course) {
    return (
      <AppLayout viewTitle="Course Details">
        {loading ? <LoadingPlaceholder /> : <ErrorPlaceholder error={error} />}
      </AppLayout>
    );
  }

  return (
    <AppLayout viewTitle="Courses" hideSmUp>
      <CourseHeader course={course} />

      <Section>
        <CourseInfoLearner course={course} isHistoryView />
      </Section>
    </AppLayout>
  );
};

export default CourseDetailsView;
