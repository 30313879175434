import * as React from "react";
import { useParams } from "react-router";

import { Button, AutoGrid, Drawer, ClosingModal } from "components";
import useToast from "utils/hooks/useToast";
import { CompanyItem } from "modules/companies/provider/companies";
import { useCompanyUpdate } from "modules/companies/provider";
import { IconCreate } from "assets/icons";

import GroupForm from "../group-record";

type CompaniesListRowActionsProps = {
  group: CompanyItem;
  refetch: () => void;
};

const GroupListRowActions: React.FC<CompaniesListRowActionsProps> = ({
  group,
  refetch
}) => {
  const { companyId: groupId } = useParams();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const { showToast } = useToast();
  const updateGroup = useCompanyUpdate({ id: group.id });

  const handleEditSubmit = React.useCallback(
    async ({ name, externalId, adminsIds, voucherNumber }) => {
      await updateGroup({
        name,
        id: group.id,
        externalId,
        adminsIds: adminsIds ?? [],
        voucherNumber
      });
      setDrawerOpen(false);
      showToast("The group was successfully updated", "success");
      refetch();
    },
    [updateGroup, group.id, showToast, refetch]
  );

  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  return (
    <>
      {groupId === group.id ? (
        <Button
          style={{ marginBottom: "-7px" }}
          onClick={() => setDrawerOpen(true)}
          variant="text"
          color="secondary"
          icon={IconCreate}
        >
          Edit group
        </Button>
      ) : (
        <AutoGrid spacing={2} wrap="nowrap">
          <Button
            onClick={() => setDrawerOpen(true)}
            variant="text"
            icon={IconCreate}
          >
            Edit
          </Button>
        </AutoGrid>
      )}
      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Edit group"
        size="sm"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <GroupForm
          group={group}
          onCancel={() => setDrawerOpen(false)}
          onSubmit={handleEditSubmit}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );
};

export default GroupListRowActions;
