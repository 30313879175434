import { splitCamelcase } from "utils/strings";

export const ProblemTypes = {
  PasswordIssues: "Password issues",
  NotPostingCompletion: "Not posting completion",
  UnableToFindACourse: "Unable to find a course",
  UnableToLaunchACourse: "Unable to launch a course",
  Other: "Other"
};

export const ProblemTypesForUnauthorized = {
  PasswordIssues: "Password issues",
  Other: "Other"
};

export enum Problems {
  PASSWORD_ISSUES = "PasswordIssues",
  NOT_POSTING_COMPLETION = "NotPostingCompletion",
  UNABLE_TO_FIND_A_COURSE = "UnableToFindACourse",
  UNABLE_TO_LAUNCH_A_COURSE = "UnableToLaunchACourse",
  OTHER = "Other"
}

export enum ProblemStatuses {
  OPENED = "Opened",
  IN_PROGRESS = "InProgress",
  CLOSED = "Closed",
  CANCELED = "Canceled"
}

export const typeSelectionOptions = Object.entries(
  ProblemTypes
).map(([value, label]) => ({ value, label }));

export const statusSelectionOptions = Object.values(ProblemStatuses).map(
  status => ({
    label: splitCamelcase(status),
    value: status
  })
);
