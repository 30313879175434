import * as React from "react";
import clsx from "clsx";
import { Avatar, Typography } from "@material-ui/core";

import useStyles from "./sidebar-avatar.styles";

type Props = {
  expandedSidebar: boolean;
  text: string;
  imgUrl?: string;
  hideAvatar?: boolean;
};

const SidebarAvatar: React.FC<Props> = ({
  expandedSidebar,
  text,
  imgUrl,
  hideAvatar = false
}) => {
  const classes = useStyles();

  return (
    <header
      className={clsx(classes.companyData, hideAvatar && classes.hideAvatar)}
    >
      {(!hideAvatar || imgUrl) && (
        <Avatar
          src={imgUrl}
          alt="Company logo"
          className={clsx(
            classes.avatar,
            hideAvatar && classes.hideAvatar,
            !expandedSidebar && classes.narrowed
          )}
        />
      )}
      <Typography
        variant="h2"
        color="primary"
        className={clsx(
          classes.name,
          hideAvatar && classes.hideAvatar,
          !expandedSidebar && classes.narrowed
        )}
      >
        {text}
      </Typography>
    </header>
  );
};

export default SidebarAvatar;
