import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      height: 7
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      backgroundColor: "rgba(0,0,0,.35)"
    }
  }
}));
