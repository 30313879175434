import React from "react";
import ReactQuill from "react-quill"; //todo change package (this pkg do nothing on backspace second and next characters)

import useStyles from "./text-editor.styles.js";

const formats = ["size", "bold", "italic", "underline", "color", "bullet"];
const modules = {
  toolbar: [
    { size: ["normal", "large"] },
    "bold",
    "italic",
    "underline",
    { color: ["#FF496A", "#41465E"] }
  ]
};

type TextEditorProps = {
  placeholder?: string;
  value: string;
  onChange: (text: string) => void;
  onBlur: (e: any) => void;
};

const TextEditor: React.FC<TextEditorProps> = ({
  placeholder,
  value,
  onChange,
  onBlur
}) => {
  const classes = useStyles();

  return (
    <ReactQuill
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      className={classes.quill}
      theme="snow"
    />
  );
};
export default TextEditor;
