import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from "@material-ui/core";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<any, HTMLElement> & {
  label?: string;
};

const CheckboxControl: React.FC<Props> = ({
  input: { checked, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error, touched },
  label,
  ...restCheckbox
}) => {
  const showError = Boolean(
    touched && ((submitError && !dirtySinceLastSubmit) || error)
  );

  return (
    <FormControl error={showError} fullWidth={false}>
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...restCheckbox}
              name={name}
              inputProps={restInput}
              onChange={onChange}
              checked={checked}
            />
          }
        />
      </FormGroup>
      {showError && (
        <FormHelperText error>{error || submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxControl;
