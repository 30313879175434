import * as React from "react";
import { Form, Field } from "react-final-form";
import { Typography } from "@material-ui/core";

import {
  FormError,
  Section,
  Header,
  FileControl,
  CheckboxControl,
  CancelConfirmButtons,
  LoadingPlaceholder
} from "components";
import { IconAttachFile } from "assets/icons";
import { createValidator } from "utils/forms";
import * as PythonApi from "services/api/python";
import useToast from "utils/hooks/useToast";
import authClient from "services/auth";
import { useTenant } from "services/tenant-provider";
import { useUploadStatus } from "modules/common/hooks/useUploadStatus";
import { useUploadOptions } from "modules/common/hooks/useUploadOptions";
import { useRepeatStatusCheckUntilSuccess } from "modules/common/hooks/useRepeatStatusCheckUntilSuccess";

import {
  useUploadCourseFile,
  useCoursePublicationStatusUpdate
} from "../provider";
import { actionTypes, useModuleState } from "../reducers/loaded-courses";

import { CourseForEdition } from "./course-list/course-list";
import useStyles from "./course-edit-content.styles";

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  course: CourseForEdition;
};

type StatusPromise = (uploadId: string) => Promise<string>;

type LoadingState = "Processing..." | "Loading..." | "";

const validator = createValidator({
  file: { presence: true }
});

const EditCourseContent: React.FC<Props> = ({ onCancel, onSubmit, course }) => {
  const { courseId } = course;
  const tenant = useTenant();
  const classes = useStyles();
  const { showToast } = useToast();
  const getUploadOptions = useUploadOptions();
  const uploadCourse = useUploadCourseFile();
  const checkCourseStatus = useUploadStatus();
  const waitForStatus = useRepeatStatusCheckUntilSuccess(checkCourseStatus);
  const publishCourse = useCoursePublicationStatusUpdate("publish");
  const [{ loadedCourses }, dispatch] = useModuleState();
  const isUploading = loadedCourses?.hasOwnProperty(courseId);

  const [loadingMessage, setLoadingMessage] = React.useState<LoadingState>("");

  return (
    <Form
      onSubmit={async ({ file, shouldPublishCourse }) => {
        const payload = { courseId: courseId, fileName: file?.name || "" };

        try {
          await authClient.refreshTokens();
          if (file) {
            setLoadingMessage("Loading...");
            dispatch({
              type: actionTypes.ADD_LOADED_COURSE,
              payload: payload
            });
            const result = await getUploadOptions({});
            if (!result.data) {
              throw new Error("Retrieving upload options failed");
            }
            const { sasToken, uploadId, storageUrl } = result.data;
            await uploadCourse({
              sasToken,
              uploadId,
              storageUrl,
              tenantId: tenant.getHostname(),
              courseId,
              file
            });
            setLoadingMessage("Processing...");
            await waitForStatus(uploadId, 10000);
            setLoadingMessage("");
            dispatch({
              type: actionTypes.DELETE_LOADED_COURSE,
              payload: payload
            });
          }
        } catch (ex) {
          setLoadingMessage("");
          dispatch({
            type: actionTypes.DELETE_LOADED_COURSE,
            payload: payload
          });
          return PythonApi.getFormErrors(ex);
        }

        if (shouldPublishCourse) {
          try {
            await publishCourse({ courses: [courseId] });
          } catch (ex) {
            return PythonApi.getFormErrors(ex);
          }
        }

        showToast("The course content was successfully updated", "success");
        onSubmit();
      }}
      validate={validator}
      initialValues={
        {
          file: undefined,
          shouldPublishCourse: false
        } as { file?: File; shouldPublishCourse: boolean }
      }
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        hasValidationErrors,
        pristine
      }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <FormError>{submitError}</FormError>}
          <Section margin="dense">
            <Header
              variant="formSection"
              number="1"
              title="Enter course file"
            />

            <Field
              name="file"
              margin="normal"
              component={FileControl}
              acceptTypes={[
                "application/zip",
                "application/x-zip-compressed",
                "multipart/x-zip",
                "application/x-compressed"
              ]}
              acceptTypeText="zip"
              maxSize={1.5 * 1000 * 1000 * 1000}
              isLoading={!!loadingMessage}
              loadingMessage={loadingMessage}
              disabled={isUploading}
            />
            {isUploading && !submitting && (
              <div className={classes.container}>
                <IconAttachFile color="secondary" />
                <Typography color="secondary" className={classes.text}>
                  {loadedCourses[courseId as keyof typeof loadedCourses]}
                </Typography>
                <LoadingPlaceholder inline message="Processing..." />
              </div>
            )}
          </Section>

          <Section margin="dense">
            <Header
              variant="formSection"
              number="2"
              title="Publish this course"
              hint="(optional)"
            />

            <Field
              name="shouldPublishCourse"
              color="primary"
              type="checkbox"
              label={<span className={classes.publishLabel}>Yes</span>}
              component={CheckboxControl}
            />
          </Section>

          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel="Save"
            onCancel={onCancel}
          />
        </form>
      )}
    </Form>
  );
};

export default EditCourseContent;
