import React, { useEffect } from "react";
import { match } from "react-router";

import { AppLayout, Header, Section, ApiResultRenderer } from "components";
import useListFilters from "utils/hooks/useListFilters";

import { actionTypes, useModuleState } from "../reducers/tenants";
import { usePrimeCoursesList } from "../provider/tenants";
import PrimeCourseList from "../components/prime-course-uploads/prime-course-list";
import PrimeCoursePagination from "../components/prime-course-uploads/prime-course-pagination";

export type Match = match<{ name: string; hostname: string }>;

type Props = {
  match: Match;
};

const TenantDetailsView: React.FC<Props> = ({ match }) => {
  const [{ primeCoursesFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters(primeCoursesFilters);

  const primeCourses = usePrimeCoursesList({
    variables: {
      hostname: match.params.hostname,
      listFilters
    }
  });

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_PRIME_COURSES_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.RESET_PRIME_COURSES_FILTERS });
    };
  }, [dispatch]);

  return (
    <AppLayout viewTitle={match.params.name}>
      <Section margin="normal">
        <ApiResultRenderer apiResult={primeCourses} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Prime course uploads (${meta?.total || 0})`}
              />
              <PrimeCourseList
                loading={loading}
                primeCourses={data}
                listFilters={listFilters}
              />
              <PrimeCoursePagination meta={meta} listFilters={listFilters} />
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default TenantDetailsView;
