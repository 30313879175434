import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch, useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { GroupItem } from "./courses";

export type NoAccessResp = {
  group: {
    id: string;
    name: string;
  };
  courses: {
    id: string;
    name: string;
  }[];
}[];

export type LearningPlanItem = {
  id: string;
  externalId: string;
  createdAt: string;
  name: string;
  groups: Array<GroupItem>;
  description: string;
  courses: {
    id: string;
    name: string;
  }[];
};

export type LearningPlanVars = {
  name: string;
  groups: string[];
  description: string;
  courses: string[];
};

export type LearningPlanListFilters = {
  groupId?: string[];
};

type LearningPlanListVars = {
  listFilters?: ListFilters<LearningPlanListFilters>;
  fields?: string;
};

type LearningPlanDetailsVars = {
  learningPlanId: string;
};

export const useLearningPlanList = (
  options: FetchOptions<LearningPlanListVars>
) => {
  const {
    variables: { listFilters }
  } = options;
  const groupId = listFilters?.filters?.groupId?.join(",");

  return useFetch(
    PythonApi.makeRequestForList<LearningPlanItem>({
      method: "GET",
      url: apiConfig.paths.authorized.learningPlan.list,
      params: {
        ...(groupId && { group__ids: groupId }),
        ...PythonApi.getParamsFromFilters(listFilters)
      }
    }),
    options
  );
};

export const useLearningPlanDetails = (
  options: FetchOptions<LearningPlanDetailsVars>
) => {
  const {
    variables: { learningPlanId }
  } = options;

  return useFetch(
    PythonApi.makeRequestForOne<LearningPlanItem>({
      method: "GET",
      url: apiConfig.paths.authorized.learningPlan.details(learningPlanId)
    }),
    options
  );
};

export const useLearningPlanCreate = () => {
  return useFetchMutation(
    PythonApi.makeMutation<LearningPlanVars, LearningPlanItem>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.learningPlan.create,
      data: variables
    }))
  );
};

export const useLearningPlanUpdate = (id: string) => {
  return useFetchMutation(
    PythonApi.makeMutation<LearningPlanVars>(variables => ({
      method: "PUT",
      url: apiConfig.paths.authorized.learningPlan.update(id),
      data: variables
    }))
  );
};

export const useLearningPlanDelete = () => {
  type Vars = {
    id: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ id }) => ({
      method: "DELETE",
      url: apiConfig.paths.authorized.learningPlan.delete(id)
    }))
  );
};
