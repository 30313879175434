const routes = {
  root: "/",
  auth: {
    login: "/login",
    register: "/register",
    forgotPassword: "/forgot-password",
    forgotPasswordSuccess: "/forgot-password-success",
    setNewPasswordSuccess: "/set-new-password-success",
    setNewPasswordExpired: "/set-new-password-expired",
    registrationSuccess: "/register-success",
    setNewPassword: "/set-new-password",
    reportProblem: "/problems/report"
  },
  myCourses: {
    list: "/my-courses",
    history: "/my-courses/history",
    available: "/available-courses",
    availableHistory: "/available-courses/history",
    singleCourse: "/my-courses/:courseId",
    learningPlans: {
      list: "/my-courses/my-learning-plan",
      details: "/my-courses/my-learning-plan/:learningPlanId",
      availableList: "/available-courses/my-learning-plan",
      availableDetails: "/available-courses/my-learning-plan/:learningPlanId"
    }
  },
  courses: {
    list: "/courses",
    approval: {
      list: "/courses/manual-submissions"
    }
  },
  learningPlans: {
    list: "/courses/learning-plan",
    details: "/courses/learning-plan/:learningPlanId"
  },
  users: {
    list: "/users",
    details: "/users/:userId"
  },
  companies: {
    list: "/companies",
    singleCompany: "/companies/:companyId"
  },
  problems: {
    list: "/problems",
    report: "/problems/report",
    userList: "/problems/user/:id",
    details: "/problems/:id"
  },
  tenant: {
    list: "/tenants"
  },
  reports: {
    list: "/reports"
  }
};

export const queries = {
  courses: {
    details: {
      uploadContent: "upload-content"
    }
  }
};

export default routes;
