import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing }: CustomTheme) => ({
  sm: {
    width: 450
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    margin: spacing(1, 0)
  },
  chip: {
    flex: 8
  },
  smChip: {
    maxWidth: 425
  }
}));
