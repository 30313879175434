import * as React from "react";

import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useListSelection from "utils/hooks/useListSelection";
import { UserItem as UserItemType } from "modules/users/provider/users";
import { AutoGrid } from "components";
import { CourseItem } from "modules/courses/provider/courses";

import UsersListToolbar from "./users-list-toolbar";
import UsersTable from "./users-list";
import UsersListPagination from "./users-list-pagination";
import UsersListSubActions from "./users-list-sub-actions";

type UsersListContainerProps = {
  users: UserItemType[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  companyId?: string;
  loading: boolean;
  course?: CourseItem;
};

const UsersListContainer: React.FC<UsersListContainerProps> = ({
  users,
  meta,
  listFilters,
  refetch,
  loading,
  course
}) => {
  const listSelection = useListSelection(users);
  const selectedUserIds = listSelection.actions.getSingleField("id");
  const clearSelection = () => listSelection.actions.unselectAll();
  return (
    <>
      <UsersListToolbar {...{ refetch, users, listFilters, course }} />
      <UsersTable
        {...{ users, loading, refetch, listSelection, listFilters }}
      />
      <AutoGrid justify="space-between" showEmpty>
        <UsersListSubActions
          {...{ refetch, selectedUserIds, clearSelection }}
        />
        <UsersListPagination {...{ meta, listFilters }} />
      </AutoGrid>
    </>
  );
};

export default UsersListContainer;
