import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import { Button, Header } from "components";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import useStyles from "./course-learning-plans.styles";

export type Props = {
  learningPlanIndicator?: {
    id: string;
    name: string;
  }[];
  margin?: "small" | "normal";
};

const CourseLearningPlans: React.FC<Props> = ({
  learningPlanIndicator = [],
  margin = "normal"
}) => {
  const classes = useStyles();
  const tenant = useTenant();
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );
  return (
    <div
      className={clsx(
        classes.infoContainer,
        margin === "small" && classes.marginSmall
      )}
    >
      <Header variant="section" title="Learning plans" />
      {learningPlanIndicator.length > 0 ? (
        <div
          className={clsx(
            classes.linksContainer,
            margin === "small" && classes.containerMarginSmall
          )}
        >
          {learningPlanIndicator.map(lp => (
            <Button
              key={lp.id}
              variant="text"
              target={isDetailsNewTab ? "_blank" : "_self"}
              component={RouterLink}
              classes={{
                root: classes.button,
                label: classes.text
              }}
              to={
                isAllowedTenantAvailableCourses
                  ? `/available-courses/my-learning-plan/${lp.id}`
                  : `/my-courses/my-learning-plan/${lp.id}`
              }
            >
              {lp.name}
            </Button>
          ))}
        </div>
      ) : (
        <div
          className={clsx(margin === "small" && classes.containerMarginSmall)}
        >
          -
        </div>
      )}
    </div>
  );
};

export default CourseLearningPlans;
