/* eslint-disable import/namespace */
/* eslint-disable import/named */
import React, { useMemo, useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { FormControlProps } from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { FieldRenderProps } from "react-final-form";
import Select from "react-select";
import { StylesConfig } from "react-select/src/styles";
import { ValueType } from "react-select/src/types";
import { SelectComponents } from "react-select/src/components";

import config from "config";

export type OptionType = { value: string; label: string };

type Props = FieldRenderProps<string[] | string, HTMLElement> & {
  label?: string;
  formControlProps?: FormControlProps;
  allowMultiple?: boolean;
  options?: OptionType[];
  isLoading?: boolean;
  noOptionsMessage?: string;
  components?: Partial<SelectComponents<OptionType>>;
  customProps?: any;
};

const SelectControl: React.FC<Props> = ({
  label,
  allowMultiple = false,
  options,
  input: { value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error, touched },
  isLoading = false,
  noOptionsMessage = "No options",
  components,
  customProps
}) => {
  const { palette, spacing, shape } = useTheme();

  //we must store previously picked options, otherwise they will be not displayed inside the select component
  const [storedPickedOptions, storedPetPickedOptions] = useState<OptionType[]>(
    []
  );

  useEffect(() => {
    storedPetPickedOptions(prevVal =>
      [...prevVal, ...selectedValues].reduce((prev, current) => {
        if (!current) {
          return prev;
        }
        const hasElement = prev.find(el => el.value === current.value);

        if (!hasElement) {
          prev.push(current);
        }

        return prev;
      }, [] as OptionType[])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (selected: ValueType<OptionType>) => {
    if (Array.isArray(selected)) {
      onChange(selected.map(s => s.value));
    } else {
      onChange(selected ? (selected as OptionType).value : undefined);
    }
  };

  const mergedOptionsWithPickedOptions = useMemo(
    () =>
      [...(options || []), ...storedPickedOptions].filter(
        (opt, index, arr) => arr.findIndex(t => t.value === opt.value) === index
      ),
    [options, storedPickedOptions]
  );

  const selectedValues = (typeof value === "string" ? [value] : value ?? [])
    .map(v => mergedOptionsWithPickedOptions!.find(o => o.value === v))
    .slice(0, config.components.SelectControl.limitOfShownFields) as Array<
    OptionType
  >;

  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  const styles: StylesConfig = {
    container: styles => ({
      ...styles,
      marginTop: spacing(2)
    }),
    control: styles => ({
      ...styles,
      minHeight: spacing(7),
      padding: spacing(0.5),
      boxShadow: "none",
      borderRadius: shape.borderRadius,
      borderColor: showError ? palette.error.main : "rgba(0, 0, 0, 0.23)",
      ":hover": {
        borderColor: showError ? palette.error.main : palette.text.primary
      },
      ":focus-within": {
        borderColor: showError ? palette.error.main : palette.text.primary
      }
    }),
    clearIndicator: styles => ({
      ...styles,
      color: showError ? palette.error.main : palette.hint.main,
      ":hover": {
        color: showError ? palette.error.main : palette.hint.dark
      }
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: showError ? palette.error.main : palette.hint.main,
      ":hover": {
        color: showError ? palette.error.main : palette.hint.dark
      }
    }),
    placeholder: styles => ({
      ...styles,
      color: showError ? palette.error.main : palette.text.hint
    }),
    singleValue: styles => ({
      ...styles,
      color: palette.text.primary
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: palette.text.default,
      backgroundColor: isFocused && palette.hint.light,
      ":active": {
        backgroundColor: palette.primary.light
      }
    }),
    menu: styles => ({
      ...styles,
      marginTop: spacing(0),
      zIndex: 2
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: palette.primary.light,
      margin: spacing(0.5),
      fontWeight: "bold"
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: palette.primary.main,
      padding: spacing(0),
      margin: spacing(1, 0, 1, 1)
    }),
    multiValueRemove: styles => ({
      ...styles,
      backgroundColor: palette.primary.light,
      color: palette.primary.main,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      ":hover": {
        backgroundColor: palette.primary.light,
        color: palette.primary.main
      }
    })
  };

  return (
    <FormControl error={showError}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Select
        id={label}
        isMulti={allowMultiple}
        name={name}
        placeholder={label}
        value={selectedValues}
        isClearable
        options={mergedOptionsWithPickedOptions}
        onChange={handleChange}
        closeMenuOnSelect={!allowMultiple}
        isLoading={isLoading}
        styles={styles}
        noOptionsMessage={() => noOptionsMessage}
        components={components}
        classNamePrefix="react-select"
        {...restInput}
        {...customProps}
      />

      {showError && <FormHelperText>{error || submitError}</FormHelperText>}
    </FormControl>
  );
};

export default SelectControl;
