import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  thumbnailUrl: undefined,
  isSidebarExpanded: true,
  indicators: undefined,
  shouldRefetchIndicators: false
};

type State = typeof initialState;

export enum actionTypes {
  SET_THUMBNAIL = "setThumbnail",
  SET_SIDEBAR_EXPAND = "setSidebarExpand",
  SET_INDICATORS = "setIndicators",
  SET_SHOULD_REFETCH_INDICATORS = "setShouldRefetchIndicators"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_THUMBNAIL: {
      return {
        ...state,
        thumbnailUrl: action.payload
      };
    }
    case actionTypes.SET_SIDEBAR_EXPAND: {
      return {
        ...state,
        isSidebarExpanded: action.payload
      };
    }
    case actionTypes.SET_INDICATORS: {
      return {
        ...state,
        indicators: action.payload
      };
    }
    case actionTypes.SET_SHOULD_REFETCH_INDICATORS: {
      return {
        ...state,
        shouldRefetchIndicators: action.payload
      };
    }
    default:
      return state;
  }
};

export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.common, dispatch];
};

export default reducer;
