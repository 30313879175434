import { makeStyles } from "@material-ui/core";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    textAlign: "center",
    padding: spacing(3, 0),

    [breakpoints.up("sm")]: {
      padding: spacing(3)
    }
  },
  imageContainer: {
    marginBottom: spacing(5),

    [breakpoints.up("sm")]: {
      marginBottom: spacing(3)
    }
  },
  image: {
    fontSize: "10rem"
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5rem",

    "$success &": {
      color: palette.text.primary
    },

    "$error &": {
      color: palette.error.main
    }
  },
  message: {
    marginTop: spacing(3)
  },
  success: {},
  error: {}
}));
