import ReactGA from "react-ga";

export const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!, {
    gaOptions: {
        cookieFlags: 'Secure'
    }
});
};

export const logPageView = (page: string) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};
