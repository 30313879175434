import * as React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router";
import clsx from "clsx";

import { useAuth } from "services/auth-provider";
import {
  RoleChip,
  Button,
  ResponsiveTableWrapper,
  DataTable,
  CourseStatusChip,
  TooltipList,
  UserStatusChip
} from "components";
import { UserRole } from "modules/users/users.constants";
import { UserItem as UserItemType } from "modules/users/provider/users";
import useStyles from "components/data-table/data-table.styles";
import { pluralize } from "utils/strings";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import UsersTableRowActions from "./users-list-row-actions";
import UserActionsDropdown from "./user-actions-dropdown";

type Props = {
  users: UserItemType[];
  refetch: () => void;
  loading: boolean;
  listSelection: any;
  listFilters: ListFiltersState;
};

const UsersTable: React.FC<Props> = ({
  users,
  loading,
  refetch,
  listSelection,
  listFilters
}) => {
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });

  const { companyId, courseId } = useParams();
  const isGroupDetailsView = Boolean(companyId);
  const isCourseDetailsView = Boolean(courseId);
  const classes = useStyles();
  const tenant = useTenant();

  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  return (
    <ResponsiveTableWrapper>
      <DataTable
        emptyText="No users"
        data={users}
        loading={loading}
        listSelection={listSelection}
        listFilters={listFilters}
        sortable={!(isGroupDetailsView || isCourseDetailsView)}
        columns={[
          {
            key: "firstName",
            title: "First name",
            sortable: true,
            render: ({ id, firstName }) => (
              <Button
                component={RouterLink}
                target={isDetailsNewTab ? "_blank" : "_self"}
                to={`/users/${id}`}
                variant="text"
              >
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {firstName}
                </span>
              </Button>
            )
          },
          {
            key: "lastName",
            title: "Last name",
            sortable: true,
            render: ({ id, lastName }) => (
              <Button
                component={RouterLink}
                to={`/users/${id}`}
                variant="text"
                target={isDetailsNewTab ? "_blank" : "_self"}
              >
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {lastName}
                </span>
              </Button>
            )
          },
          {
            key: "username",
            title: "Username",
            sortable: true,
            render: ({ id, username }) => (
              <Button
                component={RouterLink}
                to={`/users/${id}`}
                variant="text"
                target={isDetailsNewTab ? "_blank" : "_self"}
              >
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {username}
                </span>
              </Button>
            )
          },
          {
            key: "userRole",
            title: "User Role",
            render: ({ role }) => <RoleChip role={role} />
          },
          {
            key: "createdAt",
            title: "Created at",
            sortable: true,
            skip: isCourseDetailsView || isGroupDetailsView,
            render: ({ createdAt }) => moment(createdAt).format("L")
          },
          {
            key: "courseStatus",
            title: "Course status",
            skip: !isCourseDetailsView,
            render: ({ courseStatus }) => (
              <CourseStatusChip status={courseStatus} />
            )
          },
          {
            key: "groups",
            title: "Company name",
            skip: !isAdmin || isGroupDetailsView,
            render: ({ groups }) =>
              groups && groups.length ? (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <span className={clsx(classes.ellipsis, classes.customText)}>
                    {groups[0].name}
                  </span>

                  {groups.length > 1 && (
                    <TooltipList
                      title={`${groups.length} ${pluralize(
                        "group",
                        groups.length
                      )}`}
                      list={groups.map(g => g.name)}
                    />
                  )}
                </div>
              ) : (
                "-"
              )
          },
          {
            key: "status",
            title: "Status",
            render: user =>
              isGroupDetailsView || isCourseDetailsView ? (
                <UserStatusChip status={user.status} />
              ) : (
                <UserActionsDropdown user={user} refetch={refetch} />
              )
          },
          {
            key: "email",
            title: "Email",
            skip: isCourseDetailsView,
            render: ({ email }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {email}
              </span>
            )
          },
          {
            key: "phone",
            title: "Phone",
            skip: isCourseDetailsView,
            render: ({ phone }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {phone || "-"}
              </span>
            )
          },
          {
            key: "actions",
            title: "Actions",
            width: "1%",
            skip: isCourseDetailsView,
            render: user => <UsersTableRowActions {...{ user, refetch }} />
          }
        ]}
      />
    </ResponsiveTableWrapper>
  );
};

export default UsersTable;
