import React from "react";
import { Typography } from "@material-ui/core";

import { Header } from "components";

import { LearningPlanItem } from "../../../provider/learning-plan";

type Props = {
  learningPlan: LearningPlanItem;
};

const LearningPlanInfo: React.FC<Props> = ({ learningPlan }) => {
  return (
    <>
      <Header variant="section" title="Description" />
      <Typography variant="body1" component="p">
        {learningPlan.description}
      </Typography>
    </>
  );
};

export default LearningPlanInfo;
