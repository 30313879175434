export enum CourseStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  UNSATISFACTORY = "unsatisfactory",
  MANUALLY_COMPLETED = "manually_completed",
  NOT_STARTED = "not_started"
}

export enum AdminCourseStatus {
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished"
}

export enum ManualCompletionTooltipMessages {
  MANUAL_COMPLETION_ALLOWED = "Submit manual course completion and wait for approval.",
  MANUAL_COMPLETION_FORBIDDEN = "This course can't be manually completed.",
  COURSE_NOT_IN_PROGRESS = "Course must be in progress in order to be manually completed.",
  CONTENT_ABSENT = "Course must have content in order to be manually completed."
}

export enum SubmissionStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  ACCEPTED = "accepted"
}

export const getColumnsWidthMap = (isHistoryView: boolean) => {
 return [
    { columnName: 'name', width: 240 },
    { columnName: 'externalId', width: 180 },
    { columnName: 'status', width: 180 },
    { columnName: isHistoryView ? "status_timestamp" : "statusTimestamp", width: 180 },
    { columnName: "duration", width: 180 },
    { columnName: "prerequisites", width: 180 }, 
    { columnName: "actions", width: 180 }, 
  ]
}

export const courseSortableMap = [
    { columnName: 'status', sortingEnabled: false },
    { columnName: 'statusTimestamp', sortingEnabled: false },
    { columnName: 'duration', sortingEnabled: false },
    { columnName: 'prerequisites', sortingEnabled: false },
    { columnName: 'actions', sortingEnabled: false },
  ];
 