import React from "react";
import { Redirect } from "react-router-dom";

import routes from "config/routes";
import storage from "config/storage";
import { useAuth } from "services/auth-provider";

export const useRedirect = () => {
  const auth = useAuth();

  const shouldRedirectTo = localStorage.getItem(storage.local.redirectTo);

  React.useEffect(() => {
    if (
      !shouldRedirectTo &&
      !auth.isAuthenticated &&
      !Object.values(routes.auth).includes(window.location.pathname)
    ) {
      localStorage.setItem(storage.local.redirectTo, window.location.pathname);
    }
  }, [auth.isAuthenticated, shouldRedirectTo]);

  return React.useCallback(() => {
    const destination = localStorage.getItem(storage.local.redirectTo);

    if (destination) {
      localStorage.removeItem(storage.local.redirectTo);

      return <Redirect to={destination} />;
    }
  }, []);
};
