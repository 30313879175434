import * as React from "react";

import useStyles from "./logo-title.styles";

type Props = {
  subTitle: string;
};

const LogoTitle: React.FC<Props> = ({ subTitle, children }) => {
  const classes = useStyles();

  return (
    <span className={classes.logoTitle}>
      <span style={{ fontWeight: 500 }}>AT&T </span>
      {subTitle}
      {children}
    </span>
  );
};

export default LogoTitle;
