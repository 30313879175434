import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";
import {
  ResponsiveTableWrapper,
  DataTable,
  Button,
  LoadingPlaceholder,
  Chip
} from "components";
import useTableStyles from "components/data-table/data-table.styles";
import ProblemStatusChip from "components/chip/problem-status-chip";
import { IconCheck, IconClose } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { pluralize } from "utils/strings";

import { ProblemsListItem } from "../provider/problems";
import { Problems, ProblemTypes } from "../problems.constants";

import useStyles from "./problems-list.styles";

type Props = {
  problems: ProblemsListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
  onCommentsClick: (ticketId: string) => void;
};

const UserProblemsList: React.FC<Props> = ({
  problems,
  listFilters,
  loading,
  onCommentsClick
}) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const tenant = useTenant();

  const isProblemsTableSortable = tenant.isComponentAllowed(
    AllowedComponents.ProblemsTableSortable
  );

  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  return (
    <>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <ResponsiveTableWrapper>
          <DataTable
            emptyText="No problem reports"
            data={problems}
            sortable={isProblemsTableSortable}
            listFilters={listFilters}
            columns={[
              {
                key: "createdAt",
                title: "Report date",
                sortable: true,
                render: ({ createdAt }) => moment(createdAt).format("L")
              },
              {
                key: "type",
                title: "Type",
                sortable: true,
                render: ({ type }) => ProblemTypes[type]
              },
              {
                key: "courseId",
                title: "Course id",
                sortable: false,
                render: ({
                  course,
                  courseExternalId,
                  type,
                  freeTextCourse
                }) => (
                  <>
                    {type === Problems.UNABLE_TO_FIND_A_COURSE ? (
                      freeTextCourse ? (
                        <span className={clsx(tableClasses.customText)}>
                          {freeTextCourse}
                        </span>
                      ) : (
                        "-"
                      )
                    ) : course ? (
                      <Button
                        variant="text"
                        target={isDetailsNewTab ? "_blank" : "_self"}
                        component={RouterLink}
                        to={`/courses/${course}`}
                      >
                        <span
                          className={clsx(
                            tableClasses.ellipsis,
                            tableClasses.customText
                          )}
                        >
                          {courseExternalId || course}
                        </span>
                      </Button>
                    ) : (
                      "-"
                    )}
                  </>
                )
              },
              {
                key: "status",
                title: "Status",
                sortable: true,
                render: ({ status }) => <ProblemStatusChip status={status} />
              },
              {
                key: "file",
                title: "Attached file",
                render: ({ fileUrl }) =>
                  Boolean(fileUrl) ? (
                    <IconCheck
                      className={classes.attachedFileIcon}
                      fontSize="small"
                    />
                  ) : (
                    <IconClose color="error" fontSize="small" />
                  )
              },
              {
                key: "comments",
                title: "Comments",
                render: ({ id, comments }) =>
                  comments?.count && comments?.count > 0 ? (
                    <Chip
                      label={`${comments?.count} ${pluralize(
                        "comment",
                        comments?.count
                      )}`}
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        onCommentsClick(id);
                      }}
                    />
                  ) : (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => onCommentsClick(id)}
                    >
                      Add comment
                    </Button>
                  )
              }
            ]}
          />
        </ResponsiveTableWrapper>
      )}
    </>
  );
};

export default UserProblemsList;
