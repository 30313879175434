import React from "react";
import { Hidden } from "@material-ui/core";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { Header, Search } from "components";
import { ListResultsMeta } from "services/api/shared";
import LayoutSelector, {
  LayoutType
} from "components/layout-selector/layout-selector";

import useStyles from "./my-learning-plan-toolbar.styles";

type Props = {
  listFilters: ListFiltersState;
  meta: ListResultsMeta;
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
};

const MyLearningPlanToolbar: React.FC<Props> = ({
  listFilters,
  meta,
  activeLayout,
  setActiveLayout
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.searchDiv}>
        <Search listFilters={listFilters} />
      </div>
      <Header
        variant="section"
        title={`Learning plans (${(meta && meta.total) || 0})`}
      >
        <div className={classes.searchHeader}>
          <Search listFilters={listFilters} />
        </div>
        <Hidden xsDown>
          <LayoutSelector
            activeLayout={activeLayout}
            setActiveLayout={setActiveLayout}
          />
        </Hidden>
      </Header>
    </>
  );
};

export default MyLearningPlanToolbar;
