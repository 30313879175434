import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, breakpoints }: CustomTheme) => ({
  infoContainer: {
    marginTop: spacing(4),
    marginBottom: spacing(6),
    [breakpoints.up("sm")]: {
      marginTop: spacing(10)
    }
  },
  marginSmall: {
    marginTop: 0,
    marginBottom: spacing(1)
  },
  text: {
    fontSize: "0.875rem",
    color: palette.primary.main,
    textDecoration: "underline",
    [breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  containerMarginSmall: {
    marginTop: spacing(-1)
  },
  linksContainer: {
    marginLeft: spacing(-1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  button: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    display: "block",
    [breakpoints.up("lg")]: {
      maxWidth: "50%"
    }
  }
}));
