import * as React from "react";
import { Divider } from "@material-ui/core";

import { useAuth } from "services/auth-provider";
import API from "services/api";
import { Button } from "components";
import storage from "config/storage";
import { IconPowerSettingsNew } from "assets/icons";

import useStyles from "./logout.styles";

const LogOut: React.FC<{}> = () => {
  const auth = useAuth();
  const classes = useStyles();

  return (
    <div>
      <Divider className={classes.divider} />
      <Button
        classes={{
          root: classes.root,
          icon: classes.icon
        }}
        icon={IconPowerSettingsNew}
        onClick={() => {
          auth.logout();
          localStorage.removeItem(storage.local.defaultAuthSAML);
          delete API.defaults.headers.Authorization;
        }}
      >
        Log out
      </Button>
    </div>
  );
};

export default LogOut;
