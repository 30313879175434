import * as React from "react";

import { CourseStatus } from "modules/courses/courses.constants";
import Button, { Props as ButtonProps } from "components/button/button";
import useToast from "utils/hooks/useToast";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { useStartMyCourse, useCourseToken } from "../provider/my-courses";
import { useCourseAssignByUserMutation } from "../provider/available-courses";

type LearnerActionProps = {
  currentStatus?: CourseStatus;
  url?: string;
  id: string;
  externalId: string;
  button?: boolean;
  assigned?: boolean;
  disabled?: boolean;
};

const LearnerActionByStatus: React.FC<LearnerActionProps> = ({
  currentStatus,
  url,
  id,
  externalId,
  button,
  assigned,
  disabled
}: LearnerActionProps) => {
  const startMyCourse = useStartMyCourse();
  const getCourseToken = useCourseToken();
  const { showToast } = useToast();
  const courseAssignByUser = useCourseAssignByUserMutation();
  const tenant = useTenant();
  const isStartFromAvailable = tenant.isComponentAllowed(
    AllowedComponents.StartFromAvailable
  );

  const goToCourse = React.useCallback(
    async e => {
      try {
        e.stopPropagation();

        const { data: token } = await getCourseToken({ courseId: id });
        if (token) {
          document.cookie = `course_${externalId}=${token}; path=/; secure`;
          window.open(url);
          await startMyCourse({
            id,
            url,
            status: currentStatus
          });
        }
        if (!assigned && isStartFromAvailable) {
          await courseAssignByUser({ courseId: id });
        }
      } catch (ex) {
        showToast("Sorry, something went wrong", "error");
      }
    },
    [
      assigned,
      courseAssignByUser,
      currentStatus,
      externalId,
      getCourseToken,
      id,
      isStartFromAvailable,
      showToast,
      startMyCourse,
      url
    ]
  );

  const {
    label,
    ...buttonProps
  }: {
    label?: string;
    color: ButtonProps["color"];
    variant: ButtonProps["variant"];
    defaultHoverColor: boolean;
  } = React.useMemo(() => {
    const buttonTextProps: {
      variant: ButtonProps["variant"];
      color: ButtonProps["color"];
      underline: boolean;
      defaultHoverColor: boolean;
      disabled: boolean;
    } = {
      variant: "text",
      color: disabled ? "hint" : "primary",
      underline: true,
      defaultHoverColor: true,
      disabled: !!disabled
    };
    switch (currentStatus) {
      case CourseStatus.MANUALLY_COMPLETED:
      case CourseStatus.COMPLETED:
        return {
          ...buttonTextProps,
          ...(button && {
            color: "success",
            variant: "outlined",
            underline: false
          }),
          label: "Take this course again"
        };
      case CourseStatus.NOT_STARTED:
        return {
          ...buttonTextProps,
          ...(button && {
            color: "secondary",
            variant: "outlined",
            underline: false
          }),
          label: "Start this course"
        };
      case CourseStatus.IN_PROGRESS:
        return {
          ...buttonTextProps,
          ...(button && {
            color: "secondary",
            variant: "outlined",
            underline: false
          }),
          label: "Resume this course"
        };
      case CourseStatus.UNSATISFACTORY:
        return {
          ...buttonTextProps,
          ...(button && {
            color: "secondary",
            variant: "outlined",
            underline: false
          }),
          label: "Take this course again"
        };
      default:
        return { ...buttonTextProps };
    }
  }, [button, currentStatus, disabled]);
  if (!currentStatus || !url) {
    return null;
  }
  return (
    <Button {...buttonProps} onClick={goToCourse}>
      {label}
    </Button>
  );
};

export default LearnerActionByStatus;
