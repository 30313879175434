import * as React from "react";
import { Badge } from "@material-ui/core";
import { useHistory } from "react-router";

import { useAuth } from "services/auth-provider";
import { AppLayout, Button } from "components";
import routes from "config/routes";
import useListFilters from "utils/hooks/useListFilters";

import ReportProblem from "../components/report-problem.component";
import { useProblemsList } from "../provider";

import useStyles from "./problems-report.styles";

const ProblemsReportView = () => {
  const { user } = useAuth();
  const { replace } = useHistory();
  const classes = useStyles();
  const listFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["created_at,DESC"],
    filters: { sender: user?.id }
  });
  const problemsList = useProblemsList({
    variables: { listFilters }
  });

  const handleNavigateToUserProblemsList = React.useCallback(() => {
    replace(routes.problems.userList.replace(":id", user!.id));
  }, [replace, user]);

  return (
    <AppLayout
      viewTitle="Report a problem"
      titleCta={
        <Button
          className={classes.yourReports}
          onClick={handleNavigateToUserProblemsList}
          color="secondary"
        >
          Your reported issues
          {problemsList.data?.length ? (
            <Badge
              className={classes.badge}
              badgeContent={problemsList.data?.length}
            >
              {null}
            </Badge>
          ) : null}
        </Button>
      }
    >
      <ReportProblem user={user!} />
    </AppLayout>
  );
};

export default ProblemsReportView;
