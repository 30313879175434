import * as React from "react";
import { useParams } from "react-router";

import { ListToolbar, AutoGrid, Search, SelectFilter } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import GroupTreeFilter from "modules/common/components/group-tree/group-tree-filter";
import { useAuth } from "services/auth-provider";

import {
  userStatusOptions,
  UserRole,
  userRoleOptions
} from "../../users.constants";

import useStyles from "./direct-user-list-toolbar.styles";

type UsersListToolbarProps = {
  listFilters: ListFiltersState;
};

const DirectUserListToolbar: React.FC<UsersListToolbarProps> = ({
  listFilters
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const { courseId, companyId } = useParams();

  const isCourseDetailsView = Boolean(courseId);
  const isCompanyDetailsView = Boolean(companyId);

  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });

  const leftOptions = (
    <AutoGrid spacing={2} className={classes.gridContainer}>
      <Search listFilters={listFilters} />
      {isAdmin && !isCourseDetailsView && !isCompanyDetailsView && (
        <GroupTreeFilter
          label="Company"
          listFilters={listFilters}
          name="groups"
          allowMultiple
        />
      )}
      {!isCourseDetailsView && !isCompanyDetailsView && (
        <SelectFilter
          label="Status"
          listFilters={listFilters}
          name="status"
          options={userStatusOptions}
        />
      )}
      {!isCourseDetailsView && !isCompanyDetailsView && (
        <SelectFilter
          label="User role"
          listFilters={listFilters}
          name="userRole"
          options={
            isAdmin
              ? userRoleOptions
              : userRoleOptions.filter(
                  role =>
                    role.value !== UserRole.ADMIN &&
                    role.value !== UserRole.DESIGNER
                )
          }
        />
      )}
    </AutoGrid>
  );

  return <ListToolbar left={leftOptions} />;
};

export default DirectUserListToolbar;
