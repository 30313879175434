import * as React from "react";
import clsx from "clsx";
import { Input, InputAdornment, IconButton } from "@material-ui/core";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { IconClose, IconSearch } from "assets/icons";

import useStyles from "./search.styles";

type Props = {
  listFilters: ListFiltersState;
  placeholder?: string;
};

const Search: React.FC<Props> = ({ listFilters, placeholder = "Search" }) => {
  const classes = useStyles();

  const initialValue = listFilters.search || "";
  const [text, setText] = React.useState(initialValue);

  React.useEffect(() => {
    setText(listFilters.search || "");
  }, [listFilters.search]);

  const handleSubmit = React.useCallback(
    (newValue: string | undefined) => {
      const trimmedNewValue = newValue?.trim();
      if (trimmedNewValue !== initialValue) {
        listFilters.setSearch(trimmedNewValue);
      }
    },
    [initialValue, listFilters]
  );

  const handleClear = () => {
    setText("");

    if (initialValue !== "") {
      handleSubmit(undefined);
    }
  };

  return (
    <div className={classes.search}>
      <Input
        type="text"
        value={text}
        placeholder={placeholder}
        classes={{
          root: classes.root,
          input: classes.input
        }}
        disableUnderline
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        onKeyPress={event => {
          if (event.key === "Enter") {
            handleSubmit(text);
          }
        }}
        startAdornment={
          <InputAdornment
            position="start"
            classes={{
              root: clsx(classes.inputAdornment, classes.inputAdornmentSearch)
            }}
          >
            <IconButton
              onClick={() => handleSubmit(text)}
              size="small"
              aria-label="search"
            >
              <IconSearch classes={{ root: classes.icon }} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            classes={{
              root: clsx(classes.inputAdornment, classes.inputAdornmentClear)
            }}
          >
            <IconButton onClick={handleClear} size="small" aria-label="reset">
              <IconClose classes={{ root: classes.icon }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};

export default Search;
