import * as React from "react";
import { useHistory } from "react-router";
import { Dialog } from "@material-ui/core";

import { AppLayout, Section, ApiResultRenderer, Button } from "components";
import useListFilters, { ListFiltersState } from "utils/hooks/useListFilters";
import { IconKeyboardArrowLeft } from "assets/icons";
import { useAuth } from "services/auth-provider";

import { useModuleState, actionTypes } from "../reducers/problems";
import { useProblemsList } from "../provider";
import UserProblemsList from "../components/user-problems-list";
import ProblemComments from "../components/problem-comments";
import { useAddCommentMutation } from "../provider/problems";

import useStyles from "./user-problems-list.styles";

type Props = {};

const UserProblemsListView: React.FC<Props> = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const [{ problemsFilters }, dispatch] = useModuleState();
  const { user } = useAuth();
  const listFilters = useListFilters({
    ...problemsFilters,
    filters: { sender: user?.id }
  });
  const problemsList = useProblemsList({
    variables: {
      listFilters
    }
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_PROBLEMS_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  const [commentsModalId, setCommentsModalId] = React.useState<string | null>();

  const createComment = useAddCommentMutation();

  const handleSubmitComment = React.useCallback(
    async (text, isInternal) => {
      try {
        await createComment({ ticket: commentsModalId!, text, isInternal });
        problemsList.refetch();
      } catch (e) {}
    },
    [commentsModalId, createComment, problemsList]
  );

  return (
    <AppLayout
      viewTitle="Your reported issues"
      titleCta={
        <Button
          icon={IconKeyboardArrowLeft}
          onClick={() => {
            goBack();
          }}
          color="secondary"
          variant="outlined"
        >
          Go back
        </Button>
      }
    >
      <Section>
        <ApiResultRenderer apiResult={problemsList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <UserProblemsList
                problems={data}
                meta={meta!}
                listFilters={listFilters! as ListFiltersState}
                refetch={problemsList.refetch}
                loading={loading}
                onCommentsClick={setCommentsModalId}
              />
              {commentsModalId ? (
                <Dialog
                  classes={{ paper: classes.dialogPaper }}
                  open={Boolean(commentsModalId)}
                  onClose={() => setCommentsModalId(null)}
                >
                  <ProblemComments
                    comments={
                      data?.find(o => o.id === commentsModalId)?.comments
                    }
                    onSubmit={handleSubmitComment}
                    onCancel={() => setCommentsModalId(null)}
                    canMarkAsInternal={false}
                    background="white"
                  />
                </Dialog>
              ) : null}
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default UserProblemsListView;
