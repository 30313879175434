import * as React from "react";
import clsx from "clsx";
import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";
import reactToString from "react-to-string";

import { AutoGrid } from "components";

import useStyles from "./card.styles";

export type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  avatar: React.ReactNode;
  colorVariant?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "hint"
    | "error";
  avatarPosition?: "left" | "right";
  button?: React.ReactNode;
  arrowButtons?: React.ReactNode;
  variant?: "transparent" | "filled";
  hasLogo?: boolean;
};

const Card: React.FC<Props> = ({
  title,
  subtitle,
  avatar,
  colorVariant = "default",
  avatarPosition = "left",
  button,
  arrowButtons,
  variant = "transparent",
  hasLogo = false
}) => {
  const classes = useStyles();

  return (
    <MuiCard
      className={clsx(classes.root, classes[variant], classes[colorVariant])}
    >
      <aside className={clsx(classes.avatarOrder, classes[avatarPosition])}>
        <div
          className={clsx(
            classes.avatar,
            classes.avatarContainer,
            classes[variant],
            classes[colorVariant],
            hasLogo && classes.withLogo
          )}
        >
          {avatar}
        </div>
      </aside>

      <CardContent classes={{ root: classes.rootCardContent }}>
        <CardHeader
          classes={{ root: classes.title }}
          title={title}
          titleTypographyProps={{
            title: reactToString(title),
            className: clsx(
              classes.title,
              classes[variant],
              classes[colorVariant]
            ),
            variant: "h2"
          }}
          subheader={subtitle}
          subheaderTypographyProps={{
            title: reactToString(subtitle),
            variant: "h4",
            className: clsx(
              classes.subtitle,
              classes[variant],
              classes[colorVariant]
            )
          }}
        />

        {(button || arrowButtons) && (
          <AutoGrid
            justify={button ? "space-between" : "flex-end"}
            className={classes.buttons}
          >
            {button}
            {arrowButtons}
          </AutoGrid>
        )}
      </CardContent>
    </MuiCard>
  );
};

export default Card;
