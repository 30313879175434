import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const ErrorImage = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 120 165" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3387 57.2653C9.82029 68.1306 0.423828 85.465 0.423828 104.994C0.423828 137.897 27.0971 164.57 60.0002 164.57C92.9033 164.57 119.577 137.897 119.577 104.994C119.577 85.0914 109.817 67.4682 94.8229 56.649V36.1645C94.8229 16.7008 79.0445 0.922363 59.5808 0.922363C40.1171 0.922363 24.3387 16.7008 24.3387 36.1645V57.2653ZM33.2254 51.7588C41.2752 47.7022 50.3709 45.4177 60.0002 45.4177C69.2965 45.4177 78.0956 47.5469 85.9362 51.3444V36.1645C85.9362 21.6088 74.1365 9.80908 59.5808 9.80908C45.0252 9.80908 33.2254 21.6088 33.2254 36.1645V51.7588Z"
      fill="#FFDCE3"
    />
    <circle cx="60.0013" cy="90.7264" r="13.8587" fill="#FF496A" />
    <rect
      x="52.4551"
      y="93.2656"
      width="15.0893"
      height="41.9118"
      rx="7.54467"
      fill="#FF496A"
    />
  </SvgIcon>
);

export default ErrorImage;
