import * as React from "react";
import clsx from "clsx";
import { Menu, MenuItem } from "@material-ui/core";

import * as PythonApi from "services/api/python";
import { Button } from "components";
import useToast from "utils/hooks/useToast";
import { IconExpandMore } from "assets/icons";

import {
  CompanyItem,
  CompanyStatus,
  useCompanyStatusUpdate
} from "../../../provider/companies";

import useStyles from "./companies-list-status-dropdown.styles";

type Props = {
  company: CompanyItem;
  refetch: () => void;
};

const CompaniesListStatusDropdown: React.FC<Props> = ({ company, refetch }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const activateCompany = useCompanyStatusUpdate("activate");
  const deactivateCompany = useCompanyStatusUpdate("deactivate");

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleCompanyStatusChange = (action: CompanyStatus) => async () => {
    try {
      setIsSubmitting(true);
      await (action === CompanyStatus.ACTIVE
        ? activateCompany({ id: company.id })
        : deactivateCompany({ id: company.id }));
      showToast(
        `The company was successfully ${
          action === CompanyStatus.ACTIVE ? "activated" : "deactivated"
        }`,
        "success"
      );
      refetch();
    } catch (ex) {
      const errorMessages = PythonApi.getMessageFromError(ex);
      showToast(Object.values(errorMessages).join(" "), "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState();
  const openDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { status } = company;
  const statusClass =
    status === CompanyStatus.ACTIVE ? "active" : "deactivated";

  return (
    <>
      <Button
        variant="outlined"
        icon={IconExpandMore}
        iconPosition="right"
        classes={{
          root: clsx(classes.statusButton, classes[statusClass]),
          icon: clsx(classes.expandMoreIcon, classes[statusClass])
        }}
        onClick={openDropdown}
      >
        {status}
      </Button>

      <Menu
        anchorEl={anchorEl}
        classes={{
          paper: classes.dropdown,
          list: classes.dropdownList
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div
          className={clsx(
            classes.dropdownActionContainer,
            status === "Deactivated" &&
              classes.twoElementsDropdownActionContainer
          )}
        >
          {status === CompanyStatus.ACTIVE ? (
            <MenuItem
              onClick={handleCompanyStatusChange(CompanyStatus.DEACTIVATED)}
              disabled={isSubmitting}
              classes={{
                root: clsx(classes.dropdownButton, classes.deactivated)
              }}
            >
              Deactivate
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleCompanyStatusChange(CompanyStatus.ACTIVE)}
              disabled={isSubmitting}
              classes={{
                root: clsx(classes.dropdownButton, classes.active)
              }}
            >
              Activate
            </MenuItem>
          )}
        </div>
      </Menu>
    </>
  );
};

export default CompaniesListStatusDropdown;
