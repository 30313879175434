import * as React from "react";
import {
  Box,
  Grid,
  Hidden,
  Link,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { History } from "history";

import routes from "config/routes";
import { createValidator } from "utils/forms";
import PasswordPolicyManager, {
  Digits,
  LowerAlpha,
  MinLength,
  MinValidCharGroups,
  Special,
  UpperAlpha
} from "utils/validators/password-policy";
import {
  Button,
  FormError,
  Header,
  PasswordChecker,
  Section,
  SelectControl,
  TextControl
} from "components";
import GuestLayout, {
  BottomPanel,
  Content,
  SidePanel
} from "components/layouts/guest-layout/guest-layout";
import useListFilters from "utils/hooks/useListFilters";
import * as PythonApi from "services/api/python";
import { useCompaniesListPublic } from "modules/companies/provider";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";
import { IconInfo } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import ButtonContainer from "../components/button-container.component";
import { useRegisterMutation } from "../guest.provider";

import useStyles from "./guest.styles";

const validator = (voucherRequired: boolean) => {
  const voucherValidation = voucherRequired
    ? {
        voucherNumber: {
          presence: { allowEmpty: false, message: "^Voucher can't be blank" }
        },
        companyName: {
          presence: {
            allowEmpty: false,
            message: "^Company name can't be blank"
          }
        }
      }
    : {
        company: {
          presence: { allowEmpty: false, message: "^Company can't be blank" }
        }
      };

  return createValidator({
    username: {
      presence: { allowEmpty: false },
      format: {
        pattern: /\S*/,
        message: ` can't contain whitespaces`
      }
    },
    firstName: { presence: { allowEmpty: false }, length: { minimum: 2 } },
    lastName: { presence: { allowEmpty: false }, length: { minimum: 2 } },
    email: {
      presence: { allowEmpty: false },
      email: { message: "is invalid" }
    },
    phone: (_: any, attributes: any) =>
      attributes.phone && {
        format: {
          pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          message: "number is invalid"
        }
      },
    extension: (_: any, attributes: any) =>
      attributes.extension && {
        format: {
          pattern: /^\d+$/,
          message: "can contain only numbers"
        }
      },
    ...voucherValidation,
    password: { presence: { allowEmpty: false } },
    repeatPassword: {
      presence: { allowEmpty: false },
      equality: { attribute: "password" }
    }
  });
};

const policyManager = new PasswordPolicyManager([
  new MinLength(16),
  new MinValidCharGroups(4, [
    new LowerAlpha(1),
    new UpperAlpha(1),
    new Digits(1),
    new Special(1)
  ])
]);

type Props = {
  history: History;
};

const RegistrationView: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  const register = useRegisterMutation();
  const tenant = useTenant();

  const voucherRequired = tenant.isComponentAllowed(
    AllowedComponents.VoucherRequired
  );

  const [selectedGroupsId, setSelectedGroupsId] = React.useState([""]);

  const [isFetched, setIsFetched] = React.useState(false);
  const groupsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    orderBy: ["name,ASC"]
  });

  const groups = useCompaniesListPublic({
    variables: {
      listFilters: groupsListFilters
    },
    skip: isFetched || voucherRequired
  });

  const groupOptions = React.useMemo(() => {
    if (groups.data) {
      setIsFetched(true);
      return groups.data.map(({ id, name, parent }) => ({
        label: name,
        value: id,
        parent
      }));
    }
    return [];
  }, [groups.data]);

  const workAddressOptions = React.useMemo(() => {
    if (groups.data) {
      return groups.data
        .filter(
          ({ id, companyinfo }) => companyinfo && selectedGroupsId.includes(id)
        )
        .flatMap(({ companyinfo }) =>
          companyinfo!.addresses.map(address => ({
            label: address,
            value: address
          }))
        );
    }
    return [];
  }, [groups.data, selectedGroupsId]);

  const contactUsText = (
    <Typography>
      If you have problems with finding your company, please{" "}
      <Link href="mailto:betdistr@att.com">contact us</Link>
    </Typography>
  );

  return (
    <GuestLayout>
      <SidePanel variant="login" />

      <Content title="Register" form className={classes.twoColumnsForm}>
        <Form
          onSubmit={async values => {
            try {
              await register({
                email: values.email,
                username: values.username,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                groupId: Boolean(values.company) ? values.company : undefined,
                companyName: Boolean(values.companyName)
                  ? values.companyName
                  : undefined,
                voucherNumber: Boolean(values.voucherNumber)
                  ? values.voucherNumber
                  : undefined,
                workAddress: values.workAddress,
                phone: values.extension
                  ? `${values.phone} x ${values.extension}`
                  : values.phone
              });
              history.push(routes.auth.registrationSuccess);
            } catch (ex) {
              return PythonApi.getFormErrors(ex);
            }
          }}
          validate={values => {
            const errors = validator(voucherRequired)(values);

            const password = values.password || "";
            if (!policyManager.isValid(password)) {
              errors.password = policyManager.getMessage();
            }

            if (/\s/.test(password)) {
              errors.password = policyManager.getWhiteSpaceMessage();
            }

            return errors;
          }}
          initialValues={{
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            workAddress: "",
            phone: "",
            extension: "",
            company: "",
            companyName: "",
            voucherNumber: "",
            password: "",
            repeatPassword: ""
          }}
        >
          {({
            handleSubmit,
            submitting,
            submitError,
            pristine,
            hasValidationErrors
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}
              <Section margin="dense">
                <Header
                  variant="formSection"
                  number={1}
                  title="Enter your details"
                />
                <Grid container spacing={3}>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      name="firstName"
                      label="First name (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      name="lastName"
                      label="Last name (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      name="email"
                      label="E-mail (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                  <Grid item sm={6} style={{ flexGrow: 1, display: "flex" }}>
                    <Field
                      name="username"
                      label="Username (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                    <div className={classes.infoTooltip}>
                      <Tooltip
                        enterTouchDelay={0}
                        title="Please remember that username cannot be changed later."
                        placement="bottom-start"
                      >
                        <IconInfo />
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      name="phone"
                      label="Phone number (optional)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      name="extension"
                      label="Extension (optional)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                      validate={(extension, allValues) => {
                        if (extension && !(allValues as any).phone)
                          return "Extension requires phone number entered";
                      }}
                    />
                  </Grid>
                </Grid>
              </Section>

              {voucherRequired ? (
                <Section margin="dense">
                  <Header
                    variant="formSection"
                    number={2}
                    title="Provide voucher and company name"
                  />
                  <Grid container spacing={3}>
                    <Grid item sm={6} style={{ flexGrow: 1, width: "100%" }}>
                      <Field
                        name="voucherNumber"
                        label="Voucher"
                        variant="outlined"
                        margin="normal"
                        component={TextControl}
                        validationIndicator
                      />
                    </Grid>
                    <Grid item sm={6} style={{ flexGrow: 1, width: "100%" }}>
                      <Field
                        name="companyName"
                        label="Company name"
                        variant="outlined"
                        margin="normal"
                        component={TextControl}
                        validationIndicator
                      />
                    </Grid>
                  </Grid>
                </Section>
              ) : (
                <Section margin="dense">
                  <Header
                    variant="formSection"
                    number={2}
                    title="Choose your company"
                  />
                  <Grid container spacing={3}>
                    <Grid item sm={6} style={{ flexGrow: 1, width: "100%" }}>
                      <Field
                        name="company"
                        label="Company (required)"
                        component={GroupTreeControl}
                        options={groupOptions}
                        listFilters={groupsListFilters}
                        isLoading={groups.loading}
                      />
                      <Field
                        name="company"
                        subscription={{ value: true }}
                        render={({ input: { value } }) => {
                          setSelectedGroupsId(value);
                          return null;
                        }}
                      />
                      <Hidden smUp>
                        <Box mt={3}>{contactUsText}</Box>
                      </Hidden>
                    </Grid>
                    <Grid item sm={6} style={{ flexGrow: 1, width: "100%" }}>
                      <Box mb={3}>
                        <Field
                          name="workAddress"
                          label="Work address (optional)"
                          component={SelectControl}
                          isLoading={groups.loading}
                          options={workAddressOptions}
                        />
                        <input style={{ display: "none" }} />
                      </Box>
                    </Grid>
                  </Grid>

                  <Hidden xsDown>{contactUsText}</Hidden>
                </Section>
              )}

              <Section margin="dense">
                <Header
                  variant="formSection"
                  number={3}
                  title="Set your password"
                />
                <PasswordChecker policyManager={policyManager} flat />
                <Grid container spacing={3}>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      type="password"
                      name="password"
                      label="Password (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                  <Grid item sm={6} style={{ flexGrow: 1 }}>
                    <Field
                      type="password"
                      name="repeatPassword"
                      label="Repeat password (required)"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                    />
                  </Grid>
                </Grid>
              </Section>

              <ButtonContainer>
                <Button
                  type="submit"
                  color="success"
                  size="large"
                  disabled={submitting || hasValidationErrors || pristine}
                >
                  Register
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Form>
      </Content>
      <BottomPanel variant="login" />
    </GuestLayout>
  );
};

export default RegistrationView;
