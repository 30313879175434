import * as React from "react";
import { Grid } from "@material-ui/core";

const CardWrapper: React.FC<{ children: React.ReactNode }> = ({
  children: card
}) => (
  <Grid item xs={6} sm={12} md={6} lg={4} xl={3}>
    {card}
  </Grid>
);

export default CardWrapper;
