import * as React from "react";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import { CourseOwner as CourseOwnerType } from "modules/courses/provider/courses";
import { IconInfo, IconMailOutline, IconSmartphone } from "assets/icons";

import useStyles from "./course-owner.style";

type Props = {
  courseOwner: CourseOwnerType;
};

const CourseOwner: React.FC<Props> = ({ courseOwner }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.content}>
        <Typography color="primary">Course owner</Typography>
        <Tooltip
          title="In case of any problems or questions you can contact this course owner."
          placement="bottom-start"
          color="disabled"
        >
          <IconInfo className={classes.rightIcon} color="primary" />
        </Tooltip>
      </Grid>
      <Grid item>
        <Typography>
          {courseOwner?.firstName} {courseOwner?.lastName}
        </Typography>
      </Grid>
      <Grid item className={classes.content}>
        <IconMailOutline className={classes.leftIcon} />
        <Typography>{courseOwner?.email}</Typography>
      </Grid>
      <Grid item className={classes.content}>
        <IconSmartphone className={classes.leftIcon} />
        <Typography>{courseOwner?.phone || <>&mdash;</>}</Typography>
      </Grid>
    </Grid>
  );
};

export default CourseOwner;
