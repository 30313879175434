import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import { CustomTheme } from "config/theme";

const useStyles = makeStyles(({ spacing }: CustomTheme) => ({
  chipContainer: {
    margin: spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

type Props = {};

const ChipContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.chipContainer}>{children}</Box>;
};

export default ChipContainer;
