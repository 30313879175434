import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing }: CustomTheme) => ({
  container: {
    marginBottom: spacing(8),
    width: 400
  }
}));
