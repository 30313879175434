import { makeStyles } from "@material-ui/core";

export default makeStyles(({ spacing }) => ({
  root: {
    fontSize: "1rem",
    marginBottom: spacing(3)
  },
  centeredError: {
    textAlign: "center"
  }
}));
