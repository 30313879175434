import React from "react";
import { Grid } from "@material-ui/core";
import { Field, useForm, useFormState } from "react-final-form";

import useListFilters from "utils/hooks/useListFilters";
import { Button, Header, Section } from "components";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";

import { useLightCoursesList } from "../../../provider/courses";

import CoursesList from "./courses-list";
import useStyles from "./courses-section.styles";

const CoursesSection = () => {
  const classes = useStyles();
  const { values } = useFormState();
  const { mutators } = useForm();

  const listFilters = useListFilters({
    pagination: { rowsPerPage: 25 },
    orderBy: ["name,ASC"]
  });

  const courses = useLightCoursesList({
    variables: {
      listFilters: listFilters
    }
  });

  const courseOptions = React.useMemo(() => {
    if (courses.data) {
      return courses.data.map(({ id, name }) => ({
        label: name,
        value: id,
        parent: null
      }));
    }
    return [];
  }, [courses.data]);

  const handleAddCourse = () => {
    values.courseIds?.map((courseId: string) => {
      const course = courses.data?.find(course => course.id === courseId);
      const occurred = values.courses?.find(
        (course: { id: string; name: string }) => course.id === courseId
      );
      if (course && !occurred) {
        mutators.push("courses", { id: course.id, name: course.name });
      }
    });
  };

  return (
    <>
      <Section margin="dense">
        <Header variant="formSection" number="5" title="Add courses" />
        <Field
          name="courseIds"
          label="Course name"
          onlyOneFromBranch
          component={GroupTreeControl}
          options={courseOptions}
          isLoading={courses.loading}
          listFilters={listFilters}
          allowMultiple={true}
        />

        <Grid className={classes.buttonContainer}>
          <Button
            color="secondary"
            onClick={handleAddCourse}
            disabled={values.courseIds?.length === 0}
          >
            Add to list
          </Button>
        </Grid>
      </Section>
      <CoursesList />
    </>
  );
};

export default CoursesSection;
