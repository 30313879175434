import * as React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { useParams } from "react-router";

import { useAuth } from "services/auth-provider";
import {
  Button,
  ResponsiveTableWrapper,
  AutoGrid,
  Pagination,
  LoadingPlaceholder,
  Drawer,
  TooltipList,
  CourseStatusChip,
  EmptyListPlaceholder
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { AdminCourseStatus } from "modules/courses/courses.constants";
import useListSelection from "utils/hooks/useListSelection";
import { pluralize } from "utils/strings";
import useTableStyles from "components/data-table/data-table.styles";
import { UserRole } from "modules/users/users.constants";
import { useCoursePublicationStatusUpdate } from "modules/courses/provider";
import { CourseItem } from "modules/courses/provider/courses";
import { IconClose, IconCheck } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import EditCourseContent from "../course-edit-content.component";
import CourseDeletionModal from "../course-deletion-modal.component";
import CourseRemoveFromGroupModal from "../course-remove-from-group-modal.component";

import CoursesToolbar from "./course-list-toolbar";
import CourseListRowActions from "./course-list-row-actions";
import useStyles from "./course-list.styles";
import ButtonWithDropdown from "./button-with-dropdown";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableSelection,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';

type Props = {
  courses: CourseItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
};

export type CourseForEdition = {
  externalId: string;
  courseId: string;
};

const CoursesList: React.FC<Props> = ({
  courses,
  meta,
  listFilters,
  refetch,
  loading
}) => {
  const { companyId } = useParams();
  const auth = useAuth();
  const isTenantAdmin = auth.checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const tenant = useTenant();

  const publishCourse = useCoursePublicationStatusUpdate("publish");
  const unpublishCourse = useCoursePublicationStatusUpdate("unpublish");

  const [
    isCourseEditionDrawerOpen,
    setCourseEditionDrawerOpen
  ] = React.useState(false);

  const [courseForEdition, setCourseForEdition] = React.useState({
    externalId: "",
    courseId: ""
  });

  const onEditContentClick = (externalId: string, courseId: string) => {
    setCourseForEdition({
      externalId,
      courseId
    });
    setCourseEditionDrawerOpen(true);
  };

  const onEditContentSubmit = () => {
    setCourseEditionDrawerOpen(false);
    refetch();
  };

  const [
    isDropdownButtonSubmitting,
    setIsDropdownButtonSubmitting
  ] = React.useState(false);
  const onDropdownButtonClick = (
    isPublished: boolean,
    id: string
  ) => async () => {
    setIsDropdownButtonSubmitting(true);
    try {
      await (isPublished
        ? unpublishCourse({ courses: [id] })
        : publishCourse({ courses: [id] }));
      refetch();
    } catch (ex) {
      // @TODO: handle errors
    } finally {
      setIsDropdownButtonSubmitting(false);
    }
  };

  const [
    isPublicationButtonSubmitting,
    setIsPublicationButtonSubmitting
  ] = React.useState(false);
  const handlePublicationButtonClick = (
    operation: "publish" | "unpublish"
  ) => async () => {
    setIsPublicationButtonSubmitting(true);
    try {
      await (operation === "publish"
        ? publishCourse({
            courses: listSelection.actions.getSingleField("id")
          })
        : unpublishCourse({
            courses: listSelection.actions.getSingleField("id")
          }));
      listSelection.actions.unselectAll();
      refetch();
    } catch (ex) {
      // @TODO: handle errors
    } finally {
      setIsPublicationButtonSubmitting(false);
    }
  };

  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  const [
    courseDeletionModalState,
    setCourseDeletionModalState
  ] = React.useState<{
    course?: CourseItem;
    isOpen: boolean;
  }>({ course: undefined, isOpen: false });

  const [
    courseRemoveFromGroupModalState,
    setRemoveFromGroupModalState
  ] = React.useState<{
    course?: CourseItem;
    isOpen: boolean;
  }>({ course: undefined, isOpen: false });

  const listSelection = useListSelection(courses);
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = React.useState<string[]>([]);

  React.useEffect(()=>{
    const hiddenColumns: string[] = [];
    if (isTenantAdmin) {
      hiddenColumns.concat(["status", "actions"])
    }
    if (!!companyId) {
      hiddenColumns.concat(["groups", "assignedUsers", "usersInProgress", "content"])
    }
    setDefaultHiddenColumnNames(hiddenColumns)
  }, [isTenantAdmin, companyId])
 
  // TODO set correct type
  const [columnWidths, setColumnWidths] = React.useState<any>([
    { columnName: 'name', width: 240 },
    { columnName: 'courseId', width: 180 },
    { columnName: 'addedDate', width: 180 },
    { columnName: 'groups', width: 180 },
    { columnName: 'status', width: 180 },
    { columnName: 'assignedUsers', width: 160 },
    { columnName: 'usersInProgress', width: 160 },
    { columnName: 'content', width: 140 },
    { columnName: 'actions', width: 180 },
  ]);
  const [selection, setSelection] = React.useState([]);

  return (
    <>
      <CoursesToolbar listFilters={listFilters} refetch={refetch} />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <ResponsiveTableWrapper>
          <Grid
            rows={courses}
            columns={[
              {
                name: "name",
                title: "Course name",
                getCellValue: c => (
                  <Button
                    variant="text"
                    target={isDetailsNewTab ? "_blank" : "_self"}
                    component={RouterLink}
                    to={
                      isTenantAdmin && companyId
                        ? `/companies/${companyId}/courses/${c.id}`
                        : `/courses/${c.id}`
                    }
                  >
                    <span
                      className={clsx(
                        tableClasses.customText
                      )}
                    >
                      {c.name}
                    </span>
                  </Button>
                )
              },
              {
                name: "courseId",
                title: "Course id",
                getCellValue: c => (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {c.externalId}
                  </span>
                )
              },
              {
                name: "addedDate",
                title: "Added Date",
                getCellValue: c => moment(c.createdAt).format("L")
              },
              {
                name: "groups",
                title: "Groups",
                getCellValue: ({ groups }) =>
                  groups instanceof Array && groups.length > 0 ? (
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <span
                        className={clsx(
                          tableClasses.ellipsis,
                          tableClasses.customText
                        )}
                      >
                        {groups[0].name}
                      </span>
                      {groups.length > 1 && (
                        <TooltipList
                          title={`${groups.length} ${pluralize(
                            "group",
                            groups.length
                          )}`}
                          list={groups.map(group => group.name)}
                        />
                      )}
                    </div>
                  ) : (
                    "-"
                  )
              },
              {
                name: "status",
                title: "Status",
                getCellValue: ({ isPublished, url, id }) => {
                  const status = isPublished
                    ? AdminCourseStatus.PUBLISHED
                    : AdminCourseStatus.UNPUBLISHED;
                  return !!companyId ? (
                    <CourseStatusChip status={status} />
                  ) : (
                    <ButtonWithDropdown
                      status={status}
                      actionDisabled={
                        (!Boolean(url) && !isPublished) ||
                        isDropdownButtonSubmitting
                      }
                      showTooltip={!Boolean(url) && !isPublished}
                      handleAction={onDropdownButtonClick(isPublished, id)}
                    />
                  );
                }
              },
              {
                name: "assignedUsers",
                title: "Assigned Users",
                getCellValue: c => (
                  <div className={tableClasses.center}>
                    {c.assignedUsers}
                  </div>
                  )
              },
              {
                name: "usersInProgress",
                title: "Users in progress",
                  getCellValue: c => (
                    <div className={
                  clsx(
                    c.countInProgress > 0 ? classes.usersInProgress : "",
                    tableClasses.center
                  )}>
                      {c.countInProgress || 0}
                    </div>
                    
                    )
              },
              {
                name: "content",
                title: "Content",
                getCellValue: c =>
                  c.url ? (
                    <IconCheck
                      className={classes.contentIcon}
                      fontSize="small"
                    />
                  ) : (
                    <IconClose color="error" fontSize="small" />
                  )
              },
              {
                name: "actions",
                title: "Actions",
                getCellValue: c => (
                  <CourseListRowActions
                    {...{
                      course: c,
                      setCourseDeletionModalState,
                      setRemoveFromGroupModalState,
                      onEditContentClick
                    }}
                  />
                )
              }
            ]}
          >
            <Table />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
              resizingMode={"widget"}
            />
            <SelectionState
              selection={selection}
              onSelectionChange={(e) => {
                setSelection(e as any)
                if (e.length > 0 ){
                  listSelection.actions.selectMultiple(e.map(item => courses[Number(item)]))
                }
                else {
                  listSelection.actions.unselectAll()
                }
              }}
            />
            <IntegratedSelection />
            <TableHeaderRow />
            <TableColumnVisibility
              defaultHiddenColumnNames={defaultHiddenColumnNames}
            />
            <TableSelection selectByRowClick showSelectAll />
          </Grid>
          {courses.length === 0 && <EmptyListPlaceholder text="No courses" />}
        </ResponsiveTableWrapper>
        
      )}

      <AutoGrid justify="space-between" showEmpty>
        {listSelection.selected.size > 0 && (
          <AutoGrid spacing={3}>
            <Button
              onClick={handlePublicationButtonClick("unpublish")}
              color="hint"
              disabled={isPublicationButtonSubmitting}
            >
              Unpublish Courses
            </Button>

            <Button
              onClick={handlePublicationButtonClick("publish")}
              color="success"
              disabled={
                !listSelection.actions.isValid(course => !!course.url) ||
                isPublicationButtonSubmitting
              }
            >
              Publish Courses
            </Button>
          </AutoGrid>
        )}

        {meta && meta.total > 0 && (
          <Pagination
            totalCount={meta.total}
            onChange={listFilters.setPagination}
            rowsPerPage={listFilters.pagination.rowsPerPage}
            page={listFilters.pagination.page}
          />
        )}
      </AutoGrid>

      <Drawer
        isOpen={isCourseEditionDrawerOpen}
        onClose={() => setCourseEditionDrawerOpen(false)}
        title="Edit course content"
        size="md"
      >
        <EditCourseContent
          course={courseForEdition}
          onCancel={() => setCourseEditionDrawerOpen(false)}
          onSubmit={onEditContentSubmit}
        />
      </Drawer>

      {courseDeletionModalState.course && (
        <CourseDeletionModal
          isOpen={courseDeletionModalState.isOpen}
          course={courseDeletionModalState.course}
          refetch={refetch}
          onClose={() =>
            setCourseDeletionModalState({ course: undefined, isOpen: false })
          }
        />
      )}
      {courseRemoveFromGroupModalState.course && (
        <CourseRemoveFromGroupModal
          isOpen={courseRemoveFromGroupModalState.isOpen}
          course={courseRemoveFromGroupModalState.course}
          refetch={refetch}
          onClose={() =>
            setRemoveFromGroupModalState({ course: undefined, isOpen: false })
          }
        />
      )}
    </>
  );
};

export default CoursesList;
