import * as React from "react";
import { Grid, Box } from "@material-ui/core";
import { Form, Field } from "react-final-form";

import {
  FormError,
  Section,
  Header,
  TextControl,
  Button,
  SelectControl,
  FileControl
} from "components";
import useToast from "utils/hooks/useToast";
import { createValidator } from "utils/forms";
import { splitPhoneAndExtension } from "utils/strings";
import * as PythonApi from "services/api/python";
import { IUser } from "services/auth-provider";
import ButtonContainer from "modules/guest/components/button-container.component";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";
import { useUploadStatus } from "modules/common/hooks/useUploadStatus";
import { useRepeatStatusCheckUntilSuccess } from "modules/common/hooks/useRepeatStatusCheckUntilSuccess";
import { useUploadOptions } from "modules/common/hooks/useUploadOptions";
import { useTenant } from "services/tenant-provider";

import {ProblemTypes, ProblemTypesForUnauthorized, Problems} from "../problems.constants";
import { useUploadReportFile, useProblemCreate } from "../provider";
import useListFilters from "../../../utils/hooks/useListFilters";
import {useMyCoursesList} from "../../courses/provider";
import GroupTreeControl from "../../common/components/group-tree/group-tree-control";

const validator = createValidator({
  senderEmail: {
    presence: { allowEmpty: false, message: "^Email can't be blank" },
    email: { message: "is invalid" }
  },
  senderUsername: {
    format: {
      pattern: /\S*/,
      message: ` can't contain whitespaces`
    }
  },
  senderFirstName: {
    presence: { allowEmpty: false, message: "^First name can't be blank" }
  },
  senderLastName: {
    presence: { allowEmpty: false, message: "^Last name can't be blank" }
  },
  senderPhoneNumber: (_: any, attributes: any) =>
    attributes.senderPhoneNumber && {
      format: {
        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        message: "^Phone number is invalid"
      }
    },
  extension: (_: any, attributes: any) =>
    attributes.extension && {
      format: {
        pattern: /^\d+$/,
        message: "can contain only numbers"
      }
    },
  type: {
    presence: {
      allowEmpty: false,
      message: "^Problem type has to be selected"
    }
  },
  description: {
    presence: {
      allowEmpty: false,
      message: "^Description can't be blank"
    },
    length: { minimum: 4, maximum: 10000 }
  }
});

type Props = {
  user?: IUser;
};

const ReportProblem: React.FC<Props> = ({ user }) => {
  const { showToast } = useToast();
  const { getHostname } = useTenant();
  const getUploadOptions = useUploadOptions();
  const uploadReportFile = useUploadReportFile();
  const checkReportFileStatus = useUploadStatus();
  const waitForStatus = useRepeatStatusCheckUntilSuccess(checkReportFileStatus);
  const reportProblem = useProblemCreate();
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();

  const phoneAndExtension = splitPhoneAndExtension(user?.phone || "");

  const listFilters = useListFilters({
    pagination: { rowsPerPage: 50 },
    orderBy: ["name,ASC"]
  });

  const courses = useMyCoursesList({
    variables: { id: user ? user.id : "", listFilters, isLightList: true }
  });

  const courseOptions = React.useMemo(() => {
    if (courses.data) {
      return courses.data.map(({ id, name }) => ({
        label: name,
        value: id,
        parent: null
      }));
    }
    return [];
  }, [courses.data]);

  const problemSelectionOptions = React.useMemo(() => ( Object.entries(
      user ? ProblemTypes : ProblemTypesForUnauthorized
    ).map(([value, label]) => ({ value, label }))
  ), [user]);

  type InputType = {
    senderEmail: string;
    senderUsername: string;
    senderFirstName: string;
    senderLastName: string;
    senderPhoneNumber: string;
    extension: string;
    type?: string;
    course?: string;
    freeTextCourse?: string,
    description: string;
    file?: File;
  };
  const initialValues = user
    ? ({
        senderEmail: user.email,
        senderUsername: user.username,
        senderFirstName: user.firstName,
        senderLastName: user.lastName,
        senderPhoneNumber: phoneAndExtension.phone,
        extension: phoneAndExtension.extension,
        type: undefined,
        course: undefined,
        freeTextCourse: undefined,
        description: "",
        file: undefined
      } as InputType)
    : ({
        senderEmail: "",
        senderUsername: "",
        senderFirstName: "",
        senderLastName: "",
        senderPhoneNumber: "",
        extension: "",
        type: undefined,
        course: undefined,
        freeTextCourse: undefined,
        description: "",
        file: undefined
      } as InputType);

  return (
    <Form
      onSubmit={async ({
        senderEmail,
        senderUsername,
        senderFirstName,
        senderLastName,
        senderPhoneNumber,
        extension,
        type,
        course,
        freeTextCourse,
        description,
        file
      }) => {
        let fileUrl = undefined;

        try {
          if (file) {
            const result = await getUploadOptions({});
            if (!result.data) {
              throw new Error("Retrieving upload options failed");
            }
            const { sasToken, uploadId, storageUrl } = result.data;

            await uploadReportFile({
              sasToken,
              uploadId,
              storageUrl,
              tenantId: getHostname(),
              file
            });

            fileUrl = await waitForStatus(uploadId, 5000);
          }
        } catch (ex) {
          return PythonApi.getFormErrors(ex);
        }

        try {
          await reportProblem(
            user
              ? {
                  type: type!,
                  description,
                  ...((type === Problems.NOT_POSTING_COMPLETION ||
                    type === Problems.UNABLE_TO_LAUNCH_A_COURSE) && {
                    course
                  }),
                  ...(type === Problems.UNABLE_TO_FIND_A_COURSE && {
                    freeTextCourse
                  }),
                  fileUrl: fileUrl
                }
              : {
                  senderEmail,
                  senderUsername,
                  senderFirstName,
                  senderLastName,
                  senderPhoneNumber:
                    senderPhoneNumber && extension
                      ? `${senderPhoneNumber} x ${extension}`
                      : senderPhoneNumber || "",
                  type: type!,
                  description,
                  fileUrl: fileUrl
                }
          );
          setShouldRefetchIndicatorsToTrue();
          showToast("Your problem has been successfully reported", "success");
        } catch (ex) {
          return PythonApi.getFormErrors(ex);
        }
      }}
      validate={validator}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        pristine,
        hasValidationErrors,
        values,
        form: { reset }
      }) => (
        <form
          style={{ maxWidth: 700 }}
          onSubmit={async event => {
            const errors = await handleSubmit(event);
            !errors && reset();
          }}
        >
          {submitError && <FormError>{submitError}</FormError>}
          <Section margin="dense">
            <Header
              variant="formSection"
              number={1}
              title={
                Boolean(user)
                  ? "Your details (data from your user profile)"
                  : "Enter your details"
              }
            />

            <Grid container spacing={3}>
              <Grid item sm={6} style={{ flexGrow: 1 }}>
                <Field
                  name="senderEmail"
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                />
              </Grid>
              <Grid item sm={6} style={{ flexGrow: 1 }}>
                <Field
                  name="senderUsername"
                  label={`Username ${Boolean(user) ? "" : "(optional)"}`}
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={6} style={{ flexGrow: 1 }}>
                <Field
                  name="senderFirstName"
                  label="First name"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                />
              </Grid>
              <Grid item sm={6} style={{ flexGrow: 1, display: "flex" }}>
                <Field
                  name="senderLastName"
                  label="Last name"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={6} style={{ flexGrow: 1 }}>
                <Field
                  name="senderPhoneNumber"
                  label="Phone number (optional)"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                  validate={(senderPhoneNumber, allValues) => {
                    if (
                      senderPhoneNumber === undefined &&
                      (allValues as any).extension
                    )
                      return "Phone number is required for extension";
                  }}
                />
              </Grid>
              <Grid item sm={6} style={{ flexGrow: 1 }}>
                <Field
                  name="extension"
                  label="Extension (optional)"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  disabled={Boolean(user)}
                  validate={(extension, allValues) => {
                    const phone = (allValues as any).senderPhoneNumber;
                    if (
                      extension &&
                      typeof phone === "string" &&
                      phone.length === 0
                    )
                      return "Extension requires phone number entered";
                  }}
                />
              </Grid>
            </Grid>
          </Section>

          <Section margin="dense">
            <Header
              variant="formSection"
              number={2}
              title="What kind of problem do you have?"
            />
            <Box mb={3}>
              <Field
                name="type"
                label="Problem type"
                component={SelectControl}
                options={problemSelectionOptions}
              />
            </Box>
          </Section>

          {(values.type === Problems.UNABLE_TO_LAUNCH_A_COURSE || values.type === Problems.NOT_POSTING_COMPLETION) && (
            <Box mb={3}>
              <Field
                name="course"
                label="Choose a course"
                component={GroupTreeControl}
                options={courseOptions}
                isLoading={courses.loading}
                listFilters={listFilters}
                problemsView={true}
              />
            </Box>
          )}

          {values.type === Problems.UNABLE_TO_FIND_A_COURSE && (
            <Box mb={3}>
              <Field
                name="freeTextCourse"
                label="Course"
                variant="outlined"
                margin="normal"
                component={TextControl}
                multiline
              />
            </Box>
          )}

          <Section margin="dense">
            <Header
              variant="formSection"
              number={3}
              title="Describe your problem"
            />
            <Field
              name="description"
              label="Description"
              variant="outlined"
              margin="normal"
              component={TextControl}
              validationIndicator
              multiline
            />
          </Section>

          <Section margin="dense">
            <Header
              variant="formSection"
              number="4"
              title="Upload related file"
              hint="(optional)"
            />
            <Field
              name="file"
              margin="normal"
              component={FileControl}
              maxSize={10 * 1000 * 1000}
              isLoading={false}
            />
          </Section>

          {user ? (
            <Button
              type="submit"
              color="success"
              disabled={submitting || hasValidationErrors || pristine}
            >
              Report
            </Button>
          ) : (
            <ButtonContainer>
              <Button
                type="submit"
                color="success"
                size="large"
                disabled={submitting || hasValidationErrors || pristine}
              >
                Report
              </Button>
            </ButtonContainer>
          )}
        </form>
      )}
    </Form>
  );
};

export default ReportProblem;
