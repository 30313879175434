import * as React from "react";
import _ from "lodash";

import { useAuth } from "services/auth-provider";
import {
  useModuleState,
  actionTypes
} from "modules/courses/reducers/available-courses";
import { AppLayout, ApiResultRenderer, DisclaimerModal } from "components";
import { LayoutType } from "components/layout-selector/layout-selector";
import useListFilters from "utils/hooks/useListFilters";
import {
  useAvailableCoursesList,
  AvailableCoursesListFilters
} from "modules/courses/provider/available-courses";
import useStateWithLocalStorage from "utils/hooks/useStateWithLocalStorage";
import storage from "config/storage";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import AvailableCoursesList from "../components/course-list-available/course-list-available";

type Props = {};

const AvailableCoursesView: React.FC<Props> = () => {
  const { user } = useAuth();
  const tenant = useTenant();
  const isMinPagination = tenant.isComponentAllowed(
    AllowedComponents.MinPagination
  );

  const [{ availableCoursesFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters<AvailableCoursesListFilters>(
    isMinPagination
      ? { ...availableCoursesFilters, pagination: { rowsPerPage: 5 } }
      : availableCoursesFilters
  );
  const availableCoursesList = useAvailableCoursesList({
    variables: {
      id: user!.id,
      listFilters: _.merge(listFilters, { filters: { usersId: user?.id } }) // main AvailableCourses filter logic
    }
  });

  const [layout, setLayout] = useStateWithLocalStorage<LayoutType>({
    localStorageKey: storage.local.myCoursesLayoutPrefix + user!.id,
    defaultValue: "grid"
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_AVAILABLE_COURSES_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  const isLegalDisclaimer = tenant.isComponentAllowed(
    AllowedComponents.LegalDisclaimer
  );

  return (
    <AppLayout viewTitle="Available courses" hideSmUp>
      {isLegalDisclaimer && <DisclaimerModal />}
      <ApiResultRenderer
        apiResult={availableCoursesList}
        reloadsSection={false}
      >
        {({ data = [], meta, loading }) => (
          <AvailableCoursesList
            courses={data}
            meta={meta!}
            listFilters={listFilters}
            activeLayout={layout}
            setActiveLayout={setLayout}
            loading={loading}
          />
        )}
      </ApiResultRenderer>
    </AppLayout>
  );
};

export default AvailableCoursesView;
