import * as React from "react";

import Card, { Props as CardProps } from "components/card/card";
import { CardIcon } from "components";
import { CourseStatus } from "modules/courses/courses.constants";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import {
  IconDone,
  IconPlayArrow,
  IconReportProblem,
  IconFeaturedPlayListOutlined
} from "assets/icons";

type StatusCardPropsVariants = {
  [key in CourseStatus]: {
    title: React.ReactNode;
    colorVariant: CardProps["colorVariant"];
    avatar: React.ReactNode;
  };
};

export const StatusCardPropsVariants: StatusCardPropsVariants = {
  [CourseStatus.IN_PROGRESS]: {
    title: "In progress",
    colorVariant: "info",
    avatar: <CardIcon icon={IconFeaturedPlayListOutlined} />
  },
  [CourseStatus.NOT_STARTED]: {
    title: "Not started",
    colorVariant: "hint",
    avatar: <CardIcon icon={IconPlayArrow} />
  },
  [CourseStatus.COMPLETED]: {
    title: "Completed",
    colorVariant: "success",
    avatar: <CardIcon icon={IconDone} />
  },
  [CourseStatus.MANUALLY_COMPLETED]: {
    title: "Manually Completed",
    colorVariant: "success",
    avatar: <CardIcon icon={IconDone} />
  },
  [CourseStatus.UNSATISFACTORY]: {
    title: "Unsatisfactory",
    colorVariant: "secondary",
    avatar: <CardIcon icon={IconReportProblem} />
  }
};

export type StatusCardProps = Pick<MyCoursesListItem, "status">;

const StatusCard: React.FC<StatusCardProps> = ({ status }) => {
  if (!status) return null;
  return (
    <Card
      {...StatusCardPropsVariants[status]}
      subtitle="Status"
      variant="filled"
    />
  );
};

export default StatusCard;
