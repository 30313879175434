import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

import * as PythonApi from "services/api/python";
import { Button, FormError } from "components";

import { CustomColor } from "../button/button";

import useStyles from "./confirmation-dialog.styles";

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitDisabled?: boolean;
  submitText?: string;
  submitColor?: ButtonProps["color"] | CustomColor;
};

const ConfirmationDialog: React.FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  onSubmit,
  submitDisabled,
  submitText,
  submitColor
}) => {
  const classes = useStyles();

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string>();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle className={classes.titleWrap} disableTypography>
        <Typography variant="h3" align="center" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {Boolean(description) && (
          <DialogContentText
            component="div"
            align="center"
            className={classes.contentText}
          >
            {description}
          </DialogContentText>
        )}
        {error && <FormError center>{error}</FormError>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          disabled={submitting || submitDisabled}
          onClick={async () => {
            setError(undefined);
            setSubmitting(true);
            try {
              await onSubmit();
              onClose();
            } catch (ex) {
              setError(PythonApi.getMessageStringFromError(ex));
            } finally {
              setSubmitting(false);
            }
          }}
          color={submitColor}
          type="submit"
        >
          {submitText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
