import { makeStyles } from "@material-ui/core";

export default makeStyles(({ breakpoints, palette, spacing, typography }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [breakpoints.up("sm")]: {
      marginBottom: spacing(3)
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: spacing(1),
    [breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      marginBottom: 0
    }
  },
  text: {
    ...typography.h4,
    marginBottom: spacing(1),
    [breakpoints.up("sm")]: {
      ...typography.h1
    }
  },
  title: {
    color: palette.text.primary,
    marginTop: "auto",
    marginBottom: "auto",

    "&$withMargin": {
      marginRight: spacing(1),
      [breakpoints.up("sm")]: {
        marginRight: spacing(3)
      }
    }
  },
  hint: {
    color: palette.hint.main,
    marginTop: "auto",
    marginBottom: "auto",

    [breakpoints.up("sm")]: {
      marginRight: spacing(3)
    }
  },
  subtitle: {
    [breakpoints.up("sm")]: {
      marginBottom: spacing(5)
    }
  },
  button: {
    flex: 1,
    marginBottom: spacing(1)
  },
  withMargin: {}
}));
