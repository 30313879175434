import * as React from "react";

import { ApiResultRenderer, AppLayout, Pagination } from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useModuleState } from "modules/courses/reducers/courses";
import {
  useModuleState as useLPModuleState,
  actionTypes as actionTypesLP
} from "modules/courses/reducers/learning-plans";

import { useLearningPlanList } from "../provider";
import LearningPlanToolbar from "../components/learning-plan/learning-plan-list/learning-plan-toolbar";
import LearningPlanList from "../components/learning-plan/learning-plan-list/learning-plans-list";
import TabsCoursesLearningPlans from "../components/learning-plan/components/tabs-courses-learning-plans";
import { useCoursesList } from "../provider/courses";

type Props = {};

const LearningPlanListView: React.FC<Props> = () => {
  const [{ learningPlansFilters }, dispatchLP] = useLPModuleState();
  const listFiltersLP = useListFilters(learningPlansFilters);

  const [{ coursesFilters }] = useModuleState();
  const listFilters = useListFilters(coursesFilters);
  const { meta } = useCoursesList({
    variables: {
      listFilters: listFilters
    }
  });

  const learningPlansList = useLearningPlanList({
    variables: { listFilters: listFiltersLP }
  });

  React.useEffect(() => {
    dispatchLP({
      type: actionTypesLP.SET_LEARNING_PLAN_FILTERS,
      payload: listFiltersLP
    });
  }, [listFiltersLP, dispatchLP]);

  return (
    <AppLayout viewTitle="Courses">
      <TabsCoursesLearningPlans
        learningPlansCount={learningPlansList.meta?.total || 0}
        coursesCount={meta?.total || 0}
        activeTab={1}
        panelsSet="admin"
      >
        <ApiResultRenderer apiResult={learningPlansList} reloadsSection={false}>
          {({ data = [], meta, refetch, loading }) => (
            <>
              <LearningPlanToolbar
                listFilters={listFiltersLP}
                refetch={refetch}
              />
              <LearningPlanList
                learningPlans={data}
                refetch={refetch}
                loading={loading}
                listFilters={listFiltersLP}
              />
              {meta && meta.total > 0 && (
                <Pagination
                  totalCount={meta.total}
                  onChange={listFiltersLP.setPagination}
                  rowsPerPage={listFiltersLP.pagination.rowsPerPage}
                  page={listFiltersLP.pagination.page}
                />
              )}
            </>
          )}
        </ApiResultRenderer>
      </TabsCoursesLearningPlans>
    </AppLayout>
  );
};

export default LearningPlanListView;
