import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing }: CustomTheme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: spacing(4)
  }
}));
