import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnVisibility
} from "@devexpress/dx-react-grid-material-ui";

import { useAuth } from "services/auth-provider";
import {
  ResponsiveTableWrapper,
  AutoGrid,
  Pagination,
  LoadingPlaceholder,
  EmptyListPlaceholder
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useTableStyles from "components/data-table/data-table.styles";
import { UserRole } from "modules/users/users.constants";
import {
  CourseHistoryItem,
  CourseChange
} from "modules/courses/provider/courses";

type Props = {
  history: CourseHistoryItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
  companyId: string;
};

export type CourseForEdition = {
  externalId: string;
  courseId: string;
};

const CourseHistoryList: React.FC<Props> = ({
  history,
  meta,
  listFilters,
  refetch,
  loading,
  companyId
}) => {
  const auth = useAuth();
  const isTenantAdmin = auth.checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const tableClasses = useTableStyles();

  const [
    defaultHiddenColumnNames,
    setDefaultHiddenColumnNames
  ] = React.useState<string[]>([]);

  React.useEffect(() => {
    const hiddenColumns: string[] = [];
    if (isTenantAdmin) {
      hiddenColumns.concat(["status", "actions"]);
    }
    if (!!companyId) {
      hiddenColumns.concat([
        "groups",
        "assignedUsers",
        "usersInProgress",
        "content"
      ]);
    }
    setDefaultHiddenColumnNames(hiddenColumns);
  }, [isTenantAdmin, companyId]);

  const prepareHistoryRows = (history: CourseHistoryItem[]) => {
    const result: CourseHistoryItem[] = [];
    history.forEach(row => {
      if (row.changes?.length) {
        row.changes.forEach((change: CourseChange) => {
          result.push({
            ...row,
            changes: change
          });
        });
      } else {
        result.push(row);
      }
    });
    return result;
  };

  const getChangeValue = (changes: any) => {
    if (changes !== null || changes !== undefined) {
      if (Array.isArray(changes)) {
        return changes[0] ? changes[0].url : "-";
      }
      if (Number.isInteger(changes)) {
        return moment()
          .startOf("day")
          .seconds(changes)
          .format("H:mm:ss");
      }
      return String(changes);
    }
    return "-";
  };

  return (
    <>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <ResponsiveTableWrapper>
          <Grid
            rows={prepareHistoryRows(history)}
            columns={[
              {
                name: "historyDate",
                title: "Date",
                getCellValue: c => moment(c.historyDate).format("L LT")
              },
              {
                name: "historyUser",
                title: "Author",
                getCellValue: ({ historyUser }) =>
                  historyUser
                    ? `${historyUser.firstName} ${historyUser.lastName}`
                    : "-"
              },
              {
                name: "historyType",
                title: "Type",
                getCellValue: c => (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {c.historyType}
                  </span>
                )
              },
              {
                name: "changesField",
                title: "Affected fields",
                getCellValue: ({ changes }) => (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {changes ? changes.field : "-"}
                  </span>
                )
              },
              {
                name: "changesOld",
                title: "Old value",
                getCellValue: ({ changes }) => (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {getChangeValue(changes?.old)}
                  </span>
                )
              },
              {
                name: "changesNew",
                title: "New value",
                getCellValue: ({ changes }) => (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {getChangeValue(changes?.new)}
                  </span>
                )
              }
            ]}
          >
            <Table />
            <TableHeaderRow />
            <TableColumnVisibility
              defaultHiddenColumnNames={defaultHiddenColumnNames}
            />
          </Grid>
          {history.length === 0 && <EmptyListPlaceholder text="No courses" />}
        </ResponsiveTableWrapper>
      )}

      <AutoGrid justify="space-between" showEmpty>
        {meta && meta.total > 0 && (
          <Pagination
            totalCount={meta.total}
            onChange={listFilters.setPagination}
            rowsPerPage={listFilters.pagination.rowsPerPage}
            page={listFilters.pagination.page}
          />
        )}
      </AutoGrid>
    </>
  );
};

export default CourseHistoryList;
