import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, typography }: CustomTheme) => ({
  title: {
    marginBottom: spacing(1),
    fontSize: typography.pxToRem(14),
    font: typography.fontFamily,
    color: palette.text.hint
  },
  breadcrumbs: {
    marginTop: spacing(3)
  }
}));
