import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import API from "services/api";
import * as PythonApi from "services/api/python";
import apiConfig from "config/api";

import {
  AllowedComponents,
  ITenantClient,
  OnUpdateHandler,
  TenantSaml,
  TenantText,
  TenantLegalDisclaimer
} from "../";

type TenantAPIResponse = {
  name: string;
  hostname: string;
  url?: string; // url to main domain
  config?: {
    access?: {
      [key in AllowedComponents]: boolean;
    };
    samlUrl?: string;
  };
  uiConfig: {
    theme?: ThemeOptions;
    text?: TenantText;
    legalDisclaimer?: TenantLegalDisclaimer;
  };
};

export default class TenantClient implements ITenantClient {
  private tenantData: TenantAPIResponse | null = null;
  private isInitialized: boolean = false;
  private onUpdateHandler: OnUpdateHandler = () => {};

  isComponentAllowed(
    component: AllowedComponents | Array<AllowedComponents>
  ): boolean {
    if (typeof component === "string") {
      return this.tenantData?.config?.access?.[component] === true;
    } else {
      return (
        (component as Array<AllowedComponents>).filter(
          requiredComponent =>
            this.tenantData?.config?.access?.[requiredComponent] === true
        ).length > 0
      );
    }
  }

  getText(): TenantText | undefined {
    return this.tenantData?.uiConfig.text;
  }

  getName(): string {
    return this.tenantData?.name || "NPWT";
  }

  getHostname(): string {
    return this.tenantData?.hostname || "test-npwt";
  }

  getStyles(): ThemeOptions | undefined {
    return this.tenantData?.uiConfig.theme;
  }

  getLegalDiscalimer(): TenantLegalDisclaimer | undefined {
    return this.tenantData?.uiConfig.legalDisclaimer;
  }

  getSaml(): TenantSaml | undefined {
    const loginUrl = this.tenantData?.config?.samlUrl;
    const callbackUrl = this.tenantData?.url;

    if (loginUrl && callbackUrl)
      return {
        loginUrl,
        callbackUrl
      };
  }

  async init() {
    try {
      const response = await TenantClient.getTenantData();
      this.tenantData = response.data;
    } catch (ex) {
      console.error("Cannot get tenant config:", ex.message);
    } finally {
      this.isInitialized = true;
      this.onUpdateHandler();
    }
  }

  private static async getTenantData() {
    try {
      const response = await API.get<TenantAPIResponse>(
        apiConfig.paths.unauthorized.tenant
      );

      return { data: response.data };
    } catch (ex) {
      const errorMessage = PythonApi.getMessageFromError(ex) as string;

      throw new Error(errorMessage);
    }
  }

  registerOnUpdateHandler(handler: OnUpdateHandler): void {
    this.onUpdateHandler = handler;
  }

  getIsInitialized(): boolean {
    return this.isInitialized;
  }
}
