import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { Grid } from "@material-ui/core";

import {
  Button,
  CourseStatusChip,
  EmptyListPlaceholder,
  LoadingPlaceholder,
  Pagination,
  ResponsiveTableWrapper
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { LayoutType } from "components/layout-selector/layout-selector";
import { formatDuration } from "utils/datetime";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { AvailableCoursesListItem } from "modules/courses/provider/available-courses";
import useTableStyles from "components/data-table/data-table.styles";
import { CourseStatus, getColumnsWidthMap, courseSortableMap } from "modules/courses/courses.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import routes from "config/routes";

import LearnerActionByStatus from "../course-learner-action.component";
import CourseCard from "../course-card/course-card";

import PrerequisitesList from "./prerequisites-list";
import AvailableCoursesListToolbar from "./course-list-available-toolbar";
import useStyles from "./course-list-available.styles";
import {
  Grid as XGrid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  Sorting
} from '@devexpress/dx-react-grid';

type Props = {
  courses: AvailableCoursesListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
  loading: boolean;
};

const AvailableCoursesList: React.FC<Props> = ({
  courses,
  meta,
  listFilters,
  activeLayout,
  setActiveLayout,
  loading
}) => {
  const { pathname } = useLocation();
  const tableClasses = useTableStyles();
  const history = useHistory();
  const classes = useStyles();
  const tenant = useTenant();
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );

  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = React.useState<string[]>([]);

  React.useEffect(()=>{
    const hiddenColumns: string[] = [];
    if (!isCoursePrerequisites) {
      hiddenColumns.push("prerequisites")
    };
    setDefaultHiddenColumnNames(hiddenColumns)
  }, [isCoursePrerequisites])

  const [sorting, setSorting] = React.useState([{ columnName: 'name', direction: 'asc' }]);

  const [columnWidths, setColumnWidths] = React.useState<any>(getColumnsWidthMap(false));

  return (
    <>
      <AvailableCoursesListToolbar
        meta={meta}
        listFilters={listFilters}
        activeLayout={activeLayout}
        setActiveLayout={setActiveLayout}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          {activeLayout === "grid" ? (
            courses.length > 0 ? (
              <Grid container spacing={5}>
                {courses.map(course => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={`${course.id}-${course.statusTimestamp}`}
                  >
                    <CourseCard course={course} isHistoryView={false} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div className={classes.emptyPlaceholderWrap}>
                <EmptyListPlaceholder text="No courses" />
                {tenant.isComponentAllowed(
                  AllowedComponents.CourseListForStudent
                ) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push(routes.myCourses.available);
                    }}
                    className={classes.emptyPlaceholderButton}
                  >
                    Go to available courses
                  </Button>
                )}
              </div>
            )
          ) : (
            <ResponsiveTableWrapper>
              <XGrid
                rows={courses}
                // rowIdKey="id"
                columns={[
                  {
                    name: "name",
                    title: "Course name",
                    getCellValue: c => (
                      <Button
                        variant="text"
                        component={RouterLink}
                        to={`${pathname}/${c.id}`}
                      >
                        <span
                          className={clsx(
                            tableClasses.ellipsis,
                            tableClasses.customText
                          )}
                        >
                          {c.name}
                        </span>
                      </Button>
                    )
                  },
                  {
                    name: "externalId",
                    title: "Course id",
                    getCellValue: c => (
                      <span
                        className={clsx(
                          tableClasses.ellipsis,
                          tableClasses.customText
                        )}
                      >
                        {c.externalId}
                      </span>
                    )
                  },
                  {
                    name: "status",
                    title: "Status",
                    getCellValue: ({ status }) => <CourseStatusChip status={status} />
                  },
                  {
                    name: "statusTimestamp",
                    title: "Completed date",
                    getCellValue: ({ status, statusTimestamp }) =>
                      status === CourseStatus.COMPLETED ||
                      status === CourseStatus.MANUALLY_COMPLETED
                        ? moment(statusTimestamp).format("L")
                        : "-"
                  },
                  {
                    name: "duration",
                    title: "Duration",
                    getCellValue: c =>
                      c.duration
                        ? formatDuration(c.duration * 1000, "H[h] mm[min]")
                        : "-"
                  },
                  {
                    name: "prerequisites",
                    title: "Prerequisites courses",
                    getCellValue: ({ prerequisiteCourses }) => (
                      <PrerequisitesList
                        prerequisitesCourses={prerequisiteCourses || []}
                      />
                    ),
                  },
                  {
                    name: "actions",
                    title: "Actions",
                    getCellValue: ({
                      currentStatus,
                      status,
                      url,
                      id,
                      externalId,
                      assigned,
                      prerequisiteCourses
                    }) => (
                      <LearnerActionByStatus
                        {...{
                          currentStatus: currentStatus || status,
                          url,
                          id,
                          externalId,
                          assigned,
                          disabled:
                            prerequisiteCourses?.find((c: any) => !c.isFinished) &&
                            isCoursePrerequisites
                        }}
                      />
                    )
                  }
                ]}
              >
                <SortingState
                  columnExtensions={courseSortableMap}
                  sorting={sorting as Sorting[]}
                  onSortingChange={(e)=> {
                  setSorting(e)
                  const event = e[0];
                  listFilters!.setOrderBy([`${event.columnName},${event.direction.toUpperCase()}`]);
                  }}
                />
                <IntegratedSorting />
                <Table />
                <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
                resizingMode={"widget"}
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
              />   
                </XGrid>
                {courses.length === 0 && <EmptyListPlaceholder text="No courses" />}
            </ResponsiveTableWrapper>
          )}

          {meta && meta.total > 0 && (
            <Pagination
              totalCount={meta.total}
              onChange={listFilters.setPagination}
              rowsPerPage={listFilters.pagination.rowsPerPage}
              page={listFilters.pagination.page}
            />
          )}
        </>
      )}
    </>
  );
};

export default AvailableCoursesList;
