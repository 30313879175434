import * as React from "react";
import moment from "moment";

import { ResponsiveTableWrapper, DataTable } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useStyles from "components/data-table/data-table.styles";

import { PrimeCourseItem } from "../../provider/tenants";

type Props = {
  loading: boolean;
  primeCourses: Array<PrimeCourseItem>;
  listFilters: ListFiltersState;
};

const PrimeCourseList: React.FC<Props> = ({
  loading,
  primeCourses,
  listFilters
}) => {
  const classes = useStyles();

  return (
    <ResponsiveTableWrapper>
      <DataTable
        loading={loading}
        sortable
        emptyText="No course uploads"
        data={primeCourses}
        listFilters={listFilters}
        rowIdKey="user"
        columns={[
          {
            key: "addedDate",
            title: "Date",
            sortable: false,
            render: ({ createdAt }) => (
              <span className={classes.customText}>
                {moment(createdAt)?.format("L")}
              </span>
            )
          },
          {
            key: "status",
            title: "Status",
            sortable: false,
            render: ({ status }) => (
              <span className={classes.customText}>{status}</span>
            )
          },
          {
            key: "message",
            title: "Error message",
            sortable: false,
            render: ({ message }) => (
              <span className={classes.customText}>
                {message ? message : "-"}
              </span>
            )
          }
        ]}
      />
    </ResponsiveTableWrapper>
  );
};

export default PrimeCourseList;
