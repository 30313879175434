import validate from "validate.js";
import _ from "lodash";
import moment from "moment";
import * as Sentry from "@sentry/react";

type FinalFormValues = {
  [key: string]: any;
};

validate.extend(validate.validators.datetime, {
  parse: (value: moment.MomentInput) => moment.utc(value),
  format: (value: moment.MomentInput) => moment.utc(value).format("L")
});

export const createValidator = (schema: object) => {
  return (values: FinalFormValues) => {
    let errors;

    try {
      errors = validate(values, schema);
    } catch (ex) {
      const scope = new Sentry.Scope();
      scope.setLevel(Sentry.Severity.Warning);
      scope.addBreadcrumb({
        category: "values",
        message: "Catched validate error",
        data: {
          schema: JSON.stringify(schema),
          values: JSON.stringify(values)
        },
        level: Sentry.Severity.Debug
      });
      Sentry.captureException(ex, scope);
    }

    if (!errors) {
      return {};
    }

    return _.mapValues(errors, err =>
      Array.isArray(err) && err.length > 0 ? err[0] : err
    );
  };
};

export const isDateBetween = (
  start: moment.MomentInput,
  end: moment.MomentInput
) => (date: string) => {
  const current = moment(date);

  if (current.isAfter(end)) {
    return "Please provide valid date";
  }

  if (current.toString() === moment(end).toString()) {
    return null;
  }

  return current.isBetween(start, end, "days", "[]")
    ? null
    : "Please provide valid date";
};
