import * as React from "react";
import clsx from "clsx";
import { Hidden, IconButton } from "@material-ui/core";

import { useSizeCheck } from "utils/hooks/useSizeCheck";
import { IconMenu } from "assets/icons";

import UserAvatar from "./components/user-avatar.component";
import Sidebar from "./components/sidebar.component";
import useStyles from "./app-layout.styles";

type Props = {
  viewTitle: string;
  hideSmUp?: boolean;
  titleCta?: React.ReactChild;
};

const AppLayout: React.FC<Props> = ({
  children,
  viewTitle,
  hideSmUp = false,
  titleCta
}) => {
  const classes = useStyles();
  const [isOpen, toggleDrawer] = React.useState(false);
  const [isProfileDrawerOpen, setProfileDrawerOpen] = React.useState(false);

  const isMobile = useSizeCheck("xs");

  return (
    <div className={classes.root}>
      <aside>
        <Sidebar isOpen={isOpen} onClose={() => toggleDrawer(false)} />
      </aside>
      <div className={classes.container}>
        <header className={classes.header}>
          <Hidden smUp>
            <IconButton
              onClick={() => toggleDrawer(true)}
              classes={{ root: classes.menuTriggerButton }}
            >
              <IconMenu />
            </IconButton>
          </Hidden>
          {viewTitle && (
            <div
              className={clsx(classes.viewTitle, hideSmUp && classes.hideTitle)}
            >
              {isProfileDrawerOpen && isMobile ? "Profile" : viewTitle}
              {titleCta && <div className={classes.titleCta}>{titleCta}</div>}
            </div>
          )}

          <div className={classes.headerToolbar}>
            <UserAvatar
              isDrawerOpen={isProfileDrawerOpen}
              setDrawerOpen={setProfileDrawerOpen}
              isMobile={isMobile}
            />
          </div>
        </header>

        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
