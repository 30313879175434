import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) => ({
  input: {
    paddingTop: 13,
    paddingBottom: 13,
    cursor: "pointer"
  },
  label: {
    transform: "translate(14px, 15px) scale(1)",
    color: palette.text.default
  }
}));
