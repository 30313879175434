import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, breakpoints }) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block"
  },
  customText: {
    maxWidth: 150,
    [breakpoints.up("lg")]: {
      maxWidth: 250
    }
  },
  center: { textAlign: "center" },
  active: {
    backgroundColor: palette.primary.main,
    "& > *": {
      color: palette.primary.contrastText
    }
  }
}));
