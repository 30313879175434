import * as React from "react";
import { FormHelperText } from "@material-ui/core";

import useStyles from "./form-error.styles";

type Props = {
  center?: boolean;
};

const FormError: React.FC<Props> = ({ children, center = false }) => {
  const classes = useStyles();

  return (
    <FormHelperText
      error
      classes={{
        root: classes.root,
        error: center ? classes.centeredError : undefined
      }}
    >
      {children}
    </FormHelperText>
  );
};

export default FormError;
