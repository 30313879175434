import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, shadows }) => ({
  usersInProgress: {
    color: palette.success.main
  },
  contentIcon: {
    color: palette.success.main
  },

  // course status buttons
  statusButton: {
    borderRadius: 2,
    fontWeight: "bold",
    padding: spacing(0.5, 1),
    margin: spacing(0.5, 0)
  },
  published: {
    color: palette.success.main,
    borderColor: palette.success.main
  },
  unpublished: {
    color: palette.hint.main,
    borderColor: palette.hint.main
  },
  expandMoreIcon: {
    "&$published": {
      color: palette.success.main
    },
    "&$unpublished": {
      color: palette.hint.main
    }
  },
  dropdown: {
    marginTop: 26,
    borderRadius: 2,
    boxShadow: shadows[2]
  },
  dropdownList: { padding: 0 },
  dropdownButton: {
    fontSize: "0.875rem",
    padding: spacing(0.5, 1),
    borderWidth: 1,
    borderStyle: "solid",

    "&$published": {
      width: 112,
      borderColor: palette.hint.main,
      color: palette.text.default
    },
    "&$published:hover": {
      backgroundColor: palette.hint.main,
      color: palette.hint.contrastText
    },
    "&$unpublished": {
      width: 130,
      borderColor: palette.success.main,
      color: palette.success.main
    },
    "&$unpublished:hover": {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText
    }
  },
  dropdownActionContainer: {
    position: "relative",
    display: "flex"
  },
  tooltip: {
    position: "absolute",
    right: 5,
    height: "100%"
  },
  groupsTitle: {
    whiteSpace: "nowrap"
  }
}));
