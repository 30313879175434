import * as React from "react";
import _ from "lodash";

import { useModuleState, actionTypes } from "../reducers/common";
import { fetchIndicators } from "../provider/indicators";

type UseIndicatorsInitializerOptions = {
  skip: boolean;
};

const useIndicatorsInitializer = (options: UseIndicatorsInitializerOptions) => {
  const [{ indicators, shouldRefetchIndicators }, dispatch] = useModuleState();

  React.useEffect(() => {
    if (!options.skip && (!indicators || shouldRefetchIndicators)) {
      shouldRefetchIndicators &&
        dispatch({
          type: actionTypes.SET_SHOULD_REFETCH_INDICATORS,
          payload: false
        });

      (async () => {
        try {
          const fetchedIndicators = await fetchIndicators();

          !_.isEqual(fetchedIndicators, indicators) &&
            dispatch({
              type: actionTypes.SET_INDICATORS,
              payload: fetchedIndicators
            });
        } catch (ex) {
          console.error(ex);
        }
      })();
    }
  }, [dispatch, indicators, options.skip, shouldRefetchIndicators]);
};

export default useIndicatorsInitializer;
