import { AxiosError } from "axios";

import { BASE_URLS } from "services/api";

import { getMessageFromError as getMessageFromErrorPython } from "./python";

export type ListResultsMeta = {
  total: number;
};

type ConvertibleParams = {
  [key: string]: any | any[];
};
export const convertToUrlSearchParams = (params: ConvertibleParams) => {
  const usp = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(v => {
        usp.append(key, v as string);
      });
    } else if (typeof value !== "undefined") {
      usp.set(key, value as string);
    }
  });

  return usp;
};

export const isNetworkError = (ex: AxiosError) =>
  !ex.response || !ex.response.data;

export const getMessageFromError = (error: AxiosError) => {
  if (error.config?.baseURL === BASE_URLS.PYTHON_API) {
    return getMessageFromErrorPython(error);
  }

  return error.message;
};
