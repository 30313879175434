import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  button: {
    fontSize: "1rem",
    marginBottom: spacing(2),
    fontWeight: 500,
    backgroundColor: "rgba(229, 229, 229, 0.6)",
    width: 160,
    height: 35,
    color: palette.text.default
  }
}));
