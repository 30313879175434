import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import * as Sentry from "@sentry/react";
import clsx from "clsx";

import { AllowedComponents, useTenant } from "services/tenant-provider";
import { useAuth } from "services/auth-provider";
import GuestRouter from "modules/guest/guest.router";
import CoursesMyRouter from "modules/courses/courses-my.router";
import CoursesRouter from "modules/courses/courses.router";
import AvailableCoursesRouter from "modules/courses/routers/available-courses.router";
import UsersRouter from "modules/users/users.router";
import CompaniesRouter from "modules/companies/companies.router";
import ProblemsRouter from "modules/problems/problems.router";
import ReportsView from "modules/reports/views/reports-list.view";
import { Button, LoadingPlaceholder } from "components";
import { logPageView } from "services/analytics";
import { UserRole } from "modules/users/users.constants";
import useStyles from "modules/guest/views/guest.styles";
import { useRedirect } from "utils/hooks/use-redirect";
import storage from "config/storage";
import routes from "config/routes";
import TenantsRouter from "modules/tenants/tenants.router";

const App = () => {
  const auth = useAuth();
  const tenant = useTenant();
  const location = useLocation();
  const renderRedirect = useRedirect();
  const classes = useStyles();

  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isTenantAdmin = auth.checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const isInternalAdmin = auth.checkAccess({
    roles: [UserRole.INTERNAL_ADMIN]
  });
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );
  const defaultAuthSAML =
    localStorage.getItem(storage.local.defaultAuthSAML) === "true";

  React.useEffect(
    () => {
      tenant.init();
      auth.init();
    },
    [] // eslint-disable-line
  );

  React.useEffect(() => {
    logPageView(location.pathname);
  }, [location.pathname]);

  if (!auth.isInitialized || !tenant.isInitialized) {
    return (
      <div className={classes.fullPageContainer}>
        <LoadingPlaceholder message="Initializing the application..." />
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    const saml = tenant.getSaml();
    const defaultSAMLRedirect = `${saml?.loginUrl}&RelayState=${saml?.callbackUrl}`;

    if (saml && defaultAuthSAML) {
      setTimeout(() => {
        window.location.href = defaultSAMLRedirect;
      }, 3000);

      return (
        <div className={clsx(classes.fullPageContainer, classes.column)}>
          <LoadingPlaceholder message="Redirecting to Global Logon..." />
          <Button
            type="button"
            onClick={() => {
              localStorage.removeItem(storage.local.defaultAuthSAML);
              window.location.reload();
            }}
          >
            Change authentication method
          </Button>
        </div>
      );
    }

    return <GuestRouter />;
  }

  Sentry.setUser({
    id: auth.user?.id,
    email: auth.user?.email,
    username: auth.user?.username
  });

  // Authorized user
  return (
    <Switch>
      {isAllowedTenantAvailableCourses && (
        <Route
          path={routes.myCourses.list}
          component={AvailableCoursesRouter}
        />
      )}

      <Route
        path={
          isAllowedTenantAvailableCourses
            ? routes.myCourses.available
            : routes.myCourses.list
        }
        component={CoursesMyRouter}
      />

      <Route path={routes.problems.list} component={ProblemsRouter} />

      {(isAdmin || isDesigner || isTenantAdmin) && (
        <Route path={routes.courses.list} component={CoursesRouter} />
      )}

      {(isAdmin || isTenantAdmin) && (
        <Route path={routes.users.list} component={UsersRouter} />
      )}

      {(isAdmin || isTenantAdmin) && (
        <Route path={routes.companies.list} component={CompaniesRouter} />
      )}

      {isAdmin && <Route path={routes.tenant.list} component={TenantsRouter} />}

      {(isAdmin || isInternalAdmin || isDesigner) && (
        <Route path={routes.reports.list} component={ReportsView} />
      )}

      {renderRedirect()}

      {(isAdmin || isDesigner) && <Redirect to={routes.courses.list} />}

      <Redirect to={routes.myCourses.list} />
    </Switch>
  );
};

export default App;
