import * as React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Field, Form } from "react-final-form";
import clsx from "clsx";

import { useAuth } from "services/auth-provider";
import routes from "config/routes";
import { Button, FormError, TextControl } from "components";
import { createValidator } from "utils/forms";
import GuestLayout, {
  BottomPanel,
  Content,
  SidePanel
} from "components/layouts/guest-layout/guest-layout";
import * as PythonApi from "services/api/python";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import storage from "config/storage";
import { AttLogo } from "assets/icons";
import { useSizeCheck } from "utils/hooks/useSizeCheck";

import LogoTitle from "../components/logo-title.component";

import useStyles from "./guest.styles";

const validator = createValidator({
  username: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } }
});

type Props = {};

const LoginView: React.FC<Props> = () => {
  const classes = useStyles();
  const auth = useAuth();
  const tenant = useTenant();

  const [defaultAuthSAML, setDefaultAuthSAML] = React.useState(
    localStorage.getItem(storage.local.defaultAuthSAML) === "true"
  );

  const saml = tenant.getSaml();
  const defaultSAMLRedirect = `${saml?.loginUrl}&RelayState=${saml?.callbackUrl}`;

  const isLoginScreenLogo = tenant.isComponentAllowed(
    AllowedComponents.LoginScreenLogo
  );

  const isSmall = useSizeCheck("sm");

  const onRememberSelection = (event: { target: { checked: boolean } }) => {
    setDefaultAuthSAML(event.target.checked);
    localStorage.setItem(
      storage.local.defaultAuthSAML,
      event.target.checked.toString()
    );
  };

  return (
    <GuestLayout>
      <SidePanel variant="register" />

      <div className={classes.loginContainer}>
        {isLoginScreenLogo && (
          <div className={classes.logoContainer}>
            <LogoTitle subTitle="Cybersecurity" />

            {!isSmall && (
              <div className={classes.flex}>
                <AttLogo className={classes.attLogo}></AttLogo>
                <LogoTitle subTitle="Business" />
              </div>
            )}
          </div>
        )}

        <Content title="Sign in" form className={classes.form}>
          <Form
            onSubmit={async ({ username, password }) => {
              try {
                await auth.loginWithUserAndPassword({
                  username,
                  password
                });
              } catch (ex) {
                return PythonApi.getFormErrors(ex);
              }
            }}
            validate={validator}
            initialValues={{
              username: "",
              password: ""
            }}
          >
            {({
              handleSubmit,
              submitError,
              submitting,
              hasValidationErrors,
              pristine
            }) => (
              <form onSubmit={handleSubmit}>
                {submitError && <FormError>{submitError}</FormError>}
                <Field
                  name="username"
                  label="Username"
                  variant="outlined"
                  component={TextControl}
                  margin="normal"
                  autoComplete="on"
                  validationIndicator
                />
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                  component={TextControl}
                  margin="normal"
                  autoComplete="on"
                  validationIndicator
                />
                <div className={classes.actionsContainer}>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="submit"
                      color="primary"
                      size={
                        tenant.isComponentAllowed(AllowedComponents.SAML) &&
                        saml
                          ? "medium"
                          : "large"
                      }
                      disabled={submitting || pristine || hasValidationErrors}
                      className={clsx(
                        classes.signInButton,
                        tenant.isComponentAllowed(AllowedComponents.SAML) &&
                          saml &&
                          classes.samlSignInButton
                      )}
                    >
                      Sign in
                    </Button>

                    <Box textAlign="right" style={{ margin: "auto 0" }}>
                      <Link
                        component={RouterLink}
                        to={routes.auth.forgotPassword}
                        color="primary"
                        variant="button"
                      >
                        Forgot password or username?
                      </Link>
                    </Box>
                  </div>
                  {tenant.isComponentAllowed(AllowedComponents.SAML) && saml && (
                    <>
                      <div className={classes.divider}>or</div>
                      <Button
                        type="button"
                        color="secondary"
                        size="medium"
                        rel="noopener noreferrer"
                        href={defaultSAMLRedirect}
                      >
                        Sign in with Global Logon
                      </Button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onRememberSelection}
                            checked={defaultAuthSAML}
                            color="secondary"
                          />
                        }
                        label="Use Global Logon as default authentication method"
                      />
                    </>
                  )}
                </div>

                <Typography variant="h4" className={classes.termsDisclaimer}>
                  By logging into the {tenant.getName()} portal, I acknowledge
                  that all materials provided to me hereunder are proprietary
                  and confidential to AT&T and are not for use, copying or
                  disclosure outside of AT&T except under written authorization.
                </Typography>
              </form>
            )}
          </Form>

          {isSmall && isLoginScreenLogo && (
            <div className={classes.mobileLogoContainer}>
              <AttLogo className={classes.attLogo}></AttLogo>
              <LogoTitle subTitle="Business" />
            </div>
          )}
        </Content>
      </div>

      <BottomPanel variant="register" />
    </GuestLayout>
  );
};

export default LoginView;
