import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    borderTop: `1px solid #e0e0e0`,
    padding: spacing(5, 0),
    marginTop: spacing(3)
  },
  text: {
    color: palette.hint.main
  }
}));
