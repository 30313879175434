import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, breakpoints }) => ({
  dialogPaper: {
    padding: spacing(2, 1),
    display: "flex",
    minWidth: "50vw",
    [breakpoints.up("sm")]: {
      padding: spacing(4)
    }
  },
  dialogContent: {
    padding: spacing(2, 1.5)
  },
  dialogHeaderTitle: {
    fontSize: "1.6rem",
    lineHeight: "1.6rem",
    marginRight: spacing(3),
    marginTop: spacing(1)
  },
  dialogHeaderHint: {
    marginLeft: spacing(0),
    marginTop: spacing(1),
    fontSize: "1.5rem",
    lineHeight: "1.7rem"
  },
  header: {
    flexWrap: "wrap",
    marginTop: spacing(-1)
  },
  dialogActions: {
    flexWrap: "wrap"
  },
  closeButton: {
    [breakpoints.down("xs")]: {
      order: 3,
      width: "50%"
    }
  },
  button: {
    [breakpoints.down("sm")]: {
      margin: spacing(1, 0)
    }
  }
}));
