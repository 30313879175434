import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, shape, spacing }) => ({
  root: {
    borderRadius: 2,
    padding: spacing(1),
    marginBottom: spacing(0.5),
    border: "none"
  },
  icon: {
    color: "inherit",
    fontSize: "inherit",
    marginLeft: 0
  },
  valid: {
    backgroundColor: palette.success.light,
    color: palette.success.main
  },
  invalid: {
    backgroundColor: palette.error.light,
    color: palette.error.main
  }
}));
