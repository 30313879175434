import * as React from "react";
import { Typography } from "@material-ui/core";

import { IconError } from "assets/icons";

import Placeholder from "./placeholder";

type Props = {
  error?: Error;
  inline?: boolean;
};

const ErrorPlaceholder: React.FC<Props> = ({ error, inline = false }) => (
  <Placeholder
    image={<IconError color="error" fontSize={inline ? "small" : "large"} />}
    message={
      <Typography color="error">
        {error ? error.message : "Something went wrong"}
      </Typography>
    }
    inline={inline}
  />
);

export default ErrorPlaceholder;
