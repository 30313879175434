import * as React from "react";
import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";

import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { Button } from "components";
import { IconTimerOutlined, IconVideoLibraryOutlined } from "assets/icons";
import { formatDuration } from "utils/datetime";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import LearnerActionByStatus from "../course-learner-action.component";

import useStyles from "./course-card-catalogue.styles";
import PrerequisiteCourses from "./prerequisite-courses";

type Props = {
  course: MyCoursesListItem;
  onOpenModal: () => void;
};

const CourseCardCatalogue: React.FC<Props> = ({ course, onOpenModal }) => {
  const classes = useStyles();
  const tenant = useTenant();
  const isStartFromAvailable = tenant.isComponentAllowed(
    AllowedComponents.StartFromAvailable
  );
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );
  const startNotAllowed = course.prerequisiteCourses?.find(c => !c.isFinished);
  const isPrerequisitesVisible =
    isCoursePrerequisites &&
    isStartFromAvailable &&
    course.prerequisiteCourses &&
    course.prerequisiteCourses?.length > 0;

  return (
    <>
      <Paper
        elevation={4}
        className={clsx(classes.root, course.assigned && classes.rootTriangle)}
        onClick={onOpenModal}
      >
        {course.assigned && (
          <div className={classes.assigned}>
            <IconVideoLibraryOutlined />
          </div>
        )}

        <div className={classes.contentContainer}>
          <Typography variant="h2" className={classes.header} paragraph>
            <span className={classes.name}>{course.name}</span>
          </Typography>
          <Typography variant="body2" className={classes.textWithIcon}>
            <IconTimerOutlined className={classes.icon} />
            {course.duration
              ? `Duration - ${formatDuration(
                  course.duration * 1000,
                  "H[h] mm[min]"
                )}`
              : "-"}
          </Typography>
          {isPrerequisitesVisible && (
            <PrerequisiteCourses
              prerequisiteCourses={course.prerequisiteCourses || []}
            />
          )}
        </div>

        {isStartFromAvailable ? (
          <div>
            <LearnerActionByStatus
              button
              {...{
                currentStatus: course.currentStatus || course.status,
                url: course.url,
                id: course.id,
                externalId: course.externalId,
                assigned: course.assigned,
                disabled: startNotAllowed && isCoursePrerequisites
              }}
            />
          </div>
        ) : (
          <div>
            <Button color="secondary" variant="outlined">
              Open course details
            </Button>
          </div>
        )}
      </Paper>
    </>
  );
};

export default CourseCardCatalogue;
