import * as React from "react";
import { Typography, Box, Grid, Link } from "@material-ui/core";
import { Form, Field } from "react-final-form";

import {
  FormError,
  Section,
  TextControl,
  Header,
  SelectControl,
  CancelConfirmButtons
} from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useUsersList } from "modules/users/provider";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";
import { createValidator } from "utils/forms";
import * as PythonApi from "services/api/python";
import { useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";
import { IconAttachFile } from "assets/icons";
import useToast from "utils/hooks/useToast";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";
import { OptionType as OptionTypeSelect } from "components/forms/select-control/select-control";

import {
  ProblemsListItem,
  useProblemDetailsUpdate
} from "../provider/problems";
import { statusSelectionOptions, ProblemStatuses } from "../problems.constants";

import useStyles from "./problem-edit-form.styles";

const validator = createValidator({
  status: {
    presence: {
      allowEmpty: false,
      message: "^Status has to be selected"
    }
  },
  assignee: {
    presence: {
      allowEmpty: false,
      message: "^Assignee has to be selected"
    }
  }
});

type Props = {
  problem?: ProblemsListItem;
  onCancel: () => void;
};

const EditProblemForm: React.FC<Props> = ({ onCancel, problem }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { showToast } = useToast();
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();

  const adminsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    filters: {
      roleName: PythonApi.prepareFilterValues([
        UserRole.ADMIN,
        UserRole.TENANT_ADMIN,
        UserRole.INTERNAL_ADMIN
      ])
    }
  });
  const { data: admins, loading: adminsLoading } = useUsersList({
    variables: { listFilters: adminsListFilters }
  });

  const adminsOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      admins?.map(admin => ({
        value: admin.id,
        label: `${admin.firstName} ${admin.lastName}`,
        parent: null
      })) || [],
    [admins]
  );

  const editProblem = useProblemDetailsUpdate();

  if (!problem) return <></>;

  return (
    <Form
      onSubmit={async ({ assignee, status, notes }) => {
        const shouldRefetchIndicators =
          (assignee === user?.id &&
            status in [ProblemStatuses.CLOSED, ProblemStatuses.CANCELED]) ||
          (problem?.assigneeId === user?.id && assignee !== user?.id);

        try {
          await editProblem({
            problemId: problem.id,
            data: {
              assignee_id: assignee,
              status,
              notes: notes ? notes.trim() : ""
            }
          });
          shouldRefetchIndicators && setShouldRefetchIndicatorsToTrue();
          showToast("The problem was successfully updated", "success");
        } catch (ex) {
          return PythonApi.getFormErrors(ex);
        }
      }}
      initialValues={{
        assignee: problem?.assigneeId,
        status: problem?.status,
        notes: problem?.notes ?? ""
      }}
      validate={validator}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        hasValidationErrors,
        pristine
      }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <FormError>{submitError}</FormError>}
          <Section margin="dense">
            <Header
              variant="formSection"
              number={1}
              title="Description"
              className={classes.header}
            />
            <Grid container item className={classes.gridContainer} spacing={3}>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography className={classes.description}>
                  {problem.description}
                </Typography>
                {Boolean(problem.fileUrl) && (
                  <Box className={classes.attachedFileContainer}>
                    <IconAttachFile color="secondary" />
                    <Link
                      className={classes.attachedFileLink}
                      href={problem.fileUrl}
                    >
                      Attached file
                    </Link>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Section>
          <Section margin="dense">
            <Header
              variant="formSection"
              number={2}
              title="Notes"
              hint="(optional)"
              className={classes.header}
            />
            <Grid
              container
              item
              className={classes.gridContainer}
              md={11}
              spacing={3}
            >
              <Grid item className={classes.gridItem}>
                <Field
                  name="notes"
                  label="Notes"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  multiline
                />
              </Grid>
            </Grid>
          </Section>

          <Section margin="dense">
            <Header
              variant="formSection"
              number={3}
              title="Assignee"
              className={classes.header}
            />
            <Grid
              container
              item
              className={classes.gridContainer}
              md={11}
              spacing={3}
            >
              <Grid item className={classes.gridItem}>
                <Field
                  name="assignee"
                  label="Assign person"
                  component={GroupTreeControl}
                  onlyOneFromBranch
                  options={adminsOptions}
                  isLoading={adminsLoading}
                  listFilters={adminsListFilters}
                />
              </Grid>
            </Grid>
          </Section>
          <Section margin="dense">
            <Header
              variant="formSection"
              number={4}
              title="Status"
              className={classes.header}
            />
            <Grid
              container
              item
              className={classes.gridContainer}
              md={11}
              spacing={3}
            >
              <Grid item className={classes.gridItem}>
                <Field
                  name="status"
                  label="Change status"
                  component={SelectControl}
                  options={statusSelectionOptions}
                />
              </Grid>
            </Grid>
          </Section>

          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel="Save details"
            confirmButtonProps={{ key: "edit-problem" }}
            onCancel={onCancel}
            justify="flex-start"
          />
        </form>
      )}
    </Form>
  );
};

export default EditProblemForm;
