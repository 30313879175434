import * as React from "react";
import { useParams } from "react-router";

import { useAuth } from "services/auth-provider";
import {
  AutoGrid,
  Button,
  ClosingModal,
  Drawer,
  ListToolbar,
  Search,
  SelectFilter
} from "components";
import useToast from "utils/hooks/useToast";
import { ListFiltersState } from "utils/hooks/useListFilters";
import CourseReport from "modules/common/components/course-report/course-report";
import GroupTreeFilter from "modules/common/components/group-tree/group-tree-filter";
import {
  UserCreateUpdateVars as UserCreateUpdateVarsType,
  UserItem as UserItemType
} from "modules/users/provider/users";
import { useUserCreate } from "modules/users/provider";
import { CourseItem } from "modules/courses/provider/courses";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import {
  UserRole,
  userRoleOptions,
  userStatusOptions
} from "../../users.constants";
import AddUser from "../user-record/user-record";

import useStyles from "./users-list-toolbar.styles";

type UsersListToolbarProps = {
  users: UserItemType[];
  course?: CourseItem;
  refetch: () => void;
  listFilters: ListFiltersState;
};

const UsersListToolbar: React.FC<UsersListToolbarProps> = ({
  users,
  refetch,
  listFilters,
  course
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const createUser = useUserCreate();
  const { showToast } = useToast();
  const { courseId, companyId } = useParams();
  const { isComponentAllowed } = useTenant();

  const [isDrawerOpen, setDrawerOpen] = React.useState();
  const isCourseDetailsView = Boolean(courseId);
  const isCompanyDetailsView = Boolean(companyId);
  const isCompanyCourseDetailsView = Boolean(courseId) && Boolean(companyId);

  const isNoSponsorAndAdmins = isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );

  const handleAddSubmit = async (values: UserCreateUpdateVarsType) => {
    await createUser(values);
    listFilters.setPagination({
      offset: 0,
      rowsPerPage: listFilters.pagination.rowsPerPage
    });
    refetch();
    setDrawerOpen(false);
    showToast("The user was successfully added to the list", "success");
  };

  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isInternal = auth.checkAccess({ roles: [UserRole.INTERNAL_ADMIN] });

  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  const getRoleOptions = () => {
    let roles = userRoleOptions;
    if (!isAdmin) {
      roles = roles.filter(
        role =>
          role.value !== UserRole.ADMIN && role.value !== UserRole.DESIGNER
      );
    }
    if (isNoSponsorAndAdmins) {
      roles = roles.filter(
        role =>
          role.value !== UserRole.TENANT_ADMIN &&
          role.value !== UserRole.INTERNAL_ADMIN
      );
    }
    return roles;
  };

  const leftOptions = (
    <AutoGrid spacing={2} className={classes.gridContainer}>
      <Search listFilters={listFilters} />
      {(isAdmin || isInternal) &&
        !isCourseDetailsView &&
        !isCompanyDetailsView && (
          <GroupTreeFilter
            label="Company"
            listFilters={listFilters}
            //name="groupId" // todo: Why FE runtime crashes? Maybe because of `groupId: "in"`
            name="groupIds" // temporary BE fix
            allowMultiple
          />
        )}
      {!isCourseDetailsView && !isCompanyDetailsView && (
        <SelectFilter
          label="Status"
          listFilters={listFilters}
          name="authStatus"
          options={userStatusOptions}
        />
      )}
      {!isCourseDetailsView && !isCompanyDetailsView && (
        <SelectFilter
          label="User role"
          listFilters={listFilters}
          name="roleName"
          options={getRoleOptions()}
        />
      )}
    </AutoGrid>
  );

  const rightOptions = (
    <>
      <AutoGrid spacing={2} justify="flex-end">
        {(isCourseDetailsView || isCompanyCourseDetailsView) &&
          course &&
          !isComponentAllowed(AllowedComponents.ReportCourseDetails) && (
            <CourseReport
              id={course.id}
              name={course.name}
              isDisabled={users.length === 0}
            />
          )}

        {!Boolean(courseId) && (
          <Button onClick={() => setDrawerOpen(true)} color="secondary">
            Add new user
          </Button>
        )}
      </AutoGrid>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Add new user"
        size="md"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <AddUser
          onSubmit={handleAddSubmit}
          onCloseDrawer={() => setDrawerOpen(false)}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );

  return <ListToolbar left={leftOptions} right={rightOptions} />;
};

export default UsersListToolbar;
