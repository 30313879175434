import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: "flex",
    justifyContent: "center",

    flexGrow: 0,
    [breakpoints.up("md")]: {
      flexGrow: 1
    }
  },
  formContainer: {
    marginBottom: spacing(2),

    [breakpoints.up("sm")]: {
      paddingTop: spacing(10)
    },
    [breakpoints.up("md")]: {
      padding: spacing(10, 14),
      justifyContent: "flex-start"
    }
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: 0
  },
  titles: {
    textAlign: "left",
    margin: spacing(2, 0),

    [breakpoints.up("sm")]: {
      textAlign: "center"
    },
    [breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600
  },
  text: {
    marginTop: spacing(3),
    textAlign: "left",
    maxWidth: 350,

    [breakpoints.only("sm")]: {
      textAlign: "center"
    }
  }
}));
