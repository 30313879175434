import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess } from "services/auth-provider";
import {
  useModuleState,
  actionTypes
} from "modules/problems/reducers/problems";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import ProblemsListView from "../views/problems-list.view";

type Props = {
  match: match;
};

const ProblemsListRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  const tenant = useTenant();

  const isProblemsUserDetails = tenant.isComponentAllowed(
    AllowedComponents.ProblemsUserDetails
  );

  React.useEffect(
    () => () => {
      if (isProblemsUserDetails && sessionStorage.getItem("problemId")) {
        return;
      }
      dispatch({ type: actionTypes.RESET_PROBLEMS_FILTERS });
    },
    [dispatch, isProblemsUserDetails]
  );
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_ASSIGNEES_FILTERS });
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route exact path={match.path}>
        <HasAccess roles={[UserRole.ADMIN, UserRole.TENANT_ADMIN]}>
          <ProblemsListView />
        </HasAccess>
        <HasAccess roles={[UserRole.DESIGNER, UserRole.STUDENT]}>
          <Redirect to={`${match.path}/report`} />
        </HasAccess>
      </Route>
    </Switch>
  );
};

export default ProblemsListRouter;
