import { makeStyles } from "@material-ui/core";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  container: {
    right: 0,
    top: 0,
    marginTop: spacing(-1)
  },
  toast: {
    borderRadius: 0,
    [breakpoints.up("sm")]: {
      borderRadius: "0px 0px 0px 10px"
    }
  },
  success: {
    backgroundColor: palette.success.main
  },
  error: {
    backgroundColor: palette.error.main
  },
  info: {
    backgroundColor: palette.info.main
  }
}));
