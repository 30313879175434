import * as React from "react";
import { match } from "react-router";

import {
  BackButton,
  Header,
  Section,
  AppLayout,
  LoadingPlaceholder,
  ErrorPlaceholder,
  ApiResultRenderer
} from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useUserCoursesList } from "modules/courses/provider/user-courses";
import {
  useCompaniesList,
  CompaniesListFilters
} from "modules/companies/provider/companies";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import {
  UserHeader,
  UserInfo,
  UserStatsCards,
  CoursesList
} from "../components/user-details";
import { useUserRead } from "../provider";
import { UserRole } from "../users.constants";
import GroupList from "../components/user-details/group-list.component";

type Props = {
  match: match<{ userId: string }>;
};

const UserDetailsView: React.FC<Props> = ({ match }) => {
  const { userId } = match.params;
  const tenant = useTenant();

  const isProblemsUserDetails = tenant.isComponentAllowed(
    AllowedComponents.ProblemsUserDetails
  );

  const { data: user, loading, error, refetch } = useUserRead({
    variables: { userId }
  });

  const shouldDisplayGroups = user?.role
    ? [UserRole.TENANT_ADMIN, UserRole.STUDENT].includes(user?.role)
    : false;

  const listFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  });
  const userCourses = useUserCoursesList({
    variables: {
      id: userId,
      listFilters
    }
  });

  const groupsListFilters = useListFilters<CompaniesListFilters>({
    pagination: { rowsPerPage: 10 },
    filters: { membership__user__id: userId }
  });

  const groups = useCompaniesList({
    variables: { listFilters: groupsListFilters },
    skip: !shouldDisplayGroups
  });

  if (loading || error || !user) {
    return (
      <AppLayout viewTitle="User Details">
        {loading ? <LoadingPlaceholder /> : <ErrorPlaceholder error={error} />}
      </AppLayout>
    );
  }

  return (
    <AppLayout viewTitle="User Details" hideSmUp>
      {isProblemsUserDetails && sessionStorage.getItem("problemId") && (
        <BackButton />
      )}

      <UserHeader user={user} refetch={refetch} />

      <Section margin="dense">
        <UserInfo user={user} />
      </Section>
      <Section margin="big">
        <UserStatsCards user={user} />
      </Section>

      {shouldDisplayGroups && (
        <Section margin="big">
          <ApiResultRenderer apiResult={groups} reloadsSection={false}>
            {({ data = [], meta, loading }) => (
              <>
                <Header
                  variant="section"
                  title={`Groups (${(meta && meta.total) || 0})`}
                />
                <GroupList
                  groups={data}
                  meta={meta!}
                  listFilters={groupsListFilters}
                  loading={loading}
                />
              </>
            )}
          </ApiResultRenderer>
        </Section>
      )}

      <Section margin="big">
        <ApiResultRenderer apiResult={userCourses} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Courses (${(meta && meta.total) || 0})`}
              />
              <CoursesList
                courses={data}
                meta={meta!}
                listFilters={listFilters}
                loading={loading}
                user={user}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default UserDetailsView;
