import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(1)
  },
  item: {
    padding: spacing(1.5, 3),
    margin: 0
  },
  date: {
    color: palette.hint.main,
    "&$unread": {
      color: palette.text.default
    }
  },
  title: {
    color: palette.text.default,
    "&$unread": {
      fontWeight: "bold"
    }
  },
  unread: {}
}));
