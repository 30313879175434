import * as React from "react";

import { UserRole } from "modules/users/users.constants";

import { AllowedComponents, useTenant } from "../../tenant-provider";

import { useAuth } from "..";

interface IProps {
  children: React.ReactNode;
  roles?: Array<UserRole> | UserRole;
  permissions?: string[] | string;
  componentAccess?: AllowedComponents | Array<AllowedComponents>;
}

const HasAccess: React.FC<IProps> = ({
  children,
  roles,
  permissions,
  componentAccess
}) => {
  const auth = useAuth();
  const tenant = useTenant();

  if (!auth) {
    return null;
  }

  if (
    auth.checkAccess({
      permissions:
        typeof permissions === "string" ? [permissions] : permissions,
      roles: typeof roles === "string" ? [roles] : roles
    }) &&
    ((componentAccess && tenant.isComponentAllowed(componentAccess)) ||
      !componentAccess)
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
};

export default HasAccess;
