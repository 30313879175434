import * as React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";
import { InputLabelProps } from "@material-ui/core/InputLabel";

import { IconCheckCircle, IconError } from "assets/icons";

import useStyles from "./text-control.styles";

type Props = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  validationIndicator?: boolean;
  inputLabelProps?: Partial<InputLabelProps>;
};

const TextControl: React.FC<Props> = ({
  label,
  input: { value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error, touched },
  validationIndicator = false,
  inputLabelProps = {},
  ...restTextField
}) => {
  const classes = useStyles();

  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  const labelId = `label-${name}`;

  let endAdornment;
  if (validationIndicator && isDirty) {
    endAdornment = (
      <InputAdornment position="end">
        {hasErrors ? (
          <IconError className={classes.indicatorError} />
        ) : (
          <IconCheckCircle className={classes.indicatorSuccess} />
        )}
      </InputAdornment>
    );
  }

  return (
    <TextField
      {...restTextField}
      label={label}
      name={name}
      helperText={showError ? error || submitError : undefined}
      error={showError}
      InputProps={{
        inputProps: {
          autoComplete: "off",
          spellCheck: false,
          autoCorrect: "off",
          autoCapitalize: "none",
          "aria-labelledby": labelId,
          ...restInput
        },
        endAdornment
      }}
      InputLabelProps={{
        id: labelId,
        ...inputLabelProps
      }}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextControl;
