import axios from "axios";

import * as interceptors from "config/interceptors";
import apiConfig from "config/api";

export const BASE_URLS = {
  PYTHON_API: "/api/v2/"
};

const API = axios.create({
  responseType: "json",
  baseURL: BASE_URLS.PYTHON_API,
  timeout: apiConfig.timeout
});

export const StorageAPI = axios.create({
  responseType: "json",
  timeout: apiConfig.timeout
});

StorageAPI.interceptors.response.use(
  interceptors.cameliseDeepInterceptor,
  interceptors.cameliseDeepErrorInterceptor
);
StorageAPI.interceptors.request.use(interceptors.addIETimeStampInterceptor);

API.interceptors.response.use(
  interceptors.cameliseDeepInterceptor,
  interceptors.cameliseDeepErrorInterceptor
);
API.interceptors.response.use(undefined, interceptors.refreshAuthInterceptor);
API.interceptors.request.use(interceptors.addIETimeStampInterceptor);
API.interceptors.request.use(interceptors.snakeCaseDeepInterceptor);

export default API;
