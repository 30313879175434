import * as React from "react";

import { IconCreate, IconDelete } from "assets/icons";
import { useAuth } from "services/auth-provider";
import { Button, AutoGrid } from "components";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { LearningPlanItem } from "../../../provider/learning-plan";

import { InitialStateType } from "./learning-plans-list";

type Props = {
  learningPlan: LearningPlanItem;
  setLearningPlanForEdition: React.Dispatch<
    React.SetStateAction<InitialStateType>
  >;
  setLearningPlanDeletionModalState: React.Dispatch<
    React.SetStateAction<InitialStateType>
  >;
};

const LearningPlanListRowActions: React.FC<Props> = ({
  learningPlan,
  setLearningPlanDeletionModalState,
  setLearningPlanForEdition
}) => {
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });
  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );

  return (
    <AutoGrid spacing={2} wrap="nowrap" justify="space-between">
      {(isAdmin || isDesigner) && isLearningPlans && (
        <>
          <Button
            onClick={() => {
              setLearningPlanForEdition({
                learningPlan,
                isOpen: true
              });
            }}
            variant="text"
            icon={IconCreate}
          >
            Edit
          </Button>
          {!isDesigner && (
            <Button
              onClick={() => {
                setLearningPlanDeletionModalState({
                  learningPlan,
                  isOpen: true
                });
              }}
              variant="text"
              icon={IconDelete}
              color="error"
              defaultHoverColor
            >
              Delete
            </Button>
          )}
        </>
      )}
    </AutoGrid>
  );
};

export default LearningPlanListRowActions;
