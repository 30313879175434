import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  iconsList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end"
  },
  button: {
    color: "inherit",
    padding: spacing(1),
    minWidth: spacing(4)
  },
  buttonActive: {
    color: palette.primary.main
  },
  icon: {
    fontSize: "1.8rem",
    [breakpoints.down("md")]: {
      fontSize: "2.5rem"
    }
  }
}));
