import * as React from "react";
import { Typography } from "@material-ui/core";
import parseHTML from "html-react-parser";

import { Button, Header, Section } from "components";
import { removeHtmlTags } from "utils/strings";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { PrerequisiteCourse } from "../../../provider/courses";

import PrerequisitesCourses from "./prerequisites-courses";
import useStyles from "./course-description.styles";

export type CourseDescriptionProps = {
  notes?: string;
  description: string;
  links?: Array<{
    url: string;
    title: string;
  }>;
  prerequisiteCourses?: PrerequisiteCourse[];
};

const CourseDescription: React.FC<CourseDescriptionProps> = ({
  notes,
  description,
  links,
  prerequisiteCourses
}) => {
  const classes = useStyles();
  const tenant = useTenant();

  const isNotesHaveText = notes && Boolean(removeHtmlTags(notes));
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );

  return (
    <div className={classes.infoContainer}>
      <Section margin="normal">
        {description && (
          <>
            <Header variant="section" title="Description" />
            <Typography
              variant="body1"
              className={classes.text}
              component="div"
            >
              {parseHTML(description)}
            </Typography>
          </>
        )}
        {links && links.length > 0 && (
          <>
            <Typography
              variant="h4"
              className={classes.linksTitle}
              component="div"
            >
              Links to more information:
            </Typography>
            <div className={classes.linksContainer}>
              {links.map(link => (
                <Button
                  key={`${link.url}-${link.title}`}
                  variant="text"
                  color="secondary"
                  underline
                  href={link.url || ""}
                  disabled={!link.url}
                  target="_blank"
                  rel="noreferer noopener"
                >
                  {link.title || "untitled link"}
                </Button>
              ))}
            </div>
          </>
        )}
      </Section>
      {isNotesHaveText && (
        <Section margin="normal">
          <Header variant="section" title="Notes" />
          <Typography variant="body1" className={classes.text} component="div">
            {parseHTML(notes!)}
          </Typography>
        </Section>
      )}
      {isCoursePrerequisites &&
        prerequisiteCourses &&
        prerequisiteCourses?.length > 0 && (
          <PrerequisitesCourses
            prerequisitesCourses={prerequisiteCourses}
            view="admin"
          />
        )}
    </div>
  );
};

export default CourseDescription;
