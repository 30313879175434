import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "config/theme";

export default makeStyles(({ breakpoints, spacing }: CustomTheme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "stretch",
    padding: 0,
    alignItems: "stretch"
  },
  container: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "stretch",
    alignItems: "stretch",
    borderRadius: 0,
    flexDirection: "column",

    [breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: spacing(4),
    flexGrow: 1,
    justifyContent: "center",

    [breakpoints.up("sm")]: {
      justifyContent: "stretch",
      padding: 0
    },
    [breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "flex-start"
    }
  },
  wrapper: {
    display: "flex" // to prevent IE 11 bug with minHeight it's required to wrap element that's
  } // using minHeight with another element with display: flex property.
}));
