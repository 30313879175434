import * as React from "react";

import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useListSelection from "utils/hooks/useListSelection";
import { UserItem as UserItemType } from "modules/users/provider/users";
import { AutoGrid } from "components";

import UsersListPagination from "../user-list/users-list-pagination";
import UsersListSubActions from "../user-list/users-list-sub-actions";

import ApprovedUsersListToolbar from "./approved-users-list-toolbar";
import ApprovedUsersTable from "./approved-users-list";

type ApprovedUsersListContainerProps = {
  users: UserItemType[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  companyId?: string;
  loading: boolean;
};

const ApprovedUsersListContainer: React.FC<ApprovedUsersListContainerProps> = ({
  users,
  meta,
  listFilters,
  refetch,
  loading
}) => {
  const listSelection = useListSelection(users);
  const selectedUserIds = listSelection.actions.getSingleField("id");
  const clearSelection = () => listSelection.actions.unselectAll();
  return (
    <>
      <ApprovedUsersListToolbar {...{ listFilters }} />
      <ApprovedUsersTable
        {...{ users, loading, refetch, listSelection, listFilters }}
      />
      <AutoGrid justify="space-between" showEmpty>
        <UsersListSubActions
          {...{ refetch, selectedUserIds, clearSelection }}
        />
        <UsersListPagination {...{ meta, listFilters }} />
      </AutoGrid>
    </>
  );
};

export default ApprovedUsersListContainer;
