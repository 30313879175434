import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess } from "services/auth-provider";
import CourseDetailsAdminView, {
  Match as CourseDetailsAdminViewMatch
} from "modules/courses/views/course-details.view";
import { UserRole } from "modules/users/users.constants";

import { useModuleState, actionTypes } from "./reducers/companies";
import CompaniesView from "./views/company-list.view";
import CompanyDetailsAdminView, {
  Match as CompanyDetailsAdminViewMatch
} from "./views/company-details.admin.view";

type Props = {
  match: match;
};

const CompaniesRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_COMPANIES_FILTERS });
    },
    [dispatch]
  );

  return (
    <Switch>
      {/* COMPANIES LIST */}
      <Route path={match.path} exact component={CompaniesView} />

      {/* COURSES */}
      <Route exact path={`${match.path}/:companyId/courses/:courseId`}>
        {({ match }) => (
          <HasAccess roles={[UserRole.TENANT_ADMIN]}>
            <CourseDetailsAdminView
              match={match as CourseDetailsAdminViewMatch}
            />
          </HasAccess>
        )}
      </Route>

      {/* COMPANY DETAILS */}
      <Route exact path={`${match.path}/:companyId`}>
        {({ match }) => (
          <HasAccess roles={[UserRole.ADMIN, UserRole.TENANT_ADMIN]}>
            <CompanyDetailsAdminView
              key={match!.params.companyId}
              match={match as CompanyDetailsAdminViewMatch}
            />
          </HasAccess>
        )}
      </Route>

      <Redirect to={match.path} />
    </Switch>
  );
};

export default CompaniesRouter;
