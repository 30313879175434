import * as React from "react";
import { arrayToTree } from "performant-array-to-tree";
import { Typography } from "@material-ui/core";

import config from "config";

import Tree from "./tree";
import useStyles from "./tree.styles";

type TreeOptionProp = { value: string; label: string; parent?: string };

const TreeContainer: React.FC<{
  options: Array<TreeOptionProp>;
  isLoading: boolean;
  infiniteScroll?: boolean;
}> = ({ options, isLoading, infiniteScroll }) => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = React.useState(1);

  const groupTree = React.useMemo(
    () =>
      arrayToTree(
        infiniteScroll
          ? options.slice(
              0,
              pageNumber *
                config.components.TreeContainer.infiniteScrollRowsPerPage
            )
          : options,
        {
          id: "value",
          parentId: "parent"
        }
      ),
    [infiniteScroll, options, pageNumber]
  );

  const handleListScroll: React.UIEventHandler = ({ target }: any) => {
    if (infiniteScroll) {
      const isEnd =
        target.scrollHeight - target.scrollTop === target.clientHeight;

      if (
        isEnd &&
        options.length >
          pageNumber * config.components.TreeContainer.infiniteScrollRowsPerPage
      ) {
        setPageNumber(prev => prev + 1);
      }
    }
  };

  if (isLoading) {
    return (
      <Typography variant="h3" className={classes.notify}>
        Loading...
      </Typography>
    );
  }

  if (groupTree.length === 0) {
    return (
      <Typography variant="h3" className={classes.notify}>
        No results
      </Typography>
    );
  }

  return (
    <div className={classes.list} onScroll={handleListScroll}>
      {groupTree.map(({ data: { value, label }, children: treeChildren }) => (
        <Tree key={value} {...{ value, label, treeChildren }} />
      ))}
    </div>
  );
};

export default TreeContainer;
