import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  checkContainer: {
    display: "flex",
    alignContent: "center",
    color: palette.text.default
  },
  checkLabel: {
    fontSize: "0.875rem"
  },
  infoIcon: {
    display: "flex",
    alignItems: "center",
    color: palette.success.main
  },
  loading: {
    width: "100%",
    justifyContent: "center",
    marginTop: spacing(6)
  }
}));
