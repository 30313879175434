import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "config/routes";

import LoginView from "./views/login.view";
import RegistrationView from "./views/registration.view";
import SetNewPasswordSuccessView from "./views/set-new-password-success.view";
import SetNewPasswordExpiredView from "./views/set-new-password-expired.view";
import RegistrationSuccessView from "./views/registration-success.view";
import SetNewPasswordView from "./views/set-new-password.view";
import ResetPasswordView from "./views/reset-password.view";
import ResetPasswordSuccessView from "./views/reset-password-success.view";
import ReportProblemView from "./views/report-problem.view";

type Props = {};

const GuestRouter: React.FC<Props> = () => (
  <Switch>
    <Route exact path={routes.auth.login} component={LoginView} />
    <Route exact path={routes.auth.register} component={RegistrationView} />
    <Route
      exact
      path={routes.auth.setNewPasswordSuccess}
      component={SetNewPasswordSuccessView}
    />
    <Route
      exact
      path={routes.auth.setNewPasswordExpired}
      component={SetNewPasswordExpiredView}
    />
    <Route
      exact
      path={routes.auth.forgotPasswordSuccess}
      component={ResetPasswordSuccessView}
    />
    <Route
      exact
      path={routes.auth.registrationSuccess}
      component={RegistrationSuccessView}
    />
    <Route
      exact
      path={routes.auth.setNewPassword}
      component={SetNewPasswordView}
    />
    <Route
      exact
      path={routes.auth.forgotPassword}
      component={ResetPasswordView}
    />
    <Route
      exact
      path={routes.auth.reportProblem}
      component={ReportProblemView}
    />
    <Redirect to={routes.auth.login} />
  </Switch>
);

export default GuestRouter;
