import * as React from "react";

import { Section, AppLayout } from "components";

import ReportsListContainer from "../components/reports-list-container";

const ReportsView: React.FC = () => {
  return (
    <AppLayout viewTitle="Reports">
      <Section>
        <ReportsListContainer />
      </Section>
    </AppLayout>
  );
};

export default ReportsView;
