import React, { useState, useEffect } from "react";
import {
  DraggableProvidedDragHandleProps,
  DropResult
} from "react-beautiful-dnd";
import { FormHelperText } from "@material-ui/core";
import { FieldArray } from "react-final-form-arrays";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useForm, useFormState } from "react-final-form";

import { Header, Section } from "components";

import CourseChip from "../components/course-chip";
import DragNDrop from "../components/drag-n-drop";

import useStyles from "./courses-list.styles";

const CoursesList: React.FC = () => {
  const { values, errors, dirtyFields } = useFormState();
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (!visited) {
      const dirty = Object.keys(dirtyFields)?.find(key => key === "courses");
      dirty && setVisited(true);
    }
  }, [dirtyFields, visited]);

  const { mutators } = useForm();
  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;
    mutators.move("courses", source.index, destination.index);
  };

  return (
    <Section margin="dense">
      <Header variant="formSection" title="Added courses" />
      {values.courses?.length === 0 ? (
        <CourseChip
          label="There are no courses on the list. Please add new one"
          empty={true}
        />
      ) : (
        <div>
          <FieldArray name="courses">
            {({ fields }) => (
              <DragNDrop
                onDragEnd={onDragEnd}
                items={values.courses}
                onRemove={fields.remove}
              />
            )}
          </FieldArray>
        </div>
      )}
      {errors.courses && visited && (
        <FormHelperText className={classes.error}>
          {errors.courses}
        </FormHelperText>
      )}
    </Section>
  );
};

export default CoursesList;

type DragIndicatorProps = {
  draggableProps: DraggableProvidedDragHandleProps | undefined;
};

export const DragIndicator: React.FC<DragIndicatorProps> = ({
  draggableProps
}) => {
  const classes = useStyles();
  return (
    <div {...draggableProps}>
      <DragIndicatorIcon className={classes.dragIndicator} />
    </div>
  );
};
