import * as PythonApi from "services/api/python";
import { useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export const useGenerateCourseReportMutation = () => {
  type Vars = {
    courseId: string;
  };
  type Item = {
    id: string;
    createdAt: string;
    status: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(({ courseId }) => ({
      method: "POST",
      url: apiConfig.paths.authorized.report.createForCourse(courseId)
    }))
  );
};

export const useAllAvailableCoursesReportMutation = () => {
  return useFetchMutation(
    PythonApi.makeMutation(() => ({
      method: "POST",
      url: apiConfig.paths.authorized.report.createForAllAvailableCoursesUsers
    }))
  );
};
