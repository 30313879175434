import * as PythonApi from "services/api/python";
import { useFetch, useFetchMutation, FetchOptions } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export const useRegisterMutation = () => {
  type Vars = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    groupId?: string;
    companyName?: string;
    voucherNumber?: string;
    username: string;
    workAddress: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.unauthorized.register,
      data: variables
    }))
  );
};

export const useForgotPasswordMutation = () => {
  type Vars = {
    email: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.unauthorized.requireRecoverPassword,
      data: variables
    }))
  );
};

export const useChangePasswordMutation = () => {
  type Vars = {
    id: string; // User ID
    token: string;
    newPassword: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.unauthorized.changePassword,
      data: variables
    }))
  );
};

type CheckTokenVars = {
  id: string; // User ID
  token: string;
};

const createCheckTokenConfig = (variables: CheckTokenVars) =>
  PythonApi.makeRequestForOne<null>({
    method: "POST",
    url: apiConfig.paths.unauthorized.recoverPasswordVerifyKey,
    data: variables
  });

export const useCheckToken = (options: FetchOptions<CheckTokenVars>) =>
  useFetch(createCheckTokenConfig(options.variables), options);
