import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ breakpoints, palette, spacing, shadows }: CustomTheme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      fontWeight: 600,
      minWidth: 300,
      textAlign: "center",
      padding: spacing(10),
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,

      [breakpoints.up("md")]: {
        marginBottom: spacing(0)
      },
      [breakpoints.up("lg")]: {
        width: "25%"
      }
    },
    rootReversedPalette: {
      backgroundColor: palette.primary.contrastText,
      color: palette.text.default,
      boxShadow: shadows[7],
      "& $button, & $textButton": {
        color: palette.primary.main
      }
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    title: {
      fontWeight: 700,
      marginBottom: spacing(3),
      fontSize: "2.25rem",
      minWidth: 230,
      [breakpoints.up("md")]: {
        marginBottom: spacing(1.5),
        fontSize: "4rem"
      }
    },
    text: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontSize: "1.125rem",
      marginBottom: spacing(3),

      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
        marginBottom: spacing(6)
      }
    },
    button: {
      fontWeight: "bold",
      display: "inline-block",
      fontSize: "1.125rem",

      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
        width: 220,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    textButton: {
      fontSize: "1rem",
      fontWeight: 600,

      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline"
      }
    }
  })
);
