import * as React from "react";
import { Field } from "react-final-form";
import { Grid, Tooltip } from "@material-ui/core";

import { CheckboxControl } from "components";
import { IconInfo } from "assets/icons";

import useStyles from "./user-form-sections.styles";

type UserActivationSectionProps = {};

const UserActivationSection: React.FC<UserActivationSectionProps> = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <div className={classes.checkContainer}>
        <Field
          name="isAccepted"
          color="primary"
          type="checkbox"
          label={<span className={classes.checkLabel}>Activate user</span>}
          component={CheckboxControl}
        />

        <Tooltip
          title="A message about activation will be sent to users."
          placement="bottom-start"
        >
          <div className={classes.infoIcon}>
            <IconInfo />
          </div>
        </Tooltip>
      </div>
    </Grid>
  );
};

export default UserActivationSection;
