import * as React from "react";
import { Field, Form } from "react-final-form";
import moment, { Moment } from "moment";
import { Grid, Typography } from "@material-ui/core";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import {
  ConfirmationDialog,
  KeyboardDatePickerControl,
  FormError,
  SelectControl
} from "components";
import * as PythonApi from "services/api/python";
import { createValidator, isDateBetween } from "utils/forms";

import {
  checkIfReportIsGenerated,
  ReportStatus,
  usePrepareReportUrl,
  useReportDownloadCallback,
  useReportStatusCallback
} from "../../modules/common/provider/reports";
import { downloadBlob } from "../../utils/fileDownload";
import useToast from "../../utils/hooks/useToast";

import useStyles from "./report-modal.styles";

export type ReportSubmitData = {
  reportType: string; // value
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
};

type Child = {
  generating: boolean;
  handleOpen: () => void;
};

type NonEmptyArray<T> = [T, ...T[]];

type Props = {
  filePrefix: string;
  reportTypes: NonEmptyArray<ReportTypeProps>; // cannot be blank
  startDate?: ParsableDate;
  children: (props: Child) => React.ReactNode;
};

type ReportTypeProps = {
  label: string;
  value: string; // cannot repeat
  requestPath: string;
  body?: Record<string, any>;
};

const validator = createValidator({
  reportType: { presence: { allowEmpty: false } },
  dateFrom: {
    presence: { allowEmpty: false }
  },
  dateTo: {
    presence: { allowEmpty: false }
  }
});

const dateTo = moment(); // Today

const ReportModal: React.FC<Props> = ({
  filePrefix,
  startDate = moment("2020-01-01"),
  reportTypes,
  children
}) => {
  const initialValues = {
    reportType: reportTypes[0].value,
    dateFrom: moment(dateTo).subtract(1, "months"),
    dateTo: dateTo
  };

  const classes = useStyles();
  const { showToast } = useToast();
  const prepareReportUrl = usePrepareReportUrl();
  const getReportStatus = useReportStatusCallback();
  const downloadReport = useReportDownloadCallback();

  const [reportIsBeingGenerated, setReportIsBeingGenerated] = React.useState(
    false
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onSuccessfulReportGeneration = React.useCallback(() => {
    showToast(
      "Your report was prepared and it'll be downloaded now",
      "success"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
    setReportIsBeingGenerated(false);
  }, [showToast]);

  const onUnsuccessfulReportGeneration = React.useCallback(() => {
    showToast(
      "Something went wrong, try again in a moment or report a problem",
      "error"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
    setReportIsBeingGenerated(false);
  }, [showToast]);

  const downloadReportAndBlob = React.useCallback(
    async (reportId: string, filename: string) => {
      const { data: fileBlob } = await downloadReport({ reportId });
      fileBlob && downloadBlob(fileBlob, filename);
      onSuccessfulReportGeneration();
    },
    [downloadReport, onSuccessfulReportGeneration]
  );

  // Recursion is introduced because request time is volatile
  // It's better to make another requests after earlier request is finished
  // Alternative is to use interval, but it's prone to errors
  const downloadReportWhenReady = React.useCallback(
    async (reportId: string, filename: string) => {
      try {
        const { data: reportStatusData } = await getReportStatus({ reportId });

        if (reportStatusData?.status === ReportStatus.FAILURE) {
          onUnsuccessfulReportGeneration();
          return;
        }

        if (checkIfReportIsGenerated(reportStatusData?.status)) {
          await downloadReportAndBlob(reportId, filename);
          return;
        } else {
          setTimeout(() => downloadReportWhenReady(reportId, filename), 2000);
        }
      } catch (ex) {
        onUnsuccessfulReportGeneration();
      }
    },
    [downloadReportAndBlob, getReportStatus, onUnsuccessfulReportGeneration]
  );

  const onReportSubmit = React.useCallback(
    async ({ dateFrom, dateTo, reportType }: ReportSubmitData) => {
      try {
        setReportIsBeingGenerated(true);

        const { data: generatedReportData } = await prepareReportUrl({
          path: reportTypes.find(e => e.value === reportType)!.requestPath,
          dateFrom: dateFrom,
          dateTo: dateTo,
          body: reportTypes.find(e => e.value === reportType)?.body
        });

        if (generatedReportData) {
          const filename = `${filePrefix}-report-${moment(
            generatedReportData.createdAt
          ).format("L")}.xlsx`;

          if (checkIfReportIsGenerated(generatedReportData.status)) {
            await downloadReportAndBlob(generatedReportData.id, filename);
          } else {
            await downloadReportWhenReady(generatedReportData.id, filename);
          }
        }
      } catch (e) {
        setReportIsBeingGenerated(false);
        throw e;
      }
    },
    [
      downloadReportAndBlob,
      downloadReportWhenReady,
      filePrefix,
      prepareReportUrl,
      reportTypes
    ]
  );

  const handleSubmit = React.useCallback(
    async ({ dateFrom, dateTo, reportType }) => {
      try {
        await onReportSubmit({ dateFrom, dateTo, reportType });
      } catch (ex) {
        return PythonApi.getFormErrors(ex);
      }
    },
    [onReportSubmit]
  );

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validator}
        validateOnBlur={true}
      >
        {({
          handleSubmit,
          submitting,
          submitError,
          hasValidationErrors,
          values: { dateTo, dateFrom }
        }) => (
          <form>
            {children({
              generating: reportIsBeingGenerated,
              handleOpen: () => {
                setIsModalOpen(true);
              }
            })}
            <ConfirmationDialog
              title="Generate report"
              isOpen={isModalOpen}
              description={
                <>
                  {submitError && <FormError>{submitError}</FormError>}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {reportTypes.length > 1 ? (
                        <Field
                          name="reportType"
                          label="Report type"
                          component={SelectControl}
                          options={reportTypes}
                        />
                      ) : (
                        <Typography variant="h4">
                          {reportTypes[0].label}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name="dateFrom"
                        label="Date from"
                        component={KeyboardDatePickerControl}
                        inputFormat="MM/DD/YYYY"
                        validate={isDateBetween(
                          startDate as Moment,
                          initialValues.dateTo
                        )}
                        startDate={startDate}
                        endDate={initialValues.dateTo}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <div className={classes.dateSeparator}>-</div>
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name="dateTo"
                        label="Date to"
                        component={KeyboardDatePickerControl}
                        inputFormat="MM/DD/YYYY"
                        validate={isDateBetween(dateFrom, initialValues.dateTo)}
                        startDate={initialValues.dateFrom}
                        endDate={initialValues.dateTo}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              onClose={() => {
                setIsModalOpen(false);
              }}
              onSubmit={handleSubmit}
              submitDisabled={submitting || hasValidationErrors}
              submitText="Generate report"
              submitColor="success"
            />
          </form>
        )}
      </Form>
    </>
  );
};

export default ReportModal;
