import * as React from "react";
import { useParams } from "react-router";

import { AutoGrid, Button } from "components";
import * as PythonApi from "services/api/python";
import { CourseStatus } from "modules/courses/courses.constants";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { useCourseCompletionForUserCreate } from "modules/courses/provider/courses-submissions";
import useToast from "utils/hooks/useToast";

type Props = Pick<MyCoursesListItem, "id" | "status">;

const CoursesListRowActions: React.FC<Props> = ({ id, status }) => {
  const { userId } = useParams();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitCourseCompletion = useCourseCompletionForUserCreate();

  const onSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    try {
      await submitCourseCompletion({ course: id, user: userId! });
      showToast("Manual completion has been successfully submitted", "success");
    } catch (ex) {
      const errorMessages = PythonApi.getMessageFromError(ex);
      showToast(Object.values(errorMessages).join(" "), "error");
    } finally {
      setIsSubmitting(false);
    }
  }, [id, showToast, submitCourseCompletion, userId]);

  return (
    <AutoGrid spacing={2} wrap="nowrap" justify="space-between">
      {status === CourseStatus.IN_PROGRESS && (
        <Button
          onClick={onSubmit}
          variant="text"
          color="primary"
          underline
          disabled={isSubmitting}
          defaultHoverColor
        >
          Submit manual completion
        </Button>
      )}
    </AutoGrid>
  );
};

export default CoursesListRowActions;
