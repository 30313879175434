import * as React from "react";

import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";

import FullscreenMessage from "../components/fullscreen-message.component";

const SetNewPasswordSuccessView = () => (
  <GuestLayout>
    <SidePanel variant="login" />
    <Content>
      <FullscreenMessage
        variant="success"
        title="The registration was successful!"
        message={
          <span>
            Please wait for the email with information that you have been
            granted access to the portal.
            <br />
            <br />
            Have a good day!
          </span>
        }
      />
    </Content>
    <BottomPanel variant="login" />
  </GuestLayout>
);

export default SetNewPasswordSuccessView;
