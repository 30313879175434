import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
  gridItem: {
    [breakpoints.down("xs")]: {
      flexGrow: 1
    }
  },
  gridSearch: {
    order: -1,
    marginBottom: spacing(3),
    [breakpoints.up("sm")]: {
      order: 0,
      marginBottom: spacing(0)
    }
  }
}));
