import * as React from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";

import { useAuth } from "services/auth-provider";
import { Card, CardIcon } from "components";
import { pluralize } from "utils/strings";
import { formatDuration } from "utils/datetime";
import { UserRole } from "modules/users/users.constants";
import { CourseItem } from "modules/courses/provider/courses";
import {
  IconCheck,
  IconClear,
  IconClose,
  IconDateRange,
  IconFeaturedPlayListOutlined,
  IconPeople,
  IconRestore
} from "assets/icons";

import { CardWrapper } from "../common";

type AdminCardsProps = Pick<
  CourseItem,
  | "duration"
  | "createdAt"
  | "groups"
  | "isPublished"
  | "url"
  | "assignedUsers"
  | "countInProgress"
>;

const AdminCards: React.FC<AdminCardsProps> = ({
  duration,
  createdAt,
  groups,
  isPublished,
  url,
  assignedUsers,
  countInProgress
}) => {
  const { checkAccess } = useAuth();
  const canPublish = checkAccess({
    roles: [UserRole.ADMIN, UserRole.DESIGNER]
  });
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });

  return (
    <Grid container spacing={3}>
      {canPublish && (
        <CardWrapper>
          <Card
            title={isPublished ? "Published" : "Unpublished"}
            subtitle="Status"
            avatar={<CardIcon icon={isPublished ? IconCheck : IconClear} />}
            colorVariant={isPublished ? "success" : "hint"}
            variant="filled"
          />
        </CardWrapper>
      )}
      <CardWrapper>
        <Card
          title={moment(createdAt).format("L")}
          subtitle="Added date"
          avatar={<CardIcon icon={IconDateRange} />}
          colorVariant="primary"
        />
      </CardWrapper>
      {duration && (
        <CardWrapper>
          <Card
            title={formatDuration(duration * 1000, "H[h] mm[min]")}
            subtitle="Duration"
            avatar={<CardIcon icon={IconRestore} />}
            colorVariant="secondary"
          />
        </CardWrapper>
      )}
      {canPublish && (
        <CardWrapper>
          <Card
            title={url ? "Content added" : "No content"}
            subtitle="Course content"
            avatar={<CardIcon icon={url ? IconCheck : IconClose} />}
            colorVariant={url ? "success" : "error"}
          />
        </CardWrapper>
      )}
      {isTenantAdmin && (
        <CardWrapper>
          <Card
            title={`${groups} ${pluralize("group", groups as number)}`}
            subtitle="Assigned groups"
            avatar={<CardIcon icon={IconPeople} />}
            colorVariant="success"
          />
        </CardWrapper>
      )}
      <CardWrapper>
        <Card
          title={`${assignedUsers} ${pluralize("user", assignedUsers)}`}
          subtitle="Assigned users"
          avatar={<CardIcon icon={IconPeople} />}
          colorVariant={isTenantAdmin ? "primary" : "success"}
        />
      </CardWrapper>
      {canPublish && (
        <CardWrapper>
          <Card
            title={`${countInProgress} ${pluralize("user", countInProgress)}`}
            subtitle="During the course"
            avatar={<CardIcon icon={IconFeaturedPlayListOutlined} />}
            colorVariant="primary"
          />
        </CardWrapper>
      )}
    </Grid>
  );
};

export default AdminCards;
