import * as React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { FormControl } from "@material-ui/core";

import { IconCalendarTodayOutlined } from "assets/icons";

import useStyles from "./keyboard-date-picker-control.styles";

type Props = FieldRenderProps<string, HTMLElement> & {
  inputFormat?: string;
  startDate?: ParsableDate;
  endDate?: ParsableDate;
};

const KeyboardDatePickerControl: React.FC<Props> = ({
  startDate,
  endDate,
  input: { value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error, dirty },
  inputFormat,
  ...datePickerProps
}) => {
  const classes = useStyles();

  const isDirty = Boolean(dirty);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  const labelId = `label-${name}`;

  return (
    <FormControl error={showError} fullWidth={false}>
      <KeyboardDatePicker
        id={name}
        autoOk={true}
        format={inputFormat}
        value={value || null}
        onChange={onChange}
        inputVariant="outlined"
        error={showError}
        helperText={showError ? error || submitError : undefined}
        className={classes.root}
        keyboardIcon={<IconCalendarTodayOutlined fontSize="small" />}
        InputAdornmentProps={{
          classes: { root: classes.icon }
        }}
        InputProps={{
          inputProps: {
            "aria-labelledby": labelId,
            ...restInput
          }
        }}
        InputLabelProps={{
          id: labelId
        }}
        minDate={startDate}
        maxDate={endDate}
        {...datePickerProps}
      />
    </FormControl>
  );
};

export default KeyboardDatePickerControl;
