import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  pendingSubmissionsFilters: {
    pagination: { rowsPerPage: 10 },
    filters: { status: "pending" },
    orderBy: ["created_at,DESC"]
  },
  processedSubmissionsFilters: {
    pagination: { rowsPerPage: 10 },
    filters: { status: "accepted,rejected" },
    orderBy: ["created_at,DESC"]
  }
};

type State = typeof initialState;

export enum actionTypes {
  SET_PENDING_SUBMISSIONS_FILTERS = "setPendingSubmissionsFilters",
  SET_PROCESSED_SUBMISSIONS_FILTERS = "setProcessedSubmissionsFilters",
  RESET_SUBMISSIONS_FILTERS = "resetSubmissionsFilters"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_PENDING_SUBMISSIONS_FILTERS: {
      return {
        ...state,
        pendingSubmissionsFilters: action.payload
      };
    }
    case actionTypes.SET_PROCESSED_SUBMISSIONS_FILTERS: {
      return {
        ...state,
        processedSubmissionsFilters: action.payload
      };
    }
    case actionTypes.RESET_SUBMISSIONS_FILTERS: {
      return {
        ...state,
        pendingSubmissionsFilters: initialState.pendingSubmissionsFilters,
        processedSubmissionsFilters: initialState.processedSubmissionsFilters
      };
    }
    default:
      return state;
  }
};

export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.submissions, dispatch];
};

export default reducer;
