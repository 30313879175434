const apiConfig = {
  paths: {
    unauthorized: {
      tenant: "/tenants/config/",
      login: "/auth/token/",
      register: "/users/register/",
      requireRecoverPassword: "/users/request-password-reset/",
      recoverPasswordVerifyKey: "/users/password-reset/validate-token/",
      changePassword: "/users/password-reset/"
    },
    authorized: {
      auth: {
        refreshToken: "/auth/token/refresh/"
      },
      course: {
        list: "/courses/",
        lightList: "/courses/light-courses/",
        details: (courseId: string) => `/courses/${courseId}/`,
        listOfCurrentUser: "/courses/my-courses/",
        listOfUser: (userId: string) => `/courses/users-courses/${userId}/`,
        detailsOfUser: (courseId: string) => `/courses/my-courses/${courseId}/`,
        listOfFinished: "/courses/finishedCourses/",
        detailOfFinished: (courseId: string) =>
          `/courses/finishedCourses/${courseId}/`,
        start: (courseId: string) => `/courses/startCourse/${courseId}/start/`,
        getToken: (courseId: string) => `/lrs/lrs_course_token/${courseId}`,
        manualCompletions: {
          list: "/manual-completions/list/",
          reviewers: "/manual-completions/list/reviewers/",
          create: "/manual-completions/",
          completeAsUser: "/manual-completions/as-user/",
          createProcessSubmission: (courseId: string, operationType: string) =>
            `/manual-completions/${courseId}/${operationType}/`
        },
        create: "/courses/create-course/",
        uploadCourseList: "/courses/prime/",
        listCoursesUpdateStatus: (operationType: string) =>
          `/courses/bulk_${operationType}/`,
        assignToGroup: (courseId: string) =>
          `/courses/${courseId}/assign-groups/`,
        unAssignToGroup: (courseId: string) =>
          `/courses/${courseId}/unassign-groups/`,
        assignToUser: "/courses/bulk-direct-assign/",
        unassignToUser: "/courses/bulk-direct-unassign/",
        assignByUser: (courseId: string) =>
          `/courses/my-courses/${courseId}/assign/`,
        unassignFromGroup: (courseId: string, groupId: string) =>
          `/courses/unassign-course/${courseId}/${groupId}/`,
        assignedToGroup: (groupId: string) =>
          `/courses/groups-courses/${groupId}/`,
        prime: (hostname: string) => `/courses/prime-log/${hostname}/`,
        history: (couseId: string) => `/courses/${couseId}/history/`
      },
      learningPlan: {
        list: "/learning-plans/",
        details: (id: string) => `/learning-plans/${id}/`,
        create: "/learning-plans/",
        update: (id: string) => `/learning-plans/${id}/`,
        delete: (id: string) => `/learning-plans/${id}/`,
        studentList: "/learning-plans/my-learning-plans/",
        studentDetails: (id: string) =>
          `/learning-plans/my-learning-plans/${id}/`
      },
      group: {
        list: "/groups/",
        listOfPublic: "/groups/public/",
        details: (groupId: string) => `/groups/${groupId}`,
        unassignUser: (groupId: string, userId: string) =>
          `/groups/unassign-user/${groupId}/${userId}`,
        tree: (parentGroupId: string) => `/groups/tree/${parentGroupId}`,
        treeLight: (parentGroupId: string) =>
          `/groups/tree-light/${parentGroupId}`,
        changeStatus: (
          groupId: string,
          operationType: "activate" | "deactivate"
        ) => `/groups/${groupId}/${operationType}/`
      },
      user: {
        current: "/users/profile/",
        approve: "/users/approve-disclaimer/",
        list: "/users/users/",
        lightList: "/users/light-users/",
        getUser: (userId: string) => `/users/detail-user/${userId}/`,
        changeUser: (userId: string) => `/users/users/${userId}/`,
        listOfCourse: (courseId: string) => `/users/in-course/${courseId}/`,
        directListOfCourse: (courseId: string) =>
          `/users/direct-users-in-course/${courseId}/`,
        changeStatus: (userId: string, operationType: string) =>
          `/users/users/${userId}/${operationType}/`,
        listChangeStatus: (operationType: string) =>
          `/users/users/bulk_${operationType}/`,
        requireResetPassword: (userId: string) =>
          `/users/request-password-reset/${userId}/`,
        changePassword: "/users/change-password/",
        approveList: "/users/approved-users/",
        approvers: "/users/approved-users/approvers/"
      },
      report: {
        createForAllCoursesUsers: "/reports/create/all-courses",
        createForAllAvailableCoursesUsers:
          "/reports/create/all-available-courses",
        createForCourse: (courseId: string) =>
          `/reports/create/for-course/${courseId}`,
        createForCourseDetails: (courseId: string) =>
          `/reports/create/for-course-details/${courseId}`,
        createForUser: (userId: string) => `/reports/create/for-user/${userId}`,
        createForUserDetails: () => `/reports/create/for-users-details`,
        createForGroup: () => `/reports/create/for-groups`,
        createForTenant: (tenantId: string) =>
          `/reports/create/for-performance/${tenantId}`,
        details: (reportId: string) => `/reports/${reportId}`,
        download: (reportId: string) => `/reports/download/${reportId}`
      },
      problems: {
        list: "/feedback/",
        listOfAssignees: "/feedback/assignees-list/",
        add: "/feedback/post-new/",
        update: (reportId: string) => `/feedback/${reportId}/`,
        comment: "/feedback/comment/"
      },
      media: {
        generateStorageUrl: "/common/generate-storage-url/",
        checkUploadStatus: (uploadId: string) => `/storage/${uploadId}`,
        uploadThumbnail: (
          tenantId: string,
          sasToken: string,
          uploadId: string,
          extension: string
        ) =>
          `/uploads/thumbnails/${tenantId}/${uploadId}.${extension}?${sasToken}`,
        uploadReportAttachment: (
          tenantId: string,
          sasToken: string,
          uploadId: string,
          extension: string
        ) =>
          `/uploads/attachments/${tenantId}/${uploadId}.${extension}?${sasToken}`,
        uploadCourse: (
          courseId: string,
          tenantId: string,
          sasToken: string,
          uploadId: string
        ) =>
          `/uploads/courses/${tenantId}/${courseId}/${uploadId}.zip?${sasToken}`
      },
      tenants: {
        resources: "/tenants/resources/",
        list: "/tenants/",
        getTenant: (tenantId: string) => `/tenants/${tenantId}/`
      },
      pendingActions: "/stats/pending-actions/"
    }
  },
  timeout: 300 * 1000 // request timeout in milliseconds
};

export default apiConfig;
