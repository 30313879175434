import * as React from "react";

import { ListToolbar, AutoGrid, Search } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";

import { TenantItem } from "../../provider/tenants";

type Props = {
  tenants: Array<TenantItem>;
  listFilters: ListFiltersState;
};

const TenantsListToolbar: React.FC<Props> = ({ listFilters }) => {
  const left = React.useMemo(
    () => (
      <AutoGrid spacing={2}>
        <Search listFilters={listFilters} />
      </AutoGrid>
    ),
    [listFilters]
  );

  return <ListToolbar left={left} />;
};

export default TenantsListToolbar;
