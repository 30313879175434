import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  attachedFileIcon: {
    color: palette.success.main
  },
  popoverContent: {
    padding: spacing(1),
    fontSize: "0.9rem"
  },
  popover: {
    pointerEvents: "none",
    marginLeft: spacing(5)
  }
}));
