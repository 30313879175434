import React, { useState, useMemo } from "react";
import { Field } from "react-final-form";

import { Header, Section } from "components";
import { useAuth } from "services/auth-provider";
import useListFilters from "utils/hooks/useListFilters";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";
import { useCompaniesListPublic } from "modules/companies/provider";
import { UserRole } from "modules/users/users.constants";

const GroupsSection = () => {
  const { checkAccess } = useAuth();
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });

  const [groupIsFetched, setGroupIsFetched] = useState(false);

  const groupsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    orderBy: ["name,ASC"]
  });

  const groups = useCompaniesListPublic({
    variables: {
      listFilters: groupsListFilters,
      ...(isTenantAdmin && { onlyOwn: true })
    },
    skip: groupIsFetched
  });

  const options = useMemo(() => {
    if (groups.data) {
      setGroupIsFetched(true);
      return groups.data.map(({ id, name, parent }) => ({
        label: name,
        value: id,
        parent: isTenantAdmin
          ? groups.data!.some(({ id }) => id === parent)
            ? parent
            : null
          : parent
      }));
    }
    return [];
  }, [groups.data, isTenantAdmin]);

  return (
    <Section margin="dense">
      <Header variant="formSection" number="3" title="Assign to groups" />
      <Field
        name="groups"
        label="Groups"
        onlyOneFromBranch={false}
        allowMultiple={true}
        component={GroupTreeControl}
        options={options}
        isLoading={groups.loading}
        listFilters={groupsListFilters}
      />
    </Section>
  );
};

export default GroupsSection;
