import * as React from "react";

import { HasAccess } from "services/auth-provider";

import ReportsListItem from "../components/reports-list-item";
import { ReportTypes, ReportType } from "../reports.constants";

import useStyles from "./reports-list-container.styles";
import ReportForm from "./report-form";

const ReportsListContainer: React.FC = () => {
  const classes = useStyles();

  const [currentReportType, setCurrentReportType] = React.useState(
    ReportTypes.courseReport.type
  );

  const reportTypes = Object.values(ReportTypes);
  const reportType: ReportType =
    reportTypes.find(
      (report: ReportType) => report.type === currentReportType
    ) || reportTypes[0];

  return (
    <div className={classes.container}>
      <section className={classes.reportsSection}>
        {Object.values(ReportTypes).map((report: ReportType) => (
          <HasAccess
            key={report.type}
            roles={report.roles}
            componentAccess={report.componentAccess}
          >
            <ReportsListItem
              report={report.type}
              isActive={report.type === currentReportType}
              setReport={setCurrentReportType}
            />
          </HasAccess>
        ))}
      </section>

      <section className={classes.reportFormSection}>
        <ReportForm reportType={reportType} />
      </section>
    </div>
  );
};

export default ReportsListContainer;
