import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  tooltip: {
    padding: spacing(2),
    maxWidth: 250
  },
  title: {
    color: palette.text.primary,
    fontSize: "0.875rem"
  },
  divider: {
    margin: spacing(1, 0)
  },
  content: {
    fontSize: "0.875rem"
  },
  more: {
    marginLeft: spacing(2),
    fontSize: "0.8125rem",
    textDecoration: "underline",
    whiteSpace: "nowrap"
  }
}));
