import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  selectContainer: {
    marginBottom: spacing(3)
  },
  outlinedButton: {
    borderRadius: 10,
    color: palette.text.default,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    width: 315,
    height: spacing(7),
    display: "flex",
    justifyContent: "space-between"
  },
  arrowDownIcon: {
    fontSize: "2rem !important",
    color: palette.hint.main
  },
  paperRoot: {
    width: 315,
    padding: spacing(4)
  },
  title: {
    marginBottom: spacing(4)
  },
  items: {
    height: 180,
    overflowY: "auto",
    margin: spacing(6, 0, 8)
  },
  rootMenuItem: {
    color: palette.text.default,
    display: "flex",
    justifyContent: "space-between"
  },
  checkbox: {
    color: palette.hint.main
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));
