import { AxiosRequestConfig, AxiosResponse } from "axios";

import authClient from "services/auth";
import API, { BASE_URLS } from "services/api";
import { checkIfIE } from "utils/browser";
import { cameliseDeep, snakeCaseDeep } from "utils/transforms";

import apiConfig from "./api";

export const addIETimeStampInterceptor = (
  value: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  if (checkIfIE()) {
    value.params = {
      ...value.params,
      timestamp: new Date().getTime()
    };
  }
  return value;
};

export const cameliseDeepInterceptor = (
  value: AxiosResponse
): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
  value.data = cameliseDeep(value.data);
  return value;
};

export const cameliseDeepErrorInterceptor = (error: any) => {
  if (error.response && error.response.data) {
    error.response.data = cameliseDeep(error.response.data);
  }
  return Promise.reject(error);
};

export const snakeCaseDeepInterceptor = (
  value: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  if (BASE_URLS.PYTHON_API === value.baseURL) {
    value.data = snakeCaseDeep(value.data);
  }
  return value;
};

let refreshRequestsInProgress: ((value?: unknown) => void)[][] = [];
const makeRefresh = () =>
  new Promise((resolve, reject) => {
    refreshRequestsInProgress.push([resolve, reject]);
    if (refreshRequestsInProgress.length === 1) {
      authClient
        .refreshTokens()
        .then(response => {
          refreshRequestsInProgress.forEach(([resolve]) => {
            resolve(response);
          });
        })
        .catch(error => {
          refreshRequestsInProgress.forEach(([, reject]) => {
            reject(error);
          });
        })
        .finally(() => {
          refreshRequestsInProgress = [];
        });
    }
  });

export const refreshAuthInterceptor = async (error: any) => {
  if (
    error.config && // Is axios
    !error.config.url.includes(apiConfig.paths.unauthorized.login) && // Not log in request
    ((error.config.params && !error.config.params.isRetry) || // Not repeated request
      !error.config.params) &&
    (error.request.status === 401 || error.request.status === 403) // Response unauthorized or not accessed
  ) {
    await makeRefresh();
    return API.request({
      ...error.config,
      baseURL: null, // Response error.config.url already contains baseURL
      headers: {
        ...error.config.headers,
        Authorization: `Bearer ${authClient.getToken()}`
      },
      params: {
        ...(error.config.params && { ...error.config.params }),
        isRetry: true
      }
    });
  }
  return Promise.reject(error);
};
