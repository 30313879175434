import * as React from "react";
import { AxiosError, AxiosResponse, Method } from "axios";
import useAxios from "@use-hooks/axios";

import API, { StorageAPI } from "services/api";

export type UseFetchData<D> = {
  data?: D;
  meta?: {
    total: number;
  };
};

export type ResponseTransformer<R, D> = (
  response: AxiosResponse<R> | null
) => D;

export type FetcherConfig = {
  baseURL?: string;
  url: string;
  method: Method;
  params?: Record<string, any>;
  timeout?: number; // In milliseconds
  data?: any;
  headers?: any;
};

export type UseFetchConfig<R = any, D = any> = FetcherConfig & {
  baseURL: string;
  transformResponse: ResponseTransformer<R, D>;
};

type Variables = Record<string, any>;
export type FetchOptions<V extends Variables> = {
  variables: V;
  skip?: boolean;
};

export const useFetch = <R = any, D = any, V extends Variables = any>(
  config: UseFetchConfig<R, UseFetchData<D>>,
  options?: FetchOptions<V>
) => {
  const {
    url,
    params,
    data,
    method,
    baseURL,
    transformResponse,
    timeout
  } = config;

  const axiosResult = useAxios<R>({
    axios: API,
    url,
    options: {
      method,
      baseURL,
      params,
      timeout,
      data
    },
    trigger: {
      url,
      params,
      data,
      skip: Boolean(options && options.skip)
    },
    forceDispatchEffect: () => !options || !options.skip
  });

  const { loading, error, response, reFetch } = axiosResult;
  const transformedResponse = transformResponse(response);

  return {
    loading,
    error: (error as AxiosError) || undefined,
    response,
    refetch: reFetch,
    ...transformedResponse
  };
};

export type MutationConfigBuilder<V, R = any, D = any> = (
  variables: V
) => UseFetchConfig<R, D>;

export type MutationMakerConfig<V> = (variables: V) => FetcherConfig;

export const useFetchMutation = <V, R = any, D = any>(
  configBuilder: MutationConfigBuilder<V, R, D>
) => {
  // Since we pass an arrow function to this hook, we don't want to create it over and over again on each render.
  // So we store the _initial_ config builder function and always use it.
  // We ignore any builders created in the consecutive hook calls.
  // Thanks to that, we don't have to do it in every single mutation definition in the whole project.
  const configBuilderRef = React.useRef(configBuilder);

  // We use the config builder to create Axios request config with the provided variables.
  // This way, we can generate dynamic urls, with ids injected, etc.
  // Also, we can implement any request body (payload) transformations in each mutation definition separately.
  const mutation = React.useCallback(async (variables: V) => {
    const {
      baseURL,
      method,
      url,
      params,
      data,
      timeout,
      transformResponse
    } = configBuilderRef.current(variables);

    const response = await API.request<R>({
      baseURL,
      method,
      url,
      params,
      timeout,
      data
    });

    const transformedResponse = {
      ...response,
      data: transformResponse(response)
    } as AxiosResponse<D>;

    return transformedResponse;
  }, []);

  return mutation;
};

export const useUploadAPI = <V, R = any, D = any>(
  configBuilder: MutationConfigBuilder<V, R, D>
) => {
  return React.useCallback(
    async (variables: V) => {
      const {
        baseURL,
        method,
        url,
        params,
        data,
        timeout,
        transformResponse
      } = configBuilder(variables);

      const headers = {
        "x-ms-blob-type": "BlockBlob"
      };

      const response = await StorageAPI.request<R>({
        baseURL,
        method,
        headers,
        url,
        params,
        timeout,
        data
      });

      return {
        ...response,
        data: transformResponse(response)
      } as AxiosResponse<D>;
    },
    [configBuilder]
  );
};
