import * as React from "react";
import { useParams } from "react-router";

import {
  Button,
  AutoGrid,
  Drawer,
  ClosingModal,
  ReportModal
} from "components";
import CompanyForm from "modules/companies/components/company-form.component";
import { useRepeatStatusCheckUntilSuccess } from "modules/common/hooks/useRepeatStatusCheckUntilSuccess";
import useToast from "utils/hooks/useToast";
import { CompanyItem } from "modules/companies/provider/companies";
import { useUploadStatus } from "modules/common/hooks/useUploadStatus";
import { useUploadOptions } from "modules/common/hooks/useUploadOptions";
import {
  useCompanyThumbnailCreate,
  useCompanyUpdate
} from "modules/companies/provider";
import { IconCreate } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import apiConfig from "config/api";
import { ReportTypes } from "modules/reports/reports.constants";

type CompaniesListRowActionsProps = {
  company: CompanyItem;
  refetch: () => void;
};

const CompaniesListRowActions: React.FC<CompaniesListRowActionsProps> = ({
  company,
  refetch
}) => {
  const { courseId, companyId } = useParams();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const { showToast } = useToast();
  const updateCompany = useCompanyUpdate({ id: company.id });
  const getUploadOptions = useUploadOptions();
  const uploadThumbnail = useCompanyThumbnailCreate();
  const { isComponentAllowed, getHostname } = useTenant();
  const checkThumbnailStatus = useUploadStatus();
  const waitForStatus = useRepeatStatusCheckUntilSuccess(checkThumbnailStatus);

  const handleEditSubmit = React.useCallback(
    async ({
      name,
      admins,
      companyinfo,
      thumbnail,
      externalId,
      voucherNumber,
      companyDomain
    }) => {
      let newThumbnailUrl = "";
      // validate if thumbnail is current thumbnail url (string) or new image
      if (typeof thumbnail !== "string") {
        const result = await getUploadOptions({});
        if (!result.data) {
          throw new Error("Retrieving upload options failed");
        }
        const { sasToken, uploadId, storageUrl } = result.data;

        await uploadThumbnail({
          thumbnail,
          sasToken,
          uploadId,
          storageUrl,
          tenantId: getHostname()
        });

        newThumbnailUrl = (await waitForStatus(uploadId, 1500)) || "";
      }
      await updateCompany({
        name,
        adminsIds: admins ?? [],
        parent: undefined,
        companyinfo: {
          attSponsorId: companyinfo.attSponsorId
            ? companyinfo.attSponsorId
            : null,
          addresses: companyinfo.addresses,
          thumbnailUrl:
            typeof thumbnail !== "string" ? newThumbnailUrl : thumbnail
        },
        externalId,
        voucherNumber,
        companyDomain
      });
      setDrawerOpen(false);
      showToast("The company was successfully updated", "success");
      refetch();
    },
    [
      updateCompany,
      showToast,
      refetch,
      uploadThumbnail,
      getHostname,
      getUploadOptions,
      waitForStatus
    ]
  );

  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  return (
    <>
      {companyId ? (
        <>
          <Button
            style={{ marginBottom: "-7px" }}
            onClick={() => setDrawerOpen(true)}
            variant="text"
            color="secondary"
            icon={IconCreate}
          >
            Edit company
          </Button>
          {isComponentAllowed(AllowedComponents.ReportCompanyDetails) && (
            <div style={{ float: "right" }}>
              <ReportModal
                filePrefix={company.name || `company-${company.id}`}
                reportTypes={[
                  {
                    label: "Company detail",
                    value: "companyDetailReport",
                    requestPath: apiConfig.paths.authorized.report.createForGroup(),
                    body: ReportTypes.companyOverviewReport.buildBody?.(
                      company.id
                    )
                  }
                ]}
              >
                {({ generating, handleOpen }) => (
                  <Button
                    disabled={generating}
                    onClick={handleOpen}
                    color="primary"
                  >
                    {generating ? "Preparing report..." : "Generate report"}
                  </Button>
                )}
              </ReportModal>
            </div>
          )}
        </>
      ) : (
        <AutoGrid spacing={2} wrap="nowrap">
          {!courseId && (
            <>
              <Button
                onClick={() => {
                  setDrawerOpen(true);
                }}
                variant="text"
                icon={IconCreate}
              >
                Edit
              </Button>
            </>
          )}
        </AutoGrid>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Edit company"
        size="sm"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <CompanyForm
          onCancel={() => setDrawerOpen(false)}
          onSubmit={handleEditSubmit}
          company={company}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );
};

export default CompaniesListRowActions;
