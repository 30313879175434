import * as React from "react";

import { AutoGrid, ListToolbar, Search } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import ApproversTreeFilter from "modules/common/components/users-tree/approvers-tree-filter";

import useStyles from "../user-list/users-list-toolbar.styles";

type ApprovedUsersListToolbarProps = {
  listFilters: ListFiltersState;
};

const ApprovedUsersListToolbar: React.FC<ApprovedUsersListToolbarProps> = ({
  listFilters
}) => {
  const classes = useStyles();

  const leftOptions = (
    <AutoGrid spacing={2} className={classes.gridContainer}>
      <Search listFilters={listFilters} />
      <ApproversTreeFilter
        label="Approvers"
        listFilters={listFilters}
        name="approvers"
        allowMultiple
      />
    </AutoGrid>
  );

  return <ListToolbar left={leftOptions} />;
};

export default ApprovedUsersListToolbar;
