import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";

import UsersListAdminView from "./views/user-list.admin.view";
import UserDetailsView from "./views/user-details.view";
import { useModuleState, actionTypes } from "./reducers/users";

type Props = {
  match: match;
};

const UsersRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_USERS_FILTERS });
      dispatch({ type: actionTypes.RESET_APPROVAL_USERS_FILTERS });
    },
    [dispatch]
  );
  return (
    <Switch>
      <Route
        path={match.path}
        exact
        render={() => (
          <HasAccess roles={[UserRole.ADMIN, UserRole.TENANT_ADMIN]}>
            <UsersListAdminView />
          </HasAccess>
        )}
      />
      <Route path={`${match.path}/:userId`} component={UserDetailsView} />
      <Redirect to={match.path} />
    </Switch>
  );
};
export default UsersRouter;
