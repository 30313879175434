import * as React from "react";
import {
  Switch as MuiSwitch,
  FormGroup,
  FormControlLabel,
  FormControl
} from "@material-ui/core";
import { FieldRenderProps } from "react-final-form"; // eslint-disable-line
import useStyles from "./switch-control.styles";

type Props = FieldRenderProps<string, HTMLElement> & {
  label?: string;
};
const SwitchControl: React.FC<Props> = ({
  input: { value, name, onChange, checked, ...restInput },
  label,
  ...restSwitch
}) => {
  const classes = useStyles();

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <MuiSwitch
              {...restSwitch}
              name={name}
              color="primary"
              checked={checked}
              onChange={onChange}
              inputProps={restInput}
              classes={{ 
                switchBase: classes.switchBase,
                checked: classes.checked,
                track: classes.track
               }}
            />
          }
        />
      </FormGroup>
    </FormControl>
  );
};
export default SwitchControl;
