import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse",
    padding: 0,
    marginBottom: spacing(3)
  },
  options: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    margin: spacing(2, 0)
  }
}));
