import * as React from "react";
import { Grid } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import { Props as CardProps } from "components/card/card";
import {
  Card,
  CardCounter,
  CardLogo,
  CardIcon,
  CardArrowButtons
} from "components";
import { UserItem } from "modules/users/provider/users";
import { IconClear, IconDone, IconUpdate, IconPerson } from "assets/icons";

import { UserRole, UserStatus } from "../../users.constants";

const prepareUserRole = (role: string | undefined) => {
  if (!role) {
    return "";
  }
  return role === UserRole.TENANT_ADMIN ? "External admin" : role;
};

type Props = {
  user: UserItem;
};

const UserStatsCards: React.FC<Props> = ({ user }) => {
  type statusCardVariant = {
    [key in UserStatus]: {
      icon: React.ComponentType<SvgIconProps>;
      color: CardProps["colorVariant"];
    };
  };
  const statusCard: statusCardVariant = {
    [UserStatus.ACTIVE]: { icon: IconDone, color: "success" },
    [UserStatus.DEACTIVATED]: { icon: IconClear, color: "hint" },
    [UserStatus.PENDING]: { icon: IconUpdate, color: "info" }
  };

  const [activeRootGroup, setActiveRootGroup] = React.useState(0);
  const hasRootGroups = user.rootGroups.length > 0;
  const activeThumbnailUrl = hasRootGroups
    ? user.rootGroups[activeRootGroup].thumbnailUrl
    : undefined;

  return (
    <Grid container spacing={3} justify="flex-start">
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={
            hasRootGroups ? user.rootGroups[activeRootGroup].name : "No company"
          }
          subtitle="Company name"
          avatar={<CardLogo imgUrl={activeThumbnailUrl} />}
          hasLogo={Boolean(activeThumbnailUrl)}
          arrowButtons={
            user.rootGroups.length > 1 && (
              <CardArrowButtons
                count={user.rootGroups.length}
                currentIndex={activeRootGroup}
                onClickLeft={() => setActiveRootGroup(activeRootGroup - 1)}
                onClickRight={() => setActiveRootGroup(activeRootGroup + 1)}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={user.status}
          subtitle="Status"
          avatar={<CardIcon icon={statusCard[user.status].icon} />}
          colorVariant={statusCard[user.status].color}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={prepareUserRole(user?.role)}
          subtitle="Role"
          avatar={<CardIcon icon={IconPerson} />}
          colorVariant="secondary"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={
            <>
              Completed <br /> courses
            </>
          }
          avatar={
            <CardCounter
              number={user.countCompleted ? user.countCompleted : 0}
            />
          }
          colorVariant="primary"
          avatarPosition="right"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={
            <>
              Courses <br /> in progress
            </>
          }
          avatar={
            <CardCounter
              number={user.countInProgress ? user.countInProgress : 0}
            />
          }
          colorVariant="secondary"
          avatarPosition="right"
        />
      </Grid>
    </Grid>
  );
};

export default UserStatsCards;
