import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  textLine: {
    margin: spacing(1, 0),
    textAlign: "center",
    color: palette.text.default,

    [breakpoints.up("sm")]: {
      margin: spacing(2, 0),
      textAlign: "left",
      color: palette.text.hint
    }
  },
  form: {
    marginTop: spacing(7)
  },
  userFullName: {
    color: palette.text.default,
    marginTop: spacing(3)
  },
  textSecondary: {
    fontSize: "0.875rem"
  },
  textButton: {
    display: "block",
    margin: "auto",

    [breakpoints.up("sm")]: {
      marginLeft: spacing(-1)
    }
  },
  buttonCancel: {
    textAlign: "center",

    [breakpoints.up("sm")]: {
      textAlign: "left",
      marginLeft: spacing(-1)
    }
  },
  buttons: {
    marginTop: spacing(3),

    [breakpoints.up("sm")]: {
      marginTop: spacing(1)
    }
  }
}));
