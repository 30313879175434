import * as React from "react";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";
import {
  ResponsiveTableWrapper,
  Pagination,
  DataTable,
  AutoGrid,
  SubmissionStatusChip,
  LoadingPlaceholder
} from "components";
import useStyles from "components/data-table/data-table.styles";
import { SubmissionsListItem } from "modules/courses/provider/courses-submissions";

import SubmissionListToolbar from "./submission-list-toolbar";

type Props = {
  submissions: SubmissionsListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  loading: boolean;
};

const SubmissionHistoryList: React.FC<Props> = ({
  submissions,
  listFilters,
  meta,
  loading
}) => {
  const classes = useStyles();
  return (
    <>
      <SubmissionListToolbar listFilters={listFilters} isReviewersFilter />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <ResponsiveTableWrapper>
            <DataTable
              emptyText="No submissions"
              data={submissions}
              columns={[
                {
                  key: "firstName",
                  title: "First name",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "user.firstName")}
                    </span>
                  )
                },
                {
                  key: "lastName",
                  title: "Last name",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "user.lastName")}
                    </span>
                  )
                },
                {
                  key: "courseName",
                  title: "Course",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "course.name")}
                    </span>
                  )
                },
                {
                  key: "externalId",
                  title: "Course ID",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "course.externalId")}
                    </span>
                  )
                },
                {
                  key: "requestDate",
                  title: "Request date",
                  render: submission =>
                    moment(_.get(submission, "createdAt")).format("L")
                },
                {
                  key: "reviewedBy",
                  title: "Reviewed by",
                  render: submission => {
                    const firstName = _.get(submission, "reviewedBy.firstName");
                    const lastName = _.get(submission, "reviewedBy.lastName");
                    return (
                      <span
                        className={clsx(classes.ellipsis, classes.customText)}
                      >
                        {`${firstName || ""} ${lastName || ""}`.trim()}
                      </span>
                    );
                  }
                },
                {
                  key: "requestStatus",
                  title: "Request status",
                  width: "1%",
                  render: ({ status }) => (
                    <SubmissionStatusChip status={status} />
                  )
                }
              ]}
            />
          </ResponsiveTableWrapper>

          <AutoGrid justify="flex-end" showEmpty>
            {meta && meta.total > 0 && (
              <Pagination
                totalCount={meta.total}
                onChange={listFilters.setPagination}
                rowsPerPage={listFilters.pagination.rowsPerPage}
                page={listFilters.pagination.page}
              />
            )}
          </AutoGrid>
        </>
      )}
    </>
  );
};

export default SubmissionHistoryList;
