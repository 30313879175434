import * as React from "react";
import { Typography } from "@material-ui/core";

import { UserItem } from "modules/users/provider/users";
import { AutoGrid } from "components";
import { IconMailOutline, IconSmartphone } from "assets/icons";

import useStyles from "./user-info.styles";

type Props = {
  user: UserItem;
};

const UserInfo: React.FC<Props> = ({ user }) => {
  const classes = useStyles();

  return (
    <AutoGrid spacing={3}>
      <div className={classes.item}>
        <IconMailOutline className={classes.label} />
        <Typography className={classes.userData}>{user.email}</Typography>
      </div>
      <div className={classes.item}>
        <IconSmartphone className={classes.label} />
        <Typography className={classes.userData}>
          {user.phone ? user.phone : <>&mdash;</>}
        </Typography>
      </div>
    </AutoGrid>
  );
};

export default UserInfo;
