import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ breakpoints, palette, spacing, shadows }: CustomTheme) => ({
    initialsButton: {
      padding: 0,
      justifyContent: "left",
      minWidth: "auto",
      "&:hover": {
        backgroundColor: palette.background.default
      },

      [breakpoints.up("sm")]: {
        minWidth: spacing(8)
      }
    },
    dropdownMenu: {
      borderRadius: spacing(1),
      marginTop: spacing(8),
      boxShadow: shadows[3]
    },
    menuItem: {
      color: palette.text.default,
      "&:hover": {
        backgroundColor: palette.primary.light
      }
    },
    expandMoreIcon: {
      marginLeft: spacing(1)
    },
    helloUser: {
      display: "none",
      color: palette.text.primary,
      fontSize: "1rem",
      fontWeight: 500,
      marginLeft: spacing(1),

      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer"
      },

      [breakpoints.up("sm")]: {
        display: "inline-flex"
      }
    }
  })
);
