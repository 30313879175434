import * as PythonApi from "services/api/python";
import { FetchOptions, useFetch } from "utils/hooks/useFetch";
import { ListFilters } from "utils/hooks/useListFilters";
import apiConfig from "config/api";

type CompaniesListPublicVars = {
  listFilters?: ListFilters<{
    name?: string;
  }>;
  onlyOwn?: boolean;
};

export type CompaniesListPublicItem = {
  id: string;
  name: string; // maxLength : 255
  parent: string;
  companyinfo: null | {
    addresses: string[];
  };
};

const createCompaniesListPublicConfig = ({
  listFilters,
  onlyOwn
}: CompaniesListPublicVars) =>
  PythonApi.makeRequestForList<CompaniesListPublicItem>({
    method: "GET",
    url: apiConfig.paths.authorized.group.listOfPublic,
    params: {
      ...(onlyOwn && { own: true }),
      ...PythonApi.getParamsFromFilters(listFilters)
    }
  });

export const useCompaniesListPublic = (
  options: FetchOptions<CompaniesListPublicVars>
) => useFetch(createCompaniesListPublicConfig(options.variables), options);
