import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, breakpoints, spacing }: CustomTheme) => ({
  text: {
    fontSize: "0.875rem",
    color: palette.primary.main,
    textDecoration: "underline",
    [breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: spacing(-1)
  },
  button: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    display: "block",
    [breakpoints.up("lg")]: {
      maxWidth: "50%"
    }
  }
}));
