import * as React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

import { Button, DataTable, ResponsiveTableWrapper } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useStyles from "components/data-table/data-table.styles";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { useAuth } from "services/auth-provider";

import { TenantItem } from "../../provider/tenants";

import TenantsListRowActions from "./tenants-list-row-actions";

type Props = {
  loading: boolean;
  tenants: Array<TenantItem>;
  listFilters: ListFiltersState;
  refetch: () => void;
};

const TenantsList: React.FC<Props> = ({
  loading,
  tenants,
  listFilters,
  refetch
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const tenant = useTenant();
  const isPrimeCourseUploadLog = tenant.isComponentAllowed(
    AllowedComponents.PrimeCourseUploadLog
  );
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  return (
    <ResponsiveTableWrapper>
      <DataTable
        loading={loading}
        sortable
        emptyText="No tenants"
        data={tenants}
        listFilters={listFilters}
        rowIdKey="hostname"
        columns={[
          {
            key: "name",
            title: "Tenant name",
            sortable: true,
            render: ({ name, hostname }) => {
              return isPrimeCourseUploadLog && auth.user?.isSuperAdmin ? (
                <Button
                  variant="text"
                  target={isDetailsNewTab ? "_blank" : "_self"}
                  component={RouterLink}
                  to={`tenants/${name}/${hostname}`}
                >
                  <span className={clsx(classes.ellipsis, classes.customText)}>
                    {name}
                  </span>
                </Button>
              ) : (
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {name}
                </span>
              );
            }
          },
          {
            key: "countStudents",
            title: "Students",
            sortable: true,
            render: ({ countStudents }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countStudents}
              </span>
            )
          },
          {
            key: "countAdmins",
            title: "Admins",
            sortable: true,
            render: ({ countAdmins }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countAdmins}
              </span>
            )
          },
          {
            key: "countDesigners",
            title: "Designers",
            sortable: true,
            render: ({ countDesigners }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countDesigners}
              </span>
            )
          },
          {
            key: "countCourses",
            title: "Courses",
            sortable: true,
            render: ({ countCourses }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countCourses}
              </span>
            )
          },
          {
            key: "countCompanies",
            title: "Companies",
            sortable: true,
            render: ({ countCompanies }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countCompanies}
              </span>
            )
          },
          {
            key: "countProblems",
            title: "Problems",
            sortable: true,
            render: ({ countProblems }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {countProblems}
              </span>
            )
          },
          {
            key: "actions",
            title: "Actions",
            width: "1%",
            render: tenant => (
              <TenantsListRowActions tenant={tenant} refetch={refetch} />
            )
          }
        ]}
      />
    </ResponsiveTableWrapper>
  );
};

export default TenantsList;
