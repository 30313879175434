import * as PythonApi from "services/api/python";
import { useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export const useGenerateReportMutation = () => {
  type Vars = {
    userId: string;
  };
  type Item = {
    id: string;
    createdAt: string;
    status: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(({ userId }) => ({
      method: "POST",
      url: apiConfig.paths.authorized.report.createForUser(userId)
    }))
  );
};
