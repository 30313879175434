import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch, useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { CourseStatus } from "../courses.constants";

import { PrerequisiteCourse } from "./courses";

export type AvailableCoursesListFilters = {
  usersId?: string;
};

type AvailableCoursesListVars = {
  id: string;
  listFilters?: ListFilters<AvailableCoursesListFilters>;
  isNotStudent?: boolean;
};

export type AvailableCoursesListItem = {
  createdAt: string; // datestring
  description: string;
  notes: string;
  duration?: number; // in secs
  externalId: string;
  id: string;
  name: string;
  plainUrl?: string;
  status?: CourseStatus;
  currentStatus?: CourseStatus;
  statusTimestamp?: string;
  thumbnailUrl?: string;
  isPublished: boolean;
  updatedAt: string; // datestring
  url?: string;
  userId: string;
  allowManualCompletion: boolean;
  links: Array<{
    url: string;
    title: string;
  }>;
  detailsId: string; // id from lrs for available course details history view
  assigned: boolean;
  learningPlanIndicator?: {
    id: string;
    name: string;
  }[];
  prerequisiteCourses?: PrerequisiteCourse[];
};

const createAvailableCoursesListConfig = ({
  listFilters
}: AvailableCoursesListVars) =>
  PythonApi.makeRequestForList<AvailableCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.listOfCurrentUser,
    params: PythonApi.getParamsFromFilters(listFilters)
  });

export const useAvailableCoursesList = (
  options: FetchOptions<AvailableCoursesListVars>
) => {
  return useFetch(
    createAvailableCoursesListConfig({ ...options.variables }),
    options
  );
};

export const useCourseAssignByUserMutation = () => {
  type Vars = {
    courseId: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ courseId }) => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.course.assignByUser(courseId),
      data: {}
    }))
  );
};
