import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  reportItem: {
    color: palette.text.default
  },
  arrow: {
    width: 14,
    height: 14,
    transform: "rotate(45deg)",
    position: "absolute",
    right: "-1%",
    backgroundColor: palette.primary.main
  },
  active: {
    backgroundColor: palette.primary.main,
    "& $reportItem": {
      color: "white !important"
    }
  },
  button: {
    width: "100%",
    height: 50,
    justifyContent: "left",
    borderRadius: 5,
    paddingLeft: spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: palette.primary.main,
      "& $reportItem": {
        color: "white !important"
      }
    },
    [breakpoints.up("md")]: {
      width: 450
    }
  }
}));
