import * as React from "react";
import { match } from "react-router";

import {
  ApiResultRenderer,
  AppLayout,
  ErrorPlaceholder,
  Header,
  LoadingPlaceholder,
  Section
} from "components";
import useListFilters from "utils/hooks/useListFilters";
import UsersList from "modules/users/components/user-list/users-list-container";
import { useUsersList } from "modules/users/provider";
import { UsersListFilters } from "modules/users/provider/users";
import CoursesList from "modules/courses/components/course-list/course-list";
import { useGroupCoursesList } from "modules/courses/provider";
import { CourseItem } from "modules/courses/provider/courses";
import {
  actionTypes,
  useModuleState
} from "modules/companies/reducers/companies";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import CompanyStatsCards from "../components/company-stats-cards.component";
import GroupsList from "../components/group-list/group-list.component";
import CompanyDetailsSponsor from "../components/company-details-sponsor.component";
import { useCompaniesListTree, useCompanyRead } from "../provider";
import CompanyHeader from "../components/company-header.component";
import CompanyDetailsHeaderCta from "../components/company-details-header-cta";

export type Match = match<{ companyId: string }>;

type Props = {
  match: Match;
};

const CompanyDetailsView: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  const tenant = useTenant();
  const { companyId } = match.params;

  const groupsListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  });

  const usersListFilters = useListFilters<UsersListFilters>({
    pagination: { rowsPerPage: 10 },
    filters: { groupId: companyId },
    orderBy: ["lastName,ASC"]
  });

  const coursesListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  });

  const coursesList = useGroupCoursesList({
    variables: { listFilters: coursesListFilters, id: companyId }
  });

  const company = useCompanyRead({
    variables: { id: companyId }
  });

  const rootCompany = useCompanyRead({
    skip: company.data?.rootId === companyId || !company.data?.rootId,
    variables: { id: company.data?.rootId! }
  });

  const companyGroups = useCompaniesListTree({
    variables: { id: companyId, listFilters: groupsListFilters }
  });

  const users = useUsersList({
    variables: { listFilters: usersListFilters }
  });

  const refetch = () => {
    company.refetch();
    companyGroups.refetch();
    users.refetch();
    coursesList.refetch();
  };

  React.useEffect(() => {
    if (company.data) {
      dispatch({
        type: actionTypes.SET_CURRENT_COMPANY_PARENT_ID,
        payload: company.data.parent
      });
    }
  }, [company.data, dispatch]);

  if (company.loading || company.error || !company.data) {
    return (
      <AppLayout viewTitle="Company Details">
        {company.loading ? (
          <LoadingPlaceholder />
        ) : (
          <ErrorPlaceholder error={company.error} />
        )}
      </AppLayout>
    );
  }

  const hasSponsor = Boolean(
    company.data.companyinfo?.attSponsor ||
      rootCompany.data?.companyinfo?.attSponsor
  );
  const isNoSponsorAndAdmins = tenant.isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );

  return (
    <AppLayout viewTitle="Company Details" hideSmUp>
      <CompanyHeader
        button={
          <CompanyDetailsHeaderCta company={company.data} refetch={refetch} />
        }
        company={company.data}
      />
      {hasSponsor && !isNoSponsorAndAdmins && (
        <Section margin="dense">
          <CompanyDetailsSponsor
            sponsor={
              company.data.companyinfo?.attSponsor ??
              rootCompany.data?.companyinfo?.attSponsor
            }
          />
        </Section>
      )}

      <Section margin="big">
        <CompanyStatsCards
          rootLogo={rootCompany.data?.companyinfo?.thumbnailUrl}
          company={company.data}
        />
      </Section>

      <Section margin="big">
        <ApiResultRenderer apiResult={companyGroups} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Groups (${(meta && meta.total) || 0})`}
              />
              <GroupsList
                groups={data}
                meta={meta!}
                listFilters={groupsListFilters}
                refetch={refetch}
                loading={loading}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>

      <Section margin="big">
        <ApiResultRenderer apiResult={users} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Users (${(meta && meta.total) || 0})`}
              />
              <UsersList
                users={data}
                meta={meta!}
                listFilters={usersListFilters}
                refetch={refetch}
                companyId={companyId}
                loading={loading}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>

      <Section margin="big">
        <ApiResultRenderer apiResult={coursesList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Courses list (${(meta && meta.total) || 0})`}
              />
              <CoursesList
                courses={data as CourseItem[]}
                meta={meta!}
                listFilters={coursesListFilters}
                refetch={refetch}
                loading={loading}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default CompanyDetailsView;
