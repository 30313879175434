import _ from "lodash";
import { createMuiTheme, Theme } from "@material-ui/core";
import createPalette, {
  Palette,
  PaletteOptions,
  SimplePaletteColorOptions,
  TypeText
} from "@material-ui/core/styles/createPalette";
import createTypography, {
  Typography
} from "@material-ui/core/styles/createTypography";
import createBreakpoints, {
  Breakpoints,
  BreakpointsOptions
} from "@material-ui/core/styles/createBreakpoints";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { Shadows } from "@material-ui/core/styles/shadows";

type ExtendedPalette = {
  hint: SimplePaletteColorOptions & { faded?: string };
  success: SimplePaletteColorOptions;
  info: SimplePaletteColorOptions;
  alert: SimplePaletteColorOptions;
  chip: SimplePaletteColorOptions;
};

type CustomPaletteOptions = PaletteOptions & {
  text: Partial<
    {
      default: string;
      mercury: string;
      avatar: string;
    } & Partial<TypeText>
  >;
  background: Partial<
    {
      default: string;
      modalBackdrop: string;
      accent: string;
    } & Partial<TypeText>
  >;
};

type CustomPalette = Palette & ExtendedPalette & CustomPaletteOptions;

export type CustomTheme = {
  palette: CustomPalette;
} & Theme;

// Override Material-UI defaults and create handy helpers and mixins.
const basePalette: CustomPaletteOptions = {
  tonalOffset: 0.2,
  contrastThreshold: 3,
  primary: {
    main: "#606AFB",
    contrastText: "#FFFFFF",
    light: "#E7E9FE"
  },
  secondary: {
    main: "#FFA800",
    contrastText: "#FFFFFF",
    light: "#FFECC7",
    dark: "#C67B00"
  },
  error: {
    main: "#FF496A",
    light: "#FFDCE3"
  },
  background: {
    default: "#FFFFFF",
    modalBackdrop: "#6C708F4D",
    accent: "rgba(239, 239, 246, 0.5)"
  },
  text: {
    default: "#41465E",
    primary: "#202571",
    secondary: "#FFA800",
    hint: "#AEB1C2",
    mercury: "#E2E2E2",
    avatar: "#606AFB"
  },
  action: {
    selected: "#F7F8FF"
  }
};

const makePalette = (palette?: PaletteOptions): Palette =>
  createPalette(_.merge(basePalette, palette));

// Add custom colors to the palette
const customPalette = (palette: Palette): CustomPalette =>
  _.merge<ExtendedPalette, Palette>(
    {
      hint: {
        main: "#AEB1C2",
        contrastText: "#FFFFFF",
        light: "#F3F3F8",
        dark: "#6C708F",
        faded: "rgba(239, 239, 242, 0.6)"
      },
      success: {
        main: "#40BEAF",
        contrastText: "#FFFFFF",
        light: "#EAF8F6",
        dark: "#00927f"
      },
      info: {
        main: "#60A7FB",
        contrastText: "#FFFFFF",
        light: "#E7F2FE"
      },
      chip: {
        main: "#E2E4FE",
        contrastText: "#606AFB",
        light: "#F7F7F7",
        dark: "#A3A3A3"
      },
      alert: palette.secondary
    },
    palette
  );

const makeCustomPalette = (palette?: PaletteOptions): CustomPalette =>
  customPalette(makePalette(palette));

const makeTypography = (palette: Palette): Typography => {
  const cuPalette = customPalette(palette);

  return createTypography(palette, {
    fontFamily: "'ATT Aleck Sans', sans-serif",
    fontSize: 14,
    h1: {
      fontSize: "1.625rem",
      fontWeight: 500
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.4
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: 500
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 500
    },
    h5: {
      fontSize: "0.625rem",
      fontWeight: 500
    },
    h6: {
      fontSize: "0.5rem",
      fontWeight: 500
    },
    body1: {
      fontSize: "1rem",
      color: cuPalette.text.default
    },
    body2: {
      fontSize: "1rem",
      color: cuPalette.hint.main
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "none"
    }
  });
};

const baseBreakpoints: BreakpointsOptions = {};

const makeBreakpoints = (breakpoints?: BreakpointsOptions): Breakpoints =>
  createBreakpoints(_.merge(baseBreakpoints, breakpoints));

const baseShadows = (palette: ExtendedPalette): Shadows => [
  "none", // 0
  "0 4px 20px rgba(175,175,199,0.125)",
  "0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)",
  "1px 10px 40px rgba(173,178,194,0.4)",
  "1px 15px 20px rgba(175,175,197,0.2)",
  "1px 4px 20px rgba(175,179,194,0.3)", // 5
  "0 10px 20px rgba(74, 108, 242, 0.25)",
  "0px 4px 100px rgba(96, 167, 251, 0.15)",
  "0px 4px 20px rgba(229, 229, 229, 0.6)",
  "none",
  "none", // 10
  "none",
  "none",
  "none",
  "none",
  "none", // 15
  "none",
  "none",
  "none",
  "none",
  "none", // 20
  "none",
  "none",
  "none",
  "none" // 24
];

const makeThemeOptions = (
  customPalette: CustomPalette,
  breakpoints: Breakpoints,
  typography: Typography,
  shadows: Shadows
): ThemeOptions => ({
  palette: customPalette,
  breakpoints: breakpoints,
  shape: {
    borderRadius: 10
  },
  shadows,
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        color: "inherit",
        padding: 8
      }
    },
    MuiButtonBase: {
      root: {
        borderRadius: 100
      }
    },
    MuiButton: {
      root: {
        borderRadius: 100,
        textTransform: "none",
        padding: "12px 28px",
        lineHeight: "1rem",
        color: "inherit",
        minWidth: "auto",

        "&:hover": {
          backgroundColor: customPalette.hint.faded
        }
      },
      text: {
        fontWeight: 400
      },
      outlined: {
        padding: "12px 28px"
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        }
      },
      outlinedPrimary: {
        "&:hover": {
          backgroundColor: customPalette.primary.main,
          color: customPalette.primary.contrastText
        }
      },
      outlinedSecondary: {
        "&:hover": {
          backgroundColor: customPalette.secondary.main,
          color: customPalette.secondary.contrastText
        }
      },
      containedPrimary: {
        backgroundColor: customPalette.primary.main,
        color: customPalette.primary.contrastText,

        "&:hover": {
          backgroundColor: customPalette.primary.main,
          color: customPalette.primary.contrastText
        }
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: customPalette.primary.light,
          color: customPalette.primary.main
        }
      },
      sizeLarge: {
        fontSize: "0.875rem",
        padding: "16px 32px",
        lineHeight: "1.5rem"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: customPalette.hint.main,

        "&$checked": {
          color: customPalette.success.main
        }
      },
      colorSecondary: {
        color: customPalette.text.default,

        "&$checked": {
          color: customPalette.secondary.main
        }
      }
    },
    MuiTouchRipple: {
      root: {
        color: customPalette.background.default
      }
    },
    MuiTableRow: {
      root: {
        height: 45,
        "&:last-child th, &:last-child td": {
          borderBottom: 0
        },
        "&$selected": {
          backgroundColor: customPalette.action.selected
        }
      },
      head: {
        whiteSpace: "nowrap",

        // checkbox in head row
        "& > th > span": {
          color: customPalette.hint.main
        }
      },
      hover: {
        // hover table row
        "$root&:hover": {
          backgroundColor: "transparent",
          boxShadow: shadows[6],

          "& td": {
            backgroundColor: customPalette.primary.main,
            borderBottomColor: "transparent",

            "&:first-child": {
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5
            },
            "&:last-child": {
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5
            },

            "& > div": {
              backgroundColor: customPalette.primary.main
            }
          },

          "& *": {
            color: customPalette.primary.contrastText,
            borderColor: customPalette.primary.contrastText
          }
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: "12px 24px 12px 24px"
      },
      body: {
        color: customPalette.text.default,
        fontSize: "0.875rem"
      },
      head: {
        color: customPalette.hint.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
        textTransform: "uppercase"
      },
      paddingCheckbox: {
        paddingLeft: 24
      }
    },
    MuiChip: {
      root: {
        transition: "none",
        fontWeight: "bold",
        height: 36,
        borderRadius: 2
      },
      deleteIcon: {
        color: "inherit",
        height: 16
      },
      label: {
        paddingLeft: 12,
        paddingRight: 12
      },
      labelSmall: {
        paddingLeft: 12,
        paddingRight: 12
      },
      sizeSmall: {
        height: 26,
        padding: "8px 0"
      }
    },
    MuiBreadcrumbs: {
      li: {
        "& > *": {
          color: customPalette.text.default,
          fontSize: "0.875rem"
        },
        "&:last-child > *": {
          color: customPalette.primary.main,
          fontSize: "0.875rem"
        }
      },
      ol: {
        display: "flex",
        alignItems: "baseline",

        // last separator
        "& :nth-last-child(2)": {
          color: customPalette.primary.main
        }
      },
      separator: {
        color: customPalette.text.default
      }
    },
    MuiTablePagination: {
      root: {
        ...typography.body2,
        fontWeight: 500,
        [breakpoints.down("xs")]: {
          marginTop: 16
        }
      },
      toolbar: {
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignContent: "center"
      },
      input: {
        marginRight: 0
      },
      actions: {
        "& > button": {
          padding: 4
        }
      },
      caption: {
        fontSize: "inherit",
        fontWeight: "inherit",

        [breakpoints.down("xs")]: {
          marginLeft: 48
        }
      },
      select: {
        paddingRight: 32
      },
      selectIcon: {
        top: "calc(50% - 12px)",
        color: "inherit"
      }
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: customPalette.background.default,
        borderRadius: 4,
        "&$shrink": {
          transform: "translate(9px, -7px) scale(0.75)",
          padding: "2px 6px"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: customPalette.text.primary,
          borderWidth: 1
        }
      },
      inputSelect: {
        paddingRight: 32
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
        color: customPalette.text.default,
        backgroundColor: customPalette.background.default,
        padding: 32,
        borderRadius: 5,
        boxShadow: shadows[5],
        maxWidth: "none"
      }
    },
    MuiDialogContent: {
      root: {
        // Do not crop modal content. Fixes inner select dropdown z-index
        overflowY: "initial"
      }
    },
    MuiSelect: {
      icon: { marginRight: 8 }
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: 16
      }
    },
    MuiFormLabel: {
      root: {
        color: customPalette.hint.main
      }
    },
    MuiList: {
      root: {
        "&:focus": {
          outline: "none"
        }
      }
    },
    MuiListItem: {
      root: {
        borderRadius: 0,

        "&$selected": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiCard: {
      root: {
        padding: 8,
        [breakpoints.up("sm")]: {
          padding: "32px 24px"
        }
      }
    },
    MuiCardContent: {
      root: {
        padding: 0,

        "&:last-child": {
          paddingBottom: 0
        }
      }
    },
    MuiCardHeader: {
      root: {
        padding: 0
      },
      content: {
        overflow: "hidden"
      }
    },
    MuiSnackbarContent: {
      root: {
        padding: "16px 24px"
      },
      message: {
        fontWeight: 500,
        fontSize: "1rem",
        "& > span > svg": {
          marginRight: 12
        }
      }
    },
    MuiStepper: {
      root: {
        padding: "32px 0px 64px"
      }
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 0
      }
    },
    MuiStepConnector: {
      line: {
        borderColor: customPalette.primary.light
      },
      lineHorizontal: {
        borderTopWidth: 3
      },
      active: {
        "& > span": {
          borderColor: customPalette.success.main
        }
      },
      completed: {
        "& > span": {
          borderColor: customPalette.success.main
        }
      }
    },
    MuiTableSortLabel: {
      icon: {
        fontSize: "1.2rem"
      }
    }
  },
  props: {
    MuiFormControl: {
      fullWidth: true
    }
  }
});

// Recursive update basic config
const makeMuiTenantTheme = (themeOptions?: ThemeOptions): CustomTheme => {
  const customPalette = makeCustomPalette(makePalette(themeOptions?.palette));
  const breakpoints = makeBreakpoints(themeOptions?.breakpoints);
  const typography = makeTypography(customPalette);
  const shadows = baseShadows(customPalette);
  const reconfiguredThemeOptions = makeThemeOptions(
    customPalette,
    breakpoints,
    typography,
    shadows
  );

  return createMuiTheme(
    _.merge(reconfiguredThemeOptions, themeOptions)
  ) as CustomTheme;
};

export default makeMuiTenantTheme;
