import * as React from "react";
import { Typography, Popover, Paper } from "@material-ui/core";

import { Search, Button, AutoGrid } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";

import useStyles from "./dropdown.styles";

export type Props = {
  onSave: () => void;
  title: string;
  children: React.ReactElement<any>;
  content: React.ReactElement<any>;
  keepMounted?: boolean;
  onClose: () => void;
  listFilters: ListFiltersState<Record<string, any>>;
};

const Dropdown: React.FC<Props> = ({
  onSave,
  title,
  children,
  content,
  keepMounted,
  onClose,
  listFilters
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onSaveHandler = () => {
    onSave();
    setAnchorEl(null);
  };

  return (
    <div>
      {React.cloneElement(children, {
        onTouchStart: (e: any) => {
          onOpen(e);
        },
        onClick: (e: any) => {
          onOpen(e);
        }
      })}
      <Popover
        PaperProps={{ classes: { root: classes.root } }}
        keepMounted={keepMounted}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          onClose();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Paper classes={{ root: classes.paperRoot }}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Search listFilters={listFilters} />
          {content}
          <AutoGrid justify="space-evenly">
            <Button
              onClick={() => {
                setAnchorEl(null);
                onClose();
              }}
              variant="text"
              color="error"
            >
              Cancel
            </Button>
            <Button type="submit" color="success" onClick={onSaveHandler}>
              Save
            </Button>
          </AutoGrid>
        </Paper>
      </Popover>
    </div>
  );
};

export default Dropdown;
