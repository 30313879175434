import * as React from "react";

import {
  Breadcrumbs,
  Button,
  ExtendedMainHeader,
  ReportModal
} from "components";
import routes from "config/routes";
import { CourseItem } from "modules/courses/provider/courses";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import apiConfig from "config/api";

type Props = {
  course: CourseItem | MyCoursesListItem;
  refetch?: () => void;
};

type CourseHeaderButtonsProps = {
  course: CourseItem | MyCoursesListItem;
};

const CourseHeaderButtons: React.FC<CourseHeaderButtonsProps> = ({
  course
}) => (
  <div style={{ float: "right" }}>
    <ReportModal
      filePrefix={course.name || `company-${course.id}`}
      reportTypes={[
        {
          label: "Course detail",
          value: "courseDetailReport",
          requestPath: apiConfig.paths.authorized.report.createForCourseDetails(
            course.id
          )
        }
      ]}
    >
      {({ generating, handleOpen }) => (
        <Button disabled={generating} onClick={handleOpen} color="primary">
          {generating ? "Preparing report..." : "Generate report"}
        </Button>
      )}
    </ReportModal>
  </div>
);

const CourseHeader: React.FC<Props> = ({ course, refetch }) => {
  const isCoursesView = Boolean(refetch);

  const { isComponentAllowed } = useTenant();

  return (
    <ExtendedMainHeader
      title={course.name}
      hint={`(ID: ${course.externalId})`}
      subtitle={
        <Breadcrumbs
          routes={[
            {
              name: "Courses",
              path: isCoursesView ? routes.courses.list : routes.myCourses.list
            },
            { name: course.name }
          ]}
        />
      }
      button={
        isComponentAllowed(AllowedComponents.ReportCourseDetails) && (
          <CourseHeaderButtons course={course} />
        )
      }
    />
  );
};

export default CourseHeader;
