import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import parseHTML from "html-react-parser";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { useCourseAssignByUserMutation } from "modules/courses/provider/available-courses";
import * as PythonApi from "services/api/python";
import { Button, Card, CardIcon, Header } from "components";
import { IconRestore } from "assets/icons";
import useToast from "utils/hooks/useToast";
import { formatDuration } from "utils/datetime";
import { removeHtmlTags } from "utils/strings";
import routes from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { StatusCardPropsVariants } from "../course-info/learner/status-card-learner";
import PrerequisitesCourses from "../course-info/common/prerequisites-courses";
import LearnerActionByStatus from "../course-learner-action.component";
import CourseLearningPlans from "../course-info/common/course-learning-plans";

import useStyles from "./course-catalogue-modal.styles";

type Props = {
  course: MyCoursesListItem;
  isOpen: boolean;
  handleClose: () => void;
};

const CourseCatalogueModal: React.FC<Props> = ({
  course,
  isOpen,
  handleClose
}) => {
  const classes = useStyles();
  const { push, location } = useHistory();
  const { showToast } = useToast();
  const courseAssignByUser = useCourseAssignByUserMutation();

  const [
    courseAssignByUserLoading,
    setCourseAssignByUserLoading
  ] = React.useState(false);

  const isNotesHaveText = course.notes && Boolean(removeHtmlTags(course.notes));
  const tenant = useTenant();
  const isStartFromAvailable = tenant.isComponentAllowed(
    AllowedComponents.StartFromAvailable
  );
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );
  const startNotAllowed = course.prerequisiteCourses?.find(c => !c.isFinished);

  const handleGoToCourse = () => {
    push({
      ...location,
      pathname: routes.myCourses.singleCourse.replace(":courseId", course.id)
    });
  };
  const handleAddToCourse = async () => {
    setCourseAssignByUserLoading(true);
    try {
      await courseAssignByUser({ courseId: course.id });
      showToast("Course was successfully added", "success");
      handleGoToCourse();
    } catch (ex) {
      const errorMessage = PythonApi.getMessageStringFromError(ex);
      showToast(`Cannot add course: ${errorMessage}`, "error");
      setCourseAssignByUserLoading(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle classes={{ root: classes.dialogContent }}>
        <Header
          title={course.name}
          hint={`ID: ${course.externalId}`}
          variant="section"
          classes={{
            titleContainer: classes.header,
            title: classes.dialogHeaderTitle,
            hint: classes.dialogHeaderHint
          }}
        />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container spacing={2}>
          {course.status && (
            <Grid item xs={12} md={6}>
              <Card
                title={StatusCardPropsVariants[course.status].title}
                subtitle="Status"
                avatar={StatusCardPropsVariants[course.status].avatar}
                colorVariant={
                  StatusCardPropsVariants[course.status].colorVariant
                }
                variant="filled"
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Card
              title={
                course.duration
                  ? formatDuration(course.duration * 1000, "H[h] mm[min]")
                  : "-"
              }
              subtitle="Duration"
              avatar={<CardIcon icon={IconRestore} />}
              colorVariant="secondary"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Description</Typography>
            {parseHTML(course.description)}
          </Grid>
          {isNotesHaveText && (
            <Grid item xs={12}>
              <Typography variant="h4">Notes</Typography>
              {parseHTML(course.notes!)}
            </Grid>
          )}
          {isLearningPlans && (
            <Grid item xs={12}>
              <CourseLearningPlans
                learningPlanIndicator={course.learningPlanIndicator}
                margin="small"
              />
            </Grid>
          )}
          {isCoursePrerequisites &&
            course.prerequisiteCourses &&
            course.prerequisiteCourses?.length > 0 && (
              <Grid item xs={12}>
                <PrerequisitesCourses
                  prerequisitesCourses={course.prerequisiteCourses || []}
                  view="student"
                />
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleClose}
          variant="text"
          classes={{ root: clsx(classes.button, classes.closeButton) }}
        >
          Close
        </Button>
        {course?.assigned ? (
          <Button
            color="success"
            onClick={handleGoToCourse}
            classes={{ root: classes.button }}
          >
            Open course
          </Button>
        ) : (
          <Button
            onClick={handleAddToCourse}
            color="success"
            disabled={courseAssignByUserLoading}
            classes={{ root: classes.button }}
          >
            Add to my courses
          </Button>
        )}
        {isStartFromAvailable && (
          <div>
            <LearnerActionByStatus
              button
              {...{
                currentStatus: course.currentStatus || course.status,
                url: course.url,
                id: course.id,
                externalId: course.externalId,
                assigned: course.assigned,
                disabled: startNotAllowed && isCoursePrerequisites
              }}
            />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CourseCatalogueModal;
