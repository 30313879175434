import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  root: {
    display: "inline-flex",
    alignItems: "center"
  },
  icon: {
    verticalAlign: "middle",
    marginRight: spacing(2),
    fontSize: "inherit",
    color: "inherit"
  }
}));
