import * as React from "react";
import moment from "moment";
import { Paper, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { CourseStatus } from "modules/courses/courses.constants";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { formatDuration } from "utils/datetime";
import { CourseStatusChip } from "components";
import { IconTimerOutlined } from "assets/icons";
import { useTenant, AllowedComponents } from "services/tenant-provider";

import LearnerActionByStatus from "../course-learner-action.component";

import useStyles from "./course-card.styles";
import PrerequisiteCourses from "./prerequisite-courses";

type Props = {
  course: MyCoursesListItem;
  isHistoryView: boolean;
};

const CourseCard: React.FC<Props> = ({
  course: {
    id,
    detailsId,
    name,
    externalId,
    duration,
    status,
    currentStatus,
    statusTimestamp,
    url,
    assigned,
    prerequisiteCourses
  },
  isHistoryView = false
}) => {
  const classes = useStyles();
  const { push, location } = useHistory();
  const { pathname: currentPath } = useLocation();
  const tenant = useTenant();
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );
  const startNotAllowed = prerequisiteCourses?.find(c => !c.isFinished);

  const goToDetails = React.useCallback(() => {
    push({
      ...location,
      pathname: isHistoryView
        ? `${currentPath}/${detailsId}`
        : `${currentPath}/${id}`
    });
  }, [push, location, isHistoryView, currentPath, detailsId, id]);

  const isCompleted = status === CourseStatus.COMPLETED;
  const isManuallyCompleted = status === CourseStatus.MANUALLY_COMPLETED;

  return (
    <Paper elevation={4} className={classes.root} onClick={goToDetails}>
      <div className={classes.contentContainer}>
        <Typography variant="h2" className={classes.header} paragraph>
          <span className={classes.name}>{name}</span>
          <span className={classes.courseId}>ID: {externalId}</span>
        </Typography>
        <Typography variant="body2" className={classes.textWithIcon}>
          <IconTimerOutlined className={classes.icon} />
          {duration
            ? `Duration - ${formatDuration(duration * 1000, "H[h] mm[min]")}`
            : "-"}
        </Typography>
        <CourseStatusChip
          className={classes.status}
          status={status}
          label={
            isCompleted || isManuallyCompleted
              ? `${moment(statusTimestamp).format("L")} - ${
                  isCompleted ? "Completed" : "Manually Completed"
                }`
              : undefined
          }
        />
        {isCoursePrerequisites &&
          prerequisiteCourses &&
          prerequisiteCourses.length > 0 && (
            <PrerequisiteCourses prerequisiteCourses={prerequisiteCourses} />
          )}
      </div>

      <div>
        <LearnerActionByStatus
          button
          {...{
            currentStatus: currentStatus || status,
            url,
            id,
            externalId,
            assigned,
            disabled: startNotAllowed && isCoursePrerequisites
          }}
        />
      </div>
    </Paper>
  );
};

export default CourseCard;
