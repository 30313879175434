import React from "react";

import { AppLayout, LoadingPlaceholder, ErrorPlaceholder } from "components";

import { useMyLearningPlanDetails } from "../provider";
import MyLearningPlanHeader from "../components/learning-plan/my-learning-plan-details/my-learning-plan-header";
import MyLearningPlanInfo from "../components/learning-plan/my-learning-plan-details/my-learning-plan-info";

import { Match } from "./learning-plan-details.view";

type Props = {
  match: Match;
};

const MyLearningPlanDetailsView: React.FC<Props> = ({ match }) => {
  const { learningPlanId } = match.params;

  const { data: learningPlan, loading, error } = useMyLearningPlanDetails({
    variables: { learningPlanId }
  });

  if (loading || error || !learningPlan) {
    return (
      <AppLayout viewTitle="Learning plan Details">
        {loading ? <LoadingPlaceholder /> : <ErrorPlaceholder error={error} />}
      </AppLayout>
    );
  }

  return (
    <AppLayout viewTitle="Learning plan" hideSmUp>
      <MyLearningPlanHeader learningPlan={learningPlan} />
      <MyLearningPlanInfo learningPlan={learningPlan} />
    </AppLayout>
  );
};

export default MyLearningPlanDetailsView;
