import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  usersFilters: {
    pagination: { rowsPerPage: 10 },
    orderBy: ["lastName,ASC"]
  },
  approvalUsersFilters: {
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  }
};

type State = typeof initialState;

export enum actionTypes {
  SET_USERS_FILTERS = "setUsersFilters",
  SET_APPROVAL_USERS_FILTERS = "setApprovalUsersFilters",
  RESET_USERS_FILTERS = "resetUsersFilters",
  RESET_APPROVAL_USERS_FILTERS = "resetApprovalUsersFilters"
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USERS_FILTERS: {
      return {
        ...state,
        usersFilters: action.payload
      };
    }
    case actionTypes.SET_APPROVAL_USERS_FILTERS: {
      return {
        ...state,
        usersFilters: action.payload
      };
    }
    case actionTypes.RESET_USERS_FILTERS: {
      return {
        ...state,
        usersFilters: initialState.usersFilters
      };
    }
    case actionTypes.RESET_APPROVAL_USERS_FILTERS: {
      return {
        ...state,
        usersFilters: initialState.approvalUsersFilters
      };
    }
    default:
      return state;
  }
};

export const useModuleState = () => {
  const [state, dispatch] = useContext(StateContext);
  return [state.users, dispatch] as [
    State,
    Dispatch<{ type: actionTypes; payload?: any }>
  ];
};

export default reducer;
