import React from "react";
import { Paper, Tabs as MuiTabs, Tab } from "@material-ui/core";

import useStyles from "./tabs.styles";

interface Props {
  panels: {
    name: string;
    path: string;
  }[];
  activeTab: number;
  setActiveTab: (index: number) => void;
}

const Tabs = ({ panels, activeTab, setActiveTab }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper elevation={0} classes={{ root: classes.tabs }}>
        <MuiTabs
          value={activeTab}
          onChange={(_, nr) => setActiveTab(nr)}
          classes={{
            root: classes.root,
            indicator: classes.tabIndicator
          }}
        >
          {panels.map((panel) => (
            <Tab
              label={panel.name}
              key={panel.name}
              classes={{
                root: classes.tab,
                selected: classes.active
              }}
            />
          ))}
        </MuiTabs>
      </Paper>
    </div>
  );
};

export default Tabs;
