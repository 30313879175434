import moment from "moment";

export const formatDuration = (duration: number, format: string = "HH:mm:ss") =>
  moment.utc(duration).format(format);

export const createDuration = (
  hours: number,
  minutes: number,
  seconds: number
) => (hours * 3600 + minutes * 60 + seconds) * 1000;

// Converts hh:mm:ss format to seconds
export const parseDuration = (duration: string) => {
  const [hours, minutes, seconds] = duration.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};
