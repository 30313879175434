import * as React from "react";
import { createContext, useContextSelector } from "use-context-selector";
import * as Sentry from "@sentry/react";

export enum actionTypes {
  SET_CHECKED = "setChecked",
  SET_INITIAL = "setInitial"
}

type State = {
  onlyOneFromBranch: boolean;
  checked: string[];
  allowMultiple: boolean;
};
export const treeReducer = (
  state: State,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_CHECKED: {
      let newChecked: string[];
      if (state.allowMultiple) {
        newChecked = [...state.checked];
        if (action.payload.checked) {
          newChecked.push(action.payload.value);
        } else {
          newChecked.splice(newChecked.indexOf(action.payload.value), 1);
        }
      } else {
        if (action.payload.checked) {
          newChecked = [action.payload.value];
        } else {
          newChecked = [];
        }
      }

      return {
        ...state,
        checked: newChecked
      };
    }
    case actionTypes.SET_INITIAL: {
      return {
        ...state,
        checked: action.payload.initial
      };
    }

    default:
      return state;
  }
};

const createContextProvider = () => {
  const treeContexts: { [key in string]: React.Context<any> } = {};
  const getTreeContext = (treeId: string) => {
    if (!treeContexts[treeId]) {
      treeContexts[treeId] = createContext(null as any);
    }
    return treeContexts[treeId];
  };
  const removeTreeContext = (treeId: string) => {
    delete treeContexts[treeId];
  };
  return {
    getTreeContext,
    removeTreeContext
  };
};
export const TreeRootsContext = createContext(null as any);
const TreeContextProvider = createContextProvider();

export const useTreeState = (): [
  State,
  React.Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const id = React.useContext(TreeRootsContext);
  return useContextSelector(
    TreeContextProvider.getTreeContext(id),
    context => context
  );
};

export const useSelected = () => {
  const id = React.useContext(TreeRootsContext);
  try {
    // todo: after some open/close dropdown checked rows are disabled
    return useContextSelector(
      TreeContextProvider.getTreeContext(id),
      context => context[0].checked
    );
  } catch (ex) {
    Sentry.captureMessage(`Bad label name "${id}"`);
    Sentry.captureException(ex);
  }
};

export const useDispatch = () => {
  const id = React.useContext(TreeRootsContext);
  try {
    return useContextSelector(
      TreeContextProvider.getTreeContext(id),
      v => v[1]
    );
  } catch (ex) {
    Sentry.captureMessage(`Bad label name "${id}"`);
    Sentry.captureException(ex);
  }
};

export const useIsInStateChecked = (value: string) => {
  const id = React.useContext(TreeRootsContext);
  return useContextSelector(TreeContextProvider.getTreeContext(id), v =>
    v[0].checked.includes(value)
  );
};

export const useOnlyOneFromBranch = () => {
  const id = React.useContext(TreeRootsContext);
  return useContextSelector(
    TreeContextProvider.getTreeContext(id),
    v => v[0].onlyOneFromBranch
  );
};

export default TreeContextProvider;
