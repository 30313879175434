import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

type State = { loadedCourses: { [courseId: string]: string } };
type Payload = { courseId: string; fileName: string };

export const initialState = { loadedCourses: {} } as State;

export enum actionTypes {
  ADD_LOADED_COURSE = "addLoadedCourse",
  DELETE_LOADED_COURSE = "deleteLoadedCourse"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload: Payload }
) => {
  switch (action.type) {
    case actionTypes.ADD_LOADED_COURSE: {
      return {
        ...state,
        loadedCourses: {
          ...state.loadedCourses,
          [action.payload.courseId]: action.payload.fileName
        }
      };
    }
    case actionTypes.DELETE_LOADED_COURSE: {
      const newLoadedCourses = state.loadedCourses;
      const { courseId, fileName } = action.payload;
      if (newLoadedCourses[courseId] === fileName) {
        delete newLoadedCourses[courseId];
      }
      return { ...state, loadedCourses: newLoadedCourses };
    }
    default:
      return state;
  }
};

export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload: Payload }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.loadedCourses, dispatch];
};

export default reducer;
