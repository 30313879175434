import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, breakpoints }) => ({
  dateSeparator: {
    textAlign: "center",
    height: spacing(6),
    lineHeight: spacing(6) + "px",
    fontSize: "1rem"
  },
  form: {
    textAlign: "center"
  },
  reportTitle: {
    marginBottom: spacing(4)
  }
}));
