import * as React from "react";
import moment from "moment";

import {
  ResponsiveTableWrapper,
  CourseStatusChip,
  Pagination,
  DataTable,
  LoadingPlaceholder
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { CourseStatus } from "modules/courses/courses.constants";
import { UserCoursesListItem } from "modules/courses/provider/user-courses";
import { UserItem } from "modules/users/provider/users";

import CourseListToolbar from "./course-list-toolbar.component";
import CoursesListRowActions from "./course-list-row-actions";

type Props = {
  courses: UserCoursesListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  loading: boolean;
  user?: UserItem;
};

const CoursesList: React.FC<Props> = ({
  courses,
  meta,
  listFilters,
  loading,
  user
}) => (
  <div>
    <CourseListToolbar
      courses={courses}
      user={user}
      listFilters={listFilters}
    />

    {loading ? (
      <LoadingPlaceholder />
    ) : (
      <ResponsiveTableWrapper>
        <DataTable
          sortable
          emptyText="No courses"
          data={courses}
          listFilters={listFilters}
          columns={[
            {
              key: "name",
              title: "Course name",
              sortable: true,
              render: c => c.name
            },
            {
              key: "courseId",
              title: "Course ID",
              render: c => c.externalId
            },
            {
              key: "status",
              title: "Status",
              sortable: true,
              render: ({ status }) => <CourseStatusChip status={status} />
            },
            {
              key: "completedDate",
              title: "Completed date",
              sortable: true,
              render: ({ status, statusTimestamp }) =>
                status === CourseStatus.COMPLETED ||
                status === CourseStatus.MANUALLY_COMPLETED
                  ? moment(statusTimestamp).format("L")
                  : "-"
            },
            {
              key: "actions",
              title: "Actions",
              render: ({ id, status }) => (
                <CoursesListRowActions {...{ id, status }} />
              ),
              skip: !courses.find(c => c.status === CourseStatus.IN_PROGRESS)
            }
          ]}
        />
      </ResponsiveTableWrapper>
    )}

    {meta && meta.total > 0 && (
      <Pagination
        totalCount={meta.total}
        onChange={listFilters.setPagination}
        rowsPerPage={listFilters.pagination.rowsPerPage}
        page={listFilters.pagination.page}
      />
    )}
  </div>
);

export default CoursesList;
