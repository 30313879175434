import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { Popover, Typography } from "@material-ui/core";
import { sessionStorage } from "globalthis/implementation";

import {
  ResponsiveTableWrapper,
  DataTable,
  Button,
  LoadingPlaceholder
} from "components";
import useTableStyles from "components/data-table/data-table.styles";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { ProblemsListItem } from "../provider/problems";
import { Problems, ProblemTypes } from "../problems.constants";

import useStyles from "./problems-list.styles";

type Props = {
  problem?: ProblemsListItem;
  loading: boolean;
};

const ProblemDetailsTable: React.FC<Props> = ({ problem, loading }) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const tenant = useTenant();

  const getSenderNameTable = (fullName: string) => {
    return (
      <span
        className={clsx(tableClasses.ellipsis, tableClasses.customText)}
        title={fullName}
      >
        {fullName}
      </span>
    );
  };

  const setProblemInStorage = (problemId: string) =>
    sessionStorage.setItem("problemId", problemId);

  const isProblemsUserDetails = tenant.isComponentAllowed(
    AllowedComponents.ProblemsUserDetails
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  if (!problem) return <></>;

  return (
    <>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <ResponsiveTableWrapper>
          <DataTable
            emptyText="No problem details"
            data={[problem]}
            columns={[
              {
                key: "sender",
                title: "Sender name",
                render: ({ id, sender, senderFirstName, senderLastName }) => {
                  const fullName = sender
                    ? `${sender.firstName} ${sender.lastName}`
                    : `${senderFirstName} ${senderLastName}`;
                  return isProblemsUserDetails ? (
                    sender ? (
                      <Button
                        onClick={() => setProblemInStorage(id)}
                        component={RouterLink}
                        to={`/users/${sender.id}`}
                        variant="text"
                      >
                        {getSenderNameTable(fullName)}
                      </Button>
                    ) : (
                      <div>
                        <Button
                          aria-owns={isOpen ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                          variant="text"
                        >
                          {getSenderNameTable(fullName)}
                        </Button>

                        <Popover
                          id="mouse-over-popover"
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          className={classes.popover}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "left"
                          }}
                          disableRestoreFocus
                        >
                          <Typography className={classes.popoverContent}>
                            This problem was reported by a non-logged in user
                          </Typography>
                        </Popover>
                      </div>
                    )
                  ) : (
                    getSenderNameTable(fullName)
                  );
                }
              },
              {
                key: "type",
                title: "Type",
                render: ({ type }) => ProblemTypes[type]
              },
              {
                key: "courseId",
                title: "Course id",
                render: ({
                  course,
                  courseExternalId,
                  type,
                  freeTextCourse
                }) => (
                  <>
                    {type === Problems.UNABLE_TO_FIND_A_COURSE ? (
                      freeTextCourse ? (
                        <span className={clsx(tableClasses.customText)}>
                          {freeTextCourse}
                        </span>
                      ) : (
                        "-"
                      )
                    ) : course ? (
                      <Button
                        variant="text"
                        component={RouterLink}
                        to={`/courses/${course}`}
                      >
                        <span
                          className={clsx(
                            tableClasses.ellipsis,
                            tableClasses.customText
                          )}
                        >
                          {courseExternalId || course}
                        </span>
                      </Button>
                    ) : (
                      "-"
                    )}
                  </>
                )
              },
              {
                key: "createdAt",
                title: "Report date",
                render: ({ createdAt }) => moment(createdAt).format("L")
              }
            ]}
          />
        </ResponsiveTableWrapper>
      )}
    </>
  );
};

export default ProblemDetailsTable;
