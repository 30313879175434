import * as React from "react";
import { match } from "react-router";

import { useAuth } from "services/auth-provider";
import {
  ApiResultRenderer,
  AppLayout,
  ErrorPlaceholder,
  Header,
  LoadingPlaceholder,
  Section
} from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useCompaniesList } from "modules/companies/provider";
import CompaniesList from "modules/companies/components/companies-list/companies-list-container";
import { useCourseUsersList } from "modules/users/provider";
import { useCourseDirectUsersList } from "modules/users/provider/users";
import UsersList from "modules/users/components/user-list/users-list-container";
import DirectUserList from "modules/users/components/direct-user-list/direct-user-list-container";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { CourseInfoAdmin } from "../components/course-info/admin";
import { CourseHeader, CourseOwner } from "../components/course-info/common";
import { useCourseRead } from "../provider";
import CourseHistoryList from "../components/course-history/course-history-list";
import { CourseHistory, useCourseHistory } from "../provider/courses";

export type Match = match<{ courseId: string; companyId: string }>;

type Props = {
  match: Match;
};

const CourseDetailsView: React.FC<Props> = ({ match }) => {
  const { courseId, companyId } = match.params;
  const tenant = useTenant();
  const auth = useAuth();
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });

  const { data: course, loading, error, refetch } = useCourseRead({
    variables: { courseId }
  });

  const companiesListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  });
  const companiesList = useCompaniesList({
    variables: { listFilters: companiesListFilters, courseId },
    skip: isDesigner
  });

  const usersListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["lastName,ASC"]
  });
  const usersList = useCourseUsersList({
    variables: { listFilters: usersListFilters, id: courseId },
    skip: isDesigner
  });

  const directUsersListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["lastName,ASC"]
  });
  const directUsersList = useCourseDirectUsersList({
    variables: { listFilters: directUsersListFilters, id: courseId },
    skip:
      isDesigner ||
      !tenant.isComponentAllowed(AllowedComponents.CourseUserAssign)
  });

  const historyListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["lastName,ASC"]
  });
  const historyList = useCourseHistory({
    variables: { listFilters: historyListFilters, courseId },
    skip: isDesigner
  });

  const refetchAll = React.useCallback(() => {
    refetch();
    companiesList.refetch();
    usersList.refetch();
    directUsersList.refetch();
    historyList.refetch();
  }, [companiesList, directUsersList, historyList, refetch, usersList]);

  if (loading || error || !course) {
    return (
      <AppLayout viewTitle="Course Details">
        {loading ? <LoadingPlaceholder /> : <ErrorPlaceholder error={error} />}
      </AppLayout>
    );
  }

  return (
    <AppLayout viewTitle="Courses" hideSmUp>
      <CourseHeader refetch={refetchAll} course={course} />
      {course?.courseOwner && <CourseOwner courseOwner={course.courseOwner} />}
      <Section margin="big">
        <CourseInfoAdmin
          course={course}
          refetch={refetch}
          refetchAll={refetchAll}
        />
      </Section>

      {!isDesigner && (
        <Section margin="big">
          <ApiResultRenderer apiResult={companiesList} reloadsSection={false}>
            {({ data = [], meta, refetch, loading }) => (
              <>
                <Header
                  variant="section"
                  title={`Companies and groups (${meta?.total ?? 0})`}
                />
                <CompaniesList
                  refetch={refetch}
                  loading={loading}
                  companies={data}
                  meta={meta!}
                  listFilters={companiesListFilters}
                />
              </>
            )}
          </ApiResultRenderer>
        </Section>
      )}

      {!isDesigner && (
        <Section margin="big">
          <ApiResultRenderer apiResult={usersList} reloadsSection={false}>
            {({ data = [], meta, refetch, loading }) => (
              <>
                <Header
                  variant="section"
                  title={`Users (${meta?.total ?? 0})`}
                />
                <UsersList
                  refetch={refetch}
                  loading={loading}
                  users={data}
                  course={course}
                  meta={meta!}
                  listFilters={usersListFilters}
                />
              </>
            )}
          </ApiResultRenderer>
        </Section>
      )}

      {!isDesigner &&
        tenant.isComponentAllowed(AllowedComponents.CourseUserAssign) && (
          <Section margin="big">
            <ApiResultRenderer apiResult={directUsersList}>
              {({ data = [], meta, refetch, loading }) => (
                <>
                  <Header
                    variant="section"
                    title={`Direct users (${meta?.total ?? 0})`}
                  />
                  <DirectUserList
                    refetch={refetch}
                    loading={loading}
                    users={data}
                    meta={meta!}
                    listFilters={directUsersListFilters}
                  />
                </>
              )}
            </ApiResultRenderer>
          </Section>
        )}
      {!isDesigner && (
        <Section margin="big">
          <ApiResultRenderer apiResult={historyList}>
            {({ data = [], meta, refetch, loading }) => (
              <>
                <Header variant="section" title="History" />
                <CourseHistoryList
                  history={(data as CourseHistory).data}
                  refetch={refetch}
                  loading={loading}
                  listFilters={historyListFilters}
                  meta={meta!}
                  companyId={companyId}
                />
              </>
            )}
          </ApiResultRenderer>
        </Section>
      )}
    </AppLayout>
  );
};

export default CourseDetailsView;
