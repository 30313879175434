import * as React from "react";
import { IconButton } from "@material-ui/core";

import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from "assets/icons";

import useStyles from "./card.styles";

export type Props = {
  count: number;
  currentIndex: number;
  onClickLeft?: () => void;
  onClickRight?: () => void;
};

const CardArrowButtons: React.FC<Props> = ({
  count,
  currentIndex,
  onClickLeft,
  onClickRight
}) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        className={classes.arrowButton}
        disabled={currentIndex <= 0}
        onClick={onClickLeft}
      >
        <IconKeyboardArrowLeft classes={{ root: classes.arrowIcon }} />
      </IconButton>

      <IconButton
        className={classes.arrowButton}
        disabled={currentIndex + 1 >= count}
        onClick={onClickRight}
      >
        <IconKeyboardArrowRight classes={{ root: classes.arrowIcon }} />
      </IconButton>
    </>
  );
};

export default CardArrowButtons;
