import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  divider: {
    backgroundColor: palette.primary.main,
    marginRight: spacing(5.5)
  },
  root: {
    width: "100%",
    fontSize: "1rem",
    color: palette.text.default,
    backgroundColor: palette.background.default,
    borderRadius: 0,
    display: "flex",
    justifyContent: "left",
    height: spacing(10),
    padding: spacing(0, 4),
    "&:hover": {
      backgroundColor: palette.action.selected,
      boxShadow: "none"
    }
  },
  icon: {
    color: `${palette.primary.main} !important`
  }
}));
