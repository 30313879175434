import * as React from "react";
import clsx from "clsx";

import useStyles from "./placeholder.styles";

type Props = {
  image: React.ReactNode;
  message: React.ReactNode;
  inline: boolean;
};

const Placeholder: React.FC<Props> = ({ image, message, inline = false }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, inline && classes.inline)}>
      <div className={clsx(classes.content, inline && classes.inline)}>
        <div className={classes.image}>{image}</div>
        <div className={clsx(classes.message, inline && classes.inline)}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default Placeholder;
