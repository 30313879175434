import * as React from "react";
import clsx from "clsx";
import { Hidden } from "@material-ui/core";

import useStyles from "./extended-main-header.styles";

export type Props = {
  title: string;
  hint?: string;
  subtitle?: React.ReactNode;
  button?: React.ReactNode;
};

const ExtendedMainHeader: React.FC<Props> = ({
  title,
  hint,
  subtitle,
  button
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div
          className={clsx(
            classes.title,
            classes.text,
            (hint || button) && classes.withMargin
          )}
        >
          {title}
        </div>
        {hint && <div className={clsx(classes.hint, classes.text)}>{hint}</div>}
        {button && (
          <Hidden smDown>
            <div className={classes.button}>{button}</div>
          </Hidden>
        )}
      </header>

      {subtitle && (
        <Hidden smDown>
          <div className={classes.subtitle}>{subtitle}</div>
        </Hidden>
      )}
    </div>
  );
};

export default ExtendedMainHeader;
