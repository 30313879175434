import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
  root: {
    marginRight: spacing(3),
    color: palette.hint.main
  },
  icon: {
    color: palette.hint.main
  }
}));
