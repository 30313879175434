import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, breakpoints }) => ({
  typography: {
    fontSize: "0.875rem",
    [breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  cancelButton: {
    marginTop: spacing(2),
    order: 1,
    [breakpoints.up("sm")]: {
      marginTop: spacing(0),
      order: 0
    }
  }
}));
