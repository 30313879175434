import * as React from "react";

import {
  CourseStatus,
  AdminCourseStatus
} from "modules/courses/courses.constants";
import {
  IconClose,
  IconDone,
  IconPlayArrow,
  IconReplay,
  IconReportProblem
} from "assets/icons";

import Chip, { ChipVariant } from "./chip";

type StatusVariants = {
  [key in CourseStatus | AdminCourseStatus]: {
    variant: ChipVariant;
    icon?: React.ReactElement;
    defaultLabel: string;
  };
};

export const statusVariants: StatusVariants = {
  [AdminCourseStatus.PUBLISHED]: {
    variant: "success",
    icon: <IconDone />,
    defaultLabel: "Published"
  },
  [AdminCourseStatus.UNPUBLISHED]: {
    variant: "hint",
    icon: <IconClose />,
    defaultLabel: "Unpublished"
  },
  [CourseStatus.COMPLETED]: {
    variant: "success",
    icon: <IconDone />,
    defaultLabel: "Completed"
  },
  [CourseStatus.MANUALLY_COMPLETED]: {
    variant: "success",
    icon: <IconDone />,
    defaultLabel: "Manually Completed"
  },
  [CourseStatus.UNSATISFACTORY]: {
    variant: "secondary",
    icon: <IconReportProblem />,
    defaultLabel: "Unsatisfactory"
  },
  // [CourseStatus.WAIVED]: { variant: "secondary", defaultLabel: "Waived" },
  [CourseStatus.IN_PROGRESS]: {
    variant: "info",
    icon: <IconReplay />,
    defaultLabel: "In progress"
  },
  [CourseStatus.NOT_STARTED]: {
    variant: "hint",
    icon: <IconPlayArrow />,
    defaultLabel: "Not started"
  }
};

interface Props {
  status?: CourseStatus | AdminCourseStatus;
  label?: string;
  className?: string;
}

const CourseStatusChip: React.FC<Props> = ({ status, label, className }) => {
  if (!status) {
    return null;
  }
  const { icon, variant, defaultLabel } = statusVariants[status];

  return (
    <Chip
      {...{ icon, className, variant }}
      label={label || defaultLabel}
      size="small"
    />
  );
};

export default CourseStatusChip;
