import * as React from "react";
import { match } from "react-router";

import {
  ApiResultRenderer,
  AppLayout,
  ErrorPlaceholder,
  Header,
  LoadingPlaceholder,
  Section
} from "components";
import useListFilters from "utils/hooks/useListFilters";

import { useLearningPlanDetails } from "../provider";
import CourseChip from "../components/learning-plan/components/course-chip";
import LearningPlanHeader from "../components/learning-plan/learning-plan-details/learning-plan-header";
import ChipContainer from "../components/learning-plan/components/chip-container";
import ChipIndex from "../components/learning-plan/components/chip-index";
import { useCompaniesList } from "../../companies/provider";
import LearningPlanInfo from "../components/learning-plan/learning-plan-details/learning-plan-info";
import CompaniesList from "../components/learning-plan/my-learning-plan-details/companies-list";

export type Match = match<{ learningPlanId: string }>;

type Props = {
  match: Match;
};

const LearningPlanDetailsView: React.FC<Props> = ({ match }) => {
  const { learningPlanId } = match.params;

  const {
    data: learningPlan,
    loading,
    error,
    refetch
  } = useLearningPlanDetails({
    variables: { learningPlanId }
  });
  const companiesListFilters = useListFilters({
    pagination: { rowsPerPage: 10 },
    orderBy: ["createdAt,DESC"]
  });
  const companiesList = useCompaniesList({
    variables: {
      listFilters: companiesListFilters,
      learningPlanId
    }
  });

  if (loading || error || !learningPlan) {
    return (
      <AppLayout viewTitle="Learning plan Details">
        {loading ? <LoadingPlaceholder /> : <ErrorPlaceholder error={error} />}
      </AppLayout>
    );
  }

  return (
    <AppLayout viewTitle="Learning plan" hideSmUp>
      <LearningPlanHeader learningPlan={learningPlan} refetch={refetch} />
      <Section margin="big">
        {learningPlan?.courses?.map((course, index) => (
          <ChipContainer key={course.id}>
            <ChipIndex index={index} />
            <CourseChip
              label={[course.name, ...learningPlan.groups.map(g => g.name)]}
            />
          </ChipContainer>
        ))}
      </Section>

      <Section margin="big">
        <LearningPlanInfo learningPlan={learningPlan} />
      </Section>

      <Section margin="big">
        <ApiResultRenderer apiResult={companiesList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`Companies (${meta?.total ?? 0})`}
              />
              <CompaniesList
                groups={data}
                meta={meta!}
                listFilters={companiesListFilters}
                loading={loading}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default LearningPlanDetailsView;
