import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  myLearningPlansFilters: {
    pagination: { rowsPerPage: 10 },
    orderBy: ["name,ASC"]
  }
};

type State = typeof initialState;

export enum actionTypes {
  SET_MY_LEARNING_PLAN_FILTERS = "setMyLearningPlanFilters",
  RESET_MY_LEARNING_PLAN_FILTERS = "resetMyLearningPlanFilters"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_MY_LEARNING_PLAN_FILTERS: {
      return {
        ...state,
        myLearningPlansFilters: action.payload
      };
    }
    case actionTypes.RESET_MY_LEARNING_PLAN_FILTERS: {
      return {
        ...state,
        myLearningPlansFilters: initialState.myLearningPlansFilters
      };
    }
    default:
      return state;
  }
};

export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.myLearningPlans, dispatch];
};

export default reducer;
