import * as PythonApi from "services/api/python";
import { useFetch, FetchOptions, useFetchMutation } from "utils/hooks/useFetch";
import { ListFilters } from "utils/hooks/useListFilters";
import { CourseStatus } from "modules/courses/courses.constants";
import apiConfig from "config/api";
import { IUser } from "services/auth-provider";

import { UserRole, UserStatus } from "../users.constants";

export type UsersListFilters = {
  // Use comma separator for "in" operator when use many values (ex. roleName: `${UserRole.TENANT_ADMIN},${UserRole.INTERNAL_ADMIN}`)
  username?: string;
  roleName?: string;
  groupId?: string;
};

type UsersListVars = {
  listFilters?: ListFilters<UsersListFilters>;
  id?: string;
};

type ApprovalUsersListVars = {
  approvalListFilters?: ListFilters<UsersListFilters>;
  id?: string;
};

export type CompanyItem = {
  name: string;
  id: string;
  thumbnailUrl: string;
};

export type UserItem = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  username?: string;
  phone?: string;
  companyName: string;
  companyId?: string;
  createdAt: string;
  groups?: Array<{
    id: string;
    name: string;
    userId: string;
  }>;
  approver?: ApprovalUsersListItem;
  isAccepted?: boolean;
  isEmailConfirmed?: boolean;
  role: UserRole;
  status: UserStatus;
  updatedAt?: string;
  workAddress?: string;
  countInProgress?: number;
  countCompleted?: number;
  courseStatus?: CourseStatus;
  rootGroups: Array<{
    name: string;
    id: string;
    thumbnailUrl: string;
  }>;

  // select field additions
  groupsId?: string[]; //orDeserialized
  groupAddressId?: string; //orDeserialized
};

// should be deprecated
export type UserCreateUpdateVars = {
  email: string;
  firstName: string;
  lastName: string;
  username?: string;
  role: UserRole;
  isAccepted: boolean;
  groupId: string;
  workAddress: string;
  phone: string;

  // select field additions
  groups?: Array<{ id: string }>; // tree selector
  groupsId?: Array<string>; //orDeserialized
  groupAddressId?: string; //orDeserialized
};

export type UserProfileUpdateVars = {
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
};

export type UserReadVars = {
  userId: string;
};

export const createUsersListConfig = ({ listFilters }: UsersListVars) =>
  PythonApi.makeRequestForList<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.list,
    params: PythonApi.getParamsFromFilters(listFilters, {
      username: "exact",
      roleName: "in",
      groupId: "in"
    })
  });

export const useUsersList = (options: FetchOptions<UsersListVars>) =>
  useFetch(createUsersListConfig(options.variables), options);

export const createApprovalUsersListConfig = ({
  approvalListFilters
}: ApprovalUsersListVars) =>
  PythonApi.makeRequestForList<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.approveList,
    params: PythonApi.getParamsFromFilters(approvalListFilters, {
      username: "exact",
      roleName: "in",
      groupId: "in"
    })
  });

export const useApprovalUsersList = (
  options: FetchOptions<ApprovalUsersListVars>
) => useFetch(createApprovalUsersListConfig(options.variables), options);

export const createLightUsersListConfig = ({ listFilters }: UsersListVars) =>
  PythonApi.makeRequestForList<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.lightList,
    params: PythonApi.getParamsFromFilters(listFilters)
  });

export const useLightUsersList = (options: FetchOptions<UsersListVars>) =>
  useFetch(createLightUsersListConfig(options.variables), options);

export const createUserCreateConfig = () =>
  PythonApi.makeMutation<UserCreateUpdateVars, UserItem>(data => ({
    method: "POST",
    url: apiConfig.paths.authorized.user.list,
    data
  }));

export const useUserCreate = () => useFetchMutation(createUserCreateConfig());

export const createUserReadConfig = ({ userId }: UserReadVars) =>
  PythonApi.makeRequestForOne<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.getUser(userId)
  });

export const useUserRead = (options: FetchOptions<UserReadVars>) =>
  useFetch(createUserReadConfig(options.variables), options);

export const createUserUpdateConfig = (id: string) =>
  PythonApi.makeMutation<UserCreateUpdateVars, IUser>(variables => ({
    method: "PATCH",
    url: apiConfig.paths.authorized.user.changeUser(id),
    data: variables
  }));

export const useUserUpdate = ({ id }: { id: string }) =>
  useFetchMutation(createUserUpdateConfig(id));

export const createCourseUsersListConfig = ({
  listFilters,
  id
}: UsersListVars) =>
  PythonApi.makeRequestForList<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.listOfCourse(id || "_"),
    params: PythonApi.getParamsFromFilters(listFilters, {
      username: "contains",
      roleName: "in",
      groupId: "in"
    })
  });

export const useCourseUsersList = (options: FetchOptions<UsersListVars>) =>
  useFetch(createCourseUsersListConfig(options.variables), options);

export const createCourseDirectUsersListConfig = ({
  listFilters,
  id
}: UsersListVars) =>
  PythonApi.makeRequestForList<UserItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.directListOfCourse(id || "_"),
    params: PythonApi.getParamsFromFilters(listFilters, {
      username: "contains",
      roleName: "in",
      groupId: "in"
    })
  });

export const useCourseDirectUsersList = (
  options: FetchOptions<UsersListVars>
) => useFetch(createCourseDirectUsersListConfig(options.variables), options);

export const createUserProfileUpdateConfig = () =>
  PythonApi.makeMutation<UserProfileUpdateVars, IUser>(data => ({
    method: "PATCH",
    url: apiConfig.paths.authorized.user.current,
    data
  }));

export const useUserProfileUpdate = () =>
  useFetchMutation(createUserProfileUpdateConfig());

export const useUsersListStatusUpdate = (
  operationType: "activate" | "deactivate"
) => {
  type Vars = { users: string[] };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(data => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.user.listChangeStatus(operationType),
      data
    }))
  );
};

export const useUserStatusUpdate = (
  operationType: "activate" | "deactivate"
) => {
  type Vars = { id: string };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ id }) => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.user.changeStatus(id, operationType),
      data: {}
    }))
  );
};

type UserDeleteVars = {
  userId: string;
};

const createUserDeleteConfig = () =>
  PythonApi.makeMutation<UserDeleteVars>(({ userId }) => ({
    method: "DELETE",
    url: apiConfig.paths.authorized.user.changeUser(userId)
  }));

export const useUserDelete = () => useFetchMutation(createUserDeleteConfig());

export const useUserDisclaimerApprove = () => {
  type Vars = {};

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(() => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.user.approve,
      data: {}
    }))
  );
};

export type ApprovalUsersListItem = {
  id: string;
  firstName: string;
  lastName: string;
};

const useApproversListConfig = () =>
  PythonApi.makeRequestForList<ApprovalUsersListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.user.approvers
  });

export const useApproversList = (options: FetchOptions<UsersListVars>) =>
  useFetch(useApproversListConfig(), options);
