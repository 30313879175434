const storage = {
  local: {
    redirectTo: "redirectTo", // relative path
    defaultAuthSAML: "defaultAuthSAML", // "true"
    myCoursesLayoutPrefix: "myCoursesLayout-"
  },
  cookie: {
    // SAML login
    accessToken: "clms_access_token",
    refreshToken: "clms_refresh_token"
  },
  shared: {
    accessToken: "accessToken",
    refreshToken: "refreshToken",
    instance: "instance"
  }
};

export default storage;
