import * as PythonApi from "services/api/python";
import {
  useFetchMutation,
  FetchOptions,
  useFetch,
  useUploadAPI
} from "utils/hooks/useFetch";
import { ListFilters } from "utils/hooks/useListFilters";
import apiConfig from "config/api";

import { ProblemStatuses, ProblemTypes } from "../problems.constants";

export const useUploadReportFile = () => {
  type Vars = {
    storageUrl: string;
    tenantId: string;
    sasToken: string;
    uploadId: string;
    file: File;
  };

  return useUploadAPI(
    PythonApi.makeMutation<Vars>(
      ({ storageUrl, tenantId, sasToken, uploadId, file }) => {
        const data = new FormData();
        const nameElements = file.name.split(".");
        const extension = nameElements.pop() || "";
        data.append("file", file);

        return {
          baseURL: storageUrl,
          method: "PUT",
          url: apiConfig.paths.authorized.media.uploadReportAttachment(
            tenantId,
            sasToken,
            uploadId,
            extension
          ),
          data
        };
      }
    )
  );
};

export const useProblemCreate = () => {
  type Vars = {
    senderEmail?: string;
    senderUsername?: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderPhoneNumber?: string;
    type: string;
    course?: string;
    description: string;
    fileUrl?: string;
  };
  type Item = {
    id: string;
    description: string;
    fileUrl?: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(data => ({
      method: "POST",
      url: apiConfig.paths.authorized.problems.add,
      data
    }))
  );
};

export type ProblemsListItem = {
  id: string;
  description: string;
  fileUrl: string;
  course?: string;
  courseExternalId?: string;
  freeTextCourse?: string;
  // registered sender
  sender: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  // unregistered sender
  senderEmail: string;
  senderUsername: string;
  senderFirstName: string;
  senderLastName: string;
  senderPhoneNumber: string;

  assignee: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  assigneeId: string;
  createdAt: string;
  updatedAt: string;
  status: ProblemStatuses;
  type: keyof typeof ProblemTypes;
  notes: string;
  comments?: { data: Comment[]; count: number };
};

export type Comment = {
  id: string;
  text: string;
  sender: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  createdAt: string;
  updatedAt?: string;
  isInternal?: boolean;
};

type ProblemsListVars = {
  listFilters?: ListFilters<{}>;
};
export const useProblemsList = (options: FetchOptions<ProblemsListVars>) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<ProblemsListItem>({
      method: "GET",
      url: apiConfig.paths.authorized.problems.list,
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};

export const useProblem = (problemId: string) => {
  return useFetch(
    PythonApi.makeRequestForOne<ProblemsListItem>({
      method: "GET",
      url: `${apiConfig.paths.authorized.problems.list}${problemId}/`
    })
  );
};

export const useProblemUpdate = (problemId: string) => {
  type Vars = {
    assignee_id?: string;
    status: string;
    notes?: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(data => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.problems.update(problemId),
      data
    }))
  );
};

export const useProblemDetailsUpdate = () => {
  type Vars = {
    problemId: string;
    data: {
      assignee_id?: string;
      status: string;
      notes?: string;
    };
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(data => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.problems.update(data.problemId),
      data: data.data
    }))
  );
};

export const useAddCommentMutation = () => {
  type Vars = {
    ticket?: string;
    text: string;
    isInternal?: boolean;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(data => ({
      method: "POST",
      url: apiConfig.paths.authorized.problems.comment,
      data
    }))
  );
};

export type AssigneeItem = {
  id: string;
  firstName: string;
  lastName: string;
};
type AssigneesListVars = {
  listFilters?: ListFilters<{
    first_name?: string;
  }>;
};

export const useAssigneesList = (options: FetchOptions<AssigneesListVars>) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<AssigneeItem>({
      method: "GET",
      url: apiConfig.paths.authorized.problems.listOfAssignees,
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};
