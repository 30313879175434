import * as PythonApi from "services/api/python";
import { FetchOptions, useFetch } from "utils/hooks/useFetch";
import { ListFilters } from "utils/hooks/useListFilters";
import apiConfig from "config/api";

import { CompanyAdmin, CompanySponsor, CompanyStatus } from "./companies";

type CompaniesListTreeVars = {
  id: string;
  listFilters?: ListFilters<{
    name?: string;
  }>;
};

export type CompaniesListTree = {
  id: string;
  name: string;
  usersCount: number;
  subgroups: Array<CompaniesListTree>;

  parent: string;
  companyinfo: {
    addresses: string[];
    thumbnailUrl?: string;
    attSponsorId?: string;
    attSponsor: CompanySponsor;
  };
  coursesCount: number;
  subgroupsCount: number;
  admins: Array<CompanyAdmin>;
  externalId?: string;
  rootId?: string;
  status: CompanyStatus;
  companyDomain?: string;
};

type CompaniesListLightTreeVars = {
  id: string;
};

export type CompaniesListLightTree = {
  id: string;
  name: string;
  subgroups: Array<CompaniesListLightTree>;
  parent: string;
};

export const createCompaniesListTreeConfig = ({
  listFilters,
  id
}: CompaniesListTreeVars) =>
  PythonApi.makeRequestForList<CompaniesListTree>({
    method: "GET",
    url: apiConfig.paths.authorized.group.tree(id),
    params: PythonApi.getParamsFromFilters(listFilters)
  });

export const useCompaniesListTree = (
  options: FetchOptions<CompaniesListTreeVars>
) => useFetch(createCompaniesListTreeConfig(options.variables), options);

export const createCompaniesListLightTreeConfig = ({
  id
}: CompaniesListLightTreeVars) =>
  PythonApi.makeRequestForList<CompaniesListLightTree>({
    method: "GET",
    url: apiConfig.paths.authorized.group.treeLight(id)
  });

export const useCompaniesListLightTree = (
  options: FetchOptions<CompaniesListLightTreeVars>
) => useFetch(createCompaniesListLightTreeConfig(options.variables), options);
