import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import useTableStyles from "components/data-table/data-table.styles";
import { TooltipList } from "components";
import { pluralize } from "utils/strings";

import { PrerequisiteCourse } from "../../provider/courses";

type Props = {
  prerequisitesCourses: PrerequisiteCourse[];
};

const PrerequisitesList: React.FC<Props> = ({ prerequisitesCourses }) => {
  const tableClasses = useTableStyles();

  return prerequisitesCourses?.length > 0 ? (
    <Box display="flex" alignItems="baseline">
      <span className={clsx(tableClasses.ellipsis, tableClasses.customText)}>
        {prerequisitesCourses[0].name}
      </span>
      {prerequisitesCourses.length > 1 && (
        <TooltipList
          title={`${prerequisitesCourses.length} ${pluralize(
            "course",
            prerequisitesCourses.length
          )}`}
          list={prerequisitesCourses.map(course => course.name)}
        />
      )}
    </Box>
  ) : (
    <>-</>
  );
};

export default PrerequisitesList;
