import * as React from "react";

import {
  ApiResultRenderer,
  AppLayout,
  Section,
  DisclaimerModal
} from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useModuleState, actionTypes } from "modules/courses/reducers/courses";
import {
  useModuleState as useLPModuleState,
  actionTypes as actionTypesLP
} from "modules/courses/reducers/learning-plans";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import CoursesList from "../components/course-list/course-list";
import { useCoursesList, useLearningPlanList } from "../provider";
import TabsCoursesLearningPlans from "../components/learning-plan/components/tabs-courses-learning-plans";

type Props = {};

const CoursesView: React.FC<Props> = () => {
  const [{ coursesFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters(coursesFilters);
  const [{ learningPlansFilters }, dispatchLP] = useLPModuleState();
  const listFiltersLP = useListFilters(learningPlansFilters);

  const coursesList = useCoursesList({
    variables: { listFilters }
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_COURSES_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  const learningPlansList = useLearningPlanList({
    variables: { listFilters: listFiltersLP }
  });

  React.useEffect(() => {
    dispatchLP({
      type: actionTypesLP.SET_LEARNING_PLAN_FILTERS,
      payload: listFiltersLP
    });
  }, [listFiltersLP, dispatchLP]);

  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const isLegalDisclaimer = tenant.isComponentAllowed(
    AllowedComponents.LegalDisclaimer
  );

  const meta = coursesList.meta?.total;

  return (
    <AppLayout
      viewTitle={isLearningPlans ? "Courses" : `Courses (${meta ? meta : 0})`}
    >
      {isLearningPlans ? (
        <TabsCoursesLearningPlans
          coursesCount={coursesList.meta?.total || 0}
          learningPlansCount={learningPlansList.meta?.total || 0}
          activeTab={0}
          panelsSet="admin"
        >
          <ApiResultRenderer apiResult={coursesList} reloadsSection={false}>
            {({ data = [], meta, refetch, loading }) => (
              <CoursesList
                courses={data}
                meta={meta!}
                listFilters={listFilters}
                refetch={refetch}
                loading={loading}
              />
            )}
          </ApiResultRenderer>
        </TabsCoursesLearningPlans>
      ) : (
        <Section>
          {isLegalDisclaimer && <DisclaimerModal />}
          <ApiResultRenderer apiResult={coursesList} reloadsSection={false}>
            {({ data = [], meta, refetch, loading }) => (
              <CoursesList
                courses={data}
                meta={meta!}
                listFilters={listFilters}
                refetch={refetch}
                loading={loading}
              />
            )}
          </ApiResultRenderer>
        </Section>
      )}
    </AppLayout>
  );
};

export default CoursesView;
