import * as React from "react";
import clsx from "clsx";
import { Drawer as MuiDrawer, Typography, Divider } from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import { ModalProps } from "@material-ui/core/Modal";

import useStyles from "./drawer.styles";

export type Props = DrawerProps & {
  isOpen: boolean;
  classesPaper?: string;
  modalProps?: Partial<ModalProps>;
  title?: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  fitSize?: boolean;
  onBackdropClick?: () => void;
  hideDivider?: boolean;
};

const Drawer: React.FC<Props> = ({
  anchor = "right",
  children,
  onClose,
  isOpen,
  variant = "temporary",
  classesPaper,
  modalProps,
  title,
  size = "xs",
  fitSize = false,
  onBackdropClick,
  hideDivider = false,
  ...drawerProps
}) => {
  const classes = useStyles(0);

  const scrollSticky = () => {
    fitSize && isOpen && window.scrollTo(0, -1);
  };

  React.useEffect(() => {
    window.addEventListener("click", scrollSticky);
    window.addEventListener("scroll", scrollSticky);

    return () => {
      window.removeEventListener("click", scrollSticky);
      window.removeEventListener("scroll", scrollSticky);
    };
  });

  return (
    <MuiDrawer
      {...drawerProps}
      anchor={anchor}
      variant={variant}
      open={isOpen}
      onClose={onClose}
      onBackdropClick={onBackdropClick}
      ModalProps={{
        disableBackdropClick: Boolean(onBackdropClick),
        ...modalProps
      }}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      classes={{
        paper: clsx(
          classes.paper,
          Boolean(classesPaper) && classesPaper,
          classes[size],
          fitSize && classes.fitSize
        )
      }}
      PaperProps={{ role: "dialog" }}
    >
      {title && (
        <>
          <Typography
            variant="h2"
            className={classes.title}
            aria-label="drawer-title"
          >
            {title}
          </Typography>
          {!hideDivider && (
            <Divider
              classes={{ root: classes.divider }}
              aria-label="drawer-divider"
            />
          )}
        </>
      )}
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
