import * as React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { Collapse, Grid, Typography, Tooltip } from "@material-ui/core";

import {
  IconKeyboardArrowRight,
  IconKeyboardArrowDown,
  IconInfo
} from "assets/icons";
import {
  useGetTenant,
  TenantAccessDescription,
  TenantAccess
} from "modules/tenants/provider/tenants";
import {
  FormError,
  CancelConfirmButtons,
  Section,
  SwitchControl
} from "components";
import * as PythonApi from "services/api/python";
import { FlagCategories } from "modules/tenants/tenants.constans";

import useStyles from "./tenants-config-edit.styles";

type Props = {
  tenantId: string;
  onSubmit: (props: any) => void;
  onCancel: () => void;
  setIsModalEnabled: (isEnabled: boolean) => void;
};

const EditTenantsConfig: React.FC<Props> = ({
  tenantId,
  onSubmit,
  onCancel,
  setIsModalEnabled
}) => {
  const classes = useStyles();

  const [isGeneralTabOpen, setGeneralTabOpen] = React.useState(false);
  const [isReportsTabOpen, setReportsTabOpen] = React.useState(false);
  const [isProblemsTabOpen, setProblemsTabOpen] = React.useState(false);
  const [isCoursesTabOpen, setCoursesTabOpen] = React.useState(false);
  const [isUsersTabOpen, setUsersTabOpen] = React.useState(false);
  const [isGroupsTabOpen, setGroupsTabOpen] = React.useState(false);

  const { data: tenantConfig } = useGetTenant({
    variables: { tenantId }
  });

  const access: TenantAccess | undefined = React.useMemo(() => {
    return tenantConfig?.config?.access;
  }, [tenantConfig]);

  const accessDescription:
    | TenantAccessDescription
    | undefined = React.useMemo(() => {
    return tenantConfig?.config?.accessDescription;
  }, [tenantConfig]);

  const initialValues = React.useMemo(() => {
    if (access) return { ...access };
    else return {};
  }, [access]);

  const handleSubmit = React.useCallback(
    async values => {
      try {
        const data = tenantConfig;
        if (data?.config?.access) data!.config!.access = values;
        await onSubmit(data);
      } catch (ex) {
        return PythonApi.getFormErrors(ex);
      }
    },
    [onSubmit, tenantConfig]
  );

  const getFlagField = (flag: keyof TenantAccessDescription) => (
    <Grid key={flag} item xs={12}>
      <div className={classes.flagContainer}>
        <span className={classes.flagTitle}>{flag}</span>
        <span className={classes.flex}>
          <Tooltip
            title={accessDescription![flag].description || "No description"}
            placement="left"
            color="disabled"
            classes={{
              popper: classes.popper
            }}
          >
            <IconInfo className={classes.infoIcon} />
          </Tooltip>
          <Field
            name={flag}
            color="primary"
            margin="none"
            type="checkbox"
            component={SwitchControl}
          />
        </span>
      </div>
    </Grid>
  );

  const getCategoryFlagItem = (
    category: FlagCategories,
    isOpen: boolean,
    setOpen: (arg: boolean) => void
  ) => (
    <div className={classes.categoryContainer}>
      <div
        onClick={() => setOpen(isOpen ? false : true)}
        className={classes.categoryTitleContainer}
      >
        {isOpen ? <IconKeyboardArrowDown /> : <IconKeyboardArrowRight />}
        <Typography className={classes.categoryTitle} variant="h3">
          {category}
        </Typography>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
        {(access &&
          accessDescription &&
          Object.keys(accessDescription)
            .filter(
              flag =>
                accessDescription[flag as keyof TenantAccessDescription]
                  .category === category
            )
            .map(flag =>
              getFlagField(flag as keyof TenantAccessDescription)
            )) || (
          <h5 className={classes.noCategories}>
            Categories has not been set for this tenant
          </h5>
        )}
      </Collapse>
    </div>
  );

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        submitting,
        submitError,
        pristine,
        hasValidationErrors
      }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ pristine: true }}
            onChange={({ pristine }) => {
              setIsModalEnabled(!pristine);
            }}
          />
          {submitError && <FormError>{submitError}</FormError>}
          <Section margin="dense">
            {getCategoryFlagItem(
              FlagCategories.GENERAL,
              isGeneralTabOpen,
              setGeneralTabOpen
            )}

            {getCategoryFlagItem(
              FlagCategories.REPORT,
              isReportsTabOpen,
              setReportsTabOpen
            )}

            {getCategoryFlagItem(
              FlagCategories.PROBLEMS,
              isProblemsTabOpen,
              setProblemsTabOpen
            )}

            {getCategoryFlagItem(
              FlagCategories.COURSES,
              isCoursesTabOpen,
              setCoursesTabOpen
            )}

            {getCategoryFlagItem(
              FlagCategories.USERS,
              isUsersTabOpen,
              setUsersTabOpen
            )}

            {getCategoryFlagItem(
              FlagCategories.GROUPS,
              isGroupsTabOpen,
              setGroupsTabOpen
            )}
          </Section>
          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel="Save"
            onCancel={onCancel}
          />
        </form>
      )}
    </Form>
  );
};

export default EditTenantsConfig;
