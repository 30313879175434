import filesize from "filesize";

export const formatFileSize = (size: number | undefined) => {
  if (!size && size !== 0) {
    return;
  }
  return filesize(size, { base: 10 });
};

export const getInitials = (str: string) =>
  str
    .split(/\s+/)
    .map(s => s.trim().slice(0, 1))
    .join("");

export function removeHtmlTags(text: string) {
  return text.replace(/(<([^>]+)>)/gi, "");
}

const pluralizeTranslationTable = [
  ["y", "ies"],
  ["x", "xes"],
  ["ss", "sses"],
  ["", "s"]
];

export const pluralize = (message: string, value: number) => {
  if (value === 1) {
    return message;
  }

  for (const [oldSuffix, newSuffix] of pluralizeTranslationTable) {
    if (message.endsWith(oldSuffix)) {
      return `${message.slice(
        0,
        message.length - oldSuffix.length
      )}${newSuffix}`;
    }
  }
};

export const joinWithDots = (arr: string[]) =>
  arr
    .map(s => s.trim())
    .map(s => (s.endsWith(".") ? s.slice(0, s.length - 1) : s))
    .join(". ");

export const splitCamelcase = (text: string) =>
  text.replace(/([a-z](?=[A-Z]))/g, "$1 ");

export const splitPhoneAndExtension = (value: string | undefined) => {
  if (!value) {
    return { phone: "", extension: "" };
  } else if (value.includes("x")) {
    const [phone, extension] = value.split("x").map(v => v.trim());
    return { phone, extension };
  } else {
    return { phone: value.trim(), extension: "" };
  }
};
