import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, typography }: CustomTheme) => ({
  dragIndicator: {
    paddingLeft: spacing(1),
    color: palette.primary.contrastText,
    width: 30
  },
  error: {
    fontSize: typography.pxToRem(12),
    color: palette.error.main,
    marginLeft: spacing(1)
  }
}));
