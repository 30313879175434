import * as React from "react";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  FormControl,
  FormHelperText,
  IconButton,
  Badge
} from "@material-ui/core";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";

import { Button, LoadingPlaceholder } from "components";
import { formatFileSize } from "utils/strings";
import {
  IconAttachFile,
  IconClear,
  IconCloudUploadOutlined,
  IconError
} from "assets/icons";

import useStyles from "./file-control.styles";

type Props = FieldRenderProps<File, HTMLElement> & {
  acceptTypes?: string[];
  acceptTypeText?: string;
  maxSize?: number;
  isLoading?: Boolean;
  loadingMessage?: string;
  disabled?: Boolean;
};

const FileControl: React.FC<Props> = ({
  input: { value, onChange },
  meta: { submitError, dirtySinceLastSubmit, error },
  acceptTypes,
  acceptTypeText,
  maxSize,
  isLoading,
  loadingMessage = "Loading...",
  disabled = false
}) => {
  const classes = useStyles();

  const onDropAccepted = React.useCallback(
    acceptedFiles => {
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );

  const onDropRejected = React.useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const {
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize,
    multiple: false,
    accept: acceptTypes,
    disabled: !!disabled
  });

  // Dropzone doesn't support `touched` value and code below aims to simulate this
  // When there's no file value is string and when there's file value is object
  // If value was of type object at least once, then isDirty = true
  const [isDirty, setIsDirty] = React.useState(false);
  React.useEffect(() => {
    setIsDirty(isDirty || typeof value === "object");
  }, [isDirty, value]);

  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  let imageType;
  let previewImage;

  if (typeof value === "string" || value instanceof String) {
    imageType = value.match(/\.(jpeg|jpg|png)$/);
    previewImage = value.toString();
  } else {
    imageType = value && value.type.match("image.*");
    previewImage = value && window.URL.createObjectURL(value);
  }

  return (
    <FormControl error={showError}>
      {value && imageType ? null : (
        <div {...getRootProps()} className={classes.dropContainer}>
          <input {...getInputProps()} />

          <div className={classes.placeholder}>
            <IconCloudUploadOutlined className={classes.uploadIcon} />

            <div className={classes.dropTextContainer}>
              {isDragActive ? (
                <Typography className={classes.dropText}>
                  Drop the file here
                </Typography>
              ) : (
                <>
                  <Typography className={classes.dropText}>
                    Drag here or
                  </Typography>
                  <Button
                    color="secondary"
                    variant="text"
                    className={classes.browseButton}
                  >
                    browse
                  </Button>
                  <Typography className={classes.dropText}>
                    to upload file{" "}
                    {maxSize && `(max: ${formatFileSize(maxSize)})`}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {value &&
        (imageType ? (
          <figure>
            <Badge
              color="error"
              badgeContent={
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={() => onChange(undefined)}
                >
                  <IconClear className={classes.clearIcon} />
                </IconButton>
              }
              classes={{ badge: classes.badge }}
            >
              <img alt="" src={previewImage} className={classes.imagePreview} />
              {isLoading && (
                <LoadingPlaceholder inline message={loadingMessage} />
              )}
            </Badge>
          </figure>
        ) : (
          <div className={classes.container}>
            <IconAttachFile color="secondary" />
            <Typography color="secondary" className={classes.text}>
              {value.name} ({formatFileSize(value.size)})
            </Typography>
            {isLoading && (
              <LoadingPlaceholder inline message={loadingMessage} />
            )}
            {!isLoading && (
              <Button
                color="error"
                variant="text"
                onClick={() => onChange(undefined)}
              >
                Delete
              </Button>
            )}
          </div>
        ))}

      {rejectedFiles.length > 0 ? (
        <div className={classes.container}>
          <IconError color="error" />
          <Typography color="error" className={classes.text}>
            {rejectedFiles.length > 1
              ? "Accepted single file"
              : acceptTypes && !acceptTypes.includes(rejectedFiles[0].type)
              ? `Invalid type. Accepted: ${acceptTypeText}.`
              : maxSize && rejectedFiles[0].size > maxSize
              ? "Invalid size"
              : "Invalid file"}
          </Typography>
        </div>
      ) : (
        showError && (
          <FormHelperText error>{error || submitError}</FormHelperText>
        )
      )}
    </FormControl>
  );
};

export default FileControl;
