import * as React from "react";

import { useAuth } from "services/auth-provider";
import { Section, AppLayout, ApiResultRenderer } from "components";
import useListFilters from "utils/hooks/useListFilters";
import { UserRole } from "modules/users/users.constants";
import {
  useModuleState,
  actionTypes
} from "modules/companies/reducers/companies";

import CompaniesList from "../components/companies-list/companies-list-container";
import { useCompaniesList } from "../provider";

type Props = {};

const CompaniesView: React.FC<Props> = () => {
  const { checkAccess } = useAuth();
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const [{ companiesFilters }, dispatch] = useModuleState();

  const listFilters = useListFilters(companiesFilters);

  const companiesList = useCompaniesList({
    variables: {
      listFilters,
      ...(isTenantAdmin ? { isMember: true } : { onlyRoots: true })
    }
  });
  const { meta, refetch } = companiesList;

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_COMPANIES_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  return (
    <AppLayout
      viewTitle={`Companies ${isTenantAdmin ? "and groups" : ""} (${(meta &&
        meta.total) ||
        0})`}
    >
      <Section>
        <ApiResultRenderer apiResult={companiesList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <CompaniesList
              refetch={refetch}
              loading={loading}
              companies={data}
              meta={meta!}
              listFilters={listFilters}
            />
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default CompaniesView;
