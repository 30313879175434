import * as React from "react";

import useStyles from "./button-container.styles";

type Props = {};

const ButtonContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ButtonContainer;
