import * as PythonApi from "services/api/python";
import { BASE_URLS } from "services/api";
import { useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export enum UploadStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

export type StatusItem = {
  status?: UploadStatus;
  fileUrl?: string;
};

export type CheckUploadStatusVars = {
  uploadId: string;
};

export const useUploadStatus = () => {
  return useFetchMutation(
    PythonApi.makeMutation<CheckUploadStatusVars, StatusItem>(variables => {
      return {
        baseURL: BASE_URLS.PYTHON_API,
        method: "GET",
        url: apiConfig.paths.authorized.media.checkUploadStatus(
          variables.uploadId
        ),
        timeout: 0
      };
    })
  );
};
