import * as React from "react";

import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";

import FullscreenMessage from "../components/fullscreen-message.component";

type Props = {};

const SetNewPasswordSuccessView: React.FC<Props> = () => (
  <GuestLayout>
    <SidePanel variant="login" />
    <Content>
      <FullscreenMessage
        variant="success"
        title="Success!"
        message={
          <span>
            Your password has been changed. You can sign in.
            <br />
            <br />
            Have a good day!
          </span>
        }
      />
    </Content>
    <BottomPanel variant="login" />
  </GuestLayout>
);

export default SetNewPasswordSuccessView;
