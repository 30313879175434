import * as React from "react";
import clsx from "clsx";
import { Field, Form, FormSpy } from "react-final-form";
import { IconButton } from "@material-ui/core";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import {
  CancelConfirmButtons,
  FileControl,
  FormError,
  Header,
  Section,
  SelectControl,
  TextControl
} from "components";
import { createValidator } from "utils/forms";
import * as PythonApi from "services/api/python";
import useListFilters from "utils/hooks/useListFilters";
import { UserRole } from "modules/users/users.constants";
import { IconAdd, IconClear } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { CompanyItem } from "../provider/companies";
import { useUsersList } from "../../users/provider";

import useStyles from "./company-form.styles";

const validator = (withVoucher: boolean) =>
  createValidator({
    name: { presence: { allowEmpty: false, message: "^Name can't be blank" } },
    externalId: {
      presence: { allowEmpty: false },
      format: {
        pattern: /\S*/,
        message: ` can't contain whitespaces`
      }
    },
    voucherNumber: withVoucher
      ? {
          presence: {
            allowEmpty: false,
            message: "^Voucher can't be blank"
          }
        }
      : undefined
  });

type Props = {
  onCancel: () => void;
  onSubmit: (...args: any) => Promise<void>;
  company?: CompanyItem;
  setIsModalEnabled: (isEnabled: boolean) => void;
};

const CompanyForm: React.FC<Props> = ({
  onCancel,
  onSubmit,
  company,
  setIsModalEnabled
}) => {
  const classes = useStyles();
  const tenant = useTenant();

  const [isLoading, setIsLoading] = React.useState(false);

  const withVoucher = tenant.isComponentAllowed(AllowedComponents.Voucher);
  const isNoSponsorAndAdmins = tenant.isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );

  const isCompanyEmailDomain = tenant.isComponentAllowed(
    AllowedComponents.CompanyEmailDomain
  );

  const adminsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    filters: {
      roleName: PythonApi.prepareFilterValues([
        UserRole.TENANT_ADMIN,
        UserRole.INTERNAL_ADMIN
      ])
    }
  });
  const admins = useUsersList({
    variables: { listFilters: adminsListFilters },
    skip: isNoSponsorAndAdmins
  });

  const initialValues = React.useMemo(() => {
    if (company) {
      const {
        name,
        companyinfo,
        admins,
        externalId,
        voucherNumber,
        companyDomain
      } = company;
      return {
        name: name ? name : "",
        externalId: externalId ? externalId : "",
        addresses: companyinfo?.addresses[0] ? companyinfo.addresses : [""],
        thumbnail: companyinfo?.thumbnailUrl || "",
        admins: admins.map(admin => admin.id),
        attSponsorId: companyinfo?.attSponsorId,
        voucherNumber: Boolean(voucherNumber) ? voucherNumber : "",
        companyDomain: Boolean(companyDomain) ? companyDomain : ""
      };
    } else {
      return {
        name: "",
        externalId: "",
        addresses: [""],
        thumbnail: "",
        admins: [],
        attSponsorId: undefined,
        voucherNumber: "",
        companyDomain: ""
      };
    }
  }, [company]);

  return (
    <Form
      onSubmit={async values => {
        try {
          setIsLoading(true);
          await onSubmit({
            thumbnail: values.thumbnail ? values.thumbnail : "",
            name: values.name,
            ...(!isNoSponsorAndAdmins && { admins: values.admins }),
            parent: undefined,
            companyinfo: {
              ...(!isNoSponsorAndAdmins && {
                attSponsorId: values.attSponsorId
              }),
              addresses: values.addresses
            },
            externalId: values.externalId,
            voucherNumber: Boolean(values.voucherNumber)
              ? values.voucherNumber
              : undefined,
            companyDomain: Boolean(values.companyDomain)
              ? values.companyDomain
              : undefined
          });
        } catch (ex) {
          return PythonApi.getFormErrors(ex);
        } finally {
          setIsLoading(false);
        }
      }}
      validate={validator(withVoucher)}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        pristine,
        hasValidationErrors
      }) => (
        <form onSubmit={handleSubmit} className={classes.formContent}>
          <FormSpy
            subscription={{ pristine: true }}
            onChange={({ pristine }) => {
              setIsModalEnabled(!pristine);
            }}
          />
          {submitError && <FormError>{submitError}</FormError>}
          <Section margin="dense">
            <Header
              variant="formSection"
              number="1"
              title="Enter company details"
            />

            <Field
              name="name"
              label="Company name"
              variant="outlined"
              margin="normal"
              component={TextControl}
              validationIndicator
            />
            <Field
              name="externalId"
              label="External company ID"
              variant="outlined"
              margin="normal"
              component={TextControl}
              validationIndicator
            />
            {isCompanyEmailDomain && (
              <Field
                name="companyDomain"
                label="Company's email domain (optional)"
                variant="outlined"
                margin="normal"
                component={TextControl}
                validationIndicator
                validate={domain => {
                  if (domain && domain.includes("@"))
                    return "Enter only the domain after @";
                }}
              />
            )}
            {withVoucher && (
              <Field
                name="voucherNumber"
                label="Voucher (required)"
                variant="outlined"
                margin="normal"
                component={TextControl}
                validationIndicator
              />
            )}
          </Section>
          <Section margin="dense">
            <Header
              variant="formSection"
              number="2"
              title="Add company address"
            />

            <FieldArray name="addresses">
              {({ fields }) =>
                fields.map((address: string, index: number) => (
                  <div key={index}>
                    <Field
                      name={address}
                      label="Work address"
                      variant="outlined"
                      margin="normal"
                      component={TextControl}
                      validationIndicator
                      className={classes.addressField}
                      validate={address => {
                        if (!address) return "Address can't be blank";
                      }}
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => fields.push("")}
                        className={classes.addressButton}
                      >
                        <IconAdd />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={clsx(
                          classes.clearIcon,
                          classes.addressButton
                        )}
                        onClick={() => fields.remove(index)}
                      >
                        <IconClear />
                      </IconButton>
                    )}
                  </div>
                ))
              }
            </FieldArray>
          </Section>
          {!isNoSponsorAndAdmins && (
            <>
              <Section margin="dense">
                <Header
                  variant="formSection"
                  number="3"
                  title="Assign admins"
                  hint="(optional)"
                />
                <Field
                  multiple
                  allowMultiple
                  name="admins"
                  label="Choose admins"
                  component={SelectControl}
                  options={
                    admins.data
                      ? admins.data.map(admin => ({
                          value: admin.id,
                          label: `${admin.firstName} ${admin.lastName}`
                        }))
                      : []
                  }
                  isLoading={admins.loading}
                />
              </Section>

              <Section margin="dense">
                <Header
                  variant="formSection"
                  number="4"
                  title="Assign AT&T Sponsor"
                  hint="(optional)"
                />

                <Field
                  name="attSponsorId"
                  label="Choose AT&T Sponsor"
                  component={SelectControl}
                  options={
                    admins.data
                      ?.filter(admin => admin.role === UserRole.INTERNAL_ADMIN)
                      .map(admin => ({
                        value: admin.id,
                        label: `${admin.firstName} ${admin.lastName}`
                      })) ?? []
                  }
                  isLoading={admins.loading}
                />
              </Section>
            </>
          )}
          <Section margin="dense">
            <Header
              variant="formSection"
              number={isNoSponsorAndAdmins ? "3" : "5"}
              title="Add company logo"
              hint="(optional)"
            />

            <Field
              name="thumbnail"
              margin="normal"
              component={FileControl}
              acceptTypes={[".jpeg", ".jpg", ".png"]}
              acceptTypeText="jpeg, jpg, png"
              maxSize={500 * 1000 * 1000}
              isLoading={isLoading}
            />
          </Section>

          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel={company ? "Save" : "Add company"}
            onCancel={onCancel}
          />
        </form>
      )}
    </Form>
  );
};

export default CompanyForm;
