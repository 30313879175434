import {
  MultiPolicyValidator,
  PolicyValidator
} from "./password-policy.validators";

class PasswordPolicyManager extends MultiPolicyValidator {
  constructor(policyValidators: PolicyValidator[]) {
    // Always require all validators to pass
    super(policyValidators.length, policyValidators);
  }

  getMessage() {
    return "Password doesn't match our policy";
  }

  getWhiteSpaceMessage() {
    return "Password can't contain whitespaces";
  }
}

export default PasswordPolicyManager;
