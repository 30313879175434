import * as React from "react";

import { AppLayout, Section, ApiResultRenderer, Header } from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import UsersList from "../components/user-list/users-list-container";
import ApprovedUsersList from "../components/approved-users-list/approved-users-list-container";
import { useUsersList, useApprovalUsersList } from "../provider";
import { useModuleState, actionTypes, initialState } from "../reducers/users";

type Props = {};

const UsersView: React.FC<Props> = () => {
  const { checkAccess } = useAuth();
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const tenant = useTenant();

  const [{ usersFilters, approvalUsersFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters(
    isTenantAdmin ? initialState.usersFilters : usersFilters
  );

  const isApprovedUsersList = tenant.isComponentAllowed(
    AllowedComponents.ApprovedUsersList
  );

  const usersList = useUsersList({
    variables: { listFilters }
  });

  const { meta } = usersList;

  const approvalListFilters = useListFilters(approvalUsersFilters);

  const approvalUsersList = useApprovalUsersList({
    variables: { approvalListFilters },
    skip: !isApprovedUsersList
  });
  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_USERS_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  React.useEffect(() => {
    if (isApprovedUsersList) {
      dispatch({
        type: actionTypes.SET_APPROVAL_USERS_FILTERS,
        payload: approvalListFilters
      });
    }
  }, [dispatch, approvalListFilters, isApprovedUsersList]);

  return (
    <>
      <AppLayout viewTitle={`Users (${(meta && meta.total) || 0})`}>
        <Section>
          <ApiResultRenderer apiResult={usersList} reloadsSection={false}>
            {({ data = [], meta, refetch, loading }) => (
              <UsersList
                users={data}
                meta={meta!}
                listFilters={listFilters}
                refetch={refetch}
                loading={loading}
              />
            )}
          </ApiResultRenderer>
        </Section>
        {isApprovedUsersList && (
          <Section>
            <ApiResultRenderer
              apiResult={approvalUsersList}
              reloadsSection={false}
            >
              {({ data = [], meta, refetch, loading }) => (
                <>
                  <Header variant="section" title="History of approvals" />
                  <ApprovedUsersList
                    users={data}
                    meta={meta!}
                    listFilters={approvalListFilters}
                    refetch={refetch}
                    loading={loading}
                  />
                </>
              )}
            </ApiResultRenderer>
          </Section>
        )}
      </AppLayout>
    </>
  );
};

export default UsersView;
