import * as React from "react";
import moment from "moment";

import { ListToolbar, AutoGrid, Button, SearchFilter } from "components";
import { UserItem } from "modules/users/provider/users";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { downloadBlob } from "utils/fileDownload";
import useToast from "utils/hooks/useToast";
import { useGenerateReportMutation } from "modules/users/provider/users-report";
import { UserCoursesListItem } from "modules/courses/provider/user-courses";
import {
  useReportStatusCallback,
  useReportDownloadCallback,
  checkIfReportIsGenerated,
  ReportStatus
} from "modules/common/provider/reports";
import { IconGetApp } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type Props = {
  courses: UserCoursesListItem[];
  user?: UserItem;
  listFilters: ListFiltersState;
};

const CourseListToolbar: React.FC<Props> = ({ courses, listFilters, user }) => {
  const { showToast } = useToast();
  const { isComponentAllowed } = useTenant();

  const generateReport = useGenerateReportMutation();
  const getReportStatus = useReportStatusCallback();
  const downloadReport = useReportDownloadCallback();

  const [reportIsBeingGenerated, setReportIsBeingGenerated] = React.useState(
    false
  );

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const onSuccessfulReportGeneration = () => {
    showToast(
      "Your report was prepared and it'll be downloaded now",
      "success"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const onUnsuccessfulReportGeneration = () => {
    showToast(
      "Something went wrong, try again in a moment or report a problem",
      "error"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const downloadReportAndBlob = async (reportId: string, filename: string) => {
    const { data: fileBlob } = await downloadReport({ reportId });
    fileBlob && downloadBlob(fileBlob, filename);
    onSuccessfulReportGeneration();
  };

  // Recursion is introduced because request time is volatile
  // It's better to make another requests after earlier request is finished
  // Alternative is to use interval, but it's prone to errors
  const downloadReportWhenReady = async (
    reportId: string,
    filename: string
  ) => {
    try {
      const { data: reportStatusData } = await getReportStatus({ reportId });

      if (reportStatusData?.status === ReportStatus.FAILURE) {
        onUnsuccessfulReportGeneration();
        return;
      }

      if (checkIfReportIsGenerated(reportStatusData?.status)) {
        await downloadReportAndBlob(reportId, filename);
        return;
      } else {
        setTimeout(() => downloadReportWhenReady(reportId, filename), 2000);
      }
    } catch (ex) {
      onUnsuccessfulReportGeneration();
    }
  };

  const handleDownloadReport = async () => {
    if (user) {
      const { id: userId, username } = user;
      setReportIsBeingGenerated(true);
      try {
        const { data: generateReportData } = await generateReport({ userId });

        if (generateReportData) {
          const { status, id: reportId, createdAt } = generateReportData;
          const filename = `${username}-report-${moment(createdAt).format(
            "L"
          )}.xlsx`;

          if (checkIfReportIsGenerated(status)) {
            await downloadReportAndBlob(reportId, filename);
          } else {
            await downloadReportWhenReady(reportId, filename);
          }
        }
      } catch (ex) {
        onUnsuccessfulReportGeneration();
      }
    }
  };

  const left = (
    <AutoGrid spacing={2}>
      <SearchFilter name="name" listFilters={listFilters} />
    </AutoGrid>
  );

  const right = (
    <AutoGrid spacing={2} justify="flex-end">
      {!isComponentAllowed(AllowedComponents.ReportUserDetails) && (
        <Button
          onClick={handleDownloadReport}
          color="success"
          icon={IconGetApp}
          disabled={courses.length === 0 || reportIsBeingGenerated}
        >
          {reportIsBeingGenerated ? "Preparing report..." : "Download report"}
        </Button>
      )}
    </AutoGrid>
  );

  return <ListToolbar left={left} right={right} />;
};

export default CourseListToolbar;
