import * as React from "react";

export type FilterValue = any | any[];
export type AnyFilters = Record<string, FilterValue>;

export type PaginationState = {
  rowsPerPage: number;
  offset: number;
  page?: number;
};

type InitialListFilters<F extends AnyFilters> = {
  pagination?: Partial<PaginationState>;
  orderBy?: string[];
  filters?: F;
  search?: string;
};

export type ListFilters<F extends AnyFilters = AnyFilters> = {
  pagination: PaginationState;
  orderBy?: string[];
  filters?: F;
  search?: string;
};

export type ListFiltersState<F extends AnyFilters = AnyFilters> = ListFilters<
  F
> & {
  setPagination: (nextPagination: PaginationState) => void;
  setOrderBy: (newOrderBy: string[]) => void;
  setFilters: (newFilters: F) => void;
  setFilter: (key: string, value: FilterValue) => void;
  setSearch: (value: string | undefined) => void;
};

const useListFilters = <F extends AnyFilters>(
  initialValues?: InitialListFilters<F>
): ListFiltersState<F> => {
  const initialState = React.useMemo(() => {
    const initial = initialValues || {};

    return {
      pagination: {
        rowsPerPage: 10,
        offset: 0,
        ...initial.pagination
      },
      orderBy: initial.orderBy,
      filters: initial.filters,
      search: initial.search
    };
  }, [initialValues]);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    initialState.pagination.rowsPerPage
  );
  const [offset, setOffset] = React.useState(initialState.pagination.offset);
  const [orderBy, setOrderBy] = React.useState(initialState.orderBy);
  const [filters, setFilters] = React.useState<F | undefined>(
    initialState.filters
  );
  const [search, setSearchState] = React.useState(initialState.search);

  return React.useMemo(
    () => ({
      pagination: {
        rowsPerPage,
        offset,
        page: Math.ceil((offset - 1) / rowsPerPage) + 1
      },
      filters,
      orderBy,
      search,

      setPagination: (nextPagination: PaginationState) => {
        setRowsPerPage(nextPagination.rowsPerPage);
        setOffset(nextPagination.offset);
      },
      setSearch: newSearchValue => {
        setOffset(0);
        setSearchState(newSearchValue);
      },
      setFilter: (key: keyof F, value: F[keyof F]) => {
        setOffset(0);
        setFilters((prevFilters: F = {} as F) => ({
          ...prevFilters,
          [key]: value
        }));
      },
      setFilters,
      setOrderBy
    }),
    [filters, offset, orderBy, rowsPerPage, search]
  );
};

export default useListFilters;
