import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing }: CustomTheme) => ({
  prerequisite: {
    marginTop: spacing(2)
  },
  content: {
    fontSize: "0.875rem",
    marginTop: spacing(-1)
  },
  more: {
    marginLeft: spacing(2),
    fontSize: "0.8125rem",
    textDecoration: "underline",
    whiteSpace: "nowrap"
  }
}));
