import * as React from "react";
import { Grid } from "@material-ui/core";

import { Card, CardLogo, CardIcon, CardArrowButtons } from "components";
import {
  CompanyItem as CompanyItemType,
  CompanyAdmin as CompanyAdminType
} from "modules/companies/provider/companies";
import { pluralize } from "utils/strings";
import { IconFeaturedPlayListOutlined, IconPeople } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type Props = {
  company: CompanyItemType;
  rootLogo?: string;
};

const CompanyStatsCards: React.FC<Props> = ({ company, rootLogo }) => {
  const tenant = useTenant();
  const admins = company.admins.map(
    (e: CompanyAdminType) => `${e.firstName} ${e.lastName}`
  );
  const [activeAdmin, setActiveAdmin] = React.useState(0);

  const isNoSponsorAndAdmins = tenant.isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );

  return (
    <Grid container spacing={3}>
      {!isNoSponsorAndAdmins && (
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Card
            hasLogo={Boolean(company.companyinfo?.thumbnailUrl || rootLogo)}
            title={`${admins.length} ${pluralize("admin", admins.length)}`}
            subtitle={admins[activeAdmin]}
            avatar={
              <CardLogo
                imgUrl={company.companyinfo?.thumbnailUrl || rootLogo}
              />
            }
            arrowButtons={
              admins.length > 1 && (
                <CardArrowButtons
                  count={admins.length}
                  currentIndex={activeAdmin}
                  onClickLeft={() => setActiveAdmin(activeAdmin - 1)}
                  onClickRight={() => setActiveAdmin(activeAdmin + 1)}
                />
              )
            }
          />
        </Grid>
      )}

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={`${company.subgroupsCount} ${pluralize(
            "group",
            company.subgroupsCount
          )}`}
          subtitle="Active groups"
          avatar={<CardIcon icon={IconPeople} />}
          colorVariant="primary"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={`${company.usersCount} ${pluralize(
            "user",
            company.usersCount
          )}`}
          subtitle="Active users"
          avatar={<CardIcon icon={IconPeople} />}
          colorVariant="secondary"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card
          title={`${company.coursesCount} ${pluralize(
            "course",
            company.coursesCount
          )}`}
          subtitle="Available courses"
          avatar={<CardIcon icon={IconFeaturedPlayListOutlined} />}
          colorVariant="primary"
        />
      </Grid>
    </Grid>
  );
};

export default CompanyStatsCards;
