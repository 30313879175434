import { makeStyles } from "@material-ui/styles";

export default makeStyles(
  ({ palette, spacing, typography, breakpoints, shadows, shape }) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      boxShadow: shadows[4],
      cursor: "pointer",
      minWidth: 200,
      minHeight: 220,
      padding: spacing(2, 3),
      position: "relative",

      [breakpoints.up("sm")]: {
        minHeight: 220,
        padding: spacing(4, 5)
      },

      "&:hover": {
        transform: "scale(1.05)"
      }
    },
    contentContainer: {
      flexGrow: 1,
      marginBottom: spacing(2)
    },
    header: {
      display: "flex",
      flexWrap: "wrap"
    },
    prerequisiteHeader: {
      marginTop: spacing(2)
    },
    name: {
      color: typography.body1.color,
      minWidth: "fit-content",
      marginRight: spacing(2),
      fontSize: "1.125rem",

      [breakpoints.up("sm")]: {
        fontSize: "1.5rem"
      }
    },
    status: {
      height: 32
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
      marginBottom: spacing(1),
      fontSize: "0.875rem"
    },
    icon: {
      fontSize: "1rem",
      marginRight: spacing(1)
    },
    courseId: {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: typography.body2.color,
      fontSize: "1.125rem",

      [breakpoints.up("sm")]: {
        fontSize: "1.5rem"
      }
    },
    rootTriangle: {
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        borderColor: "transparent",
        borderStyle: "solid"
      },

      "&:before": {
        borderWidth: "1.7rem", // triangle size
        borderRightColor: palette.primary.main,
        borderTopColor: palette.primary.main,
        borderTopRightRadius: shape.borderRadius
      }
    },
    assigned: {
      position: "absolute",
      zIndex: 1,
      // Icon offset
      right: 6,
      top: 6,

      // Icon
      "& > *": {
        fontSize: "1.25em",
        color: "rgba(0, 0, 0, 0.5)"
      }
    }
  })
);
