import * as React from "react";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { AutoGrid, Search, ListToolbar } from "components";
import ReviewersTreeFilter from "modules/common/components/users-tree/reviewers-tree-filter";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import useStyles from "./submission-list-toolbar.styles";

type Props = {
  listFilters: ListFiltersState;
  isReviewersFilter?: boolean;
};

const SubmissionListToolbar: React.FC<Props> = ({
  listFilters,
  isReviewersFilter
}) => {
  const classes = useStyles();
  const tenant = useTenant();

  const isManualCompletionReviewerFilter = tenant.isComponentAllowed(
    AllowedComponents.ManualCompletionReviewerFilter
  );

  const left = (
    <>
      <AutoGrid spacing={2} className={classes.gridContainer}>
        <Search listFilters={listFilters} />
        {isManualCompletionReviewerFilter && isReviewersFilter && (
          <ReviewersTreeFilter
            label="Reviewers"
            listFilters={listFilters}
            name="reviewers"
            allowMultiple
          />
        )}
      </AutoGrid>
    </>
  );

  return <ListToolbar left={left} />;
};

export default SubmissionListToolbar;
