import * as React from "react";

import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import LearnerActionByStatus from "modules/courses/components/course-learner-action.component";
import { UserRole } from "modules/users/users.constants";
import CourseReport from "modules/common/components/course-report/course-report";
import { AutoGrid } from "components";
import { useAuth } from "services/auth-provider";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { CourseDescription } from "../common";
import PrerequisitesCourses from "../common/prerequisites-courses";
import CourseLearningPlans from "../common/course-learning-plans";

import LearnerCards from "./cards-learner";
import SubmitManualCompletionCTA from "./submit-manual-completion-cta";

export type CourseInfoLearnerProps = {
  course: MyCoursesListItem;
  isHistoryView?: boolean;
};

const CourseInfoLearner: React.FC<CourseInfoLearnerProps> = ({
  course: {
    id,
    externalId,
    name,
    description,
    notes,
    duration,
    status,
    currentStatus,
    statusTimestamp,
    url,
    links,
    allowManualCompletion,
    learningPlanIndicator,
    assigned,
    prerequisiteCourses
  },
  isHistoryView = false
}) => {
  const auth = useAuth();
  const canDownloadReport = auth.checkAccess({
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN, UserRole.TENANT_ADMIN]
  });
  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );
  const startNotAllowed = prerequisiteCourses?.find(c => !c.isFinished);

  return (
    <>
      <LearnerCards {...{ duration, status, statusTimestamp }} />
      <CourseDescription {...{ notes, description, links }} />
      {isLearningPlans && (
        <CourseLearningPlans {...{ learningPlanIndicator }} />
      )}
      {isCoursePrerequisites &&
        prerequisiteCourses &&
        prerequisiteCourses?.length > 0 && (
          <PrerequisitesCourses
            prerequisitesCourses={prerequisiteCourses || []}
            view="student"
          />
        )}
      <AutoGrid spacing={2}>
        {url && (currentStatus || status) && (
          <LearnerActionByStatus
            button
            {...{
              currentStatus: currentStatus || status,
              url,
              id,
              externalId,
              assigned,
              disabled: startNotAllowed && isCoursePrerequisites
            }}
          />
        )}
        {canDownloadReport && <CourseReport {...{ id, name }} />}
      </AutoGrid>
      {!isHistoryView && (
        <SubmitManualCompletionCTA
          {...{ id, url, allowManualCompletion, status }}
        />
      )}
    </>
  );
};

export default CourseInfoLearner;
