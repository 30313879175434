import React from "react";

import { Button } from "components";

import { LearningPlanItem } from "../../../provider/learning-plan";

import AddCoursesModal, { Child as AddChild } from "./add-courses-modal";
import EditCoursesModal, { Child as EditChild } from "./edit-courses-modal";
import useStyles from "./learning-plan-header-buttons.styles";

type Props = {
  learningPlan: LearningPlanItem;
  refetch: () => void;
};

const LearningPlanHeaderButtons: React.FC<Props> = ({
  learningPlan,
  refetch
}) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.leftButton}>
        <EditCoursesModal learningPlan={learningPlan} refetch={refetch}>
          {({ handleOpen }: EditChild) => (
            <Button onClick={handleOpen} color="secondary">
              Edit list
            </Button>
          )}
        </EditCoursesModal>
      </div>
      <AddCoursesModal learningPlan={learningPlan} refetch={refetch}>
        {({ handleOpen }: AddChild) => (
          <Button onClick={handleOpen} color="success">
            Add next to the list
          </Button>
        )}
      </AddCoursesModal>
    </div>
  );
};

export default LearningPlanHeaderButtons;
