import React from "react";
import { Typography } from "@material-ui/core";

import { AutoGrid, Breadcrumbs, ExtendedMainHeader, Header } from "components";
import routes from "config/routes";

import { LearningPlanItem } from "../../../provider/learning-plan";

import LearningPlanHeaderButtons from "./learning-plan-header-buttons";
import useStyles from "./learning-plan-header.styles";

type Props = {
  learningPlan: LearningPlanItem;
  refetch: () => void;
};

const LearningPlanHeader: React.FC<Props> = ({ learningPlan, refetch }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>Learning plan:</Typography>
      <ExtendedMainHeader
        title={learningPlan?.name}
        hint={`(ID: ${learningPlan?.externalId})`}
        subtitle={
          <Breadcrumbs
            routes={[
              {
                name: "Courses",
                path: routes.courses.list
              },
              {
                name: "Learning plans",
                path: routes.learningPlans.list
              },
              { name: learningPlan.name }
            ]}
            className={classes.breadcrumbs}
          />
        }
      />
      <AutoGrid justify="space-between">
        <Header title="Course list" variant="section" />
        <LearningPlanHeaderButtons
          learningPlan={learningPlan}
          refetch={refetch}
        />
      </AutoGrid>
    </>
  );
};

export default LearningPlanHeader;
