import * as PythonApi from "services/api/python";
import { useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

type UserFromGroupDeleteVars = {
  groupId: string;
  userId: string;
};

const createUserFromGroupDeleteConfig = () =>
  PythonApi.makeMutation<UserFromGroupDeleteVars>(({ userId, groupId }) => ({
    method: "DELETE",
    url: apiConfig.paths.authorized.group.unassignUser(groupId, userId)
  }));

export const useUserFromGroupDelete = () =>
  useFetchMutation(createUserFromGroupDeleteConfig());
