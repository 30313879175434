import * as React from "react";
import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";
import { isEqual } from "lodash";

import { useAuth } from "services/auth-provider";
import { Header, SelectControl, Section } from "components";
import { useCompaniesListPublic } from "modules/companies/provider";
import { UserRole } from "modules/users/users.constants";
import { createValidator } from "utils/forms";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";
import useListFilters from "utils/hooks/useListFilters";
import authClient from "services/auth";

type UserGroupSectionProps = {
  sectionIndex: number;
  userId: string | null;
};
const checkIfShouldSeeGroupSection = (role: UserRole) =>
  [UserRole.STUDENT, UserRole.INTERNAL_ADMIN, UserRole.TENANT_ADMIN].includes(
    role
  );

const validator = createValidator({
  groupId: {
    presence: {
      allowEmpty: false,
      message: "^Company or group can't be blank"
    }
  },
  groupIds: {
    presence: {
      allowEmpty: false,
      message: "^Companies or groups can't be blank"
    }
  }
});

const UserGroupSection = ({ sectionIndex }: UserGroupSectionProps) => {
  const [selectedGroupsId, setSelectedGroupsId] = React.useState([""]);

  const { checkAccess } = useAuth();
  const [isTokenRefreshed, setTokenRefreshed] = React.useState(false);
  const isTenantAdmin = checkAccess({
    roles: [UserRole.TENANT_ADMIN, UserRole.INTERNAL_ADMIN]
  });

  React.useEffect(() => {
    if (isTenantAdmin) {
      authClient.refreshTokens().finally(() => {
        setTokenRefreshed(true);
      });
    }
  }, [isTenantAdmin]);

  const [isFetched, setIsFetched] = React.useState(false);
  const groupsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    orderBy: ["name,ASC"]
  });

  const groups = useCompaniesListPublic({
    variables: {
      listFilters: groupsListFilters,
      ...(isTenantAdmin && { onlyOwn: true })
    },
    skip: isFetched || (isTenantAdmin && !isTokenRefreshed)
  });

  const options = React.useMemo(() => {
    if (groups.data) {
      setIsFetched(true);
      return groups.data.map(({ id, name, parent }) => ({
        label: name,
        value: id,
        parent: isTenantAdmin
          ? groups.data!.some(({ id }) => id === parent)
            ? parent
            : null
          : parent
      }));
    }
    return [];
  }, [groups.data, isTenantAdmin]);

  const workAddressOptions = React.useMemo(() => {
    if (groups.data) {
      return groups.data
        .filter(
          ({ id, companyinfo }) => companyinfo && selectedGroupsId.includes(id)
        )
        .flatMap(({ companyinfo }) =>
          companyinfo!.addresses.map(address => ({
            label: address,
            value: address
          }))
        );
    }
    return [];
  }, [groups.data, selectedGroupsId]);

  return (
    <Field
      name="role"
      subscription={{ value: true }}
      render={({ input: { value } }) => {
        const manyGroups =
          value === UserRole.TENANT_ADMIN || value === UserRole.INTERNAL_ADMIN;
        const fieldName = manyGroups ? "groupIds" : "groupId";

        return (
          checkIfShouldSeeGroupSection(value) && (
            <Section margin="dense">
              <Header
                variant="formSection"
                number={sectionIndex}
                title="Assign company or group"
              />

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name={fieldName}
                    label={
                      manyGroups
                        ? "Companies or groups (required)"
                        : "Company or group (required)"
                    }
                    onlyOneFromBranch={manyGroups}
                    allowMultiple={manyGroups}
                    component={GroupTreeControl}
                    options={options}
                    isLoading={groups.loading}
                    listFilters={groupsListFilters}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name={fieldName}
                    subscription={{ value: true }}
                    validate={fieldValue =>
                      validator({ [fieldName]: fieldValue })[fieldName]
                    }
                    render={({ input: { value: inputValue } }) => {
                      const newValue = manyGroups ? inputValue : [inputValue];
                      if (!isEqual(selectedGroupsId, newValue)) {
                        setSelectedGroupsId(newValue);
                      }
                      return null;
                    }}
                  />
                  <Field
                    name="workAddress"
                    label="Work address (optional)"
                    component={SelectControl}
                    isLoading={groups.loading}
                    options={workAddressOptions}
                  />
                </Grid>
              </Grid>
            </Section>
          )
        );
      }}
    />
  );
};

export default UserGroupSection;
