import * as React from "react";
import { useParams } from "react-router";

import {
  ListToolbar,
  AutoGrid,
  Button,
  Drawer,
  Search,
  ClosingModal
} from "components";
import useToast from "utils/hooks/useToast";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { useCompanyCreate } from "modules/companies/provider/companies";
import { useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";

import GroupForm from "../group-record";

type Props = {
  groups?: any[];
  refetch: () => void;
  listFilters: ListFiltersState;
};

const GroupListToolbar: React.FC<Props> = ({ refetch, listFilters }) => {
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });

  const [isDrawerOpen, setDrawerOpen] = React.useState();
  const { showToast } = useToast();
  const { companyId } = useParams();

  const createGroup = useCompanyCreate();

  const handleAddSubmit = React.useCallback(
    async ({ name, externalId, adminsIds, voucherNumber }) => {
      await createGroup({
        name,
        parent: companyId,
        externalId,
        adminsIds: adminsIds ?? [],
        voucherNumber
      });
      setDrawerOpen(false);
      showToast("The group was successfully added to the list", "success");
      refetch();
    },
    [createGroup, companyId, showToast, refetch]
  );

  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  const left = (
    <AutoGrid spacing={2}>
      <Search listFilters={listFilters} />
    </AutoGrid>
  );

  const right = (
    <>
      {isAdmin && (
        <AutoGrid spacing={2} justify="flex-end">
          <Button onClick={() => setDrawerOpen(true)} color="secondary">
            Add group
          </Button>
        </AutoGrid>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Add new group"
        size="sm"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <GroupForm
          onSubmit={handleAddSubmit}
          onCancel={() => setDrawerOpen(false)}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );

  return <ListToolbar left={left} right={right} />;
};

export default GroupListToolbar;
