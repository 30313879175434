import * as React from "react";
import { Typography, Button, Menu, MenuItem } from "@material-ui/core";

import { useAuth } from "services/auth-provider";
import ProfileDetails from "modules/users/components/profile-details.component";
import { UserInitials, Drawer } from "components";
import API from "services/api";
import useToast from "utils/hooks/useToast";
import storage from "config/storage";
import { IconExpandMore } from "assets/icons";

import useStyles from "./user-avatar.styles";

type Props = {
  isDrawerOpen: boolean;
  setDrawerOpen: (arg: boolean) => void;
  isMobile: boolean;
};

const UserAvatar: React.FC<Props> = ({
  isDrawerOpen,
  setDrawerOpen,
  isMobile
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const { showToast } = useToast();
  const [drawerTitleMobile, setDrawerTitleMobile] = React.useState(
    "Profile details"
  );

  // show toasts
  const handleDetailsSubmit = () => {
    !isMobile && setDrawerOpen(false);
    showToast("Your details has been successfully changed", "success");
  };
  const handlePasswordSubmit = () => {
    !isMobile && setDrawerOpen(false);
    showToast("Your password has been successfully changed", "success");
  };

  // dropdown menu
  const [menuOpen, setMenuOpen] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(event.currentTarget);
  };
  const ref = React.useRef<HTMLButtonElement>(null!);
  const [menuWidth, setMenuWidth] = React.useState(0);
  React.useEffect(() => {
    ref && ref.current && setMenuWidth(ref.current.offsetWidth);
  }, [auth.user]);

  // drawer ProfileDetails
  const openDrawer = React.useCallback(() => {
    isMobile && window.scrollTo(0, 0);
    setDrawerOpen(true);
  }, [isMobile, setDrawerOpen]);

  if (!auth.user) {
    return null;
  }

  const userNames = `${auth.user.firstName} ${auth.user.lastName}`;

  return (
    <>
      {isMobile ? (
        <Button
          className={classes.initialsButton}
          onClick={() => setDrawerOpen(true)}
        >
          <UserInitials names={userNames} size={40} />
        </Button>
      ) : (
        <>
          <Button
            className={classes.initialsButton}
            onClick={handleMenuOpen}
            ref={ref}
          >
            <UserInitials names={userNames} size={40} />
            <Typography className={classes.helloUser}>
              Hello{auth.user ? `, ${auth.user.firstName}` : ""}!
              <IconExpandMore className={classes.expandMoreIcon} />
            </Typography>
          </Button>

          <Menu
            classes={{ paper: classes.dropdownMenu }}
            anchorEl={menuOpen}
            keepMounted
            open={Boolean(menuOpen)}
            onClose={() => setMenuOpen(null)}
          >
            <MenuItem
              onClick={() => {
                openDrawer();
                setMenuOpen(null);
              }}
              style={{ width: menuWidth }}
              className={classes.menuItem}
            >
              View profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                auth.logout();
                localStorage.removeItem(storage.local.defaultAuthSAML);
                delete API.defaults.headers.Authorization;
                setMenuOpen(null);
              }}
              className={classes.menuItem}
            >
              Log out
            </MenuItem>
          </Menu>
        </>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        fitSize={isMobile}
        title={isMobile ? drawerTitleMobile : "Profile details"}
      >
        <ProfileDetails
          onDetailsSubmit={handleDetailsSubmit}
          onPasswordSubmit={handlePasswordSubmit}
          onClose={() => setDrawerOpen(false)}
          setDrawerTitle={setDrawerTitleMobile}
          isMobile={isMobile}
        />
      </Drawer>
    </>
  );
};

export default UserAvatar;
