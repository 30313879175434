import * as React from "react";
import { useParams } from "react-router";

import {
  AutoGrid,
  Button,
  ClosingModal,
  ConfirmationDialog,
  Drawer,
  ReportModal
} from "components";
import RecordForm from "modules/users/components/user-record/user-record";
import {
  UserCreateUpdateVars as UserCreateUpdateVarsType,
  UserItem as UserItemType,
  useUserDelete
} from "modules/users/provider/users";
import {
  useUserFromGroupDelete,
  useUserStatusUpdate,
  useUserUpdate
} from "modules/users/provider";
import useToast from "utils/hooks/useToast";
import { IconCreate, IconDelete } from "assets/icons";
import { useAuth } from "services/auth-provider";
import * as PythonApi from "services/api/python";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { ReportTypes } from "modules/reports/reports.constants";

type UsersTableRowActionsProps = {
  user: UserItemType;
  refetch: () => void;
};

const UsersTableRowActions: React.FC<UsersTableRowActionsProps> = ({
  user,
  refetch
}) => {
  const editUser = useUserUpdate({ id: user.id });
  const { showToast } = useToast();
  const { userId, courseId, companyId } = useParams<{
    userId: string;
    courseId: string;
    companyId: string;
  }>();
  const activateUser = useUserStatusUpdate("activate");
  const deactivateUser = useUserStatusUpdate("deactivate");
  const auth = useAuth();
  const removeUserFromGroup = useUserFromGroupDelete();
  const deleteUser = useUserDelete();
  const tenant = useTenant();

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    false
  );
  const [
    openDialogRemoveUserFromGroup,
    setOpenDialogRemoveUserFromGroup
  ] = React.useState(false); // todo where's must be setOpenDialogRemoveUserFromGroup(true)?
  const [openDialogRemoveUser, setOpenDialogRemoveUser] = React.useState(false);

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const isUserDetailsView = Boolean(userId);
  const isCourseDetailsView = Boolean(courseId);

  const handleEditSubmit = React.useCallback(
    async (values: UserCreateUpdateVarsType) => {
      try {
        const updatedUserData = await editUser(values);

        if (
          updatedUserData?.data?.id &&
          updatedUserData.data.id === auth.user?.id
        ) {
          auth.updateUser(updatedUserData.data);
        }

        user.isAccepted !== values.isAccepted &&
          (await (values.isAccepted
            ? activateUser({ id: user.id })
            : deactivateUser({ id: user.id })));
        setDrawerOpen(false);
        showToast("The user was successfully edited", "success");
        refetch();
      } catch (ex) {
        const errorMessages = PythonApi.getMessageStringFromError(ex);
        showToast(errorMessages, "error");
      }
    },
    [
      activateUser,
      auth,
      deactivateUser,
      editUser,
      refetch,
      showToast,
      user.id,
      user.isAccepted
    ]
  );

  return (
    <>
      {isUserDetailsView ? (
        <>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setDrawerOpen(true)}
            icon={IconCreate}
          >
            Edit user
          </Button>
          {tenant.isComponentAllowed(AllowedComponents.ReportUserDetails) && (
            <div style={{ float: "right" }}>
              <ReportModal
                filePrefix={user.username || `user-${user.id}`}
                reportTypes={[
                  {
                    label: "User detail",
                    value: "userDetailReport",
                    requestPath:
                      ReportTypes.userOverviewReport.requestPath?.() || "",
                    body: ReportTypes.userOverviewReport.buildBody?.(user.id)
                  }
                ]}
              >
                {({ generating, handleOpen }) => (
                  <Button
                    disabled={generating}
                    onClick={handleOpen}
                    color="primary"
                  >
                    {generating ? "Preparing report..." : "Generate report"}
                  </Button>
                )}
              </ReportModal>
            </div>
          )}
        </>
      ) : (
        <AutoGrid spacing={2} wrap="nowrap">
          {!isCourseDetailsView && (
            <>
              <Button
                onClick={() => setDrawerOpen(true)}
                variant="text"
                icon={IconCreate}
              >
                Edit
              </Button>
              {tenant.isComponentAllowed(AllowedComponents.UserDelete) && (
                <Button
                  onClick={() => setOpenDialogRemoveUser(true)}
                  variant="text"
                  icon={IconDelete}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </AutoGrid>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Edit user"
        size="md"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <RecordForm
          user={user}
          onSubmit={handleEditSubmit}
          onCloseDrawer={() => setDrawerOpen(false)}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />

      <ConfirmationDialog
        title="Are you sure you want to remove this user from group?"
        isOpen={openDialogRemoveUserFromGroup}
        onClose={() => setOpenDialogRemoveUserFromGroup(false)}
        onSubmit={async () => {
          await removeUserFromGroup({
            userId: user.id,
            groupId: companyId!
          });
          showToast(
            `${user.firstName} ${user.lastName} has been successfully removed from group`,
            "success"
          );
          refetch();
        }}
        submitText="Remove"
        submitColor="error"
      />

      {tenant.isComponentAllowed(AllowedComponents.UserDelete) && (
        <ConfirmationDialog
          title="Delete this user?"
          description={`${user.firstName} ${user.lastName}`}
          isOpen={openDialogRemoveUser}
          onClose={() => setOpenDialogRemoveUser(false)}
          onSubmit={async () => {
            await deleteUser({
              userId: user.id
            });
            showToast(`User has been successfully deleted`, "success");
            refetch();
          }}
          submitText="Delete"
          submitColor="error"
        />
      )}
    </>
  );
};

export default UsersTableRowActions;
