import * as React from "react";

import { ListToolbar, AutoGrid, Search } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";

type Props = {
  listFilters: ListFiltersState;
};

const CompaniesListToolbar: React.FC<Props> = ({ listFilters }) => {
  const left = (
    <AutoGrid spacing={2}>
      <Search listFilters={listFilters} />
    </AutoGrid>
  );

  return <ListToolbar left={left} />;
};

export default CompaniesListToolbar;
