import * as React from "react";
import clsx from "clsx";
import { Checkbox } from "@material-ui/core";
import { TreeItem } from "performant-array-to-tree";

import { IconKeyboardArrowRight, IconKeyboardArrowDown } from "assets/icons";

import useStyles from "./tree.styles";
import {
  actionTypes,
  useDispatch,
  useIsInStateChecked,
  useOnlyOneFromBranch
} from "./context";

type TreeProps = Omit<TreeItem, "children">;

const Tree: React.FC<TreeProps> = ({
  value,
  label,
  treeChildren,
  disabled = false,
  setParentExpand
}) => {
  // internal component state
  const [isChecked, setChecked] = React.useState(false);
  const [isExpanded, setExpanded] = React.useState(false);

  // get component global state to handle (ie. to handle changes caused by remove pills)
  const isInStateChecked = useIsInStateChecked(value);

  const onlyOneFromBranch = useOnlyOneFromBranch();
  // to keep internal state updated
  React.useEffect(() => {
    setChecked(!!isInStateChecked);
  }, [isInStateChecked]);

  const dispatch = useDispatch();

  const classes = useStyles();

  const haveChildren = React.useMemo(() => treeChildren.length > 0, [
    treeChildren.length
  ]);
  const toggleExpand = React.useCallback(() => {
    if (haveChildren) {
      setExpanded(!isExpanded);
    }
    const event = document.createEvent("HTMLEvents");
    event.initEvent("resize", true, false);
    document.dispatchEvent(event);
  }, [haveChildren, isExpanded]);

  React.useEffect(() => {
    if (disabled && isChecked) {
      // if my parent was checked i get disabled state if onlyOneFromBranchIsActive
      dispatch({
        type: actionTypes.SET_CHECKED,
        payload: { checked: false, value }
      });
      setChecked(false);
    } else if (isChecked && setParentExpand) {
      //if i have parent and i become checked -> i want to be seen on modal open by expanding my parents
      setParentExpand(true);
    }
  }, [disabled, dispatch, isChecked, setParentExpand, value]);

  React.useEffect(() => {
    //if i have parent and i have been expaned -> i want to be seen on modal open by expanding my parents
    if (isExpanded && setParentExpand) {
      setParentExpand(true);
    }
  }, [isExpanded, setParentExpand]);

  const changeHandler = React.useMemo(
    // update both internal and global state
    () => (_: any, checked: boolean) => {
      dispatch({
        type: actionTypes.SET_CHECKED,
        payload: { checked, value }
      });
      setChecked(checked);
    },
    [dispatch, value]
  );

  return (
    <>
      <div className={classes.option}>
        <div
          className={clsx(
            classes.treeLabelContainer,
            haveChildren && classes.pointer
          )}
          onClick={toggleExpand}
        >
          {haveChildren && (
            <div className={classes.arrow}>
              {isExpanded ? (
                <IconKeyboardArrowDown />
              ) : (
                <IconKeyboardArrowRight opacity={haveChildren ? 1 : 0.5} />
              )}
            </div>
          )}
          <div className={classes.labelName} title={label}>
            {label}
          </div>
        </div>

        <Checkbox
          className={classes.checkbox}
          name={value}
          checked={isChecked}
          disabled={disabled}
          onChange={changeHandler}
        />
      </div>
      {isExpanded && (
        <div className={classes.children}>
          {treeChildren.map(
            ({ data: { value, label }, children: treeChildren }: TreeItem) => (
              <Tree
                key={value}
                {...{
                  value,
                  label,
                  treeChildren
                }}
                setParentExpand={setExpanded}
                disabled={onlyOneFromBranch && (isChecked || disabled)}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default Tree;
