import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Button, Header, Section } from "components";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { PrerequisiteCourse } from "../../../provider/courses";

import useStyles from "./prerequisites-courses.styles";

export type Props = {
  prerequisitesCourses: PrerequisiteCourse[];
  view: "admin" | "student";
};

const PrerequisitesCourses: React.FC<Props> = ({
  prerequisitesCourses,
  view
}) => {
  const classes = useStyles();
  const tenant = useTenant();
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );

  return (
    <Section margin="normal">
      <Header variant="section" title="Prerequisite courses" />
      <div className={classes.listContainer}>
        {prerequisitesCourses?.length > 0 ? (
          <>
            {prerequisitesCourses.map(pc => (
              <Button
                key={pc.id}
                variant="text"
                target={isDetailsNewTab ? "_blank" : "_self"}
                component={RouterLink}
                classes={{
                  root: classes.button,
                  label: classes.text
                }}
                to={
                  view === "admin"
                    ? `/courses/${pc.id}`
                    : isAllowedTenantAvailableCourses
                    ? `/available-courses/${pc.id}`
                    : `/my-courses/${pc.id}`
                }
              >
                {pc.name}
              </Button>
            ))}
          </>
        ) : (
          "-"
        )}
      </div>
    </Section>
  );
};

export default PrerequisitesCourses;
