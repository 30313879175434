import * as React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router";
import clsx from "clsx";

import { useAuth } from "services/auth-provider";
import {
  RoleChip,
  Button,
  ResponsiveTableWrapper,
  DataTable,
  TooltipList
} from "components";
import { UserRole } from "modules/users/users.constants";
import { UserItem as UserItemType } from "modules/users/provider/users";
import useStyles from "components/data-table/data-table.styles";
import { pluralize } from "utils/strings";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type Props = {
  users: UserItemType[];
  refetch: () => void;
  loading: boolean;
  listSelection: any;
  listFilters: ListFiltersState;
};

const ApprovedUsersTable: React.FC<Props> = ({
  users,
  loading,
  listSelection,
  listFilters
}) => {
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });

  const { companyId, courseId } = useParams();
  const isGroupDetailsView = Boolean(companyId);
  const isCourseDetailsView = Boolean(courseId);
  const classes = useStyles();
  const tenant = useTenant();

  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  return (
    <ResponsiveTableWrapper>
      <DataTable
        emptyText="No approved users"
        data={users}
        loading={loading}
        listSelection={listSelection}
        listFilters={listFilters}
        columns={[
          {
            key: "username",
            title: "Username",
            sortable: true,
            render: ({ id, username }) => (
              <Button
                component={RouterLink}
                to={`/users/${id}`}
                variant="text"
                target={isDetailsNewTab ? "_blank" : "_self"}
              >
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {username}
                </span>
              </Button>
            )
          },
          {
            key: "firstName",
            title: "First name",
            render: ({ firstName }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {firstName}
              </span>
            )
          },
          {
            key: "lastName",
            title: "Last name",
            render: ({ lastName }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {lastName}
              </span>
            )
          },
          {
            key: "approver",
            title: "Approver",
            render: ({ approver }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {`${approver && approver.firstName} ${approver &&
                  approver.lastName}`}
              </span>
            )
          },
          {
            key: "userRole",
            title: "User Role",
            render: ({ role }) => <RoleChip role={role} />
          },
          {
            key: "createdAt",
            title: "Created at",
            skip: isCourseDetailsView || isGroupDetailsView,
            render: ({ createdAt }) => moment(createdAt).format("L")
          },
          {
            key: "groups",
            title: "Company name",
            skip: !isAdmin || isGroupDetailsView,
            render: ({ groups }) =>
              groups && groups.length ? (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <span className={clsx(classes.ellipsis, classes.customText)}>
                    {groups[0].name}
                  </span>

                  {groups.length > 1 && (
                    <TooltipList
                      title={`${groups.length} ${pluralize(
                        "group",
                        groups.length
                      )}`}
                      list={groups.map(g => g.name)}
                    />
                  )}
                </div>
              ) : (
                "-"
              )
          },
          {
            key: "email",
            title: "Email",
            skip: isCourseDetailsView,
            render: ({ email }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {email}
              </span>
            )
          }
        ]}
      />
    </ResponsiveTableWrapper>
  );
};

export default ApprovedUsersTable;
