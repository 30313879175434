import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Box } from "@material-ui/core";

import { ConfirmationDialog, FormError } from "components";
import { useLightCoursesList } from "modules/courses/provider/courses";
import useListFilters from "utils/hooks/useListFilters";
import useToast from "utils/hooks/useToast";
import * as PythonAPI from "services/api/python";
import { createValidator } from "utils/forms";

import {
  LearningPlanItem,
  NoAccessResp,
  useLearningPlanUpdate
} from "../../../provider/learning-plan";
import GroupTreeControl from "../../../../common/components/group-tree/group-tree-control";
import MessageModal from "../components/message-modal";

import useStyles from "./add-courses-modal.styles";

export type Child = {
  handleOpen: () => void;
};

type Props = {
  learningPlan: LearningPlanItem;
  refetch: () => void;
  children: (props: Child) => React.ReactNode;
};

const AddCoursesModal: React.FC<Props> = ({
  learningPlan,
  refetch,
  children
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const updateLearningPlan = useLearningPlanUpdate(learningPlan.id);
  const [isMessageModal, setMessageModal] = useState<NoAccessResp>();
  const { showToast } = useToast();

  const listFilters = useListFilters({
    pagination: { rowsPerPage: 100 },
    orderBy: ["name,ASC"]
  });

  const courses = useLightCoursesList({
    variables: {
      listFilters: listFilters
    }
  });

  const courseOptions = React.useMemo(() => {
    if (courses.data) {
      return courses.data.map(({ id, name }) => ({
        label: name,
        value: id,
        parent: null
      }));
    }
    return [];
  }, [courses.data]);

  const handleSubmit = React.useCallback(
    async (values: { courseIds: string[] }) => {
      let submitValues = {
        ...learningPlan,
        groups: learningPlan.groups.map(g => g.id),
        courses: [
          ...learningPlan.courses.map(c => c.id),
          ...values.courseIds.filter(c => {
            const occurred = learningPlan.courses?.find(
              (course: { id: string; name: string }) => course.id === c
            );
            if (!occurred) {
              return c;
            }
            return false;
          })
        ]
      };

      try {
        await updateLearningPlan(submitValues);
        showToast("Learning plan was successfully updated", "success");
        refetch();
      } catch (e) {
        const noAccess = e?.response?.data?.noAccess;
        if (e.request.status === 400 && noAccess) {
          noAccess && setMessageModal(noAccess);
        } else {
          showToast(e.message, "error");
        }
        return PythonAPI.getFormErrors(e);
      }
    },
    [learningPlan, refetch, showToast, updateLearningPlan]
  );

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ courseIds: [] }}
        validate={validator}
      >
        {({
          handleSubmit,
          submitting,
          submitError,
          hasValidationErrors,
          pristine
        }) => (
          <form>
            {children({
              handleOpen: () => {
                setModalOpen(true);
              }
            })}
            <ConfirmationDialog
              title="Add next to the list"
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onSubmit={handleSubmit}
              submitDisabled={submitting || hasValidationErrors || pristine}
              submitText="Add courses"
              submitColor="success"
              description={
                <>
                  {submitError ? (
                    <FormError>{submitError}</FormError>
                  ) : (
                    <Box className={classes.container}>
                      <Field
                        name="courseIds"
                        label="Course name"
                        onlyOneFromBranch
                        component={GroupTreeControl}
                        options={courseOptions}
                        isLoading={courses.loading}
                        listFilters={listFilters}
                        allowMultiple={true}
                        validationIndicator
                      />
                    </Box>
                  )}
                </>
              }
            />
          </form>
        )}
      </Form>
      {isMessageModal && (
        <MessageModal
          isOpen={!!isMessageModal}
          data={isMessageModal}
          onClose={() => setMessageModal(undefined)}
        />
      )}
    </>
  );
};

export default AddCoursesModal;

const validator = createValidator({
  courseIds: { presence: { allowEmpty: false } }
});
