import * as React from "react";
import clsx from "clsx";
import { Button as MuiButton } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { ButtonProps } from "@material-ui/core/Button";

import useStyles from "./button.styles";

export type CustomColor = "success" | "error" | "hint";
export type Props = Omit<ButtonProps, "color" | "variant"> & {
  classes?: {
    root?: string;
    icon?: string;
    text?: string;
  };
  component?: React.ComponentType<any> | React.ElementType;
  icon?: React.ComponentType<SvgIconProps>;
  iconPosition?: "left" | "right";
  color?: ButtonProps["color"] | CustomColor;
  variant?: ButtonProps["variant"];
  underline?: boolean;
  defaultHoverColor?: boolean;
  // Any props passed to an arbitrary 'component'
  [key: string]: any;
};

const customColors = ["success", "error", "hint"];
const Button: React.FC<Props> = ({
  children,
  classes: parentClasses,
  icon: IconComponent,
  iconPosition = "left",
  color = "default",
  variant = "contained",
  underline = false,
  defaultHoverColor = false,
  ...buttonProps
}) => {
  const classes = useStyles({ classes: parentClasses });

  const isCustomColor = customColors.includes(color as string);
  const buttonColor = isCustomColor
    ? "inherit"
    : (color as ButtonProps["color"]);

  return (
    <MuiButton
      className={clsx(
        classes.root,
        classes[variant],
        isCustomColor && classes[color as CustomColor],
        defaultHoverColor && classes.defaultHoverColor
      )}
      color={buttonColor}
      variant={variant}
      {...buttonProps}
    >
      {IconComponent && (
        <IconComponent
          className={clsx(
            classes.icon,
            classes[color as CustomColor],
            classes[iconPosition]
          )}
        />
      )}
      <span className={clsx(classes.label, underline && classes.underline)}>
        {children}
      </span>
    </MuiButton>
  );
};

export default Button;
