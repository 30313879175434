import React from "react";

import { UserRole } from "modules/users/users.constants";
import { AutoGrid, Button, Drawer, ListToolbar, Search } from "components";
import { useAuth } from "services/auth-provider";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { ListFiltersState } from "utils/hooks/useListFilters";
import GroupTreeFilter from "modules/common/components/group-tree/group-tree-filter";

import LearningPlanForm from "../learning-plan-form/learning-plan-form";

type Props = {
  listFilters: ListFiltersState;
  refetch: () => void;
};

const LearningPlanToolbar: React.FC<Props> = ({ listFilters, refetch }) => {
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });
  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const [isLearningPlanDrawerOpen, setLearningPlanDrawerOpen] = React.useState(
    false
  );

  const handleSubmit = () => {
    setLearningPlanDrawerOpen(false);
    refetch();
  };

  const left = (
    <GroupTreeFilter
      label="Company"
      listFilters={listFilters}
      name="groupId"
      allowMultiple
    />
  );

  const right = (
    <AutoGrid spacing={2} justify="flex-end">
      {(isAdmin || isDesigner) && isLearningPlans && (
        <>
          <Search listFilters={listFilters} />
          <Button
            component="label"
            color="secondary"
            onClick={() => setLearningPlanDrawerOpen(true)}
          >
            Add new learning plan
          </Button>
          <Drawer
            isOpen={isLearningPlanDrawerOpen}
            onClose={() => setLearningPlanDrawerOpen(false)}
            title="Create new learning plan"
            size="md"
          >
            <LearningPlanForm
              onSubmit={handleSubmit}
              onCancel={() => setLearningPlanDrawerOpen(false)}
            />
          </Drawer>
        </>
      )}
    </AutoGrid>
  );

  return <ListToolbar left={left} right={right} />;
};

export default LearningPlanToolbar;
