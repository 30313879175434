import * as React from "react";

import { CourseItem } from "modules/courses/provider/courses";

import { CourseDescription } from "../common";

import AdminCards from "./cards-admin";
import AdminCourseActions from "./course-actions-admin";

type CourseInfoAdminProps = {
  course: CourseItem;
  refetch: () => void;
  refetchAll: () => void;
};

const CourseInfoAdmin: React.FC<CourseInfoAdminProps> = ({
  course,
  refetch,
  refetchAll
}) => (
  <>
    <AdminCards {...course} />
    <CourseDescription
      notes={course.notes}
      description={course.description}
      links={course.links}
      prerequisiteCourses={course.prerequisiteCourses}
    />
    <AdminCourseActions
      course={course}
      refetch={refetch}
      refetchAll={refetchAll}
    />
  </>
);

export default CourseInfoAdmin;
