import * as React from "react";

type Config<T> = { localStorageKey: string; defaultValue: T };
const useStateWithLocalStorage = <T extends string = string>({
  localStorageKey,
  defaultValue
}: Config<T>): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const initialValue = localStorage.getItem(localStorageKey) || defaultValue;
  const [value, setValue] = React.useState<T>(initialValue as T);

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
