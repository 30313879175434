import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, typography }: CustomTheme) => ({
  chip: {
    padding: spacing(3, 0),
    backgroundColor: palette.chip.main,
    flex: 8,
    display: "flex",
    color: palette.chip.contrastText,
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeightRegular,
    borderRadius: 5,
    maxWidth: "100%"
  },
  emptyChip: {
    backgroundColor: palette.chip.light,
    color: palette.chip.dark
  },
  label: {
    flex: 8,
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(1, 3, 1, 2)
  },
  group: {
    display: "flex",
    alignItems: "baseline"
  },
  icon: {
    marginLeft: spacing(1),
    flex: 1
  },
  deletable: {
    "&:focus": {
      backgroundColor: palette.chip.main
    }
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeightMedium
  },
  statusText: {
    marginLeft: spacing(1)
  },
  default: {
    color: palette.hint.dark
  },
  primary: {
    color: palette.primary.main
  },
  secondary: {
    color: palette.secondary.main
  },
  success: {
    color: palette.success.main
  },
  error: {
    color: palette.error.main
  },
  hint: {
    color: palette.hint.main
  },
  info: {
    color: palette.info.main
  }
}));
