import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  problemsFilters: {
    pagination: { rowsPerPage: 10 },
    orderBy: ["created_at,DESC"]
  },
  assigneesFilters: {
    pagination: { rowsPerPage: 0 },
    orderBy: ["first_name,ASC"]
  }
};

export enum actionTypes {
  SET_PROBLEMS_FILTERS = "setProblemsFilters",
  RESET_PROBLEMS_FILTERS = "resetProblemsFilters",
  SET_ASSIGNEES_FILTERS = "setAssigneesFilters",
  RESET_ASSIGNEES_FILTERS = "resetAssigneesFilters"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_PROBLEMS_FILTERS: {
      return {
        ...state,
        problemsFilters: action.payload
      };
    }
    case actionTypes.RESET_PROBLEMS_FILTERS: {
      return {
        ...state,
        problemsFilters: initialState.problemsFilters
      };
    }
    case actionTypes.SET_ASSIGNEES_FILTERS: {
      return {
        ...state,
        assigneesFilters: action.payload
      };
    }
    case actionTypes.RESET_ASSIGNEES_FILTERS: {
      return {
        ...state,
        assigneesFilters: initialState.assigneesFilters
      };
    }
    default:
      return state;
  }
};

type State = typeof initialState;
export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.problems, dispatch];
};

export default reducer;
