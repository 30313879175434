import * as React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

import { Button } from "components";

import useStyles from "./reports-list-item.styles";

type Props = {
  report: string;
  isActive: boolean;
  setReport: (arg: string) => void;
};

const ReportsListItem: React.FC<Props> = ({ report, isActive, setReport }) => {
  const classes = useStyles();

  return (
    <Button
      key={report}
      variant="text"
      onClick={() => setReport(report)}
      className={clsx(classes.button, isActive && classes.active)}
    >
      <Typography variant="h3" className={classes.reportItem}>
        {report}
      </Typography>
      {isActive && <div className={classes.arrow}></div>}
    </Button>
  );
};

export default ReportsListItem;
