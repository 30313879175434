import * as React from "react";
import clsx from "clsx";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";

import { Button } from "components";
import { AdminCourseStatus } from "modules/courses/courses.constants";
import { IconExpandMore, IconInfo } from "assets/icons";

import useStyles from "./course-list.styles";

type Props = {
  status: AdminCourseStatus.PUBLISHED | AdminCourseStatus.UNPUBLISHED;
  actionDisabled?: boolean;
  showTooltip?: boolean;
  handleAction: () => void;
};

const ButtonWithDropdown: React.FC<Props> = ({
  status,
  actionDisabled = false,
  showTooltip = false,
  handleAction
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="outlined"
        icon={IconExpandMore}
        iconPosition="right"
        classes={{
          root: clsx(classes.statusButton, classes[status]),
          icon: clsx(classes.expandMoreIcon, classes[status])
        }}
        onClick={openDropdown}
      >
        {status === AdminCourseStatus.PUBLISHED ? "Published" : "Unpublished"}
      </Button>

      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.dropdown, list: classes.dropdownList }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.dropdownActionContainer}>
          <MenuItem
            onClick={handleAction}
            classes={{ root: clsx(classes.dropdownButton, classes[status]) }}
            disabled={actionDisabled}
          >
            {status === AdminCourseStatus.PUBLISHED ? "Unpublish" : "Publish"}
          </MenuItem>
          {showTooltip && (
            <Tooltip
              title="Course must have content in order to be published."
              placement="bottom-start"
              color="disabled"
              className={classes.tooltip}
            >
              <IconInfo />
            </Tooltip>
          )}
        </div>
      </Menu>
    </>
  );
};

export default ButtonWithDropdown;
