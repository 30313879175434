import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  dateSeparator: {
    textAlign: "center",
    height: spacing(6), // like picker input
    lineHeight: spacing(6) + "px",
    fontSize: 17
  }
}));
