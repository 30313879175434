import * as React from "react";
import { Typography, Hidden, Link, Box } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import parseHTML from "html-react-parser";

import routes from "config/routes";
import { useTenant } from "services/tenant-provider";

import useStyles from "./bottom-panel.styles";
import { Variant, variantProps } from "./side-panel.component";

type Props = {
  variant: Variant;
};

const SidePanelBottom: React.FC<Props> = ({ variant }) => {
  const classes = useStyles();
  const tenant = useTenant();

  const tenantText = tenant.getText();

  return (
    <Hidden mdUp>
      <div className={classes.bottomPanelContainer}>
        <Hidden smUp>
          <Box mb={4} mt={2}>
            <Typography variant="h4" component="h4" color="inherit">
              {variantProps[variant].text}{" "}
              <>
                <Link href={variantProps[variant].buttonHref}>
                  {variantProps[variant].buttonLabel}
                </Link>
              </>
            </Typography>
          </Box>
        </Hidden>

        <Box mb={1} mt={2} textAlign="center">
          <Typography variant="h4" component="h4" color="inherit">
            {tenantText?.loginDescription
              ? parseHTML(tenantText.loginDescription)
              : "NPWT provided by AT&T"}
          </Typography>
        </Box>

        <Box mt={3} mb={1} textAlign="center">
          <Link
            component={RouterLink}
            to={routes.auth.reportProblem}
            color="primary"
          >
            Report a problem
          </Link>
        </Box>
      </div>
    </Hidden>
  );
};

export default SidePanelBottom;
