import * as React from "react";
import clsx from "clsx";
import { Menu, MenuItem } from "@material-ui/core";

import * as PythonApi from "services/api/python";
import { Button } from "components";
import { UserItem } from "modules/users/provider/users";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";
import { useUserStatusUpdate } from "modules/users/provider";
import { UserStatus } from "modules/users/users.constants";
import useToast from "utils/hooks/useToast";
import { IconExpandMore } from "assets/icons";

import useStyles from "./user-actions-dropdown.styles";

type Props = {
  user: UserItem;
  refetch: () => void;
};

const UserActionsDropdown: React.FC<Props> = ({ user, refetch }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();
  const activateUser = useUserStatusUpdate("activate");
  const deactivateUser = useUserStatusUpdate("deactivate");

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleUserStatusChange = (
    action: "activate" | "deactivate"
  ) => async () => {
    try {
      setIsSubmitting(true);
      await (action === "activate"
        ? activateUser({ id: user.id })
        : deactivateUser({ id: user.id }));
      showToast(
        `The user was successfully ${
          action === "activate" ? "activated" : "deactivated"
        }`,
        "success"
      );
      setIsSubmitting(false);
      setShouldRefetchIndicatorsToTrue();
      refetch();
    } catch (ex) {
      const errorMessages = PythonApi.getMessageFromError(ex);
      showToast(Object.values(errorMessages).join(" "), "error");
      setIsSubmitting(false);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState();
  const openDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { status } = user;
  const statusClass =
    status === UserStatus.ACTIVE
      ? "active"
      : status === UserStatus.DEACTIVATED
      ? "deactivated"
      : "pending";

  return (
    <>
      <Button
        variant="outlined"
        icon={IconExpandMore}
        iconPosition="right"
        classes={{
          root: clsx(classes.statusButton, classes[statusClass]),
          icon: clsx(classes.expandMoreIcon, classes[statusClass])
        }}
        onClick={openDropdown}
      >
        {status}
      </Button>

      <Menu
        anchorEl={anchorEl}
        classes={{
          paper: clsx(
            classes.dropdown,
            statusClass === "pending" && classes.twoElementDropdown
          ),
          list: classes.dropdownList
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div
          className={clsx(
            classes.dropdownActionContainer,
            status === "Deactivated" &&
              classes.twoElementsDropdownActionContainer
          )}
        >
          {status === UserStatus.ACTIVE && (
            <MenuItem
              onClick={handleUserStatusChange("deactivate")}
              disabled={isSubmitting}
              classes={{
                root: clsx(classes.dropdownButton, classes.deactivated)
              }}
            >
              Deactivate
            </MenuItem>
          )}
          {status === UserStatus.DEACTIVATED && (
            <MenuItem
              onClick={handleUserStatusChange("activate")}
              disabled={isSubmitting}
              classes={{
                root: clsx(classes.dropdownButton, classes.active)
              }}
            >
              Activate
            </MenuItem>
          )}
          {status === UserStatus.PENDING && (
            <>
              <MenuItem
                onClick={handleUserStatusChange("activate")}
                disabled={isSubmitting}
                classes={{
                  root: clsx(classes.dropdownButton, classes.active)
                }}
              >
                Activate
              </MenuItem>
              <MenuItem
                onClick={handleUserStatusChange("deactivate")}
                disabled={isSubmitting}
                classes={{
                  root: clsx(classes.dropdownButton, classes.deactivated)
                }}
              >
                Deactivate
              </MenuItem>
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default UserActionsDropdown;
