import * as PythonApi from "services/api/python";
import { useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

export type ResetPasswordVars = {
  id: string;
};

const createResetPasswordConfig = () =>
  PythonApi.makeMutation<ResetPasswordVars>(({ id }) => ({
    method: "POST",
    url: apiConfig.paths.authorized.user.requireResetPassword(id)
  }));

export const useResetPasswordForUser = () =>
  useFetchMutation(createResetPasswordConfig());

export const usePasswordUpdate = () => {
  type Vars = {
    currentPassword: string;
    newPassword: string;
  };
  return useFetchMutation<Vars>(
    PythonApi.makeMutation(variables => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.user.changePassword,
      data: variables
    }))
  );
};
