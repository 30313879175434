import * as React from "react";

import { Breadcrumbs, ExtendedMainHeader } from "components";
import routes from "config/routes";
import { UserRole } from "modules/users/users.constants";
import { useAuth } from "services/auth-provider";

import { useCompaniesListPublic } from "../provider";
import { CompanyItem } from "../provider/companies";

type Props = {
  company: CompanyItem;
  button?: React.ReactNode;
};
type CompanyBreadcrumbs = {
  value: string;
  label: string;
  parent: null | string;
}[];

const findPath = (
  flattenedTree: CompanyBreadcrumbs,
  arrayPath: CompanyBreadcrumbs,
  companyId: string
): CompanyBreadcrumbs => {
  const treeItem = flattenedTree.find(({ value }) => value === companyId);
  if (treeItem) {
    arrayPath.unshift(treeItem);
    if (treeItem.parent) {
      return findPath(flattenedTree, arrayPath, treeItem.parent);
    }
  }
  return arrayPath;
};

const CompanyHeader: React.FC<Props> = ({ company, button }) => {
  const { checkAccess, user } = useAuth();
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const groups = useCompaniesListPublic({ variables: {} });

  const options = React.useMemo(() => {
    if (groups.data) {
      return groups.data.map(({ id, name, parent }) => ({
        label: name,
        value: id,
        parent: isTenantAdmin
          ? user!.groups?.includes(parent)
            ? parent
            : null
          : parent
      }));
    }
    return [];
  }, [groups.data, isTenantAdmin, user]);

  const path = React.useMemo(() => findPath(options, [], company.id), [
    company.id,
    options
  ]);

  const breadcrumbsRoutes = React.useMemo(
    () => [
      { name: "Companies", path: routes.companies.list },
      ...path.map(({ value, label }) => ({
        name: label,
        ...(company.id !== value && {
          path: routes.companies.singleCompany.replace(":companyId", value)
        })
      }))
    ],
    [company.id, path]
  );
  return (
    <ExtendedMainHeader
      button={button}
      title={company.name}
      hint={company.externalId && `(ID: ${company.externalId})`}
      subtitle={
        <Breadcrumbs
          routes={
            groups.loading
              ? [
                  { name: "Companies", path: routes.companies.list },
                  { name: "Loading..." }
                ]
              : breadcrumbsRoutes
          }
        />
      }
    />
  );
};

export default CompanyHeader;
