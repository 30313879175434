import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box/Box";

import { Button } from "components";
import { useSizeCheck } from "utils/hooks/useSizeCheck";

import { Props as ButtonProps } from "../../button/button";

export interface Props extends OptionalProps {
  onCancel: () => void;
}

interface OptionalProps {
  boxProps?: BoxProps;
  cancelButtonLabel?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonDisabled?: boolean;
  confirmButtonLabel?: "Save" | "Add" | string;
  confirmButtonProps?: ButtonProps;
  justify?: "flex-start" | "flex-end";
}

const CancelConfirmButtons: React.FC<Props> = props => {
  const isMobile = useSizeCheck("xs");

  return (
    <Box mt={8} {...props.boxProps}>
      <Grid
        container
        spacing={2}
        justify={props.justify ? props.justify : "flex-end"}
      >
        <Grid item>
          <Button
            color="error"
            onClick={props.onCancel}
            variant="outlined"
            {...props.cancelButtonProps}
          >
            {props.cancelButtonLabel || "Cancel"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="success"
            disabled={props.confirmButtonDisabled}
            type="submit"
            fullWidth={isMobile}
            {...props.confirmButtonProps}
          >
            {props.confirmButtonLabel || "Confirm"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CancelConfirmButtons;
