import * as React from "react";

import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { CompanyItem as CompanyItemType } from "modules/companies/provider/companies";

import CompaniesListToolbar from "./companies-list-toolbar";
import CompaniesList from "./companies-list";
import CompaniesListPagination from "./companies-list-pagination";

type CompaniesListContainerProps = {
  loading: boolean;
  companies: CompanyItemType[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
};

const CompaniesListContainer: React.FC<CompaniesListContainerProps> = ({
  loading,
  companies,
  meta,
  listFilters,
  refetch
}) => (
  <>
    <CompaniesListToolbar {...{ companies, listFilters, refetch }} />
    <CompaniesList {...{ loading, companies, listFilters, refetch }} />
    <CompaniesListPagination {...{ meta, listFilters }} />
  </>
);

export default CompaniesListContainer;
