import * as React from "react";
import { Tooltip } from "@material-ui/core";

import { Button, Drawer } from "components";
import useToast from "utils/hooks/useToast";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import SubmitCourseCompletion from "modules/courses/components/course-submit-completion.component";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import {
  CourseStatus,
  ManualCompletionTooltipMessages
} from "modules/courses/courses.constants";
import { IconInfo } from "assets/icons";

import useStyles from "./submit-manual-completion-cta.styles";

const generateTooltipMessage = (
  inProgress: boolean,
  hasUrl: boolean,
  allowManualCompletion: boolean
) => {
  let message = ManualCompletionTooltipMessages.MANUAL_COMPLETION_ALLOWED;

  // This is intended message precedence
  if (!allowManualCompletion) {
    message = ManualCompletionTooltipMessages.MANUAL_COMPLETION_FORBIDDEN;
  }
  if (!hasUrl) {
    message = ManualCompletionTooltipMessages.CONTENT_ABSENT;
  }
  if (!inProgress) {
    message = ManualCompletionTooltipMessages.COURSE_NOT_IN_PROGRESS;
  }

  return message;
};

export type SubmitManualCompletionCTAProps = Pick<
  MyCoursesListItem,
  "id" | "url" | "allowManualCompletion" | "status"
>;

const SubmitManualCompletionCTA: React.FC<SubmitManualCompletionCTAProps> = ({
  id,
  url,
  allowManualCompletion,
  status
}) => {
  const classes = useStyles();

  const { showToast } = useToast();
  const [
    isSubmitCourseCompletionDrawerOpen,
    setSubmitCourseCompletionDrawerOpen
  ] = React.useState(false);
  const isMobile = useSizeCheck("xs");

  const openDrawer = React.useCallback(() => {
    isMobile && window.scrollTo(0, 0);
    setSubmitCourseCompletionDrawerOpen(true);
  }, [isMobile]);

  const handleCourseCompletionSubmit = () => {
    setSubmitCourseCompletionDrawerOpen(false);
    showToast("Your request was successfully submitted", "success");
  };

  const inProgress = status === CourseStatus.IN_PROGRESS;
  const isDisabled = !url || !allowManualCompletion || !inProgress;

  return (
    <>
      <div className={classes.submitContainer}>
        <Button
          color="primary"
          onClick={openDrawer}
          variant="text"
          disabled={isDisabled}
          underline
        >
          Submit manual completion
        </Button>

        <Tooltip
          title={generateTooltipMessage(
            inProgress,
            Boolean(url),
            allowManualCompletion
          )}
          placement="bottom-start"
        >
          <IconInfo color={isDisabled ? "disabled" : "primary"} />
        </Tooltip>
      </div>

      <Drawer
        isOpen={isSubmitCourseCompletionDrawerOpen}
        onClose={() => setSubmitCourseCompletionDrawerOpen(false)}
        title="Manual completion"
        size="sm"
        fitSize={isMobile}
      >
        <SubmitCourseCompletion
          onSubmit={handleCourseCompletionSubmit}
          onCancel={() => setSubmitCourseCompletionDrawerOpen(false)}
          courseId={id}
        />
      </Drawer>
    </>
  );
};

export default SubmitManualCompletionCTA;
