import React from "react";
import { Field } from "react-final-form";

import useListFilters from "utils/hooks/useListFilters";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";

import { useLightCoursesList } from "../../provider/courses";

const CoursesSelect = () => {
  const listFilters = useListFilters({
    pagination: { rowsPerPage: 200 },
    orderBy: ["name,ASC"]
  });

  const courses = useLightCoursesList({
    variables: {
      listFilters: listFilters
    }
  });

  const courseOptions = React.useMemo(() => {
    if (courses.data) {
      return courses.data.map(({ id, name }) => ({
        label: name,
        value: id,
        parent: null
      }));
    }
    return [];
  }, [courses.data]);

  return (
    <Field
      name="prerequisiteCourses"
      label="Course name"
      onlyOneFromBranch
      component={GroupTreeControl}
      options={courseOptions}
      isLoading={courses.loading}
      listFilters={listFilters}
      allowMultiple={true}
    />
  );
};

export default CoursesSelect;
