import * as React from "react";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { History, Location } from "history";

import routes from "config/routes";
import { createValidator } from "utils/forms";
import PasswordPolicyManager, {
  MinLength,
  MinValidCharGroups,
  LowerAlpha,
  UpperAlpha,
  Digits,
  Special
} from "utils/validators/password-policy";
import {
  Section,
  FormError,
  PasswordChecker,
  TextControl,
  Button,
  LoadingPlaceholder
} from "components";
import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";
import * as PythonApi from "services/api/python";

import ButtonContainer from "../components/button-container.component";
import { useChangePasswordMutation, useCheckToken } from "../guest.provider";

import useStyles from "./guest.styles";

const validator = createValidator({
  password: { presence: { allowEmpty: false } },
  repeatPassword: {
    presence: { allowEmpty: false },
    equality: { attribute: "password" }
  }
});

const policyManager = new PasswordPolicyManager([
  new MinLength(16),
  new MinValidCharGroups(4, [
    new LowerAlpha(1),
    new UpperAlpha(1),
    new Digits(1),
    new Special(1)
  ])
]);

type Props = {
  history: History;
  location: Location;
};

const SetNewPasswordView: React.FC<Props> = ({ location, history }) => {
  const classes = useStyles();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") ?? "";
  const userID = queryParams.get("id") ?? "";

  const changePassword = useChangePasswordMutation();
  const { error, loading } = useCheckToken({
    variables: { id: userID, token: token }
  });

  if (loading) {
    return (
      <div className={classes.fullPageContainer}>
        <LoadingPlaceholder />
      </div>
    );
  }
  if (!token || error) {
    //todo redirect with error BE response description
    return <Redirect to={routes.auth.setNewPasswordExpired} />;
  }

  return (
    <GuestLayout>
      <SidePanel variant="register" />
      <Content title="Set a new password" form className={classes.form}>
        <Form
          onSubmit={async values => {
            try {
              await changePassword({
                id: userID,
                token: token,
                newPassword: values.password
              });
              history.push(routes.auth.setNewPasswordSuccess);
            } catch (ex) {
              const formErrors = PythonApi.getFormErrors(ex) as any;

              if (formErrors && formErrors.token) {
                // There's no 'token' field in the form - so display it as a global error.
                formErrors[FORM_ERROR] = formErrors.token;
              }

              return formErrors;
            }
          }}
          validate={values => {
            const errors = validator(values);

            const password = values.password || "";
            if (!policyManager.isValid(password)) {
              errors.password = policyManager.getMessage();
            }

            if (/\s/.test(password)) {
              errors.password = policyManager.getWhiteSpaceMessage();
            }

            return errors;
          }}
        >
          {({
            handleSubmit,
            submitError,
            submitting,
            pristine,
            hasValidationErrors
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}

              <Section margin="dense">
                <PasswordChecker policyManager={policyManager} flat />
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  variant="outlined"
                  component={TextControl}
                  margin="normal"
                  autoComplete="off"
                  validationIndicator
                />
                <Field
                  type="password"
                  name="repeatPassword"
                  label="Repeat password"
                  variant="outlined"
                  component={TextControl}
                  margin="normal"
                  autoComplete="off"
                  validationIndicator
                />
              </Section>
              <ButtonContainer>
                <Button
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={submitting || hasValidationErrors || pristine}
                >
                  Set password
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Form>
      </Content>
      <BottomPanel variant="register" />
    </GuestLayout>
  );
};

export default SetNewPasswordView;
