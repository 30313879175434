import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import authClient from "services/auth";
import { AuthProvider } from "services/auth-provider";
import { initializeGA } from "services/analytics";
import StateProvider from "config/state";
import { ToastProvider } from "components";
import { disableBackspaceNavigationForFirefox } from "utils/browser";
import tenantClient from "services/tenant";
import { TenantProvider } from "services/tenant-provider";
import config from "config";

import App from "./app";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: config.sentryDSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: config.env,
  denyUrls: ["localhost", "127.0.0.1"],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

initializeGA();
disableBackspaceNavigationForFirefox();

const FallbackComponent = () => {
  return <div>An error has occurred</div>;
};
const fallback = <FallbackComponent />;

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={fallback}>
    <TenantProvider client={tenantClient}>
      <AuthProvider client={authClient}>
        <BrowserRouter>
          <StateProvider>
            <>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </MuiPickersUtilsProvider>
            </>
          </StateProvider>
        </BrowserRouter>
      </AuthProvider>
    </TenantProvider>
  </Sentry.ErrorBoundary>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
