import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ palette, spacing, breakpoints, shadows }: CustomTheme) => ({
    backdrop: {
      backgroundColor: "transparent"
    },
    paper: {
      maxWidth: "100%",
      padding: spacing(3, 2),
      boxShadow: shadows[3],

      [breakpoints.up("sm")]: {
        padding: spacing(6, 6)
      }
    },
    title: {
      textAlign: "center",
      fontSize: "1.125rem",

      [breakpoints.up("sm")]: {
        fontSize: "1.5rem",
        textAlign: "left"
      }
    },
    divider: {
      margin: spacing(3, 0),
      backgroundColor: palette.hint.light,
      height: 2,

      [breakpoints.up("sm")]: {
        margin: spacing(5, 0)
      }
    },

    xs: {
      width: 400
    },
    sm: {
      width: 500
    },
    md: {
      width: 750
    },
    lg: {
      width: 1000
    },
    xl: {
      width: 1350
    },

    fitSize: {
      top: 80,
      width: "100%",
      position: "absolute",
      boxShadow: "none",
      height: "calc(100% - 80px)",

      [breakpoints.up("sm")]: {
        height: "100%"
      }
    }
  })
);
