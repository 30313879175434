import * as React from "react";
import _ from "lodash";
import { TextField, MenuItem } from "@material-ui/core";

import { ListFiltersState } from "utils/hooks/useListFilters";

import useStyles from "./select-filter.styles";

export const CLEAR_FILTER_VALUE = "NO_FILTER_SHOW_ALL";

type Props = {
  clearLabel?: string;
  label?: string;
  listFilters: ListFiltersState;
  name: string;
  options: Array<{
    label: string;
    value: string | number;
  }>;
};

const SelectFilter: React.FC<Props> = ({
  clearLabel = "- All -",
  label,
  listFilters,
  name,
  options
}) => {
  const classes = useStyles();

  const initialValue = _.get(
    listFilters,
    `filters.${name}`,
    CLEAR_FILTER_VALUE
  );

  return (
    <TextField
      select
      label={label}
      variant="outlined"
      value={initialValue}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const newValue = event.target.value;
        listFilters.setFilter(
          name,
          newValue === CLEAR_FILTER_VALUE ? undefined : newValue
        );
      }}
      SelectProps={{
        classes: {
          selectMenu: classes.input
        }
      }}
    >
      <MenuItem value={CLEAR_FILTER_VALUE}>{clearLabel}</MenuItem>
      {options.map(({ label, value }) => (
        <MenuItem value={`${value}`} key={`${value}`}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectFilter;
