import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Grid } from "@material-ui/core";

import {
  Button,
  DataTable,
  EmptyListPlaceholder,
  LoadingPlaceholder,
  ResponsiveTableWrapper
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useTableStyles from "components/data-table/data-table.styles";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { LayoutType } from "components/layout-selector/layout-selector";
import { formatDuration } from "utils/datetime";

import {
  MyLearningPlanItem,
  MyLearningPlanStatus
} from "../../../provider/my-learning-plans";
import LearningPlanStatusChip from "../components/learning-plan-status-chip";
import LearningPlanCard from "../components/learning-plan-card";

type Props = {
  learningPlans: MyLearningPlanItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  loading: boolean;
  activeLayout: LayoutType;
};

const MyLearningPlanList: React.FC<Props> = ({
  learningPlans,
  loading,
  listFilters,
  meta,
  activeLayout
}) => {
  const tableClasses = useTableStyles();
  const tenant = useTenant();
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <>
      {activeLayout === "grid" ? (
        learningPlans.length > 0 ? (
          <Grid container spacing={5}>
            {learningPlans.map(lp => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={lp.id}>
                <LearningPlanCard learningPlan={lp} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <EmptyListPlaceholder text="No learning plans" />
        )
      ) : (
        <ResponsiveTableWrapper>
          <DataTable
            loading={loading}
            emptyText="No learning plans"
            data={learningPlans}
            sortable
            listFilters={listFilters}
            columns={[
              {
                key: "name",
                title: "Name",
                sortable: true,
                render: lp => (
                  <Button
                    variant="text"
                    target={isDetailsNewTab ? "_blank" : "_self"}
                    component={RouterLink}
                    to={
                      isAllowedTenantAvailableCourses
                        ? `/available-courses/my-learning-plan/${lp.id}`
                        : `/my-courses/my-learning-plan/${lp.id}`
                    }
                  >
                    <span
                      className={clsx(
                        tableClasses.ellipsis,
                        tableClasses.customText
                      )}
                    >
                      {lp.name}
                    </span>
                  </Button>
                )
              },
              {
                key: "id",
                title: "Id",
                render: ({ externalId }) => externalId || "-"
              },
              {
                key: "addedDate",
                title: "Added date",
                sortable: true,
                render: lp => moment(lp.createdAt).format("L")
              },
              {
                key: "status",
                title: "Status",
                sortable: true,
                render: lp => (
                  <LearningPlanStatusChip
                    status={lp.status}
                    label={
                      lp.status === MyLearningPlanStatus.IN_PROGRESS
                        ? `(${lp.finishedCourses}/${lp.totalCourses})`
                        : ""
                    }
                  />
                )
              },
              {
                key: "duration",
                title: "Duration",
                render: ({ totalDuration }) =>
                  totalDuration
                    ? formatDuration(totalDuration * 1000, "H[h] mm[min]")
                    : "-"
              },
              {
                key: "totalCourses",
                setWhiteSpaceNormal: true,
                title: "Total courses",
                className: tableClasses.center,
                render: lp => lp.totalCourses
              }
            ]}
          />
        </ResponsiveTableWrapper>
      )}
    </>
  );
};

export default MyLearningPlanList;
