import { makeStyles } from "@material-ui/core";

export default makeStyles(({ spacing, typography }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(5),

    "&$inline": {
      padding: spacing(0, 2)
    }
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "&$inline": {
      flexDirection: "row"
    }
  },
  image: {
    lineHeight: 1
  },
  message: {
    marginTop: spacing(1),
    "& > *": { ...typography.h3 },

    "&$inline": {
      marginTop: spacing(0),
      marginLeft: spacing(1),
      "& > *": { ...typography.h4 }
    }
  },
  inline: {}
}));
