import * as React from "react";
import { Toolbar } from "@material-ui/core";

import useStyles from "./list-toolbar.styles";

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const ListToolbar: React.FC<Props> = ({ left, right }) => {
  const classes = useStyles();

  return (
    <Toolbar classes={{ root: classes.root }}>
      {left && <div className={classes.options}>{left}</div>}

      {right && <div className={classes.options}>{right}</div>}
    </Toolbar>
  );
};

export default ListToolbar;
