import * as React from "react";
import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";

import * as PythonApi from "services/api/python";
import { Header, TextControl, Section, Button } from "components";
import { useResetPasswordForUser } from "modules/users/provider";
import useToast from "utils/hooks/useToast";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type UserDetailsSectionProps = {
  sectionIndex: number;
  userId: string | null;
};

const UserDetailsSection = ({
  sectionIndex,
  userId
}: UserDetailsSectionProps) => {
  const resetPassword = useResetPasswordForUser();
  const { showToast } = useToast();
  const tenant = useTenant();
  const [resetIsDisabled, setResetIsDisabled] = React.useState(false);
  const resetPasswordHandler = React.useCallback(async () => {
    try {
      setResetIsDisabled(true);
      await resetPassword({ id: userId! });
      showToast("Email with reset link was sent", "success");
    } catch (ex) {
      const errObj = PythonApi.getFormErrors(ex);
      showToast(
        `Couldn't reset password ${Object.values(errObj).join(" ")}`,
        "error"
      );
    } finally {
      setResetIsDisabled(false);
    }
  }, [resetPassword, showToast, userId]);

  const isUsernameChangeable = tenant.isComponentAllowed(
    AllowedComponents.UsernameChangeable
  );

  return (
    <Section margin="dense">
      <Header
        variant="formSection"
        number={sectionIndex}
        title="Enter user details"
      />
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Field
            name="firstName"
            label="First name (required)"
            variant="outlined"
            margin="none"
            component={TextControl}
            validationIndicator
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            name="lastName"
            label="Last name (required)"
            variant="outlined"
            margin="none"
            component={TextControl}
            validationIndicator
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            name="email"
            label="E-mail (required)"
            variant="outlined"
            margin="none"
            component={TextControl}
            validationIndicator
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            name="username"
            label="Username (required)"
            variant="outlined"
            margin="none"
            disabled={isUsernameChangeable ? false : !!userId}
            component={TextControl}
            validationIndicator
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            name="phone"
            label="Phone number (optional)"
            variant="outlined"
            margin="none"
            component={TextControl}
            validationIndicator
            validate={(phone, allValues) => {
              // Validate when user has both fields filled and remove only phone (extension input is pristine)
              // and when user has only phone, removes it and enters extension
              if (phone === undefined && (allValues as any).extension)
                return "Phone number is required for extension";
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            name="extension"
            label="Extension (optional)"
            variant="outlined"
            margin="none"
            component={TextControl}
            validationIndicator
            validate={(extension, allValues) => {
              // Validate when user has both fields empty and enters only extension (phone input is pristine)
              const phone = (allValues as any).phone;
              if (extension && typeof phone === "string" && phone.length === 0)
                return "Extension requires phone number entered";
            }}
          />
        </Grid>
        {userId && (
          <Grid item sm={6}>
            <Button
              style={{
                width: "100%",
                height: 56,
                borderRadius: 10
              }}
              color="secondary"
              onClick={resetPasswordHandler}
              disabled={resetIsDisabled}
            >
              Reset Password
            </Button>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

export default UserDetailsSection;
