import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
  normal: {
    marginBottom: spacing(8)
  },
  dense: {
    marginBottom: spacing(1),

    [breakpoints.up("sm")]: {
      marginBottom: spacing(4)
    }
  },
  big: {
    marginBottom: spacing(12)
  }
}));
