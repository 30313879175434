import clsx from "clsx";
import * as React from "react";

import useStyles from "./section.styles";

type Props = {
  margin?: "normal" | "dense" | "big";
  className?: string;
};

const Section: React.FC<Props> = ({
  children,
  margin = "normal",
  className = ""
}) => {
  const classes = useStyles({ margin });

  return <div className={clsx(classes[margin], className)}>{children}</div>;
};

export default Section;
