import * as React from "react";

import { useAuth } from "services/auth-provider";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useToast from "utils/hooks/useToast";
import * as PythonApi from "services/api/python";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { AutoGrid, Button, Drawer, ListToolbar, Search } from "components";

import { useUploadPrimeFileMutation } from "../../provider/courses";
import AddOrUpdateCourse from "../course-add-or-update/course-add-or-update.component";

type Props = {
  listFilters: ListFiltersState;
  refetch: () => void;
};

const CoursesToolbar: React.FC<Props> = ({ listFilters, refetch }) => {
  const { showToast } = useToast();

  const uploadPrimeFile = useUploadPrimeFileMutation();
  const tenant = useTenant();
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const [isAddCourseDrawerOpen, setAddCourseDrawerOpen] = React.useState(false);

  const handleAddCourseSubmit = () => {
    setAddCourseDrawerOpen(false);
    showToast("The course was successfully created", "success");
    refetch();
  };
  const [
    isPrimeFileButtonSubmitting,
    setPrimeFileButtonSubmitting
  ] = React.useState(false);

  const onPrimeFileSelection = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const eventTarget = event.target;
    const file =
      eventTarget.files && eventTarget.files.length > 0 && eventTarget.files[0];

    if (file) {
      setPrimeFileButtonSubmitting(true);
      try {
        await uploadPrimeFile({ file });
        showToast("Prime file was successfully uploaded", "success");
        refetch();
      } catch (ex) {
        const errorMessage = PythonApi.getMessageFromError(ex);
        showToast(Object.values(errorMessage).join(" "), "error");
      } finally {
        setPrimeFileButtonSubmitting(false);
        // Reset input
        eventTarget.value = "";
        eventTarget.files = null;
      }
    }
  };

  const left = (
    <AutoGrid spacing={2}>
      <Search listFilters={listFilters} />
    </AutoGrid>
  );

  const right = (
    <AutoGrid spacing={2} justify="flex-end">
      {tenant.isComponentAllowed(AllowedComponents.UploadPrimeCSV) && isAdmin && (
        <Button
          disabled={isPrimeFileButtonSubmitting}
          component="label"
          color="secondary"
        >
          Upload prime file
          <input
            onChange={onPrimeFileSelection}
            type="file"
            accept=".csv"
            style={{ display: "none" }}
          />
        </Button>
      )}

      {tenant.isComponentAllowed(AllowedComponents.CourseCreation) && isAdmin && (
        <>
          <Button
            component="label"
            color="secondary"
            onClick={() => setAddCourseDrawerOpen(true)}
          >
            Add new course
          </Button>
          <Drawer
            isOpen={isAddCourseDrawerOpen}
            onClose={() => setAddCourseDrawerOpen(false)}
            title="Add new course"
            size="lg"
          >
            <AddOrUpdateCourse
              onSubmit={handleAddCourseSubmit}
              onCancel={() => setAddCourseDrawerOpen(false)}
            />
          </Drawer>

          {/* @TODO add <ClosingModal /> */}
        </>
      )}
    </AutoGrid>
  );

  return <ListToolbar left={left} right={right} />;
};

export default CoursesToolbar;
