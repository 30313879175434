import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) => ({
  indicatorError: {
    color: palette.error.main
  },
  indicatorSuccess: {
    color: palette.success.main
  }
}));
