import _ from "lodash";
import * as Sentry from "@sentry/react";

import storage from "config/storage";

import {
  IAuthClient,
  LoginWithUserAndPasswordParams,
  IUser,
  CheckAccessOptions,
  OnUpdateHandler
} from "../types";
import { JwtTokenPayload, parseJwt } from "../utils/jwt";

export interface IApiResponse<T, E = Error> {
  data: T | null;
  error?: E;
}

export type RefreshTokenOptions = {
  checkIntervalSeconds: number;
  remainingTimeLimitSeconds: number;
};

export type IdentityResponse = IUser;
export type LoginWithUserAndPasswordResponse = {
  accessToken: string;
  refreshToken: string;
};
export type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
};
export interface RefreshTokenError extends Error {
  isExpired: boolean;
}

export interface IRestAuthEndpoints {
  identity: (token: string) => Promise<IApiResponse<IdentityResponse>>;

  loginWithUserAndPassword: (
    params: LoginWithUserAndPasswordParams
  ) => Promise<IApiResponse<LoginWithUserAndPasswordResponse>>;

  refreshToken: (
    refreshToken: string
  ) => Promise<IApiResponse<RefreshTokenResponse, RefreshTokenError>>;
}

export default class RestAuthClient implements IAuthClient {
  private endpoints: IRestAuthEndpoints;
  private refreshTokenOptions: RefreshTokenOptions;
  private refreshIntervalHandle: NodeJS.Timeout | null = null;
  private onUpdateHandler: OnUpdateHandler = () => {};

  storage: Storage;
  isInitialized: boolean = false;
  isAuthenticated: boolean = false;
  user: IUser | null = null;

  constructor(
    endpoints: IRestAuthEndpoints,
    storage: Storage,
    refreshTokenOptions: Partial<RefreshTokenOptions> = {}
  ) {
    this.endpoints = endpoints;
    this.storage = storage;
    this.refreshTokenOptions = {
      remainingTimeLimitSeconds:
        refreshTokenOptions.remainingTimeLimitSeconds || 10,
      checkIntervalSeconds: refreshTokenOptions.checkIntervalSeconds || 5
    };
  }

  get refreshToken() {
    return this.storage.getItem(storage.shared.refreshToken) ?? "";
  }

  set refreshToken(newValue: string) {
    if (newValue) {
      this.storage.setItem(storage.shared.refreshToken, newValue);
    } else {
      this.storage.removeItem(storage.shared.refreshToken);
    }
  }

  get token() {
    return this.storage.getItem(storage.shared.accessToken) ?? "";
  }

  set token(newValue: string) {
    if (newValue) {
      this.storage.setItem(storage.shared.accessToken, newValue);
    } else {
      this.storage.removeItem(storage.shared.accessToken);
    }
  }
  get accessTokenObject() {
    return RestAuthClient.parseAccessToken(this.token);
  }

  private static parseAccessToken(accessToken: string): JwtTokenPayload {
    const parsedToken = parseJwt(accessToken);
    return parsedToken.payload;
  }

  private setTokens(accessToken: string, refreshToken: string) {
    this.token = accessToken;
    this.refreshToken = refreshToken;
  }

  private clearTokens() {
    this.token = "";
    this.refreshToken = "";
  }

  private static clearLocalStorage() {
    // Remove all prefixes myCoursesLayout
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.includes(storage.local.myCoursesLayoutPrefix)) {
        localStorage.removeItem(localStorage.key(i) ?? "");
      }
    }
  }

  private retrieveAndSetTokensFromCookie() {
    const accessToken =
      document?.cookie
        ?.split("; ")
        ?.find(row => row.startsWith(storage.cookie.accessToken))
        ?.split("=")[1] ?? "";

    const refreshToken =
      document?.cookie
        ?.split("; ")
        ?.find(row => row.startsWith(storage.cookie.refreshToken))
        ?.split("=")[1] ?? "";

    this.setTokens(accessToken, refreshToken);

    // Remove cookies
    document.cookie =
      storage.cookie.accessToken +
      "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie =
      storage.cookie.refreshToken +
      "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  }

  async init() {
    if (
      document.cookie.includes(storage.cookie.accessToken) &&
      document.cookie.includes(storage.cookie.refreshToken)
    ) {
      this.retrieveAndSetTokensFromCookie();
    }

    if (this.refreshToken && this.token) {
      try {
        if (this.shouldRefreshTokens()) {
          await this.refreshTokens();
        }
        await this.checkIdentity();
        this.startCheckingTokens();
      } catch (ex) {
        await this.logout();
      }
    } else {
      await this.logout();
    }

    this.isInitialized = true;
    this.onUpdateHandler();
  }

  async loginWithUserAndPassword(params: LoginWithUserAndPasswordParams) {
    const { username, password } = params;

    const { data, error } = await this.endpoints.loginWithUserAndPassword({
      username,
      password
    });

    if (error) {
      throw error;
    }

    this.isAuthenticated = true;
    this.setTokens(data!.accessToken, data!.refreshToken);

    await this.checkIdentity();
    this.startCheckingTokens();
    this.onUpdateHandler();
  }

  async logout() {
    this.isAuthenticated = false;
    this.user = null;
    Sentry.setUser(null);

    this.clearTokens();
    RestAuthClient.clearLocalStorage();
    this.stopCheckingTokens();
    this.onUpdateHandler();
  }

  async checkIdentity() {
    const { data, error } = await this.endpoints.identity(this.token);
    if (error) {
      this.isAuthenticated = false;
      this.token = "";
      this.user = null;

      throw error;
    }

    this.isAuthenticated = true;
    this.user = data;
  }

  shouldRefreshTokens() {
    // JWT tokens have 'exp' field set in seconds, but Date.now() returns milliseconds, so we need to adjust.
    const currentTimeInSeconds = Date.now() / 1000;

    try {
      const remainingTimeSeconds =
        this.accessTokenObject.exp - currentTimeInSeconds;
      return (
        remainingTimeSeconds <=
        this.refreshTokenOptions.remainingTimeLimitSeconds
      );
    } catch {
      return true;
    }
  }

  async refreshTokens() {
    const { data, error } = await this.endpoints.refreshToken(
      this.refreshToken
    );

    if (error) {
      if (error.isExpired) {
        await this.logout();
      }

      throw error;
    }

    this.setTokens(data!.accessToken, data!.refreshToken);
    this.startCheckingTokens();
    this.onUpdateHandler();
  }

  startCheckingTokens() {
    this.stopCheckingTokens();

    this.refreshIntervalHandle = setInterval(async () => {
      if (this.shouldRefreshTokens()) {
        try {
          await this.refreshTokens();
        } catch (ex) {
          console.error("Cannot refresh token:", ex);
          // Ignore any errors, because we will retry if necessary after a few seconds
        }
      }
    }, this.refreshTokenOptions.checkIntervalSeconds * 1000);
  }

  stopCheckingTokens() {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
      this.refreshIntervalHandle = null;
    }
  }

  getIsInitialized() {
    return this.isInitialized;
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getUser() {
    return this.user;
  }

  updateUser(newData: IUser) {
    this.user =
      this.user !== null ? _.merge<IUser, IUser>(this.user, newData) : newData;
    this.onUpdateHandler();
  }

  async getFreshToken() {
    if (this.shouldRefreshTokens()) {
      await this.refreshTokens();
    }

    return this.token;
  }

  checkAccess(options: CheckAccessOptions) {
    console.warn(
      "RestApiClient.checkAccess should be implemented by extending this class in your project."
    );
    return this.isAuthenticated;
  }

  registerOnUpdateHandler(handler: OnUpdateHandler) {
    this.onUpdateHandler = handler;
  }
}
