import * as React from "react";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

export interface ITenantContext {
  isInitialized: boolean;
  init: ITenantClient["init"];
  getIsInitialized: ITenantClient["getIsInitialized"];
  isComponentAllowed: ITenantClient["isComponentAllowed"];
  getStyles: ITenantClient["getStyles"];
  getSaml: ITenantClient["getSaml"];
  getText: ITenantClient["getText"];
  getName: ITenantClient["getName"];
  getHostname: ITenantClient["getHostname"];
  getLegalDiscalimer: ITenantClient["getLegalDiscalimer"];
}

export enum AllowedComponents {
  CourseCreation = "isCourseCreation",
  LearningPlanCreation = "isLearningPlans",
  CourseCompanyAssign = "isCourseCompanyAssignment",
  CourseUserAssign = "isCourseUserAssignment",
  SAML = "isSaml",
  Voucher = "isVoucher", // edit company
  VoucherRequired = "isVoucherRequired", // register
  CourseListForStudent = "isCourseListForStudent",
  UserDelete = "isUserDelete",
  CompanyStatusChange = "isCompanyStatusChange", // activate/deactivate
  ReportCourseDetails = "isReportCourseDetails",
  ReportCompanyDetails = "isReportCompanyDetails",
  ReportPerformance = "isReportPerformance",
  ReportUserDetails = "isReportUserDetails",
  UploadPrimeCSV = "isUploadPrimeCsv",
  LoginReversedColors = "isReversedPaletteInLogin",
  NoCompanySponsorAndAdmins = "isNoCompanySponsorAndAdmins",
  LoginScreenLogo = "isLoginScreenLogo",
  ProblemsUserDetails = "isProblemsUserDetails",
  ProblemsTableSortable = "isProblemsTableSortable",
  DetailsNewTab = "isDetailsNewTab",
  LegalDisclaimer = "isLegalDisclaimer",
  UsernameChangeable = "isUsernameChangeable",
  ManualCompletionReviewerFilter = "isManualCompletionReviewerFilter",
  CompanyEmailDomain = "isCompanyEmailDomain",
  ApprovedUsersList = "isApprovedUsersList",
  ReportsTab = "isReportsTab",
  AllUsersCoursesReport = "isAllUsersCoursesReport",
  FeatureToggleList = "isFeatureToggleList",
  PrimeCourseUploadLog = "isPrimeCourseUploadLog",
  MinPagination = "isMinPagination",
  StartFromAvailable = "isStartFromAvailable",
  PrerequisitesCourses = "isCoursePrerequisites"
}

export type OnUpdateHandler = () => void;

export type TenantSaml = {
  loginUrl: string;
  callbackUrl?: string;
};

export type TenantText = {
  loginTitle?: string;
  loginDescription?: string;
  htmlTitle?: string;
};

export type TenantLegalDisclaimer = {
  title?: string;
  body?: string[];
  consent?: string;
};

export interface ITenantClient {
  init(): Promise<void>;
  getIsInitialized(): boolean;
  isComponentAllowed(
    component: AllowedComponents | Array<AllowedComponents>
  ): boolean;
  registerOnUpdateHandler(handler: OnUpdateHandler): void;
  getStyles(): ThemeOptions | undefined;
  getSaml(): TenantSaml | undefined;
  getText(): TenantText | undefined;
  getName(): string;
  getHostname(): string;
  getLegalDiscalimer(): TenantLegalDisclaimer | undefined;
}

export interface IProps {
  children: React.ReactNode;
  client: ITenantClient;
}

export type TenantContextProps = ITenantContext | null;
