import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, shape, spacing, typography }) => {
  const successColor = palette.success.main;
  const errorColor = palette.error.main;

  return {
    root: {
      color: typography.body1.color,
      marginBottom: spacing(2)
    },
    policiesList: {
      padding: 0,
      margin: 0,
      listStyle: "none",

      "& &": {
        paddingLeft: spacing(4),

        "$flat &": {
          paddingLeft: 0
        }
      }
    },
    policyItem: {
      "&$valid": {
        color: successColor
      },
      "&$invalid": {
        color: errorColor
      },
      "& > span": {
        color: typography.body1.color,
        marginLeft: "-0.5rem"
      }
    },
    valid: {},
    invalid: {},
    flat: {}
  };
});
