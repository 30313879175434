import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Hidden, Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import parseHTML from "html-react-parser";

import { Button } from "components";
import routes from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import useStyles from "./side-panel.styles";

export type Variant = "register" | "login";
type VariantPropsMap = {
  [key in Variant]: {
    text: string;
    buttonLabel: string;
    buttonHref: string;
  };
};
export const variantProps: VariantPropsMap = {
  register: {
    text: "Don't have an account?",
    buttonLabel: "Register",
    buttonHref: routes.auth.register
  },
  login: {
    text: "Already have an account?",
    buttonLabel: "Sign in",
    buttonHref: routes.auth.login
  }
};

type Props = {
  variant: Variant;
};

const SidePanel: React.FC<Props> = ({ variant }) => {
  const classes = useStyles();
  const tenant = useTenant();

  const tenantText = tenant.getText();

  const isSAML = tenant.isComponentAllowed(AllowedComponents.SAML);
  const isNotRegister = variant !== "register";

  return (
    <Hidden xsDown>
      <aside
        className={clsx(
          classes.root,
          tenant.isComponentAllowed(AllowedComponents.LoginReversedColors) &&
            classes.rootReversedPalette
        )}
      >
        <div className={classes.container}>
          <Typography
            variant="h1"
            component="h2"
            color="inherit"
            classes={{ root: classes.title }}
          >
            {tenantText?.loginTitle
              ? parseHTML(tenantText.loginTitle)
              : "Hello!"}
          </Typography>

          <Hidden smDown>
            <Box mb={8}>
              <Typography variant="h4" component="h4" color="inherit">
                {tenantText?.loginDescription
                  ? parseHTML(tenantText.loginDescription)
                  : "NPWT provided by AT&T"}
              </Typography>
            </Box>
          </Hidden>

          {(!isSAML || isNotRegister) && (
            <Typography
              variant="body1"
              color="inherit"
              classes={{ root: classes.text }}
            >
              {variantProps[variant].text}
            </Typography>
          )}
          {(!isSAML || isNotRegister) && (
            <Button
              classes={{ root: classes.button }}
              variant="outlined"
              color="inherit"
              size="large"
              component={RouterLink}
              to={variantProps[variant].buttonHref}
            >
              {variantProps[variant].buttonLabel}
            </Button>
          )}

          <Hidden smDown>
            <Box mt={isSAML && !isNotRegister ? 0 : 6}>
              <Link
                className={classes.textButton}
                component={RouterLink}
                to={routes.auth.reportProblem}
                color="inherit"
              >
                Report a problem
              </Link>
            </Box>
          </Hidden>
        </div>
      </aside>
    </Hidden>
  );
};

export default SidePanel;
