import { makeStyles } from "@material-ui/core";

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  attachedFileContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: spacing(3),

    [breakpoints.only("xs")]: {
      marginBottom: spacing(3)
    }
  },
  header: {
    marginBottom: spacing(2)
  },
  gridContainer: {
    marginBottom: -spacing(2)
  },
  description: {
    wordBreak: "break-word"
  },
  attachedFileLink: {
    margin: spacing(0, 1),
    color: palette.secondary.main
  },
  gridItem: {
    flexGrow: 1,
    padding: "0 12px !important"
  }
}));
