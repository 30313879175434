import * as React from "react";
import clsx from "clsx";
import { Chip } from "@material-ui/core";

import { IconCheck, IconClose } from "assets/icons";

import useStyles from "./validation-chip.styles";

interface Props {
  isValid: boolean;
}

const ValidationChip: React.FC<Props> = ({ children, isValid }) => {
  const classes = useStyles();
  return (
    <Chip
      variant="outlined"
      icon={isValid ? <IconCheck /> : <IconClose />}
      label={children}
      className={clsx(classes.root, isValid ? classes.valid : classes.invalid)}
      classes={{
        root: classes.root,
        icon: classes.icon
      }}
    />
  );
};

export default ValidationChip;
