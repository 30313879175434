import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import {
  useFetchMutation,
  useFetch,
  FetchOptions,
  useUploadAPI
} from "utils/hooks/useFetch";
import apiConfig from "config/api";

export type CompaniesListFilters = {
  name?: string;
  membership__user__id?: string;
};

type CompaniesListVars = {
  listFilters?: ListFilters<CompaniesListFilters>;
  onlyRoots?: boolean;
  isMember?: boolean; //return only groups to wchich user is explicitly assigned
  courseId?: string;
  idIn?: string;
  learningPlanId?: string;
};

export type CompanyAdmin = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export type CompanySponsor = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export enum CompanyStatus {
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated"
}

export type CompanyItem = {
  id: string;
  name: string;
  parent: string;
  companyinfo?: {
    addresses: string[];
    thumbnailUrl?: string;
    attSponsorId?: string;
    attSponsor: CompanySponsor;
  };
  coursesCount: number;
  usersCount: number;
  subgroupsCount: number;
  admins: Array<CompanyAdmin>;
  externalId?: string;
  rootId?: string;
  voucherNumber?: string;
  status: CompanyStatus;
  companyDomain?: string;
};

export type CompaniesList = {
  count: number;
  data: Array<CompanyItem>;
};

export type CompanyCreateUpdateVars = {
  id?: string;
  name?: string;
  parent?: string;
  adminsIds?: string[];
  companyinfo?: {
    thumbnailUrl?: string;
    attSponsorId: string;
    addresses?: string[];
  };
  externalId?: string;
  voucherNumber?: string;
  companyDomain?: string;
};

type CompanyReadVars = {
  id: string;
};

export type UploadThumbnailVars = {
  thumbnail: File;
};
type ThumbnailItem = {
  data: string; //url
  path: string;
  statusCode: number;
  timestamp: string;
};

export const createCompaniesListConfig = ({
  listFilters,
  onlyRoots,
  isMember,
  courseId,
  idIn,
  learningPlanId
}: CompaniesListVars) =>
  PythonApi.makeRequestForList<CompanyItem>({
    method: "GET",
    url: apiConfig.paths.authorized.group.list,
    params: {
      ...(isMember && { is_member: true }),
      ...(onlyRoots && { level: 0 }),
      ...(courseId && { courses__id: courseId }),
      ...(idIn && { id__in: idIn }),
      ...(learningPlanId && { learningplans__ids: learningPlanId }),
      ...PythonApi.getParamsFromFilters(listFilters)
    }
  });

export const useCompaniesList = (options: FetchOptions<CompaniesListVars>) =>
  useFetch(createCompaniesListConfig(options.variables), options);

export const createCompanyCreateConfig = () =>
  PythonApi.makeMutation<CompanyCreateUpdateVars, CompanyItem>(data => ({
    method: "POST",
    url: apiConfig.paths.authorized.group.list,
    data
  }));

export const useCompanyCreate = () =>
  useFetchMutation(createCompanyCreateConfig());

export const createCompanyReadConfig = ({ id }: CompanyReadVars) =>
  PythonApi.makeRequestForOne<CompanyItem>({
    method: "GET",
    url: apiConfig.paths.authorized.group.details(id)
  });

export const useCompanyRead = (options: FetchOptions<CompanyReadVars>) =>
  useFetch(createCompanyReadConfig(options.variables), options);

export const createCompanyUpdateConfig = (groupId: string) =>
  PythonApi.makeMutation<CompanyCreateUpdateVars, CompanyItem>(data => ({
    method: "PUT",
    url: apiConfig.paths.authorized.group.details(groupId),
    data
  }));

export const useCompanyUpdate = ({ id }: { id: string }) =>
  useFetchMutation(createCompanyUpdateConfig(id));

export const companyDelete = () =>
  PythonApi.makeMutation<CompanyReadVars, CompanyItem>(({ id }) => ({
    method: "PUT",
    url: apiConfig.paths.authorized.group.details(id)
  }));

export const useCompanyDelete = () => useFetchMutation(companyDelete());

export const useCompanyThumbnailCreate = () => {
  type Vars = {
    storageUrl: string;
    tenantId: string;
    sasToken: string;
    uploadId: string;
    thumbnail: File;
  };
  return useUploadAPI(
    PythonApi.makeMutation<Vars>(
      ({ storageUrl, tenantId, sasToken, uploadId, thumbnail }) => {
        const formData = new FormData();
        const [, extension] = thumbnail.type.split("/");
        formData.append("file", thumbnail);

        return {
          baseURL: storageUrl,
          method: "PUT",
          url: apiConfig.paths.authorized.media.uploadThumbnail(
            tenantId,
            sasToken,
            uploadId,
            extension
          ),
          timeout: 0,
          data: formData
        };
      }
    )
  );
};

export const useCompanyStatusUpdate = (
  operationType: "activate" | "deactivate"
) => {
  type Vars = { id: string };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ id }) => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.group.changeStatus(id, operationType),
      data: {}
    }))
  );
};
