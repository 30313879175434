import * as React from "react";

import { CourseStatus } from "modules/courses/courses.constants";
import { IconDone, IconPlayArrow, IconReplay } from "assets/icons";
import Chip from "components/chip/chip";

import { MyLearningPlanStatus } from "../../../provider/my-learning-plans";

export type ChipVariant = "secondary" | "success" | "hint";

type StatusVariants = {
  [key in MyLearningPlanStatus]: {
    variant: ChipVariant;
    icon?: React.ReactElement;
    defaultLabel: string;
  };
};

export const statusVariants: StatusVariants = {
  [CourseStatus.COMPLETED]: {
    variant: "success",
    icon: <IconDone fontSize="small" />,
    defaultLabel: "Completed"
  },
  [CourseStatus.IN_PROGRESS]: {
    variant: "secondary",
    icon: <IconReplay fontSize="small" />,
    defaultLabel: "In progress"
  },
  [CourseStatus.NOT_STARTED]: {
    variant: "hint",
    icon: <IconPlayArrow fontSize="small" />,
    defaultLabel: "Not started"
  }
};

interface Props {
  status?: MyLearningPlanStatus;
  label?: string;
  className?: string;
}

const LearningPlanStatusChip: React.FC<Props> = ({
  status,
  label,
  className
}) => {
  if (!status) {
    return null;
  }
  const { icon, variant, defaultLabel } = statusVariants[status];

  return (
    <Chip
      {...{ icon, className, variant }}
      label={`${defaultLabel} ${label}`}
      size="small"
    />
  );
};

export default LearningPlanStatusChip;
