import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
  root: {
    border: "none",
    margin: spacing(0.5, 0),

    "&$withDelete": {
      margin: spacing(1)
    }
  },
  icon: {
    color: "inherit",
    fontSize: "inherit"
  },
  default: {
    backgroundColor: palette.hint.light,
    color: palette.hint.dark
  },
  primary: {
    backgroundColor: palette.primary.light,
    color: palette.primary.main
  },
  secondary: {
    backgroundColor: palette.secondary.light,
    color: palette.secondary.main
  },
  success: {
    backgroundColor: palette.success.light,
    color: palette.success.main
  },
  error: {
    backgroundColor: palette.error.light,
    color: palette.error.main
  },
  hint: {
    backgroundColor: palette.hint.light,
    color: palette.hint.main
  },
  info: {
    backgroundColor: palette.info.light,
    color: palette.info.main
  },
  withDelete: {}
}));
