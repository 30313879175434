import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router";
import { Grid } from "@material-ui/core";

import {
  Button,
  CourseStatusChip,
  EmptyListPlaceholder,
  LoadingPlaceholder,
  Pagination,
  ResponsiveTableWrapper
} from "components";
import { ListResultsMeta } from "services/api/shared";
import { LayoutType } from "components/layout-selector/layout-selector";
import { formatDuration } from "utils/datetime";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import useTableStyles from "components/data-table/data-table.styles";
import { CourseStatus, getColumnsWidthMap, courseSortableMap } from "modules/courses/courses.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import LearnerActionByStatus from "../course-learner-action.component";
import CourseCard from "../course-card/course-card";
import CourseCardCatalogue from "../course-card/course-card-catalogue";
import CourseCatalogueModal from "../course-catalogue-modal/course-catalogue-modal";
import PrerequisitesList from "../course-list-available/prerequisites-list";

import MyCoursesListToolbar from "./course-list-my-toolbar";
import {
  Grid as XGrid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  Sorting
} from '@devexpress/dx-react-grid';

type Props = {
  courses: MyCoursesListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
  loading: boolean;
  isHistoryView?: boolean;
};

const MyCoursesList: React.FC<Props> = ({
  courses,
  meta,
  listFilters,
  activeLayout,
  setActiveLayout,
  loading,
  isHistoryView = false
}) => {
  const { pathname } = useLocation();
  const tenant = useTenant();
  const tableClasses = useTableStyles();

  const [isOpenCatalogueModal, setIsOpenCatalogueModal] = React.useState(false);
  const [currentCourse, setCurrentCourse] = React.useState<MyCoursesListItem>();

  const isCourseCatalogue =
    !isHistoryView &&
    tenant.isComponentAllowed(AllowedComponents.CourseListForStudent);

  const isStartFromAvailable = tenant.isComponentAllowed(
    AllowedComponents.StartFromAvailable
  );

  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );

  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = React.useState<string[]>([]);

  React.useEffect(()=>{
    const hiddenColumns: string[] = [];
    if (!isCoursePrerequisites ||
      (isCourseCatalogue && !isStartFromAvailable)) {
      hiddenColumns.push("prerequisites")
    };
    if (isCourseCatalogue && !isStartFromAvailable) {
      hiddenColumns.push("actions")
    };

    setDefaultHiddenColumnNames(hiddenColumns)
  }, [isCoursePrerequisites, isCourseCatalogue, isStartFromAvailable])

  const [sorting, setSorting] = React.useState<any[]>([]);

  const [columnWidths, setColumnWidths] = React.useState<any>(getColumnsWidthMap(isHistoryView));

  return (
    <>
      {isCourseCatalogue && currentCourse && (
        <CourseCatalogueModal
          course={currentCourse}
          isOpen={isOpenCatalogueModal}
          handleClose={() => {
            setIsOpenCatalogueModal(false);
          }}
        />
      )}
      <MyCoursesListToolbar
        isHistoryView={isHistoryView}
        meta={meta}
        listFilters={listFilters}
        activeLayout={activeLayout}
        setActiveLayout={setActiveLayout}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          {activeLayout === "grid" ? (
            courses.length > 0 ? (
              <Grid container spacing={5}>
                {courses.map(course => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={`${course.id}-${course.statusTimestamp}`}
                  >
                    {isCourseCatalogue ? (
                      <CourseCardCatalogue
                        course={course}
                        onOpenModal={() => {
                          setCurrentCourse(course);
                          setIsOpenCatalogueModal(true);
                        }}
                      />
                    ) : (
                      <CourseCard
                        course={course}
                        isHistoryView={isHistoryView}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <EmptyListPlaceholder text="No courses" />
            )
          ) : (
            <ResponsiveTableWrapper>
              <XGrid
                rows={courses}
                columns={[
                  {
                    name: "name",
                    title: "Course name",
                    getCellValue: c => (
                      <Button
                        variant="text"
                        component={RouterLink}
                        to={
                          !isCourseCatalogue
                            ? isHistoryView
                              ? `${pathname}/${c.detailsId}`
                              : `${pathname}/${c.id}`
                            : undefined
                        }
                        onClick={
                          isCourseCatalogue
                            ? () => {
                                setCurrentCourse(c);
                                setIsOpenCatalogueModal(true);
                              }
                            : undefined
                        }
                      >
                        <span
                          className={clsx(
                            tableClasses.customText
                          )}
                        >
                          {c.name}
                        </span>
                      </Button>
                    )
                  },
                  {
                    name: "externalId",
                    title: "Course id",
                    getCellValue: c => (
                      <span
                        className={clsx(
                          tableClasses.ellipsis,
                          tableClasses.customText
                        )}
                      >
                        {c.externalId}
                      </span>
                    )
                  },
                  {
                    name: "status",
                    title: "Status",
                    getCellValue: ({ status }) => (
                      <CourseStatusChip status={status} />
                    ),
                  },
                  {
                    // TODO: refactor when data to send will be camelized
                    name: isHistoryView ? "status_timestamp" : "statusTimestamp",
                    title: "Completed date",
                    getCellValue: ({ status, statusTimestamp }) =>
                      status === CourseStatus.COMPLETED ||
                      status === CourseStatus.MANUALLY_COMPLETED
                        ? moment(statusTimestamp).format("L")
                        : "-"
                  },
                  {
                    name: "duration",
                    title: "Duration",
                    getCellValue: c =>
                      c.duration
                        ? formatDuration(c.duration * 1000, "H[h] mm[min]")
                        : "-"
                  },
                  {
                    name: "prerequisites",
                    title: "Prerequisites courses",
                    getCellValue: ({ prerequisiteCourses }) => (
                      <PrerequisitesList
                        prerequisitesCourses={prerequisiteCourses || []}
                      />
                    )
                  },
                  {
                    name: "actions",
                    title: "Actions",
                    getCellValue: ({
                      currentStatus,
                      status,
                      url,
                      id,
                      externalId,
                      assigned,
                      prerequisiteCourses
                    }) => (
                      <LearnerActionByStatus
                        {...{
                          currentStatus: currentStatus || status,
                          url,
                          id,
                          externalId,
                          assigned,
                          disabled:
                            prerequisiteCourses?.find((c:any) => !c.isFinished) &&
                            isCoursePrerequisites
                        }}
                      />
                    )
                  }
                ]}
              >  
              <SortingState
                columnExtensions={courseSortableMap}
                sorting={sorting as Sorting[]}
                onSortingChange={(e)=> {
                  setSorting(e)
                  const event = e[0];
                  listFilters!.setOrderBy([`${event.columnName},${event.direction.toUpperCase()}`]);
                }}
              />
              <IntegratedSorting />
              <Table />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
                resizingMode={"widget"}
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
              />   
            </XGrid>
            {courses.length === 0 && <EmptyListPlaceholder text="No courses" />}
            </ResponsiveTableWrapper>
          )}

          {meta && meta.total > 0 && (
            <Pagination
              totalCount={meta.total}
              onChange={listFilters.setPagination}
              rowsPerPage={listFilters.pagination.rowsPerPage}
              page={listFilters.pagination.page}
            />
          )}
        </>
      )}
    </>
  );
};

export default MyCoursesList;
