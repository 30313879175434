import * as React from "react";
import { SvgIcon, Typography } from "@material-ui/core";
import clsx from "clsx";

import { SuccessImage, ErrorImage } from "assets/icons";

import useStyles from "./fullscreen-message.styles";

type Variant = "success" | "error";
type Props = {
  message?: React.ReactNode;
  title: React.ReactNode;
  variant: Variant;
};

type VariantImages = {
  [key in Variant]: typeof SvgIcon;
};
const variantImages: VariantImages = {
  success: SuccessImage,
  error: ErrorImage
};

const FullscreenMessage: React.FC<Props> = ({ variant, title, message }) => {
  const Image = variantImages[variant];

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classes[variant])}>
      <div className={classes.imageContainer}>
        <Image className={classes.image} />
      </div>
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.title}
      >
        {title}
      </Typography>
      {message && (
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
      )}
    </div>
  );
};

export default FullscreenMessage;
