import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess, useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { useModuleState, actionTypes } from "./reducers/tenants";
import TenantsView from "./views/tenants-list.view";
import TenantDetailsView from "./views/tenant-details.view";

type Props = {
  match: match;
};

const TenantsRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  const auth = useAuth();
  const tenant = useTenant();
  const isPrimeCourseUploadLog = tenant.isComponentAllowed(
    AllowedComponents.PrimeCourseUploadLog
  );

  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_TENANTS_FILTERS });
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route
        path={match.path}
        exact
        render={() => (
          <HasAccess roles={[UserRole.ADMIN]}>
            <TenantsView />
          </HasAccess>
        )}
      />
      {isPrimeCourseUploadLog && auth.user?.isSuperAdmin && (
        <Route
          path={`${match.path}/:name/:hostname`}
          component={TenantDetailsView}
        />
      )}
      <Redirect to={match.path} />
    </Switch>
  );
};
export default TenantsRouter;
