import {
  mapKeys,
  camelCase,
  snakeCase,
  isPlainObject,
  mapValues,
  isArray,
  map
} from "lodash";

export const mapKeysDeep = (cb: (_: any, key: any) => string) => (
  obj: any
): any => {
  if (!isPlainObject(obj)) return obj;

  return mapValues(mapKeys(obj, cb), val => {
    if (isPlainObject(val)) return mapKeysDeep(cb)(val);
    if (isArray(val)) return map(val, v => mapKeysDeep(cb)(v));
    return val;
  });
};

export const cameliseDeep = (value: any) => {
  const cameliseFn = (_: any, key: any) => camelCase(key);
  return isArray(value)
    ? value.map(mapKeysDeep(cameliseFn))
    : mapKeysDeep(cameliseFn)(value);
};

export const snakeCaseDeep = (value: any) => {
  const snakeCaseFn = (_: any, key: any) => snakeCase(key);
  return isArray(value)
    ? value.map(mapKeysDeep(snakeCaseFn))
    : mapKeysDeep(snakeCaseFn)(value);
};
