import * as React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody
} from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell";

import { ListSelectionState } from "utils/hooks/useListSelection";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { EmptyListPlaceholder, LoadingPlaceholder } from "components";
import { IconCheckBox, IconCheckBoxOutlineBlank } from "assets/icons";

import ColumnHeader from "./column-header";
import useStyles from "./data-table.styles";

type Data = Record<string, any>;

export type Direction = "asc" | "desc";

export type OrderBy = {
  columnName?: string;
  direction?: Direction;
};

export type ColumnConfig<D> = {
  key: string;
  title: string;
  component?: TableCellProps["component"];
  scope?: TableCellProps["scope"];
  width?: string | number;
  className?: string | ((item: D) => string);
  render: (item: D) => React.ReactNode;
  skip?: boolean;
  sortable?: boolean;
  setWhiteSpaceNormal?: boolean;
};

export type Props<D extends Data> = {
  loading?: boolean;
  data: D[];
  columns: ColumnConfig<D>[];
  listSelection?: ListSelectionState<D>;
  listFilters?: ListFiltersState;
  rowIdKey?: keyof D;
  emptyText: string;
  sortable?: boolean;
  selectedRow?: D | undefined;
};

const DataTable = <T extends Data>(props: Props<T>) => {
  const {
    data,
    columns,
    listSelection,
    listFilters,
    loading,
    rowIdKey = "id",
    emptyText,
    sortable = false,
    selectedRow
  } = props;

  const classes = useStyles();
  const renderedColumns = columns.filter(c => !c.skip);
  const orderBy = sortable ? getOrderBy(listFilters) : undefined;

  const onSortChange = (columnName: string) => (direction: string) => {
    listFilters!.setOrderBy([`${columnName},${direction.toUpperCase()}`]);
  };

  if (loading) return <LoadingPlaceholder />;

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {listSelection && (
              <TableCell variant="head" padding="checkbox">
                <Checkbox
                  color="secondary"
                  icon={<IconCheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<IconCheckBox fontSize="small" />}
                  checked={listSelection.actions.isAllSelected()}
                  onChange={
                    listSelection.actions.isAllSelected()
                      ? listSelection.actions.unselectAll
                      : listSelection.actions.selectAll
                  }
                  role="select-all"
                />
              </TableCell>
            )}
            {renderedColumns.map(column => (
              <ColumnHeader
                key={column.key}
                sortable={sortable}
                column={column}
                orderBy={orderBy}
                onSortChange={onSortChange(column.key)}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(d => (
            <TableRow
              key={d[rowIdKey]}
              selected={
                listSelection ? listSelection.selected.has(d.id) : undefined
              }
              className={
                (selectedRow &&
                  selectedRow[rowIdKey] === d[rowIdKey] &&
                  classes.active) ||
                undefined
              }
              hover
            >
              {listSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    icon={<IconCheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<IconCheckBox fontSize="small" />}
                    checked={listSelection.selected.has(d.id)}
                    onChange={() => listSelection.actions.toggleOne(d)}
                  />
                </TableCell>
              )}
              {renderedColumns.map(column => (
                <TableCell
                  key={column.key}
                  variant="body"
                  className={
                    typeof column.className === "function"
                      ? column.className(d)
                      : column.className
                  }
                  component={column.component}
                  scope={column.scope}
                  style={{ width: column.width }}
                >
                  {column.render(d)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {data.length === 0 && <EmptyListPlaceholder text={emptyText} />}
    </>
  );
};

const getOrderBy = (listFilters?: ListFiltersState) => {
  if (
    !listFilters ||
    !listFilters.orderBy ||
    listFilters.orderBy.length === 0
  ) {
    return undefined;
  }

  const [columnName, direction] = listFilters.orderBy[0].split(",");
  return {
    columnName,
    direction: direction.toLowerCase()
  } as OrderBy;
};

export default DataTable;
