import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { useParams } from "react-router";

import * as PythonApi from "services/api/python";
import { Button, Header, FormError } from "components";
import useToast from "utils/hooks/useToast";

import { CourseItem } from "../provider/courses";
import { useCourseFromGroupDelete } from "../provider";

import useStyles from "./course-deletion-modal.styles";

type Props = {
  isOpen: boolean;
  course: CourseItem;
  onClose: () => void;
  refetch: () => void;
};
//TODO: make generic modal
const RemoveCourseFromGroupModal: React.FC<Props> = ({
  isOpen,
  course,
  onClose,
  refetch
}) => {
  const { companyId } = useParams();
  const classes = useStyles();
  const { showToast } = useToast();

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<
    string | PythonApi.ApiFieldError | string[]
  >();

  const deleteCourse = useCourseFromGroupDelete();

  const onDelete = async () => {
    setSubmitting(true);
    try {
      await deleteCourse({
        groupId: companyId!,
        courseId: course.id
      });
      onClose();
      showToast(
        `${course.name} has been successfully removed from group`,
        "success"
      );
      refetch();
    } catch (ex) {
      setError(PythonApi.getMessageFromError(ex));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Header
          title="Are you sure you want to remove this course from group?"
          variant="section"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" className={classes.contentText}>
          {`There are ${course.countInProgress ||
            "no"} users that are currently in progress.`}
        </DialogContentText>
        {error && <FormError center>{error}</FormError>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onDelete} color="error" disabled={submitting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveCourseFromGroupModal;
