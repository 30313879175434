import * as React from "react";
import { Typography, Avatar } from "@material-ui/core";

import useStyles from "./card.styles";

export type Props = {
  imgUrl?: string;
};

const CardLogo: React.FC<Props> = ({ imgUrl }) => {
  const classes = useStyles();

  return (
    <>
      {imgUrl ? (
        <Avatar src={imgUrl} alt="Company logo" className={classes.avatar} />
      ) : (
        <Typography className={classes.logoText} variant="h3">
          LOGO
        </Typography>
      )}
    </>
  );
};

export default CardLogo;
