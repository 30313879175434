import * as React from "react";

import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";

import { TenantItem } from "../../provider/tenants";

import TenantsList from "./tenants-list";
import TenantsListPagination from "./tenants-list-pagination";
import TenantsListToolbar from "./tenants-list-toolbar";

type TenantsListContainerProps = {
  loading: boolean;
  tenants: Array<TenantItem>;
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
};

const TenantsListContainer: React.FC<TenantsListContainerProps> = ({
  loading,
  tenants,
  meta,
  listFilters,
  refetch
}) => (
  <>
    {tenants.length > 1 && <TenantsListToolbar {...{ tenants, listFilters }} />}
    <TenantsList {...{ loading, tenants, listFilters, refetch }} />
    {tenants.length > 1 && <TenantsListPagination {...{ meta, listFilters }} />}
  </>
);

export default TenantsListContainer;
