import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { AllowedComponents, useTenant } from "services/tenant-provider";

import CourseDetailsMyView from "../views/course-details-my.view";
import { useModuleState, actionTypes } from "../reducers/available-courses";
import AvailableCoursesView from "../views/course-list-available.view";
import MyLearningPlanListView from "../views/learning-plan-list-my.view";
import MyLearningPlanDetailsView from "../views/learning-plan-my-details.view";

import HistoryRouter from "./history";

type Props = {
  match: match;
};

const AvailableCoursesRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_AVAILABLE_COURSES_FILTERS });
    },
    [dispatch]
  );
  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );
  return (
    <Switch>
      <Route path={`${match.path}/history`} component={HistoryRouter} />
      <Route exact path={match.path} component={AvailableCoursesView} />
      {isLearningPlans && !isAllowedTenantAvailableCourses && (
        <Route
          exact
          path={`${match.path}/my-learning-plan`}
          component={MyLearningPlanListView}
        />
      )}
      {isLearningPlans && !isAllowedTenantAvailableCourses && (
        <Route
          path={`${match.path}/my-learning-plan/:learningPlanId`}
          component={MyLearningPlanDetailsView}
        />
      )}
      <Route path={`${match.path}/:courseId`} component={CourseDetailsMyView} />

      <Redirect to={match.path} />
    </Switch>
  );
};

export default AvailableCoursesRouter;
