import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import clsx from "clsx";

import { DragIndicator } from "../learning-plan-form/courses-list";

import CourseChip from "./course-chip";
import ChipIndex from "./chip-index";
import useStyles from "./drag-n-drop.styles";

type Props = {
  onDragEnd: (result: DropResult) => void;
  items: { id: string; name: string }[];
  onRemove: (id: number) => void;
  maxLength?: "sm" | "none";
};

const DragNDrop: React.FC<Props> = ({
  onDragEnd,
  items,
  onRemove,
  maxLength = "none"
}) => {
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="chip-container" direction="vertical">
        {provided => (
          <div
            className={clsx(maxLength === "sm" && classes.sm)}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map(({ id, name }, index) => (
              <Draggable draggableId={id} index={index} key={id}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={classes.chipContainer}
                  >
                    <ChipIndex index={index} />
                    <span
                      className={clsx(
                        classes.chip,
                        maxLength === "sm" && classes.smChip
                      )}
                    >
                      <CourseChip
                        avatar={
                          <DragIndicator
                            draggableProps={provided.dragHandleProps}
                          />
                        }
                        label={name}
                        deleteIcon={true}
                        onDelete={() => onRemove(index)}
                      />
                    </span>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragNDrop;
