import { makeStyles } from "@material-ui/styles";

import { checkIfIE } from "utils/browser";

export default makeStyles(() => ({
  gridContainer: {
    "&>:nth-child(2)": {
      flexGrow: checkIfIE() ? 1 : 0,
      minWidth: checkIfIE() ? 350 : "auto"
    }
  }
}));
