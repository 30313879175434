import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import { ListResultsMeta } from "services/api/shared";
import {
  Button,
  Pagination,
  DataTable,
  ResponsiveTableWrapper,
  LoadingPlaceholder
} from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import useStyles from "components/data-table/data-table.styles";
import { CompanyItem } from "modules/companies/provider/companies";

import CompaniesListToolbar from "./companies-list-toolbar";

type Props = {
  groups: CompanyItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  loading: boolean;
};

const CompaniesList: React.FC<Props> = ({
  groups,
  meta,
  listFilters,
  loading
}) => {
  const classes = useStyles();
  return (
    <>
      <CompaniesListToolbar listFilters={listFilters} />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <ResponsiveTableWrapper>
            <DataTable
              emptyText="No companies"
              data={groups}
              columns={[
                {
                  key: "groupName",
                  title: "Company name",
                  render: ({ name, id }) => (
                    <Button
                      component={RouterLink}
                      to={`/companies/${id}`}
                      variant="text"
                    >
                      <span
                        className={clsx(classes.ellipsis, classes.customText)}
                      >
                        {name || "-"}
                      </span>
                    </Button>
                  )
                },
                {
                  key: "externalId",
                  title: "Company id",
                  render: ({ externalId }) => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {externalId || "-"}
                    </span>
                  )
                }
              ]}
            />
          </ResponsiveTableWrapper>

          {meta && meta.total > 0 && (
            <Pagination
              totalCount={meta.total}
              onChange={listFilters.setPagination}
              rowsPerPage={listFilters.pagination.rowsPerPage}
              page={listFilters.pagination.page}
            />
          )}
        </>
      )}
    </>
  );
};

export default CompaniesList;
