import { useSnackbar, VariantType } from "notistack";

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToast = (message: string, variant: VariantType) =>
    enqueueSnackbar(message, {
      variant: variant
    });

  const openPersistToast = (message: string, variant: VariantType) => {
    return enqueueSnackbar(message, {
      variant: variant,
      persist: true
    });
  };

  const closeToast = (key?: string | number) => {
    closeSnackbar(key);
  };

  return { showToast, openPersistToast, closeToast };
};

export default useToast;
