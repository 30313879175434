import * as React from "react";
import { Switch, Route, match } from "react-router-dom";

import HistoryRouter from "./routers/history";
import MyCoursesRouter from "./routers/my-courses";

type Props = {
  match: match;
};

const CoursesMyRouter: React.FC<Props> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/history`} component={HistoryRouter} />
    <Route path={match.path} component={MyCoursesRouter} />
  </Switch>
);

export default CoursesMyRouter;
