import React from "react";

import { ApiResultRenderer, AppLayout, Pagination } from "components";
import { useAuth } from "services/auth-provider";
import useListFilters from "utils/hooks/useListFilters";
import { useModuleState } from "modules/courses/reducers/my-courses";

import TabsCoursesLearningPlans from "../components/learning-plan/components/tabs-courses-learning-plans";
import {
  useModuleState as useLPModuleState,
  actionTypes as actionTypesLP
} from "../reducers/my-learning-plans";
import { useMyCoursesList, useMyLearningPlanList } from "../provider";
import MyLearningPlanToolbar from "../components/learning-plan/my-learning-plan-list/my-learning-plan-toolbar";
import MyLearningPlanList from "../components/learning-plan/my-learning-plan-list/my-learning-plan-list";
import { MyCoursesListFilters } from "../provider/my-courses";
import useStateWithLocalStorage from "../../../utils/hooks/useStateWithLocalStorage";
import { LayoutType } from "../../../components/layout-selector/layout-selector";
import storage from "../../../config/storage";

type Props = {};

const MyLearningPlanListView: React.FC<Props> = () => {
  const { user } = useAuth();
  const [{ myCoursesFilters }] = useModuleState();
  const listFilters = useListFilters<MyCoursesListFilters>(myCoursesFilters);
  const myCoursesList = useMyCoursesList({
    variables: { id: user!.id, listFilters }
  });

  const [{ myLearningPlansFilters }, dispatchLP] = useLPModuleState();
  const listFiltersLP = useListFilters(myLearningPlansFilters);
  const learningPlansList = useMyLearningPlanList({
    variables: { listFilters: listFiltersLP }
  });

  const [layout, setLayout] = useStateWithLocalStorage<LayoutType>({
    localStorageKey: storage.local.myCoursesLayoutPrefix + user!.id,
    defaultValue: "grid"
  });

  React.useEffect(() => {
    dispatchLP({
      type: actionTypesLP.SET_MY_LEARNING_PLAN_FILTERS,
      payload: listFiltersLP
    });
  }, [listFiltersLP, dispatchLP]);

  const meta = myCoursesList.meta;
  const metaLP = learningPlansList.meta;

  return (
    <AppLayout viewTitle="Courses">
      <TabsCoursesLearningPlans
        activeTab={1}
        coursesCount={meta?.total || 0}
        learningPlansCount={metaLP?.total || 0}
        panelsSet="student"
      >
        <ApiResultRenderer apiResult={learningPlansList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <>
              <MyLearningPlanToolbar
                listFilters={listFiltersLP}
                meta={meta!}
                activeLayout={layout}
                setActiveLayout={setLayout}
              />
              <MyLearningPlanList
                learningPlans={data}
                loading={loading}
                listFilters={listFiltersLP}
                meta={meta!}
                activeLayout={layout}
              />
              {meta && meta.total > 0 && (
                <Pagination
                  totalCount={1}
                  onChange={listFiltersLP.setPagination}
                  rowsPerPage={listFiltersLP.pagination.rowsPerPage}
                  page={listFiltersLP.pagination.page}
                />
              )}
            </>
          )}
        </ApiResultRenderer>
      </TabsCoursesLearningPlans>
    </AppLayout>
  );
};

export default MyLearningPlanListView;
