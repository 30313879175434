import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { CourseStatus } from "../courses.constants";

export type UserCoursesListFilters = {
  name?: string;
};

type UserCoursesListVars = {
  id: string;
  listFilters?: ListFilters<UserCoursesListFilters>;
};

export type UserCoursesListItem = {
  id: string;
  name: string;
  externalId: string;
  status?: CourseStatus;
  statusTimestamp?: string;
};

const createUserCoursesListConfig = ({
  id,
  listFilters
}: UserCoursesListVars) =>
  PythonApi.makeRequestForList<UserCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.listOfUser(id),
    params: PythonApi.getParamsFromFilters(listFilters, {
      name: "contains"
    })
  });

export const useUserCoursesList = (
  options: FetchOptions<UserCoursesListVars>
) => {
  return useFetch(
    createUserCoursesListConfig({ ...options.variables }),
    options
  );
};
