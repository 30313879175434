import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import clsx from "clsx";
import {
  Badge,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@material-ui/core";

import { HasAccess, useAuth } from "services/auth-provider";
import routes from "config/routes";
import { UserRole, userRoleOptions } from "modules/users/users.constants";
import { actionTypes, useModuleState } from "modules/common/reducers/common";
import useIndicatorsInitializer from "modules/common/hooks/useIndicatorsInitializer";
import { IndicatorsItem } from "modules/common/provider/indicators";
import {
  IconAddToPhotos,
  IconBooks,
  IconBusinessOutlined,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconFeaturedPlayListOutlined,
  IconHistoryOutlined,
  IconPeople,
  IconReportProblem,
  IconSubject,
  IconToc,
  IconVideoLibraryOutlined
} from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import SidebarAvatar from "./sidebar-avatar";
import LogOut from "./logout.component";
import useStyles from "./sidebar.styles";

type NavItem = {
  href: string;
  exact: boolean;
  label: string;
  icon: React.ComponentType;
  roles?: Array<UserRole>;
  componentAccess?: Array<AllowedComponents>;
  componentAccessRestrict?: Array<AllowedComponents>;
  notActive?: string[];
  withBadge?: boolean;
  badgeContent?: number;
};

const getNavItems = (indicators?: IndicatorsItem): NavItem[] => [
  {
    href: routes.myCourses.list,
    exact: false,
    label: "My Courses",
    icon: IconVideoLibraryOutlined,
    componentAccessRestrict: [AllowedComponents.CourseListForStudent],
    roles: [UserRole.STUDENT, UserRole.TENANT_ADMIN],
    notActive: [routes.myCourses.history]
  },
  {
    href: routes.myCourses.list,
    exact: false,
    label: "My Courses",
    icon: IconVideoLibraryOutlined,
    componentAccess: [AllowedComponents.CourseListForStudent],
    roles: [UserRole.STUDENT, UserRole.TENANT_ADMIN],
    notActive: [routes.myCourses.history]
  },
  {
    href: routes.myCourses.available,
    exact: false,
    label: "Available courses",
    icon: IconAddToPhotos,
    componentAccess: [AllowedComponents.CourseListForStudent],
    roles: [UserRole.STUDENT, UserRole.TENANT_ADMIN, UserRole.INTERNAL_ADMIN],
    notActive: [routes.myCourses.availableHistory]
  },
  {
    href: routes.myCourses.history,
    exact: true,
    label: "History",
    componentAccessRestrict: [AllowedComponents.CourseListForStudent],
    icon: IconHistoryOutlined,
    roles: [UserRole.STUDENT, UserRole.TENANT_ADMIN]
  },
  {
    href: routes.myCourses.availableHistory,
    exact: false,
    label: "History",
    componentAccess: [AllowedComponents.CourseListForStudent],
    icon: IconHistoryOutlined,
    roles: [UserRole.STUDENT, UserRole.TENANT_ADMIN]
  },
  {
    href: routes.courses.list,
    exact: false,
    label: "Courses",
    icon: IconFeaturedPlayListOutlined,
    roles: [UserRole.ADMIN, UserRole.DESIGNER],
    notActive: [routes.courses.approval.list]
  },
  {
    href: routes.users.list,
    exact: false,
    label: "Users",
    icon: IconPeople,
    roles: [UserRole.ADMIN, UserRole.TENANT_ADMIN],
    withBadge: true,
    badgeContent: indicators?.users ?? 0
  },
  {
    href: routes.companies.list,
    exact: false,
    label: "Companies",
    icon: IconBusinessOutlined,
    roles: [UserRole.ADMIN, UserRole.TENANT_ADMIN]
  },
  {
    href: routes.courses.approval.list,
    exact: true,
    label: "Manual completions",
    icon: IconCheck,
    roles: [UserRole.ADMIN, UserRole.TENANT_ADMIN],
    withBadge: true,
    badgeContent: indicators?.manualCompletions ?? 0
  },
  {
    href: routes.myCourses.list,
    exact: false,
    label: "My Courses",
    icon: IconVideoLibraryOutlined,
    roles: [UserRole.ADMIN, UserRole.DESIGNER],
    notActive: [routes.myCourses.history]
  },
  {
    href: routes.myCourses.available,
    exact: false,
    label: "Available courses",
    icon: IconAddToPhotos,
    componentAccess: [AllowedComponents.CourseListForStudent],
    roles: [UserRole.ADMIN, UserRole.DESIGNER],
    notActive: [routes.myCourses.history]
  },
  {
    href: routes.myCourses.history,
    exact: false,
    label: "History",
    icon: IconHistoryOutlined,
    roles: [UserRole.ADMIN, UserRole.DESIGNER]
  },
  {
    href: routes.problems.list,
    exact: false,
    label: "Problems",
    icon: IconSubject,
    roles: [UserRole.ADMIN, UserRole.TENANT_ADMIN],
    notActive: [routes.problems.report],
    withBadge: true,
    badgeContent: indicators?.tickets ?? 0
  },
  {
    href: routes.problems.report,
    exact: false,
    label: "Report a problem",
    icon: IconReportProblem
  },
  {
    href: routes.tenant.list,
    exact: false,
    label: "Tenants",
    icon: IconToc,
    roles: [UserRole.ADMIN]
  },
  {
    href: routes.reports.list,
    componentAccess: [AllowedComponents.ReportsTab],
    exact: false,
    label: "Reports",
    icon: IconBooks,
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN, UserRole.DESIGNER]
  }
];

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const Sidebar: React.FC<Props> = ({ onClose, isOpen }) => {
  const classes = useStyles();
  const auth = useAuth();
  const tenant = useTenant();

  const { user } = auth;
  const isTenantAdmin = auth.checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isLearner = auth.checkAccess({ roles: [UserRole.STUDENT] });
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });

  useIndicatorsInitializer({ skip: isLearner || isDesigner });
  const [
    { thumbnailUrl, isSidebarExpanded, indicators },
    dispatch
  ] = useModuleState();

  // Sidebar rerenders on every nav item click, this useEffect will run once per render
  // And that's what I want, to fetch indicators once on every view change
  React.useEffect(() => {
    (isAdmin || isTenantAdmin) &&
      dispatch({
        type: actionTypes.SET_SHOULD_REFETCH_INDICATORS,
        payload: true
      });
  }, [dispatch, isAdmin, isTenantAdmin]);

  const toggleSidebar = React.useMemo(
    () => () => {
      dispatch({
        type: actionTypes.SET_SIDEBAR_EXPAND,
        payload: !isSidebarExpanded
      });
    },
    [dispatch, isSidebarExpanded]
  );

  React.useEffect(() => {
    !Boolean(thumbnailUrl) &&
      dispatch({
        type: actionTypes.SET_THUMBNAIL,
        payload: user!.thumbnailUrl || ""
      });
  }, [dispatch, thumbnailUrl, user]);

  const drawer = (
    <>
      <Hidden smUp>
        <div className={classes.arrowButtonContainer}>
          <IconButton onClick={onClose} classes={{ root: classes.arrowButton }}>
            <IconChevronLeft />
          </IconButton>
        </div>
      </Hidden>

      <Hidden mdDown>
        <div
          className={clsx(
            classes.arrowButtonContainer,
            !isSidebarExpanded && classes.narrowed
          )}
        >
          <IconButton
            onClick={toggleSidebar}
            classes={{ root: classes.arrowButton }}
          >
            {isSidebarExpanded ? <IconChevronLeft /> : <IconChevronRight />}
          </IconButton>
        </div>
      </Hidden>

      <SidebarAvatar
        text={
          isLearner && user!.companyName
            ? user!.companyName
            : userRoleOptions.filter(e => e.value === user!.role)[0].label
        }
        imgUrl={thumbnailUrl}
        hideAvatar={!isLearner}
        expandedSidebar={isSidebarExpanded}
      />
      <List component="div" className={classes.navLinks}>
        {getNavItems(indicators)
          .filter(
            // filter componentAccessRestrict
            e =>
              e.componentAccessRestrict
                ? !tenant.isComponentAllowed(e.componentAccessRestrict)
                : true
          )
          .map((item, index) => (
            <HasAccess
              key={index}
              roles={item.roles}
              componentAccess={item.componentAccess}
            >
              <ListItemLink
                exact={item.exact}
                href={item.href}
                notActive={item.notActive}
                className={classes.navLink}
                label={item.label}
                isSidebarExpanded={isSidebarExpanded}
              >
                <ListItemIcon className={classes.navLinkIcon}>
                  {item.withBadge ? (
                    <Badge badgeContent={item.badgeContent} color="error">
                      <item.icon />
                    </Badge>
                  ) : (
                    <item.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: clsx(
                      classes.navLinkText,
                      !isSidebarExpanded && classes.narrowed
                    )
                  }}
                >
                  {item.label}
                </ListItemText>
              </ListItemLink>
            </HasAccess>
          ))}
        <p>{process.env.REACT_APP_SENTRY_RELEASE}</p>
      </List>
    </>
  );

  return (
    <>
      {/* MOBILE */}
      <Hidden smUp>
        <nav>
          <Drawer
            open={isOpen}
            onClose={onClose}
            classes={{ paper: clsx(classes.drawer, classes.drawerMobile) }}
            BackdropProps={{ classes: { root: classes.drawerBackdrop } }}
            ModalProps={{ keepMounted: true }}
          >
            <div className={classes.drawerContainer}>
              <div className={classes.drawerContent}>{drawer}</div>
              <LogOut />
            </div>
          </Drawer>
        </nav>
      </Hidden>

      {/* TABLET */}
      <Hidden only={["xs", "lg", "xl"]}>
        <nav className={classes.rootNavTablet}>
          <Drawer
            variant="permanent"
            classes={{ paper: clsx(classes.drawer, classes.drawerTablet) }}
          >
            <div className={classes.drawerContainer}>
              <div
                className={clsx(
                  classes.drawerContent,
                  classes.drawerContentTablet
                )}
              >
                {drawer}
              </div>
            </div>
          </Drawer>
        </nav>
      </Hidden>

      {/* DESKTOP - EXPANDABLE */}
      <Hidden mdDown>
        <nav
          className={clsx(
            classes.rootNavDesktop,
            isSidebarExpanded && classes.expanded
          )}
        >
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawer,
                classes.drawerDesktop,
                isSidebarExpanded && classes.expanded
              )
            }}
          >
            <div className={classes.drawerContainer}>
              <div
                className={clsx(
                  classes.drawerContent,
                  classes.drawerContentDesktop,
                  isSidebarExpanded && classes.expanded
                )}
              >
                {drawer}
              </div>
            </div>
          </Drawer>
        </nav>
      </Hidden>
    </>
  );
};

type ListItemLinkProps = {
  exact: boolean;
  href: string;
  notActive?: string[];
  className: string;
  label: string;
  isSidebarExpanded: boolean;
};
const ListItemLink: React.FC<ListItemLinkProps> = ({
  href,
  exact,
  notActive = [],
  className,
  label,
  isSidebarExpanded,
  ...props
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      to={href}
      className={className}
      component={React.forwardRef((props: NavLinkProps, ref: any) => (
        <Tooltip
          title={label}
          placement="right"
          classes={{ tooltip: classes.tooltip }}
          disableHoverListener={isSidebarExpanded}
        >
          <NavLink
            {...props}
            exact={exact}
            to={href}
            innerRef={ref}
            isActive={(match, location) =>
              Boolean(
                !!match &&
                  Boolean(
                    !notActive.some(route => location.pathname.includes(route))
                  )
              )
            }
            activeClassName={classes.navLinkActive}
          />
        </Tooltip>
      ))}
      {...props}
    />
  );
};

export default Sidebar;
