import { useAuth } from "services/auth-provider";
import { useModuleState, actionTypes } from "modules/common/reducers/common";
import { UserRole } from "modules/users/users.constants";

const useSetShouldRefetchIndicatorsToTrue = () => {
  const auth = useAuth();
  const shouldDispatch = auth.checkAccess({
    roles: [UserRole.ADMIN, UserRole.TENANT_ADMIN]
  });

  const [, dispatch] = useModuleState();
  return shouldDispatch
    ? () =>
        dispatch({
          type: actionTypes.SET_SHOULD_REFETCH_INDICATORS,
          payload: true
        })
    : () => {};
};

export default useSetShouldRefetchIndicatorsToTrue;
