import { EnumOptions } from "utils/constants";

export enum UserRole {
  STUDENT = "Student",
  TENANT_ADMIN = "TenantAdmin",
  INTERNAL_ADMIN = "InternalAdmin",
  ADMIN = "Admin",
  DESIGNER = "Designer"
}

export enum UserStatus {
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
  PENDING = "Pending"
}

export const userRoleOptions: EnumOptions<UserRole> = [
  { value: UserRole.STUDENT, label: "Student" },
  { value: UserRole.TENANT_ADMIN, label: "External Administrator" },
  { value: UserRole.INTERNAL_ADMIN, label: "Internal Administrator" },
  { value: UserRole.ADMIN, label: "Site Administrator" },
  { value: UserRole.DESIGNER, label: "Designer" }
];

export const userStatusOptions: EnumOptions<UserStatus> = [
  { value: UserStatus.ACTIVE, label: "Active" },
  { value: UserStatus.DEACTIVATED, label: "Deactivated" },
  { value: UserStatus.PENDING, label: "Pending" }
];
