import * as React from "react";

import { useAuth } from "services/auth-provider";
import GroupRowActions from "modules/companies/components/group-list/group-list-row-actions";
import CompanyRowActions from "modules/companies/components/companies-list/companies-list-row-actions";
import { CompanyItem } from "modules/companies/provider/companies";
import { UserRole } from "modules/users/users.constants";

type CompanyDetailsHeaderCtaProps = {
  company?: CompanyItem;
  refetch: () => void;
};

const CompanyDetailsHeaderCta: React.FC<CompanyDetailsHeaderCtaProps> = ({
  company,
  refetch
}) => {
  const { checkAccess } = useAuth();
  const isAdmin = checkAccess({ roles: [UserRole.ADMIN] });

  if (company && company.parent) {
    return <GroupRowActions group={company} refetch={refetch} />;
  } else if (company && isAdmin) {
    return <CompanyRowActions company={company} refetch={refetch} />;
  }
  return null;
};

export default CompanyDetailsHeaderCta;
