import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import { ListResultsMeta } from "services/api/shared";
import {
  Button,
  Pagination,
  DataTable,
  ResponsiveTableWrapper,
  TooltipList,
  LoadingPlaceholder
} from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { pluralize } from "utils/strings";
import useStyles from "components/data-table/data-table.styles";

import { CompaniesListTree as CompaniesListTreeType } from "../../provider/companies-list-tree";

import GroupListRowActions from "./group-list-row-actions";
import GroupListToolbar from "./group-list-toolbar.component";

type Props = {
  groups: CompaniesListTreeType[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
};

const GroupsList: React.FC<Props> = ({
  groups,
  meta,
  listFilters,
  refetch,
  loading
}) => {
  const classes = useStyles();
  return (
    <>
      <GroupListToolbar
        groups={groups}
        refetch={refetch}
        listFilters={listFilters}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <ResponsiveTableWrapper>
            <DataTable
              emptyText="No subgroups in this group"
              data={groups}
              columns={[
                {
                  key: "groupName",
                  title: "Group name",
                  render: g => (
                    <Button
                      component={RouterLink}
                      to={`/companies/${g.id}`}
                      variant="text"
                    >
                      <span
                        className={clsx(classes.ellipsis, classes.customText)}
                      >
                        {g.name}
                      </span>
                    </Button>
                  )
                },
                {
                  key: "externalId",
                  title: "Group id",
                  render: ({ externalId }) => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {externalId ? externalId : "-"}
                    </span>
                  )
                },
                {
                  key: "users",
                  title: "Users",
                  render: g => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {g.usersCount ? g.usersCount : "-"}
                    </span>
                  )
                },
                {
                  key: "subgroups",
                  title: "Subgroups",
                  render: g =>
                    g.subgroups.length ? (
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <span
                          className={clsx(classes.ellipsis, classes.customText)}
                        >
                          {g.subgroups[0].name}
                        </span>
                        {g.subgroups.length > 1 && (
                          <TooltipList
                            title={`${g.subgroups.length} ${pluralize(
                              "subgroup",
                              g.subgroups.length
                            )}`}
                            list={g.subgroups.map(sg => sg.name)}
                          />
                        )}
                      </div>
                    ) : (
                      "-"
                    )
                },
                {
                  key: "actions",
                  title: "Actions",
                  width: "1%",
                  render: group => (
                    <GroupListRowActions {...{ group, refetch }} />
                  )
                }
              ]}
            />
          </ResponsiveTableWrapper>

          {meta && meta.total > 0 && (
            <Pagination
              totalCount={meta.total}
              onChange={listFilters.setPagination}
              rowsPerPage={listFilters.pagination.rowsPerPage}
              page={listFilters.pagination.page}
            />
          )}
        </>
      )}
    </>
  );
};

export default GroupsList;
