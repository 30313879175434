import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing, breakpoints }: CustomTheme) => ({
  searchHeader: {
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  searchDiv: {
    width: "100%",
    marginBottom: spacing(3),
    [breakpoints.up("sm")]: {
      width: "70%",
      marginBottom: 0
    },
    [breakpoints.up("md")]: {
      display: "none"
    }
  }
}));
