import * as React from "react";

import { useAuth } from "services/auth-provider";
import {
  useModuleState,
  actionTypes
} from "modules/courses/reducers/my-courses";
import { AppLayout, ApiResultRenderer, DisclaimerModal } from "components";
import { LayoutType } from "components/layout-selector/layout-selector";
import useListFilters from "utils/hooks/useListFilters";
import {
  useMyCoursesList,
  MyCoursesListFilters
} from "modules/courses/provider/my-courses";
import useStateWithLocalStorage from "utils/hooks/useStateWithLocalStorage";
import storage from "config/storage";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import MyCoursesList from "../components/course-list-my/course-list-my";
import TabsCoursesLearningPlans from "../components/learning-plan/components/tabs-courses-learning-plans";
import { useModuleState as useLPModuleState } from "../reducers/my-learning-plans";
import { useMyLearningPlanList } from "../provider";

type Props = {};

const MyCoursesView: React.FC<Props> = () => {
  const { user } = useAuth();
  const tenant = useTenant();
  const isMinPagination = tenant.isComponentAllowed(
    AllowedComponents.MinPagination
  );
  const [{ myCoursesFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters<MyCoursesListFilters>(
    isMinPagination
      ? {
          ...myCoursesFilters,
          pagination: { rowsPerPage: 5 }
        }
      : myCoursesFilters
  );
  const myCoursesList = useMyCoursesList({
    variables: { id: user!.id, listFilters }
  });

  const [{ myLearningPlansFilters }] = useLPModuleState();
  const listFiltersLP = useListFilters(myLearningPlansFilters);
  const myLearningPlansList = useMyLearningPlanList({
    variables: { listFilters: listFiltersLP }
  });

  const [layout, setLayout] = useStateWithLocalStorage<LayoutType>({
    localStorageKey: storage.local.myCoursesLayoutPrefix + user!.id,
    defaultValue: "grid"
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_MY_COURSES_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  const isLegalDisclaimer = tenant.isComponentAllowed(
    AllowedComponents.LegalDisclaimer
  );
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );

  const meta = myCoursesList.meta;
  const metaLP = myLearningPlansList.meta;

  return (
    <AppLayout viewTitle="Courses" hideSmUp={!isLearningPlans}>
      {isLegalDisclaimer && <DisclaimerModal />}
      {isLearningPlans ? (
        <TabsCoursesLearningPlans
          learningPlansCount={metaLP ? metaLP.total : 0}
          coursesCount={meta ? meta.total : 0}
          activeTab={0}
          panelsSet="student"
        >
          <ApiResultRenderer apiResult={myCoursesList} reloadsSection={false}>
            {({ data = [], meta, loading }) => (
              <MyCoursesList
                courses={data}
                meta={meta!}
                listFilters={listFilters}
                activeLayout={layout}
                setActiveLayout={setLayout}
                loading={loading}
              />
            )}
          </ApiResultRenderer>
        </TabsCoursesLearningPlans>
      ) : (
        <ApiResultRenderer apiResult={myCoursesList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <MyCoursesList
              courses={data}
              meta={meta!}
              listFilters={listFilters}
              activeLayout={layout}
              setActiveLayout={setLayout}
              loading={loading}
            />
          )}
        </ApiResultRenderer>
      )}
    </AppLayout>
  );
};

export default MyCoursesView;
