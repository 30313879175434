import * as React from "react";
import { IconButton } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import clsx from "clsx";

import { IconViewList, IconViewModule } from "assets/icons";

import useStyles from "./layout-selector.styles";

export type LayoutType = "list" | "grid";
type LayoutConfig = {
  key: LayoutType;
  icon: React.ComponentType<SvgIconProps>;
  label: string;
};

const layoutViews: LayoutConfig[] = [
  {
    key: "list",
    icon: IconViewList,
    label: "List View"
  },
  {
    key: "grid",
    icon: IconViewModule,
    label: "Grid View"
  }
];

type Props = {
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
};

const LayoutSelector: React.FC<Props> = ({ activeLayout, setActiveLayout }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconsList}>
      {layoutViews.map(item => (
        <IconButton
          key={item.key}
          onClick={() => setActiveLayout(item.key)}
          title={item.label}
          classes={{
            root: clsx(
              classes.button,
              activeLayout === item.key && classes.buttonActive
            )
          }}
        >
          <item.icon classes={{ root: classes.icon }} />
        </IconButton>
      ))}
    </div>
  );
};

export default LayoutSelector;
