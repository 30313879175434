import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import {
  FetchOptions,
  useFetch,
  useFetchMutation,
  useUploadAPI
} from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { CourseStatus } from "../courses.constants";

export type GroupItem = {
  id: string; // UUID
  name: string; // max len 255
  externalId: string;
  parent?: string;
  companyinfo: {
    addresses: Array<string>;
  };
};

export type CoursesListFilters = {
  name?: string;
  created_at?: string;
};

type CoursesListVars = {
  listFilters?: ListFilters<CoursesListFilters>;
  fields?: string;
};

export type PrerequisiteCourse = {
  id: string;
  name: string;
  isFinished: boolean;
};

export type CourseItem = {
  id: string;
  externalId: string;
  name: string;
  url: string | null;
  createdAt: string;
  updatedAt: string;
  description: string;
  isPublished: boolean;
  duration?: number;
  users?: number;
  thumbnailUrl: string | null;
  links?: Array<{
    url: string;
    title: string;
  }>;
  notes: string;
  deliveryMethod?: string;
  frequencyInMoths?: number;
  frequencyInYears?: number;
  prerequisiteType?: string;
  startDate?: string;
  vendorCode?: string;
  externalBusinessUnitAccess?: string;
  isAllowLearnerCompletions?: boolean;
  primeStatus?: string;
  courseOwner?: CourseOwner;
  deleted?: boolean;
  plainUrl: string;
  groupsIds?: Array<string>;
  status: CourseStatus;
  countInProgress: number;
  assignedUsers: number;
  groups: number | Array<GroupItem>;
  company: string;
  prerequisiteCourses: PrerequisiteCourse[];
};

export type CourseHistory = {
  count: number;
  data: CourseHistoryItem[];
};

export type CourseHistoryItem = {
  changes: any;
  historyDate: string;
  historyId: number;
  historyType: string;
  historyUser: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phone: string;
  };
  change: CourseChange;
};

export type CourseChange = {
  field: string;
  old: string;
  new: string;
};

export type CourseOwner = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  workAddress: string;
};

type CourseReadVars = {
  courseId: string;
};

type CoursesHistoryListVars = {
  courseId: string;
  listFilters?: ListFilters<CoursesListFilters>;
};

export const useCoursesList = (options: FetchOptions<CoursesListVars>) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<CourseItem>({
      method: "GET",
      url: apiConfig.paths.authorized.course.list,
      params: PythonApi.getParamsFromFilters(listFilters, {
        name: "contains",
        created_at: "gte"
      })
    }),
    options
  );
};

export const useLightCoursesList = (options: FetchOptions<CoursesListVars>) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<CourseItem>({
      method: "GET",
      url: apiConfig.paths.authorized.course.lightList,
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};

export const useCourseRead = (options: FetchOptions<CourseReadVars>) => {
  const {
    variables: { courseId }
  } = options;

  return useFetch(
    PythonApi.makeRequestForOne<CourseItem>({
      method: "GET",
      url: apiConfig.paths.authorized.course.details(courseId)
    }),
    options
  );
};

export const useCourseHistory = (
  options: FetchOptions<CoursesHistoryListVars>
) => {
  const {
    variables: { courseId, listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForOne<CourseHistory>({
      method: "GET",
      url: apiConfig.paths.authorized.course.history(courseId),
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};

export const useCourseDelete = () => {
  type Vars = {
    id: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ id }) => ({
      method: "DELETE",
      url: apiConfig.paths.authorized.course.details(id)
    }))
  );
};

export const useCoursePublicationStatusUpdate = (
  operationType: "publish" | "unpublish"
) => {
  type Vars = {
    courses: string[];
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.course.listCoursesUpdateStatus(
        operationType
      ),
      data: variables
    }))
  );
};

export const useUploadCourseFile = () => {
  type Vars = {
    storageUrl: string;
    courseId: string;
    tenantId: string;
    sasToken: string;
    uploadId: string;
    file: File;
  };
  return useUploadAPI(
    PythonApi.makeMutation<Vars>(variables => {
      const formData = new FormData();
      formData.append("file", variables.file);

      return {
        baseURL: variables.storageUrl,
        method: "PUT",
        url: apiConfig.paths.authorized.media.uploadCourse(
          variables.courseId,
          variables.tenantId,
          variables.sasToken,
          variables.uploadId
        ),
        timeout: 0,
        data: formData
      };
    })
  );
};

export function useCourseCreate() {
  type Vars = {
    name: string;
    description: string;
    duration: number;
    thumbnailUrl?: string;
    externalId: string;
    isAllowLearnerCompletions: boolean;
    notes: string;
    links: Array<{
      title: string;
      url: string;
    }>;
    primeStatus?: string;
    courseOwner?: string; // ID
    groupsIds?: Array<string>;
  };
  type Item = {
    id: string;
    name: string;
    description: string;
    thumbnailUrl: string | null;
    externalId: string;
    isAllowLearnerCompletions: boolean;
    notes: string | null;
    links: Array<{
      title: string;
      url: string;
    }>;
    courseOwner: string; // ID
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.create,
      data: variables
    }))
  );
}

export function useCourseUpdate(courseId: string) {
  type Vars = {
    name: string;
    description: string;
    duration: number;
    externalId: string;
    isAllowLearnerCompletions: boolean;
    notes: string;
    links: Array<{
      title: string;
      url: string;
    }>;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "PATCH",
      url: apiConfig.paths.authorized.course.details(courseId),
      data: variables
    }))
  );
}

export function useUploadPrimeFileMutation() {
  type Vars = {
    file: File;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ file }) => {
      const data = new FormData();
      data.append("file", file);

      return {
        method: "POST",
        url: apiConfig.paths.authorized.course.uploadCourseList,
        data
      };
    })
  );
}
