import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    borderRadius: 10,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: palette.background.default,
    padding: spacing(3, 2),
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [breakpoints.up("sm")]: {
      padding: spacing(9, 7, 6),
      width: 750,
      height: "auto"
    }
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    marginBottom: spacing(4)
  },
  articlesContainer: {
    height: "62%",
    overflowY: "scroll",
    overflowX: "hidden",
    marginBottom: spacing(2),
    [breakpoints.up("sm")]: {
      height: 290
    }
  },
  articlesContainerHighMobile: {
    height: "70%"
  },
  disclaimerArticle: {
    color: palette.text.default,
    fontSize: "1rem",
    marginBottom: spacing(3)
  },
  buttonContainer: {
    marginTop: spacing(4),
    textAlign: "right"
  },
  label: {
    fontWeight: 500
  }
}));
