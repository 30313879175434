import * as React from "react";
import { useFormState } from "react-final-form";

import { useAuth } from "services/auth-provider";
import { FormError } from "components";
import { UserRole } from "modules/users/users.constants";

import UserDetailsSection from "./form-sections/user-details";
import UserGroupSection from "./form-sections/user-group";
import UserRoleSection from "./form-sections/user-role";
import UserActivationSection from "./form-sections/user-activation";

type UserFormBodyProps = {
  userId: string | null;
};

const UserFormBody: React.FC<UserFormBodyProps> = ({ userId }) => {
  const { submitError } = useFormState({
    subscription: { submitError: true }
  });
  const auth = useAuth();

  const canChooseRole = auth.checkAccess({
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN]
  });

  const formSections = React.useMemo(
    () => [
      UserDetailsSection,
      ...(canChooseRole ? [UserRoleSection] : []),
      UserGroupSection
    ],
    [canChooseRole]
  );

  return (
    <>
      {submitError && <FormError>{submitError}</FormError>}
      {formSections.map((FormSection, sectionIndex) => (
        <FormSection
          userId={userId}
          key={sectionIndex}
          sectionIndex={sectionIndex + 1}
        />
      ))}
      <UserActivationSection />
    </>
  );
};

export default UserFormBody;
