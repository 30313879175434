import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  dialogPaper: {
    minWidth: 350,
    padding: spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  titleWrap: {
    paddingTop: 0
  },
  title: {
    lineHeight: 1.6
  },
  contentText: {
    color: palette.text.default,
    fontSize: "0.9rem"
  },
  dialogActions: {
    marginTop: spacing(2),
    width: "65%",
    justifyContent: "space-around"
  }
}));
