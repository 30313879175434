import { AxiosError } from "axios";
import { FORM_ERROR } from "final-form";
import _ from "lodash";

import { AnyFilters, ListFilters } from "utils/hooks/useListFilters";
import { joinWithDots } from "utils/strings";
import { BASE_URLS } from "services/api";
import {
  FetcherConfig,
  MutationConfigBuilder,
  MutationMakerConfig,
  UseFetchConfig,
  UseFetchData
} from "utils/hooks/useFetch";

import {
  ApiErrorResponse,
  ApiFieldError,
  ApiFormValidationResult,
  ApiGeneralError,
  FilterOperator,
  ListPayload,
  ListResults,
  ParsedListResult,
  SingleItemPayload
} from "./python.types";
import { isNetworkError } from "./shared";

export * from "./python.types";

export const parseListResults = <D>(
  results?: ListResults<D> | D[]
): ParsedListResult<D> => {
  if (!results) {
    return { data: undefined, meta: undefined };
  }

  if (Array.isArray(results)) {
    return { data: results, meta: undefined };
  }

  const { data, count } = results as ListResults<D>;
  return {
    data,
    meta: {
      total: +count
    }
  };
};

type GetQueryParams = {
  per_page?: number;
  offset?: number;
  sort?: string;
  filter?: string[];
  search?: string;
};

export const getParamsFromFilters = <F extends AnyFilters>(
  listFilters?: ListFilters<F>,
  operatorsMap?: OperatorsMap<F>
): GetQueryParams => {
  if (!listFilters) {
    return {};
  }

  const nestedFieldsMap: { [key: string]: string } = {
    assignee: "assignee__first_name,assignee__last_name",
    senderEmail: "sender__email",
    senderPhone: "sender__phone"
  };

  const { pagination, orderBy, filters, search } = listFilters;

  const params: GetQueryParams = {};

  if (pagination) {
    if (typeof pagination.rowsPerPage !== "undefined") {
      params.per_page = pagination.rowsPerPage;
    }
    if (typeof pagination.offset !== "undefined") {
      params.offset = pagination.offset;
    }
  }

  if (orderBy) {
    const [columnKey, sortingOrder] = orderBy[0].split(",");
    if (Object.keys(nestedFieldsMap).includes(columnKey)) {
      params.sort = prepareOrderingForNestedFields(
        nestedFieldsMap[columnKey],
        sortingOrder
      );
    } else {
      params.sort = prepareOrderingForApi(orderBy);
    }
  }

  if (filters) {
    const preparedFilters = prepareFiltersForApi(filters, operatorsMap);
    Object.assign(params, preparedFilters);
  }

  if (search) {
    params.search = search;
  }

  return params;
};

const prepareOrderingForApi = (orderingDefinitions: string[]): string => {
  return orderingDefinitions
    .map(orderingDefinition => {
      const [column, sortingOrder] = orderingDefinition.split(",");
      return `${sortingOrder === "DESC" ? "-" : ""}${_.snakeCase(column)}`;
    })
    .join(",");
};

const prepareOrderingForNestedFields = (
  columns: string,
  sortingOrder: string
): string => {
  const columnsList = columns.split(",");
  return columnsList
    .map(column => `${sortingOrder === "DESC" ? "-" : ""}${column}`)
    .join(",");
};

type OperatorsMap<F> = Record<keyof F, FilterOperator>;
type PreparedFilters = Record<string, any>;

export const prepareFilterKey = (key: string): string => {
  const customFilterFieldsMap: { [key: string]: string } = {
    reviewers: "reviewers_ids",
    approvers: "auth__approved_by__in"
  };
  if (Object.keys(customFilterFieldsMap).includes(key))
    return customFilterFieldsMap[key];
  return _.snakeCase(key).replace(/_/g, "__");
};

export const prepareFilterValues = (values: any): any => {
  if (Array.isArray(values)) {
    return values.map(e => String(e)).join(",");
  }

  if ([undefined, null, ""].includes(values)) {
    return undefined;
  }

  return String(values);
};

export const prepareFiltersForApi = <F extends AnyFilters>(
  filters: F,
  operatorsMap?: OperatorsMap<F>
): PreparedFilters =>
  Object.entries(filters).reduce((acc: PreparedFilters, [key, value]) => {
    const operator = operatorsMap && operatorsMap[key];
    const isValidNullOperator =
      operator === "isnull" && typeof value === "boolean";

    if (!isValidNullOperator && !value) {
      return acc;
    }

    return {
      ...acc,
      [`${prepareFilterKey(key)}${
        operator ? "__" + operator : ""
      }`]: prepareFilterValues(value)
    };
  }, {} as PreparedFilters);

export const getMessageFromError = (
  error: AxiosError
): string | string[] | ApiFieldError => {
  if (!error.isAxiosError || isNetworkError(error)) {
    return error.message;
  }

  const data = error.response!.data as ApiErrorResponse;

  const generalError = data as ApiGeneralError;
  if (generalError.detail) {
    return generalError.detail;
  }

  const { nonFieldErrors, detail, ...errors } = error.response!.data;
  const formError = {
    fieldErrors: errors,
    nonFieldErrors
  } as ApiFormValidationResult;

  if (formError.nonFieldErrors) {
    return formError.nonFieldErrors;
  }

  if (formError.fieldErrors) {
    return formError.fieldErrors;
  }

  return error.message;
};

export const getMessageStringFromError = (error: AxiosError): string => {
  const errorMessages = getMessageFromError(error);

  let err = errorMessages as string;

  if (errorMessages instanceof Object || Array.isArray(errorMessages)) {
    err = Object.values(errorMessages)
      .map(e => e as string)
      .join(" ");
  }

  return err;
};

type FormErrors = Record<string, string>;

export const getFormErrors = (ex: AxiosError): FormErrors => {
  const errorMessage = getMessageFromError(ex);
  if (Array.isArray(errorMessage)) {
    return {
      [FORM_ERROR]: joinWithDots(errorMessage)
    };
  }

  if (typeof errorMessage === "string") {
    return { [FORM_ERROR]: errorMessage };
  }

  return _.mapValues(errorMessage, v => (Array.isArray(v) ? v[0] : v));
};

export const makeRequestForList = <D>(
  config: FetcherConfig
): UseFetchConfig<ListPayload<D>, UseFetchData<D[]>> => ({
  baseURL: BASE_URLS.PYTHON_API,
  ...config,
  transformResponse: response =>
    parseListResults<D>(_.get(response, "data", undefined))
});

export const makeRequestForOne = <D>(
  config: FetcherConfig
): UseFetchConfig<SingleItemPayload<D>, UseFetchData<D>> => ({
  baseURL: BASE_URLS.PYTHON_API,
  ...config,
  transformResponse: response => ({
    data: _.get(response, "data", undefined)
  })
});

export const makeMutation = <V, D = any>(
  configBuilder: MutationMakerConfig<V>
): MutationConfigBuilder<V, SingleItemPayload<D>, D | undefined> => (
  variables: V
) => ({
  baseURL: BASE_URLS.PYTHON_API,
  ...configBuilder(variables),
  transformResponse: response => _.get(response, "data", undefined)
});
