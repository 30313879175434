import React from "react";
import { Typography, Hidden } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

import { Button } from "components";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { IconTimerOutlined } from "assets/icons";
import { formatDuration } from "utils/datetime";

import ChipContainer from "../components/chip-container";
import ChipIndex from "../components/chip-index";
import CourseChip from "../components/course-chip";
import {
  MyLearningPlanDetailsItem,
  MyLearningPlanStatus
} from "../../../provider/my-learning-plans";
import LearningPlanStatusChip from "../components/learning-plan-status-chip";

import useStyles from "./my-learning-plan-info.styles";

type Props = {
  learningPlan: MyLearningPlanDetailsItem;
};

const MyLearningPlanInfo: React.FC<Props> = ({ learningPlan }) => {
  const classes = useStyles();
  const tenant = useTenant();
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.columnItem, classes.left)}>
        <div className={classes.infoItem}>
          <Typography variant="h4" className={classes.title}>
            ID
          </Typography>
          <Typography>{learningPlan.externalId || "-"}</Typography>
        </div>

        <div className={classes.infoItem}>
          <Typography variant="h4" className={classes.title}>
            Added Date
          </Typography>
          <Typography>{moment(learningPlan.createdAt).format("L")}</Typography>
        </div>

        <div className={classes.infoItem}>
          <Typography variant="h4" className={classes.title}>
            Status
          </Typography>
          <LearningPlanStatusChip
            status={learningPlan.status}
            label={
              learningPlan.status === MyLearningPlanStatus.IN_PROGRESS
                ? `(${learningPlan.finishedCourses}/${learningPlan.totalCourses})`
                : ""
            }
          />
        </div>

        <div className={classes.infoItem}>
          <div className={classes.durationContainer}>
            <IconTimerOutlined className={classes.icon} />
            <Typography variant="h4" className={classes.title}>
              Duration
            </Typography>
          </div>
          <Typography>
            {learningPlan.totalDuration
              ? formatDuration(
                  learningPlan.totalDuration * 1000,
                  "H[h] mm[min]"
                )
              : "-"}
          </Typography>
        </div>

        <div className={classes.description}>
          <Typography variant="h4" className={classes.title}>
            Description
          </Typography>
          <Typography>{learningPlan.description}</Typography>
        </div>
      </div>

      <div className={clsx(classes.columnItem, classes.right)}>
        <Typography variant="h4" className={classes.title}>
          Courses list
        </Typography>
        {learningPlan?.courses?.map((course, index) => (
          <ChipContainer key={course.id}>
            <ChipIndex index={index} />
            <CourseChip
              label={
                <>
                  <Button
                    variant="text"
                    target={isDetailsNewTab ? "_blank" : "_self"}
                    component={RouterLink}
                    classes={{
                      root: classes.button
                    }}
                    to={
                      isAllowedTenantAvailableCourses
                        ? `/available-courses/${course.id}`
                        : `/my-courses/${course.id}`
                    }
                  >
                    <span className={classes.name}>{course.name}</span>
                  </Button>
                  <Hidden xsDown>
                    <Typography
                      variant="body2"
                      classes={{ root: classes.duration }}
                    >
                      <IconTimerOutlined className={classes.icon} />
                      {course.duration
                        ? formatDuration(course.duration * 1000, "H[h] mm[min]")
                        : "-"}
                    </Typography>
                  </Hidden>
                </>
              }
              status={course.status}
            />
          </ChipContainer>
        ))}
      </div>
    </div>
  );
};

export default MyLearningPlanInfo;
