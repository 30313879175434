import * as React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import useStyles from "./card.styles";

export type Props = {
  icon: React.ComponentType<SvgIconProps>;
};

const CardIcon: React.FC<Props> = ({ icon: IconComponent }) => {
  const classes = useStyles();

  return <IconComponent className={classes.icon} />;
};

export default CardIcon;
