import * as React from "react";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { useAuth } from "services/auth-provider";
import { AutoGrid, Button, Drawer } from "components";
import useToast from "utils/hooks/useToast";
import * as PythonApi from "services/api/python";
import { useCoursePublicationStatusUpdate } from "modules/courses/provider";
import { CourseItem } from "modules/courses/provider/courses";
import { useCourseToken } from "modules/courses/provider/my-courses";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { queries } from "config/routes";

import EditCourseContent from "../../course-edit-content.component";
import CourseAssign, { CourseToAssign } from "../../course-assign.component";
import { CourseForEdition } from "../../course-list/course-list";
import AddOrUpdateCourse from "../../course-add-or-update/course-add-or-update.component";

type AdminCardsProps = {
  course: CourseItem;
  refetch: () => void;
  refetchAll: () => void;
};

type CourseDrawerData = {
  title: string;
  edit?: {
    courseId: string;
  };
  editContent?: CourseForEdition;
  assign?: CourseToAssign;
};

const AdminCourseActions: React.FC<AdminCardsProps> = ({
  course,
  refetch,
  refetchAll
}) => {
  const { search } = useLocation();
  const { showToast } = useToast();
  const tenant = useTenant();
  const { checkAccess } = useAuth();
  const history = useHistory();

  const { plainUrl, url, isPublished, externalId, id } = course;
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });
  const isAdmin = checkAccess({ roles: [UserRole.ADMIN] });
  const isDesigner = checkAccess({ roles: [UserRole.DESIGNER] });

  const [isDrawerOpen, setCourseDrawerOpen] = React.useState(false);
  const [courseDrawerData, setCourseDrawerData] = React.useState<
    CourseDrawerData
  >();

  // Open context edit dialog if exists the relevant query
  React.useEffect(
    () => {
      const queryParams = new URLSearchParams(search);
      if (queryParams.get(queries.courses.details.uploadContent)) {
        onEditContentClick(externalId, id);
      }
    },
    [] // eslint-disable-line
  );

  const onEditContentClick = React.useCallback(
    (externalId: string, courseId: string) => {
      setCourseDrawerData({
        title: "Edit course content",
        editContent: {
          externalId,
          courseId
        }
      });
      setCourseDrawerOpen(true);
    },
    []
  );
  const onEditClick = React.useCallback((courseId: string) => {
    setCourseDrawerData({
      title: "Edit course details",
      edit: {
        courseId
      }
    });
    setCourseDrawerOpen(true);
  }, []);

  const onAssignClick = React.useCallback(
    (externalId: string, courseId: string) => {
      setCourseDrawerData({
        title: "Assign course",
        assign: {
          courseId
        }
      });
      setCourseDrawerOpen(true);
    },
    []
  );

  const handleDrawerClose = () => {
    setCourseDrawerData(undefined);
    setCourseDrawerOpen(false);
  };

  const handleDrawerSubmit = React.useCallback(() => {
    handleDrawerClose();
    refetchAll();
  }, [refetchAll]);

  // Publication
  const publishCourse = useCoursePublicationStatusUpdate("publish");
  const unpublishCourse = useCoursePublicationStatusUpdate("unpublish");

  const handlePublication = React.useCallback(async () => {
    try {
      await (isPublished
        ? unpublishCourse({ courses: [id] })
        : publishCourse({ courses: [id] }));
      refetch();
      showToast(
        `Course has been successfully ${
          isPublished ? "unpublished" : "published"
        }`,
        "success"
      );
    } catch (ex) {
      const errorMessages = PythonApi.getMessageFromError(ex);
      showToast(Object.values(errorMessages).join(" "), "error");
    }
  }, [id, isPublished, publishCourse, refetch, showToast, unpublishCourse]);

  const getCourseToken = useCourseToken();
  const openCourse = React.useCallback(async () => {
    try {
      const { data: token } = await getCourseToken({ courseId: id });
      if (token) {
        document.cookie = `course_${externalId}=${token}; path=/; secure`;
        window.open(plainUrl);
      }
    } catch (ex) {
      showToast("Sorry, something went wrong", "error");
    }
  }, [externalId, getCourseToken, id, plainUrl, showToast]);

  return (
    <Box mt={8}>
      <AutoGrid spacing={2}>
        {!isTenantAdmin && (
          <>
            <Button
              color={isPublished ? "hint" : "success"}
              onClick={handlePublication}
              disabled={!url}
            >
              {isPublished ? "Unpublish" : "Publish"}
            </Button>
            {tenant.isComponentAllowed(AllowedComponents.CourseCreation) &&
              !isDesigner && (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    onEditClick(id);
                  }}
                >
                  Edit details
                </Button>
              )}
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                onEditContentClick(externalId, id);
              }}
            >
              Edit content
            </Button>
            {(tenant.isComponentAllowed(
              AllowedComponents.CourseCompanyAssign
            ) ||
              tenant.isComponentAllowed(AllowedComponents.CourseUserAssign)) &&
              !isDesigner && (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    onAssignClick(externalId, id);
                  }}
                >
                  Assign
                </Button>
              )}
          </>
        )}
        <Button color="secondary" disabled={!plainUrl} onClick={openCourse}>
          Test this course
        </Button>
      </AutoGrid>

      <Drawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        title={courseDrawerData?.title}
        size="md"
      >
        {courseDrawerData?.edit && isAdmin && (
          <AddOrUpdateCourse
            course={course}
            onCancel={handleDrawerClose}
            onSubmit={handleDrawerSubmit}
          />
        )}
        {courseDrawerData?.editContent && (
          <EditCourseContent
            course={courseDrawerData.editContent}
            onCancel={handleDrawerClose}
            onSubmit={() => {
              handleDrawerSubmit();
              history.push({ search: "" });
            }}
          />
        )}
        {courseDrawerData?.assign && isAdmin && (
          <CourseAssign
            course={courseDrawerData.assign}
            onCancel={handleDrawerClose}
            onSubmit={handleDrawerSubmit}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default AdminCourseActions;
