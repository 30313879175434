import * as React from "react";
import { FormControlProps } from "@material-ui/core/FormControl";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";

import { TreeControl } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";

export type OptionType = {
  value: string;
  label: string;
  parent: string | null;
};

type Props = FieldRenderProps<string[] | string, HTMLElement> & {
  label?: string;
  formControlProps?: FormControlProps;
  allowMultiple?: boolean; //false - radiocheckboxes, if true -> checkboxes
  filteredOptions?: Array<OptionType>;
  options?: Array<OptionType>;
  isLoading?: boolean;
  infiniteScroll?: boolean;
  noOptionsMessage?: string;
  onlyOneFromBranch?: boolean;
  listFilters?: ListFiltersState<Record<string, any>>;
  problemsView?: boolean;
};

const GroupTreeControl: React.FC<Props> = props => {
  const filteredOptions = React.useMemo(() => {
    if (props.listFilters!.search) {
      if (props.problemsView) {
        return props.options;
      }
      const strictlyFiltered = props.options!.filter(({ label }) =>
        label.toLowerCase().includes(props.listFilters!.search!.toLowerCase())
      );
      return strictlyFiltered.map(item => ({
        ...item,
        parent: strictlyFiltered.some(({ value }) => value === item.parent)
          ? item.parent
          : null
      }));
    }
    return props.options;
  }, [props.listFilters, props.options, props.problemsView]);

  React.useEffect(() => {
    const event = document.createEvent("HTMLEvents");
    event.initEvent("resize", true, false);
    document.dispatchEvent(event);
  }, [filteredOptions]);

  return (
    <TreeControl
      {...props}
      onlyOneFromBranch={props.onlyOneFromBranch}
      filteredOptions={filteredOptions}
      options={props.options}
      listFilters={props.listFilters}
    />
  );
};

export default GroupTreeControl;
