import * as React from "react";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import { IconInfo, IconMailOutline, IconSmartphone } from "assets/icons";

import { CompanySponsor } from "../provider/companies";

import useStyles from "./company-details-sponsor.styles";

type Props = {
  sponsor?: CompanySponsor;
};

const CompanyDetailsSponsor: React.FC<Props> = ({ sponsor }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.content}>
        <Typography color="primary">AT&T Sponsor</Typography>
        <Tooltip
          title="In case of any problems or questions you can contact your sponsor."
          placement="bottom-start"
          color="disabled"
        >
          <IconInfo className={classes.rightIcon} color="primary" />
        </Tooltip>
      </Grid>
      <Grid item>
        <Typography>
          {sponsor?.firstName} {sponsor?.lastName}
        </Typography>
      </Grid>
      <Grid item className={classes.content}>
        <IconMailOutline className={classes.leftIcon} />
        <Typography>{sponsor?.email}</Typography>
      </Grid>
      <Grid item className={classes.content}>
        <IconSmartphone className={classes.leftIcon} />
        <Typography>{sponsor?.phone ? sponsor.phone : <>&mdash;</>}</Typography>
      </Grid>
    </Grid>
  );
};

export default CompanyDetailsSponsor;
