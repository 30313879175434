const config = {
  components: {
    SelectControl: {
      limitOfShownFields: 15
    },
    TreeContainer: {
      infiniteScrollRowsPerPage: 50
    }
  },
  sentryDSN:
    "https://90df5a11a044465cb41b705af819518e@sentry2.tools.acaisoft.io/82",
  env: process.env.REACT_APP_ENV || "development"
};

export default config;
