import * as React from "react";
import { Container } from "@material-ui/core";

import { useModuleState, actionTypes } from "modules/common/reducers/common";

import useStyles from "./guest-layout.styles";

type Props = {};

const GuestLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  const [{ thumbnailUrl }, dispatch] = useModuleState();

  React.useEffect(
    () => () => {
      dispatch({
        type: actionTypes.SET_THUMBNAIL,
        payload: undefined
      });
    },
    [dispatch, thumbnailUrl]
  );

  return (
    <div className={classes.wrapper}>
      <Container className={classes.root} maxWidth={false}>
        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
        </div>
      </Container>
    </div>
  );
};

export { default as Content } from "./components/content.component";
export { default as SidePanel } from "./components/side-panel.component";
export { default as BottomPanel } from "./components/bottom-panel.component";

export default GuestLayout;
