import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch, useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { SubmissionStatus } from "../courses.constants";

export const useCourseCompletionCreate = () => {
  type Vars = {
    course: string;
  };
  type Item = {
    course: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.manualCompletions.create,
      data: variables
    }))
  );
};

export const useCourseCompletionForUserCreate = () => {
  type Vars = {
    course: string;
    user: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(variables => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.manualCompletions.completeAsUser,
      data: variables
    }))
  );
};

export type SubmissionsListFilters = {
  status?: string;
};
type SubmissionsListVars = {
  listFilters?: ListFilters<SubmissionsListFilters>;
};
export type SubmissionsListItem = {
  id: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  created_at: string;
  course: {
    id: string;
    name: string;
  };
  reviewed_by?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  status: SubmissionStatus;
};

export const useSubmissionsList = (
  options: FetchOptions<SubmissionsListVars>
) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<SubmissionsListItem>({
      method: "GET",
      url: apiConfig.paths.authorized.course.manualCompletions.list,
      params: PythonApi.getParamsFromFilters(listFilters, {
        status: "in"
      })
    })
  );
};

export const useProcessSubmissionCreate = (
  operationType: "accept" | "reject"
) => {
  type Vars = {
    submissionId: string;
  };
  return useFetchMutation(
    PythonApi.makeMutation<Vars>(({ submissionId }) => ({
      method: "POST",
      url: apiConfig.paths.authorized.course.manualCompletions.createProcessSubmission(
        submissionId,
        operationType
      )
    }))
  );
};

export type ReviewersListItem = {
  id: string;
  firstName: string;
  lastName: string;
};

const useSubmissionReviewersListConfig = () =>
  PythonApi.makeRequestForList<ReviewersListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.manualCompletions.reviewers
  });

export const useSubmissionLReviewersList = (
  options: FetchOptions<SubmissionsListVars>
) => useFetch(useSubmissionReviewersListConfig(), options);
