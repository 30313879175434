import * as React from "react";

import useStyles from "./responsive-table-wrapper.styles";

type Props = {};

const ResponsiveTableWrapper: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ResponsiveTableWrapper;
