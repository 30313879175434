import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  publishLabel: {
    fontSize: "0.875rem"
  },
  container: {
    display: "flex",
    marginBottom: spacing(1)
  },
  text: {
    margin: spacing(0, 1)
  }
}));
