import * as React from "react";
import { useParams } from "react-router";

import { IconCreate, IconDelete } from "assets/icons";
import { useAuth } from "services/auth-provider";
import { Button, AutoGrid } from "components";
import { UserRole } from "modules/users/users.constants";
import { CourseItem } from "modules/courses/provider/courses";

type Props = {
  course: CourseItem;
  onEditContentClick: (externalId: string, courseId: string) => void;
  setCourseDeletionModalState: React.Dispatch<
    React.SetStateAction<{
      course?: CourseItem | undefined;
      isOpen: boolean;
    }>
  >;
  setRemoveFromGroupModalState: React.Dispatch<
    React.SetStateAction<{
      course?: CourseItem | undefined;
      isOpen: boolean;
    }>
  >;
};

const CoursesListRowActions: React.FC<Props> = ({
  course,
  onEditContentClick,
  setCourseDeletionModalState,
  setRemoveFromGroupModalState
}) => {
  const { companyId } = useParams();
  const auth = useAuth();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });

  return (
    <AutoGrid spacing={2} wrap="nowrap" justify="space-between">
      {isAdmin && !!companyId ? (
        <Button
          onClick={() =>
            setRemoveFromGroupModalState({
              course,
              isOpen: true
            })
          }
          icon={IconDelete}
          color="error"
          variant="text"
          defaultHoverColor
        >
          Remove
        </Button>
      ) : (
        <>
          <Button
            onClick={() => {
              onEditContentClick(course.externalId, course.id);
            }}
            variant="text"
            icon={IconCreate}
          >
            Edit content
          </Button>
          {!isDesigner && (
            <Button
              onClick={() =>
                setCourseDeletionModalState({
                  course,
                  isOpen: true
                })
              }
              variant="text"
              icon={IconDelete}
              color="error"
              defaultHoverColor
            >
              Delete
            </Button>
          )}
        </>
      )}
    </AutoGrid>
  );
};

export default CoursesListRowActions;
