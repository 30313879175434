import { useContext, Dispatch } from "react";

import { StateContext } from "config/state";

export const initialState = {
  myCoursesHistoryFilters: {
    pagination: { rowsPerPage: 10 },
    orderBy: ["status_timestamp,DESC"]
  }
};

type State = typeof initialState;

export enum actionTypes {
  SET_MY_COURSES_HISTORY_FILTERS = "setMyCoursesHistoryFilters",
  RESET_HISTORY_FILTERS = "resetHistoryFilters"
}

const reducer = (
  state = initialState,
  action: { type: actionTypes; payload?: any }
) => {
  switch (action.type) {
    case actionTypes.SET_MY_COURSES_HISTORY_FILTERS: {
      return {
        ...state,
        myCoursesHistoryFilters: action.payload
      };
    }
    case actionTypes.RESET_HISTORY_FILTERS: {
      return {
        ...state,
        myCoursesHistoryFilters: initialState.myCoursesHistoryFilters
      };
    }
    default:
      return state;
  }
};

export const useModuleState = (): [
  State,
  Dispatch<{ type: actionTypes; payload?: any }>
] => {
  const [state, dispatch] = useContext(StateContext);
  return [state.history, dispatch];
};

export default reducer;
