import * as React from "react";
import moment from "moment";
import _ from "lodash";
import { DatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { IconDateRange } from "assets/icons";

import useStyles from "./date-filter.styles";

export type Props = {
  format?: string;
  label: string;
  listFilters: ListFiltersState;
  name: string;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  timeBoundary?: "dayStart" | "dayEnd";
};

const DateFilter: React.FC<Props> = ({
  format = "L",
  label,
  listFilters,
  name,
  maxDate,
  minDate,
  timeBoundary = "dayStart"
}) => {
  const classes = useStyles();
  const initialValue = _.get(listFilters, `filters.${name}`, null);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      listFilters.setFilter(name, undefined);
    } else {
      listFilters.setFilter(
        name,
        timeBoundary === "dayStart"
          ? moment.utc(date.startOf("day")).toISOString()
          : moment.utc(date.endOf("day")).toISOString()
      );
    }
  };

  return (
    <DatePicker
      clearable
      label={label}
      format={format}
      value={initialValue}
      onChange={handleDateChange}
      inputVariant="outlined"
      InputProps={{
        endAdornment: <IconDateRange color="disabled" />,
        classes: {
          input: classes.input
        }
      }}
      InputLabelProps={{
        classes: {
          outlined: classes.label
        }
      }}
      fullWidth={false}
      maxDate={maxDate}
      minDate={minDate}
      role="button"
    />
  );
};

export default DateFilter;
