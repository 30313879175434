import * as React from "react";
import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";

import { Search, ListToolbar, ExtendedMainHeader, Header } from "components";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import LayoutSelector, {
  LayoutType
} from "components/layout-selector/layout-selector";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";

import useStyles from "./course-list-available-toolbar.styles";

type Props = {
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
};

const AvailableCoursesListToolbar: React.FC<Props> = ({
  meta,
  listFilters,
  activeLayout,
  setActiveLayout
}) => {
  const classes = useStyles();
  const isMobile = useSizeCheck("xs");

  const left = (
    <Grid container justify="flex-start" spacing={2}>
      <Grid item xs={12} className={classes.gridItem}>
        <ExtendedMainHeader
          title={
            isMobile ? `Courses (${(meta && meta.total) || 0})` : "Courses"
          }
        />
      </Grid>
      <Grid
        item
        xs="auto"
        className={clsx(classes.gridItem, classes.gridSearch)}
      >
        <Search listFilters={listFilters} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <ListToolbar left={left} />
      <Hidden xsDown>
        <Header
          variant="section"
          title={`My courses (${(meta && meta.total) || 0})`}
        >
          <LayoutSelector
            activeLayout={activeLayout}
            setActiveLayout={setActiveLayout}
          />
        </Header>
      </Hidden>
    </>
  );
};

export default AvailableCoursesListToolbar;
