import useSelectedState, {
  Item,
  SelectedStateItems,
  SelectedStateActions
} from "./useSelectedState";

type ListSelectionActions<T> = {
  selectAll: () => void;
  isAllSelected: () => boolean;
  isSomeSelected: () => boolean;
  isValid: (predicate: (item: T) => boolean) => boolean;
  getSingleField: (field: keyof T) => Array<any>; // @TODO: Find better type
};

export type ListSelectionState<I extends Item> = {
  selected: SelectedStateItems<I>;
  actions: SelectedStateActions<I> & ListSelectionActions<I>;
};

const useListSelection = <I extends Item>(
  allAvailableItems: I[],
  initialSelection?: I[],
  key: string = "id"
): ListSelectionState<I> => {
  const { selected, actions } = useSelectedState<I>(initialSelection, key);

  const helpers: ListSelectionActions<I> = {
    selectAll: () => {
      actions.setAll(allAvailableItems);
    },
    isAllSelected: () =>
      allAvailableItems.length > 0 &&
      selected.size === allAvailableItems.length,
    isSomeSelected: () =>
      selected.size > 0 && selected.size <= allAvailableItems.length,
    isValid: predicate => Array.from(selected.values()).every(predicate),
    getSingleField: field => [...selected.values()].map(item => item[field])
  };

  return { selected, actions: { ...actions, ...helpers } };
};

export default useListSelection;
