import * as React from "react";

import { Pagination, AutoGrid } from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";

type UsersListPaginationProps = {
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
};

const DirectUserListPagination: React.FC<UsersListPaginationProps> = ({
  meta,
  listFilters
}) => (
  <AutoGrid justify="flex-end" showEmpty>
    {meta && meta.total > 0 && (
      <Pagination
        totalCount={meta.total}
        onChange={listFilters.setPagination}
        rowsPerPage={listFilters.pagination.rowsPerPage}
        page={listFilters.pagination.page}
      />
    )}
  </AutoGrid>
);

export default DirectUserListPagination;
