import * as React from "react";
import clsx from "clsx";

import PasswordPolicyManager from "utils/validators/password-policy";
import {
  PolicyValidator,
  MultiPolicyValidator
} from "utils/validators/password-policy/password-policy.validators";

import ValidationChip from "../validation-chip/validation-chip";

import useStyles from "./password-checker.styles";

interface PasswordCheckerProps {
  policyManager: PasswordPolicyManager;
  flat?: boolean;
}

interface PasswordCheckerListProps {
  policies: PolicyValidator[];
  validationResults: boolean[];
  flat: boolean;
}

const PasswordCheckerList: React.FC<PasswordCheckerListProps> = ({
  policies,
  validationResults,
  flat
}) => {
  const classes = useStyles();

  return (
    <ul className={classes.policiesList}>
      {policies.map((p, index) => {
        let nestedList = null;
        if (p instanceof MultiPolicyValidator) {
          const compoundPolicy = p as MultiPolicyValidator;
          nestedList = (
            <PasswordCheckerList
              policies={compoundPolicy.getPolicies()}
              validationResults={compoundPolicy.getValidationResults()}
              flat={flat}
            />
          );
        }
        const chip = (
          <ValidationChip isValid={validationResults[index]}>
            {p.getMessage()}
          </ValidationChip>
        );

        return (
          <li key={index} className={clsx(classes.policyItem)}>
            {flat ? (
              nestedList || chip
            ) : (
              <>
                {chip}
                {nestedList}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const PasswordChecker: React.FC<PasswordCheckerProps> = ({
  policyManager,
  flat = false
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, flat && classes.flat)}>
      <PasswordCheckerList
        policies={policyManager.getPolicies()}
        validationResults={policyManager.getValidationResults()}
        flat={flat}
      />
    </div>
  );
};

export default PasswordChecker;
