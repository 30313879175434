import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Field, Form } from "react-final-form";
import moment from "moment";

import {
  Button,
  CheckboxControl,
  Chip,
  Header,
  TextControl,
  UserInitials
} from "components";
import { IconHistoryOutlined } from "assets/icons";

import { Comment } from "../provider/problems";

import useStyles from "./problem-comments.styles";

interface ProblemComments {
  comments?: { data: Comment[]; count: number };
  onSubmit: (text: string, isInternal: boolean) => {};
  onCancel?: () => void;
  background?: "white" | "purple";
  canMarkAsInternal?: boolean;
}

export default ({
  comments,
  onSubmit,
  onCancel,
  background = "purple",
  canMarkAsInternal = true
}: ProblemComments) => {
  const classes = useStyles({ background });

  return (
    <Box className={classes.container}>
      <Header title={`Comments (${comments?.count ?? 0})`} variant="section" />
      <div className={classes.list}>
        {comments?.data.map(o => {
          const createdAt = moment(o.createdAt);
          const now = moment();

          const diffPrevDay = createdAt.diff(now, "days");

          const diffPrevDayMsg = diffPrevDay
            ? diffPrevDay === 1
              ? `${diffPrevDay} day`
              : `${diffPrevDay} days`
            : null;

          const diffHours = now.diff(createdAt, "hours");

          const diffHoursMsg = diffHours && `${diffHours} h`;

          return (
            <div key={o.id}>
              <Box display="flex">
                <UserInitials
                  names={`${o.sender.firstName} ${o.sender.lastName}`}
                  classes={{ root: classes.avatar }}
                />
                <div className={classes.infoContainer}>
                  <Typography color="textPrimary">
                    {o.sender.firstName} {o.sender.lastName}
                  </Typography>
                  <Typography className={classes.date}>
                    <IconHistoryOutlined className={classes.icon} />
                    {diffPrevDayMsg || diffHoursMsg || "Less than hour ago"}
                  </Typography>
                </div>
              </Box>
              {o.isInternal && (
                <Chip
                  className={classes.chip}
                  variant="info"
                  label="Internal"
                />
              )}
              <Typography className={classes.text}>{o.text}</Typography>
            </div>
          );
        })}
      </div>

      <div className={classes.formContainer}>
        <Form
          onSubmit={values => onSubmit(values.text, Boolean(values.isInternal))}
        >
          {({
            handleSubmit,
            submitting,
            pristine,
            hasValidationErrors,
            values,
            form: { reset }
          }) => (
            <form
              onSubmit={async event => {
                const errors = await handleSubmit(event);
                !errors && reset();
              }}
            >
              <Field
                classes={{
                  root: classes.commentInputRoot
                }}
                inputLabelProps={{
                  className: classes.commentInputLabel
                }}
                name="text"
                variant="outlined"
                label="Type here to reply"
                component={TextControl}
              />
              {canMarkAsInternal ? (
                <Field
                  name="isInternal"
                  color="primary"
                  type="checkbox"
                  label={
                    <span className={classes.checkLabel}>Mark as internal</span>
                  }
                  component={CheckboxControl}
                />
              ) : null}
              <Grid
                className={classes.buttons}
                container
                justify={onCancel ? "center" : "flex-start"}
              >
                {onCancel ? (
                  <Button
                    className={classes.cancel}
                    color="error"
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  className={classes.submit}
                  type="submit"
                  color="success"
                  disabled={
                    !values.text ||
                    submitting ||
                    hasValidationErrors ||
                    pristine
                  }
                >
                  Reply
                </Button>
              </Grid>
            </form>
          )}
        </Form>
      </div>
    </Box>
  );
};
