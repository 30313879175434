import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette }) => ({
  switchBase: {
    color: palette.hint.main,
    "&$checked": {
      color: palette.success.main
    },
    "&$checked + $track": {
      backgroundColor: palette.hint.main
    }
  },
  checked: {},
  track: {
    backgroundColor: palette.hint.main
  }
}));
