import * as React from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

import useStyles from "./header.styles";

type Variant = "section" | "formSection";

type TypographyPropsMap = {
  [key in Variant]: {
    variant: TypographyProps["variant"];
    component: TypographyProps["component"];
  };
};
const typographyPropsMap: TypographyPropsMap = {
  formSection: {
    variant: "h4",
    component: "h4"
  },
  section: {
    variant: "h3",
    component: "h3"
  }
};

type Props = {
  children?: React.ReactNode;
  classes?: object;
  number?: number | string;
  hint?: string;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  variant: Variant;
  className?: string;
};

const Header: React.FC<Props> = ({
  children,
  classes: parentClasses,
  number,
  hint,
  subtitle,
  title,
  variant,
  className
}) => {
  const classes = useStyles({ classes: parentClasses });

  const hasSubtitle = Boolean(subtitle);
  const hasNumber = Boolean(number);
  const hasHint = Boolean(hint);

  return (
    <div
      className={clsx(
        classes.root,
        classes[variant],
        hasSubtitle && classes.withSubtitle,
        className
      )}
    >
      <div>
        <div className={classes.titleContainer}>
          {hasNumber && (
            <Typography
              color="textSecondary"
              {...typographyPropsMap[variant]}
              className={classes.number}
            >
              {number}.
            </Typography>
          )}

          <Typography
            color="textPrimary"
            {...typographyPropsMap[variant]}
            className={classes.title}
          >
            {title}
          </Typography>

          {hasHint && (
            <Typography
              {...typographyPropsMap[variant]}
              className={classes.hint}
            >
              {hint}
            </Typography>
          )}
        </div>

        {hasSubtitle && (
          <div className={classes.subtitleContainer}>{subtitle}</div>
        )}
      </div>

      {children && <div className={classes.childrenContainer}>{children}</div>}
    </div>
  );
};

export default Header;
