import * as React from "react";
import _ from "lodash";
import { Form, Field } from "react-final-form";

import { useAuth } from "services/auth-provider";
import useListFilters, { ListFiltersState } from "utils/hooks/useListFilters";
import { UserRole } from "modules/users/users.constants";
import authClient from "services/auth";

import { useApproversList } from "../../../users/provider";

import useStyles from "./users-tree-filter.styles";
import ReviewersTreeControl from "./users-tree-control";

type Props = {
  clearLabel?: string;
  label?: string;
  listFilters: ListFiltersState;
  name: string;
  allowMultiple?: boolean;
};

const UsersTreeFilter: React.FC<Props> = props => {
  const { checkAccess } = useAuth();
  const [isTokenRefreshed, setTokenRefreshed] = React.useState(false);
  const isTenantAdmin = checkAccess({ roles: [UserRole.TENANT_ADMIN] });

  React.useEffect(() => {
    if (isTenantAdmin) {
      authClient.refreshTokens().finally(() => {
        setTokenRefreshed(true);
      });
    }
  }, [isTenantAdmin]);

  const [isFetched, setIsFetched] = React.useState(false);

  const approversListFilters = useListFilters({
    pagination: { rowsPerPage: 0 }
  });

  const approvers = useApproversList({
    variables: {},
    skip: isFetched || (isTenantAdmin && !isTokenRefreshed)
  });

  const options = React.useMemo(() => {
    if (approvers.data) {
      setIsFetched(true);
      return approvers.data.map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
        parent: null
      }));
    }
    return [];
  }, [approvers.data]);

  const currentValue = _.get(
    props.listFilters,
    `filters.${props.name}`,
    props.allowMultiple ? [] : ""
  );
  const onFilterHandler = React.useCallback(
    newValues => {
      if (props.allowMultiple) {
        if (!_.isEqual(currentValue, newValues)) {
          if (newValues.length === 0) {
            props.listFilters.setFilter(props.name, undefined);
          } else {
            props.listFilters.setFilter(props.name, newValues);
          }
        }
      } else {
        if (newValues[0] !== currentValue) {
          props.listFilters.setFilter(props.name, newValues[0]);
        }
      }
    },
    [currentValue, props.allowMultiple, props.listFilters, props.name]
  );
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Form
        onSubmit={() => {}}
        render={() => (
          <Field
            label={props.label}
            name={props.name}
            allowMultiple={props.allowMultiple}
            onFilter={onFilterHandler}
            component={ReviewersTreeControl}
            listFilters={approversListFilters}
            options={options}
          />
        )}
      />
    </div>
  );
};

export default UsersTreeFilter;
