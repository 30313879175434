import * as React from "react";
import { Typography, Box } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import clsx from "clsx";

import { useAuth } from "services/auth-provider";
import {
  Button,
  Section,
  Header,
  UserInitials,
  TextControl,
  PasswordChecker,
  FormError,
  AutoGrid
} from "components";
import { createValidator } from "utils/forms";
import PasswordPolicyManager, {
  MinLength,
  MinValidCharGroups,
  LowerAlpha,
  UpperAlpha,
  Digits,
  Special
} from "utils/validators/password-policy";
import { splitPhoneAndExtension } from "utils/strings";
import * as PythonApi from "services/api/python";

import { usePasswordUpdate, useUserProfileUpdate } from "../provider";

import useStyles from "./profile-details.styles";

const detailsValidator = createValidator({
  firstName: { presence: { allowEmpty: false }, length: { minimum: 2 } },
  lastName: { presence: { allowEmpty: false }, length: { minimum: 2 } },
  email: { presence: { allowEmpty: false }, email: { message: "is invalid" } },
  phone: (_: any, attributes: any) =>
    attributes.phone && {
      format: {
        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        message: "number is invalid"
      }
    },
  extension: (_: any, attributes: any) =>
    attributes.extension && {
      format: {
        pattern: /^\d+$/,
        message: "can contain only numbers"
      }
    }
});

const passwordValidator = createValidator({
  currentPassword: { presence: { allowEmpty: false }, length: { minimum: 8 } },
  newPassword: { presence: { allowEmpty: false } },
  repeatPassword: {
    presence: { allowEmpty: false },
    equality: { attribute: "newPassword" }
  }
});

const policyManager = new PasswordPolicyManager([
  new MinLength(16),
  new MinValidCharGroups(4, [
    new LowerAlpha(1),
    new UpperAlpha(1),
    new Digits(1),
    new Special(1)
  ])
]);

type Props = {
  onDetailsSubmit: () => void;
  onPasswordSubmit: () => void;
  onClose: () => void;
  setDrawerTitle: (title: string) => void;
  isMobile: boolean;
};

const ProfileDetails: React.FC<Props> = ({
  onDetailsSubmit,
  onPasswordSubmit,
  onClose,
  setDrawerTitle,
  isMobile
}) => {
  const classes = useStyles();
  const [isFormDetailsOpen, setFormDetailsOpen] = React.useState(false);
  const [isFormPasswordOpen, setFormPasswordOpen] = React.useState(false);

  const isFormOpen = [isFormDetailsOpen, isFormPasswordOpen].some(el => el);

  const auth = useAuth();
  const user = auth.user!;
  const editProfile = useUserProfileUpdate();
  const resetPassword = usePasswordUpdate();

  const phoneAndExtension = splitPhoneAndExtension(user.phone || "");

  const header = (
    <Section>
      <UserInitials
        names={`${user.firstName} ${user.lastName}`}
        size={isMobile ? 125 : 60}
      />
      <Typography
        variant="h2"
        className={clsx(classes.userFullName, classes.textLine)}
      >
        {user.firstName} {user.lastName}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.textSecondary, classes.textLine)}
      >
        {user.username}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.textSecondary, classes.textLine)}
      >
        {user.companyName}
      </Typography>
    </Section>
  );

  return (
    <div>
      {isMobile ? !isFormOpen && header : header}
      {!isFormOpen && (
        <>
          <Button
            variant={isMobile ? "outlined" : "text"}
            color="secondary"
            className={classes.textButton}
            onClick={() => {
              setFormDetailsOpen(true);
              setDrawerTitle("Edit your details");
            }}
          >
            Edit your details
          </Button>
          <Button
            variant={isMobile ? "outlined" : "text"}
            color="secondary"
            className={clsx(classes.textButton, classes.buttons)}
            onClick={() => {
              setFormPasswordOpen(true);
              setDrawerTitle("Edit your details");
            }}
          >
            Edit your password
          </Button>
          <div className={clsx(classes.buttonCancel, classes.buttons)}>
            <Button
              variant={isMobile ? "outlined" : "text"}
              color="error"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </>
      )}

      {isFormDetailsOpen && (
        <Form
          onSubmit={async ({
            firstName,
            lastName,
            email,
            phone,
            extension
          }) => {
            try {
              const updatedUser = await editProfile({
                firstName,
                lastName,
                email:
                  auth.user !== null && email !== auth.user.email
                    ? email
                    : undefined,
                phone:
                  phone && extension ? `${phone} x ${extension}` : phone || ""
              });
              auth.updateUser(updatedUser.data!);
              setFormDetailsOpen(false);
              onDetailsSubmit();
            } catch (ex) {
              return PythonApi.getFormErrors(ex);
            }
          }}
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: phoneAndExtension.phone,
            extension: phoneAndExtension.extension
          }}
          validate={detailsValidator}
        >
          {({
            handleSubmit,
            submitError,
            submitting,
            pristine,
            hasValidationErrors
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}
              <Section margin="dense">
                <Header
                  variant="formSection"
                  number="1"
                  title="Edit your details"
                />
                <Field
                  name="firstName"
                  label="First name"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                />
                <Field
                  name="lastName"
                  label="Last name"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                />
                <Field
                  name="phone"
                  label="Phone number (optional)"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                  validate={(phone, allValues) => {
                    // Validate when user has both fields filled and remove only phone (extension input is pristine)
                    // and when user has only phone, removes it and enters extension
                    if (phone === undefined && (allValues as any).extension)
                      return "Phone number is required for extension";
                  }}
                />
                <Field
                  name="extension"
                  label="Extension (optional)"
                  variant="outlined"
                  margin="normal"
                  component={TextControl}
                  validationIndicator
                  validate={(extension, allValues) => {
                    // Validate when user has both fields empty and enters only extension (phone input is pristine)
                    const phone = (allValues as any).phone;
                    if (
                      extension &&
                      typeof phone === "string" &&
                      phone.length === 0
                    )
                      return "Extension requires phone number entered";
                  }}
                />
                <Field
                  name="email"
                  label="Email address"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                />
              </Section>
              <Box mt={6}>
                <AutoGrid spacing={2} justify="flex-end">
                  <Button
                    xs={isMobile ? 6 : "auto"}
                    type="button"
                    variant={isMobile ? "outlined" : "text"}
                    color="error"
                    disabled={submitting}
                    onClick={() => {
                      setFormDetailsOpen(false);
                      setDrawerTitle("Profile details");
                    }}
                    fullWidth={isMobile}
                  >
                    Cancel
                  </Button>
                  <Button
                    xs={isMobile ? 6 : "auto"}
                    type="submit"
                    variant={isMobile ? "outlined" : "contained"}
                    disabled={submitting || hasValidationErrors || pristine}
                    color="success"
                    autoFocus
                    fullWidth={isMobile}
                  >
                    Save changes
                  </Button>
                </AutoGrid>
              </Box>
            </form>
          )}
        </Form>
      )}

      {isFormPasswordOpen && (
        <Form
          onSubmit={async values => {
            try {
              await resetPassword({
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
              });
              setFormPasswordOpen(false);
              onPasswordSubmit();
            } catch (ex) {
              return PythonApi.getFormErrors(ex);
            }
          }}
          initialValues={{
            currentPassword: "",
            newPassword: "",
            repeatPassword: ""
          }}
          validate={values => {
            const errors = passwordValidator(values);

            const password = values.newPassword || "";
            if (!policyManager.isValid(password)) {
              errors.newPassword = policyManager.getMessage();
            }

            if (/\s/.test(password)) {
              errors.newPassword = policyManager.getWhiteSpaceMessage();
            }

            return errors;
          }}
        >
          {({
            handleSubmit,
            submitError,
            submitting,
            pristine,
            hasValidationErrors
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}

              <Section margin="dense">
                <Header
                  variant="formSection"
                  number="1"
                  title="Edit your password"
                />
                <PasswordChecker policyManager={policyManager} flat />

                <input
                  type="password"
                  name="fake-password"
                  autoComplete="new-password"
                  tabIndex={-1}
                  style={{
                    display: "none"
                  }}
                />
                <Field
                  name="currentPassword"
                  label="Current password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  autoComplete="new-password"
                  component={TextControl}
                  validationIndicator
                />
                <Field
                  name="newPassword"
                  label="New password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                />
                <Field
                  name="repeatPassword"
                  label="Repeat password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  autoComplete="on"
                  component={TextControl}
                  validationIndicator
                />
              </Section>
              <Box mt={6}>
                <AutoGrid spacing={2} justify="flex-end">
                  <Button
                    xs={isMobile ? 6 : "auto"}
                    variant={isMobile ? "outlined" : "text"}
                    fullWidth={isMobile}
                    color="error"
                    disabled={submitting}
                    onClick={() => {
                      setFormPasswordOpen(false);
                      setDrawerTitle("Profile details");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    xs={isMobile ? 6 : "auto"}
                    variant={isMobile ? "outlined" : "contained"}
                    fullWidth={isMobile}
                    color="success"
                    type="submit"
                    disabled={submitting || hasValidationErrors || pristine}
                    autoFocus
                  >
                    Save changes
                  </Button>
                </AutoGrid>
              </Box>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};

export default ProfileDetails;
