import * as React from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";
import clsx from "clsx";

import { OrderBy, Direction } from "./data-table";
import useStyles from "./column-header.styles";

type ColumnConfig = {
  key: string;
  title: React.ReactNode;
  width?: string | number;
  sortable?: boolean;
  setWhiteSpaceNormal?: boolean;
};

type Props = {
  column: ColumnConfig;
  orderBy?: OrderBy;
  onSortChange: (direction: Direction) => void;
  sortable?: boolean;
};

const ColumnHeader: React.FC<Props> = ({
  column,
  orderBy,
  sortable,
  onSortChange
}) => {
  const classes = useStyles();
  if (!sortable || !column.sortable) {
    return (
      <TableCell
        key={column.key}
        variant="head"
        className={clsx(column.setWhiteSpaceNormal && classes.whiteSpaceNormal)}
      >
        {column.title}
      </TableCell>
    );
  }

  return (
    <TableCell
      key={column.key}
      variant="head"
      sortDirection={(orderBy && orderBy.direction) || false}
    >
      <TableSortLabel
        active={Boolean(orderBy && orderBy.columnName === column.key)}
        direction={orderBy && orderBy.direction}
        onClick={() => {
          onSortChange(
            switchSortDirection((orderBy && orderBy.direction) || "asc")
          );
        }}
      >
        {column.title}
      </TableSortLabel>
    </TableCell>
  );
};

const switchSortDirection = (direction: Direction) =>
  direction === "asc" ? "desc" : "asc";

export default ColumnHeader;
