import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing }: CustomTheme) => ({
  contentText: {
    color: palette.text.default,
    fontSize: "0.9rem"
  },
  dialogActions: {
    marginTop: spacing(2),
    width: "65%",
    justifyContent: "space-around"
  },
  dialogPaper: {
    padding: spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));
