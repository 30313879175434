import * as React from "react";
import moment from "moment";

import { Button } from "components";
import useToast from "utils/hooks/useToast";
import { downloadBlob } from "utils/fileDownload";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { useGenerateCourseReportMutation } from "modules/courses/provider/course-report";
import {
  useReportStatusCallback,
  useReportDownloadCallback,
  checkIfReportIsGenerated,
  ReportStatus
} from "modules/common/provider/reports";
import { IconGetApp } from "assets/icons";

type Props = Pick<MyCoursesListItem, "id" | "name"> & { isDisabled?: boolean };

const CourseReport: React.FC<Props> = ({ id: courseId, name, isDisabled }) => {
  const { showToast } = useToast();

  const generateCourseReport = useGenerateCourseReportMutation();
  const getReportStatus = useReportStatusCallback();
  const downloadReport = useReportDownloadCallback();

  const [reportIsBeingGenerated, setReportIsBeingGenerated] = React.useState(
    false
  );

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const onSuccessfulReportGeneration = () => {
    showToast(
      "Your report was prepared and it'll be downloaded now",
      "success"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const onUnsuccessfulReportGeneration = () => {
    showToast(
      "Something went wrong, try again in a moment or report a problem",
      "error"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const downloadReportAndBlob = async (reportId: string, filename: string) => {
    const { data: fileBlob } = await downloadReport({ reportId });
    fileBlob && downloadBlob(fileBlob, filename);
    onSuccessfulReportGeneration();
  };

  const downloadReportWhenReady = async (
    reportId: string,
    filename: string
  ) => {
    try {
      const { data: reportStatusData } = await getReportStatus({ reportId });

      if (reportStatusData?.status === ReportStatus.FAILURE) {
        onUnsuccessfulReportGeneration();
        return;
      }

      if (checkIfReportIsGenerated(reportStatusData?.status)) {
        await downloadReportAndBlob(reportId, filename);
        return;
      } else {
        setTimeout(() => downloadReportWhenReady(reportId, filename), 2000);
      }
    } catch (ex) {
      onUnsuccessfulReportGeneration();
    }
  };

  const handleDownloadReport = async () => {
    if (courseId) {
      setReportIsBeingGenerated(true);
      try {
        const { data: generateReportData } = await generateCourseReport({
          courseId
        });
        if (generateReportData) {
          const { status, id: reportId, createdAt } = generateReportData;
          const filename = `${name}-report-${moment(createdAt).format(
            "L"
          )}.xlsx`;
          if (checkIfReportIsGenerated(status)) {
            await downloadReportAndBlob(reportId, filename);
          } else {
            await downloadReportWhenReady(reportId, filename);
          }
        }
      } catch (ex) {
        onUnsuccessfulReportGeneration();
      }
    }
  };

  return (
    <Button
      onClick={handleDownloadReport}
      color="success"
      icon={IconGetApp}
      disabled={reportIsBeingGenerated || isDisabled}
    >
      {reportIsBeingGenerated ? "Preparing report..." : "Download report"}
    </Button>
  );
};

export default CourseReport;
