import * as React from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

import useStyles from "./content.styles";

type Props = {
  title?: string;
  text?: string;
  form?: boolean;
  className?: string;
};

const Content: React.FC<Props> = ({
  children,
  title,
  text,
  form = false,
  className
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, form && classes.formContainer)}>
      <div className={clsx(classes.content, className)}>
        {(title || text) && (
          <>
            <div className={classes.titles}>
              <Typography
                variant="h4"
                component="h1"
                color="textPrimary"
                gutterBottom
                classes={{ root: classes.title }}
              >
                {title}
              </Typography>
              {Boolean(text) && (
                <Typography
                  variant="body1"
                  paragraph
                  classes={{ root: classes.text }}
                >
                  {text}
                </Typography>
              )}
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};

export default Content;
