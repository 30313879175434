import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router";

import { ApiResultRenderer, AppLayout, Button, Section } from "components";
import { IconKeyboardArrowLeft } from "assets/icons";

import ProblemDetailsTable from "../components/problem-details-table.component";
import ProblemComments from "../components/problem-comments";
import EditProblemForm from "../components/problem-edit-form";
import { useAddCommentMutation, useProblem } from "../provider/problems";

import useStyles from "./problem-details.styles";

export default () => {
  const classes = useStyles();

  const { goBack } = useHistory();
  const { id: problemId } = useParams();

  const problem = useProblem(problemId!);

  const createComment = useAddCommentMutation();

  const handleSubmitComment = useCallback(
    async (text, isInternal) => {
      try {
        await createComment({ ticket: problemId, text, isInternal });
        problem.refetch();
      } catch (e) {}
    },
    [createComment, problemId, problem]
  );

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <AppLayout
      viewTitle="Details"
      titleCta={
        <Button
          icon={IconKeyboardArrowLeft}
          onClick={handleGoBack}
          color="secondary"
          variant="outlined"
        >
          Go back
        </Button>
      }
    >
      <ApiResultRenderer apiResult={problem} reloadsSection={false}>
        {({ data, loading }) => (
          <>
            <Section>
              <div className={classes.tableContainer}>
                <div className={classes.table}>
                  <ProblemDetailsTable problem={data} loading={loading} />
                </div>
              </div>
            </Section>
            <Section className={classes.detailsContainer}>
              <div className={classes.details}>
                <EditProblemForm problem={data} onCancel={handleGoBack} />
              </div>
              <div className={classes.comments}>
                <ProblemComments
                  comments={data?.comments}
                  onSubmit={handleSubmitComment}
                />
              </div>
            </Section>
          </>
        )}
      </ApiResultRenderer>
    </AppLayout>
  );
};
