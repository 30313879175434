import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import clsx from "clsx";

import { Button } from "components";

import { NoAccessResp } from "../../../provider/learning-plan";

import useStyles from "./message-modal.styles";

type Props = {
  isOpen: boolean;
  data: NoAccessResp;
  onClose: () => void;
};

const MessageModal: React.FC<Props> = ({ isOpen, data, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        {data.map(item => (
          <div key={item?.group?.id}>
            <DialogContentText
              align="center"
              className={clsx(classes.contentText, classes.company)}
            >
              {item?.group?.name} company hasn’t got access to these courses:
            </DialogContentText>
            {item?.courses?.map(course => (
              <DialogContent key={course?.id} className={classes.course}>
                {course?.name}
              </DialogContent>
            ))}
          </div>
        ))}
      </DialogContent>
      <DialogContent>Please add these courses in Companies tab</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="contained" color="success">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;
