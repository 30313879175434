import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const SuccessImage = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 173 121" fill="none" {...props}>
    <circle cx="89.5801" cy="60.5" r="60.5" fill="#40BEAF" fillOpacity="0.11" />
    <path
      d="M78.0801 63.9773L99.8178 86L156.08 29"
      stroke="#40BEAF"
      strokeWidth="6"
      strokeLinecap="round"
      fill="none"
    />
    <rect x="0.0800781" y="16" width="14" height="2" rx="1" fill="#E1E2E8" />
    <rect
      x="8.08008"
      y="10"
      width="14"
      height="2"
      rx="1"
      transform="rotate(90 8.08008 10)"
      fill="#E1E2E8"
    />
    <rect x="158.08" y="106" width="14" height="2" rx="1" fill="#E1E2E8" />
    <rect
      x="166.08"
      y="100"
      width="14"
      height="2"
      rx="1"
      transform="rotate(90 166.08 100)"
      fill="#E1E2E8"
    />
  </SvgIcon>
);

export default SuccessImage;
