import * as React from "react";
import { Tooltip as MuiTooltip, Typography, Divider } from "@material-ui/core";

import useStyles from "./tooltip-list.styles";

export type Props = {
  title: string;
  list: string[];
};

const TooltipList: React.FC<Props> = ({ title, list }) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      classes={{ tooltip: classes.tooltip }}
      placement="right-start"
      title={
        <>
          <Typography className={classes.title}>{title}</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.content}>{list.join(", ")}</Typography>
        </>
      }
    >
      <span className={classes.more}>{`+${list.length - 1} more`}</span>
    </MuiTooltip>
  );
};

export default TooltipList;
