import * as React from "react";

import { Pagination } from "components";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";

type TenantsListPaginationProps = {
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
};

const TenantsListPagination: React.FC<TenantsListPaginationProps> = ({
  meta,
  listFilters
}) =>
  meta && meta.total > 0 ? (
    <Pagination
      totalCount={meta.total}
      onChange={listFilters.setPagination}
      rowsPerPage={listFilters.pagination.rowsPerPage}
      page={listFilters.pagination.page}
    />
  ) : null;

export default TenantsListPagination;
