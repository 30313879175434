import * as React from "react";
import { TablePagination } from "@material-ui/core";

import { PaginationState } from "utils/hooks/useListFilters";

interface Props {
  offset?: number;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  totalCount: number;
  onChange?: (nextPagination: PaginationState) => void;
}

const Pagination: React.FC<Props> = ({
  offset,
  page,
  onChange = () => {},
  rowsPerPage = 10,
  rowsPerPageOptions = [5, 10, 25],
  totalCount
}) => {
  const currentPage =
    typeof page !== "undefined"
      ? page - 1
      : offsetToPage(offset || 0, rowsPerPage);

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={rowsPerPage}
      count={totalCount}
      page={currentPage}
      onChangePage={(e, newPage) => {
        onChange({
          page: newPage,
          offset: pageToOffset(newPage, rowsPerPage),
          rowsPerPage
        });
      }}
      onChangeRowsPerPage={e => {
        onChange({
          page: 0,
          offset: 0,
          rowsPerPage: +e.target.value
        });
      }}
    />
  );
};

const offsetToPage = (offset: number, rowsPerPage: number) =>
  Math.trunc(offset / rowsPerPage);
const pageToOffset = (page: number, rowsPerPage: number) => page * rowsPerPage;

export default Pagination;
