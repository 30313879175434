import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing, palette }: CustomTheme) => ({
  notify: {
    textAlign: "center",
    color: palette.text.hint,
    marginTop: spacing(4),
    marginBottom: spacing(4)
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 42
  },
  treeLabelContainer: { display: "contents" },
  labelName: {
    flex: 1,
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    margin: "auto 0"
  },
  arrow: {
    margin: "auto 0 auto -6px" // vertical centerize and move left
  },
  children: {
    marginLeft: spacing(2) + 2 // +2 - arrow left offset
  },
  container: {
    padding: spacing(3)
  },
  list: {
    maxWidth: 400,
    maxHeight: "calc(100vh - 275px)",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    overscrollBehavior: "contain",
    marginTop: spacing(2),
    marginBottom: spacing(3)
  },
  pointer: {
    cursor: "pointer"
  },
  checkbox: {
    marginRight: spacing(1)
  }
}));
