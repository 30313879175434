import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => ({
  formContent: {
    paddingRight: spacing(3)
  },
  addressField: {
    position: "relative"
  },
  addressButton: {
    position: "absolute",
    marginLeft: spacing(1.5),
    marginTop: spacing(3)
  },
  clearIcon: {
    color: palette.error.main,
    "&:hover": {
      backgroundColor: palette.error.light
    }
  }
}));
