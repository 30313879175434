import * as React from "react";
import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";

import { useAuth } from "services/auth-provider";
import { Header, SelectControl, Section } from "components";
import { userRoleOptions, UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type UserRoleSectionProps = {
  sectionIndex: number;
};

const UserRoleSection: React.FC<UserRoleSectionProps> = ({ sectionIndex }) => {
  const tenant = useTenant();
  const auth = useAuth();
  const isInternal = auth.checkAccess({ roles: [UserRole.INTERNAL_ADMIN] });

  const isNoSponsorAndAdmins = tenant.isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );

  const getRoleOptions = () => {
    if (isInternal) {
      return userRoleOptions.filter(option =>
        [UserRole.STUDENT, UserRole.TENANT_ADMIN].includes(option.value)
      );
    } else if (isNoSponsorAndAdmins) {
      return userRoleOptions.filter(
        option =>
          ![UserRole.INTERNAL_ADMIN, UserRole.TENANT_ADMIN].includes(
            option.value
          )
      );
    }
    return userRoleOptions;
  };

  return (
    <Section margin="dense">
      <Header
        variant="formSection"
        number={sectionIndex}
        title="Choose user role"
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name="role"
            label="Role (required)"
            component={SelectControl}
            options={getRoleOptions()}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default UserRoleSection;
