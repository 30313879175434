import { makeStyles } from "@material-ui/core";

export default makeStyles(({ breakpoints, palette, spacing, typography }) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "stretch"
  },
  container: {
    padding: spacing(3, 2),
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: "100%",
    overflowX: "auto",

    [breakpoints.up("sm")]: {
      padding: spacing(8, 7)
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "stretch"
    },
    [breakpoints.down("sm")]: {
      marginBottom: spacing(4)
    }
  },
  headerToolbar: {
    display: "flex",

    [breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      marginBottom: spacing(6)
    }
  },
  menuTriggerButton: {
    marginLeft: -spacing(1.5),
    color: palette.text.primary
  },
  content: {},
  viewTitle: {
    ...typography.h4,
    color: palette.text.primary,
    display: "flex",
    alignItems: "center",
    margin: spacing(0, 3),

    [breakpoints.up("sm")]: {
      order: 2,
      margin: 0,
      marginBottom: spacing(6),
      ...typography.h1,

      "&$hideTitle": {
        display: "none"
      }
    }
  },
  hideTitle: {},
  titleCta: { margin: spacing(0, 3) }
}));
