import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess, useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";
import routes from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import CourseListAdminView from "../views/course-list.view";
import CourseDetailsAdminView from "../views/course-details.view";
import { useModuleState, actionTypes } from "../reducers/courses";
import LearningPlanDetailsView from "../views/learning-plan-details.view";
import LearningPlanListView from "../views/learning-plan-list.view";

type Props = {
  match: match;
};

const CoursesRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_COURSES_FILTERS });
    },
    [dispatch]
  );
  const tenant = useTenant();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const auth = useAuth();
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });

  return (
    <Switch>
      <Route exact path={match.path}>
        <>
          <HasAccess roles={[UserRole.ADMIN, UserRole.DESIGNER]}>
            <CourseListAdminView />
          </HasAccess>
          <HasAccess roles={[UserRole.TENANT_ADMIN]}>
            <Redirect to={routes.courses.approval.list} />
          </HasAccess>
        </>
      </Route>

      {isLearningPlans && (
        <Route
          path={`${match.path}/learning-plan`}
          component={LearningPlanListView}
          exact
        />
      )}

      {isLearningPlans && !isDesigner && (
        <Route
          path={`${match.path}/learning-plan/:learningPlanId`}
          component={LearningPlanDetailsView}
        />
      )}

      <Route
        path={`${match.path}/:courseId`}
        component={CourseDetailsAdminView}
      />

      <Redirect to={match.path} />
    </Switch>
  );
};

export default CoursesRouter;
