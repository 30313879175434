import { useFetchMutation } from "utils/hooks/useFetch";
import * as PythonApi from "services/api/python";
import { BASE_URLS } from "services/api";
import apiConfig from "config/api";

export const useUploadOptions = () => {
  type Vars = {};
  type Item = {
    sasToken: string;
    uploadId: string;
    storageUrl: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Vars, Item>(() => {
      return {
        baseURL: BASE_URLS.PYTHON_API,
        method: "GET",
        url: apiConfig.paths.authorized.media.generateStorageUrl,
        timeout: 0
      };
    })
  );
};
