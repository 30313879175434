import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ spacing, typography, breakpoints, shadows }: CustomTheme) => ({
    root: {
      padding: spacing(2, 3),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: 200,
      minWidth: 200,
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      boxShadow: shadows[4],

      [breakpoints.up("sm")]: {
        minHeight: 250,
        padding: spacing(4, 5)
      },

      "&:hover": {
        transform: "scale(1.05)"
      }
    },
    contentContainer: {
      flexGrow: 1,
      marginBottom: spacing(2)
    },
    header: {
      display: "flex",
      flexWrap: "wrap"
    },
    name: {
      color: typography.body1.color,
      minWidth: "fit-content",
      marginRight: spacing(2),
      fontSize: "1.125rem",

      [breakpoints.up("sm")]: {
        fontSize: "1.5rem"
      }
    },
    lpId: {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: typography.body2.color,
      fontSize: "1.125rem",

      [breakpoints.up("sm")]: {
        fontSize: "1.5rem"
      }
    },
    status: {
      height: 32
    },
    total: {
      color: typography.body2.color,
      fontSize: typography.pxToRem(16),
      marginTop: spacing(2),
      marginBottom: spacing(2)
    },
    textWithIcon: {
      display: "flex",
      alignItems: "center",
      marginBottom: spacing(1),
      fontSize: "0.875rem"
    },
    icon: {
      fontSize: "1rem",
      marginRight: spacing(1)
    }
  })
);
