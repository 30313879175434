import * as React from "react";
import { Form, Field, FormSpy } from "react-final-form";

import {
  Header,
  TextControl,
  FormError,
  Section,
  SelectControl,
  CancelConfirmButtons
} from "components";
import { createValidator } from "utils/forms";
import * as PythonApi from "services/api/python";
import {
  CompanyItem,
  CompanyCreateUpdateVars
} from "modules/companies/provider/companies";
import useListFilters from "utils/hooks/useListFilters";
import { UserRole } from "modules/users/users.constants";

import { useUsersList } from "../../users/provider";

const validator = createValidator({
  name: { presence: { allowEmpty: false } },
  externalId: {
    presence: { allowEmpty: false },
    format: {
      pattern: /\S*/,
      message: ` can't contain whitespaces`
    }
  }
});

type GroupCreateUpdateVars = Pick<
  CompanyCreateUpdateVars,
  "adminsIds" | "externalId" | "name"
>;
type Props = {
  group?: CompanyItem;
  onSubmit: (props: GroupCreateUpdateVars) => void;
  onCancel: () => void;
  setIsModalEnabled: (isEnabled: boolean) => void;
};

const GroupRecord: React.FC<Props> = ({
  onSubmit,
  onCancel,
  group,
  setIsModalEnabled
}) => {
  const isEdit = Boolean(group);

  const adminsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    filters: {
      roleName: PythonApi.prepareFilterValues([
        UserRole.TENANT_ADMIN,
        UserRole.INTERNAL_ADMIN
      ])
    }
  });
  const admins = useUsersList({
    variables: { listFilters: adminsListFilters }
  });

  const initialValues = React.useMemo(() => {
    if (group) {
      const { name, admins, externalId } = group;
      return {
        name,
        externalId,
        adminsIds: admins?.map(admin => admin.id)
      };
    } else {
      return {
        name: "",
        externalId: "",
        adminsIds: []
      };
    }
  }, [group]);

  const handleSubmit = React.useCallback(
    async ({ name, externalId, adminsIds }) => {
      try {
        await onSubmit({ name, externalId, adminsIds });
      } catch (ex) {
        return PythonApi.getFormErrors(ex);
      }
    },
    [onSubmit]
  );

  const adminsOptions = React.useMemo(
    () =>
      admins.data
        ? admins.data.map(admin => ({
            value: admin.id,
            label: `${admin.firstName} ${admin.lastName}`
          }))
        : [],
    [admins.data]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validator}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        pristine,
        hasValidationErrors
      }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ pristine: true }}
            onChange={({ pristine }) => {
              setIsModalEnabled(!pristine);
            }}
          />

          {submitError && <FormError>{submitError}</FormError>}

          <Section margin="dense">
            <Header
              variant="formSection"
              number="1"
              title="Enter group details"
            />
            <Field
              name="name"
              label="Group name"
              variant="outlined"
              margin="normal"
              component={TextControl}
              validationIndicator
            />

            <Field
              name="externalId"
              label="External group ID"
              variant="outlined"
              margin="normal"
              component={TextControl}
              validationIndicator
            />
          </Section>

          <Section margin="dense">
            <Header
              variant="formSection"
              number="2"
              title="Assign admins"
              hint="(optional)"
            />
            <Field
              multiple
              allowMultiple
              name="adminsIds"
              label="Choose admins"
              component={SelectControl}
              options={adminsOptions}
              isLoading={admins.loading}
            />
          </Section>

          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel={isEdit ? "Edit group" : "Add group"}
            onCancel={onCancel}
          />
        </form>
      )}
    </Form>
  );
};

export default GroupRecord;
