import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  logoTitle: {
    marginTop: spacing(5),
    fontWeight: 300,
    color: "#000000",
    [breakpoints.up("xs")]: {
      fontSize: "1.5rem"
    },
    [breakpoints.up("sm")]: {
      marginTop: spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      fontSize: "1.7rem"
    },
    [breakpoints.up("md")]: {
      marginTop: spacing(4),
      marginRight: 0,
      marginLeft: 0,
      fontSize: "1.3rem"
    },
    [breakpoints.up("lg")]: {
      marginTop: spacing(4),
      fontSize: "1.5rem"
    }
  }
}));
