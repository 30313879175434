import * as React from "react";

import { Button, ReportModal, Drawer, ClosingModal } from "components";
import { IconAssessment, IconLink, SettingsIcon } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import apiConfig from "config/api";
import useToast from "utils/hooks/useToast";
import {
  useTenantConfigUpdate,
  TenantItem
} from "modules/tenants/provider/tenants";

import EditTenantsConfig from "./tenants-config-edit.component";

type TenantsListRowActionsProps = {
  tenant: TenantItem;
  refetch: () => void;
};

const TenantsListRowActions: React.FC<TenantsListRowActionsProps> = ({
  tenant,
  refetch
}) => {
  const tenantCtx = useTenant();

  const { showToast } = useToast();

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  const editConfig = useTenantConfigUpdate(tenant?.hostname || "");

  const handleEditSubmit = React.useCallback(
    async values => {
      await editConfig(values);
      setDrawerOpen(false);
      showToast("Tenant settings was successfully updated", "success");
      refetch();
    },
    [refetch, editConfig, showToast]
  );

  return (
    <>
      {Boolean(tenant.url) && (
        <Button
          href={tenant.url}
          target="_blank"
          style={{ marginBottom: "-7px" }}
          variant="text"
          color="secondary"
          icon={IconLink}
        >
          Open Portal
        </Button>
      )}
      {tenantCtx.isComponentAllowed(AllowedComponents.ReportPerformance) && (
        <ReportModal
          reportTypes={[
            {
              label: "Overall performance",
              value: "overallPerformance",
              requestPath: apiConfig.paths.authorized.report.createForTenant(
                tenant.hostname
              )
            }
          ]}
          filePrefix={tenant.hostname}
        >
          {({ generating, handleOpen }) => (
            <Button
              onClick={handleOpen}
              style={{ marginBottom: "-7px" }}
              variant="text"
              color="secondary"
              icon={IconAssessment}
              disabled={generating}
            >
              {generating ? "Preparing report..." : "Generate report"}
            </Button>
          )}
        </ReportModal>
      )}
      {tenantCtx.isComponentAllowed(AllowedComponents.FeatureToggleList) &&
        tenant.config?.access?.isFeatureToggleList && (
          <Button
            variant="text"
            color="secondary"
            icon={SettingsIcon}
            onClick={() => setDrawerOpen(true)}
          >
            Settings
          </Button>
        )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title={`${tenant.name} Settings`}
        size="sm"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <EditTenantsConfig
          tenantId={tenant.hostname}
          onCancel={() => setDrawerOpen(false)}
          onSubmit={handleEditSubmit}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );
};

export default TenantsListRowActions;
