import * as React from "react";
import { Typography } from "@material-ui/core";

import useStyles from "./card.styles";

export type Props = {
  number: number;
};

const CardCounter: React.FC<Props> = ({ number }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.number} variant="h6">
      {number}
    </Typography>
  );
};

export default CardCounter;
