import * as React from "react";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";

import useToast from "utils/hooks/useToast";
import * as PythonApi from "services/api/python";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";
import {
  ResponsiveTableWrapper,
  DataTable,
  Button,
  AutoGrid,
  Pagination,
  LoadingPlaceholder
} from "components";
import useStyles from "components/data-table/data-table.styles";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";
import { useProcessSubmissionCreate } from "modules/courses/provider";
import { SubmissionsListItem } from "modules/courses/provider/courses-submissions";
import { IconCheck, IconClear } from "assets/icons";

import SubmissionListToolbar from "./submission-list-toolbar";

type Props = {
  submissions: SubmissionsListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetchAll: () => void;
  loading: boolean;
};

const SubmissionList: React.FC<Props> = ({
  listFilters,
  submissions,
  meta,
  loading,
  refetchAll
}) => {
  const { showToast } = useToast();
  const approveSubmission = useProcessSubmissionCreate("accept");
  const rejectSubmission = useProcessSubmissionCreate("reject");
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();

  const [submitting, setSubmitting] = React.useState(false);
  const handleSubmissionAction = async (
    operationType: "accept" | "reject",
    submissionId: string
  ) => {
    try {
      setSubmitting(true);
      operationType === "accept"
        ? await approveSubmission({ submissionId })
        : await rejectSubmission({ submissionId });
      setShouldRefetchIndicatorsToTrue();
      refetchAll();
    } catch (ex) {
      const errorMessage = PythonApi.getMessageFromError(ex);
      showToast(errorMessage as string, "error");
    } finally {
      setSubmitting(false);
    }
  };
  const classes = useStyles();
  return (
    <>
      <SubmissionListToolbar listFilters={listFilters} />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <ResponsiveTableWrapper>
            <DataTable
              emptyText="No submissions"
              data={submissions}
              columns={[
                {
                  key: "firstName",
                  title: "First name",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "user.firstName")}
                    </span>
                  )
                },
                {
                  key: "lastName",
                  title: "Last name",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "user.lastName")}
                    </span>
                  )
                },
                {
                  key: "courseName",
                  title: "Course",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "course.name")}
                    </span>
                  )
                },
                {
                  key: "externalId",
                  title: "Course ID",
                  render: submission => (
                    <span
                      className={clsx(classes.ellipsis, classes.customText)}
                    >
                      {_.get(submission, "course.externalId")}
                    </span>
                  )
                },
                {
                  key: "requestDate",
                  title: "Request date",
                  render: submission =>
                    moment(_.get(submission, "createdAt")).format("L")
                },
                {
                  key: "actions",
                  title: "Actions",
                  width: "1%",
                  render: submission => (
                    <AutoGrid spacing={2} wrap="nowrap" justify="space-between">
                      <Button
                        onClick={() =>
                          handleSubmissionAction("accept", submission.id)
                        }
                        icon={IconCheck}
                        variant="text"
                        color="success"
                        disabled={submitting}
                        defaultHoverColor
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() =>
                          handleSubmissionAction("reject", submission.id)
                        }
                        icon={IconClear}
                        variant="text"
                        color="error"
                        disabled={submitting}
                        defaultHoverColor
                      >
                        Reject
                      </Button>
                    </AutoGrid>
                  )
                }
              ]}
            />
          </ResponsiveTableWrapper>

          <AutoGrid justify="flex-end" showEmpty>
            {meta && meta.total > 0 && (
              <Pagination
                totalCount={meta.total}
                onChange={listFilters.setPagination}
                rowsPerPage={listFilters.pagination.rowsPerPage}
                page={listFilters.pagination.page}
              />
            )}
          </AutoGrid>
        </>
      )}
    </>
  );
};

export default SubmissionList;
