import * as React from "react";

import { UserRole, userRoleOptions } from "modules/users/users.constants";

import Chip, { ChipVariant } from "./chip";

const roleVariants: Record<UserRole, ChipVariant> = {
  [UserRole.STUDENT]: "default",
  [UserRole.TENANT_ADMIN]: "info",
  [UserRole.INTERNAL_ADMIN]: "info",
  [UserRole.ADMIN]: "primary",
  [UserRole.DESIGNER]: "secondary"
};

interface Props {
  role?: UserRole;
}

const RoleChip: React.FC<Props> = ({ role }) => {
  if (!role) {
    return null;
  }
  return (
    <Chip
      label={
        userRoleOptions.find(option => option.value === role)?.label ||
        "Unknown"
      }
      size="small"
      variant={roleVariants[role]}
    />
  );
};

export default RoleChip;
