import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    textDecoration: "none",
    width: "100%"
  },
  input: {
    padding: 13,
    fontSize: "1rem",
    "&::-ms-clear": {
      display: "none"
    }
  },
  search: {
    borderRadius: 100,
    backgroundColor: palette.hint.faded,

    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  inputAdornment: {
    color: palette.hint.main
  },
  inputAdornmentSearch: {
    marginLeft: spacing(3)
  },
  inputAdornmentClear: {
    marginRight: spacing(2)
  },
  icon: {
    fontSize: "1rem"
  }
}));
