import { AxiosError } from "axios";

import {
  IRestAuthEndpoints,
  RefreshTokenError
} from "services/auth-provider/clients/RestApiClient";
import {
  RestApiClient,
  CheckAccessOptions,
  LoginWithUserAndPasswordParams
} from "services/auth-provider";
import API from "services/api";
import * as PythonApi from "services/api/python";
import { deleteCoursesCookies } from "utils/browser";
import { UserRole } from "modules/users/users.constants";
import * as storageConfig from "config/storage";
import apiConfig from "config/api";

export const endpointsMapping: IRestAuthEndpoints = {
  loginWithUserAndPassword: async ({ username, password }) => {
    try {
      const response = await API.post(apiConfig.paths.unauthorized.login, {
        username,
        password
      });

      const { access: accessToken, refresh: refreshToken } = response.data;

      return {
        data: { accessToken, refreshToken }
      };
    } catch (ex) {
      return { data: null, error: ex };
    }
  },
  identity: async token => {
    try {
      const response = await API.get(apiConfig.paths.authorized.user.current, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      API.defaults.headers.Authorization = `Bearer ${token}`;

      return { data: response.data };
    } catch (ex) {
      return { data: null, error: ex };
    }
  },
  refreshToken: async refreshToken => {
    try {
      delete API.defaults.headers.Authorization;

      // todo can be fail when open >1 browser page
      //  creating localStorage record like refreshingToken and waiting before this flag exists can resolve the problem
      const response = await API.post(
        apiConfig.paths.authorized.auth.refreshToken,
        {
          refresh: refreshToken
        }
      );

      const { access: accessToken, refresh: newRefreshToken } = response.data;

      API.defaults.headers.Authorization = `Bearer ${accessToken}`;

      return {
        data: { accessToken, refreshToken: newRefreshToken }
      };
    } catch (ex) {
      const exception = ex as AxiosError;
      const errorMessage = PythonApi.getMessageFromError(exception) as string;
      const error = new Error(errorMessage) as RefreshTokenError;
      error.isExpired = Boolean(
        exception.response && exception.response.status >= 400
      );

      return { data: null, error };
    }
  }
};

export class CustomRestApiClient extends RestApiClient {
  instance: number;
  shouldAutoRefresh: boolean;
  constructor(mappings: IRestAuthEndpoints, storage: Storage) {
    super(mappings, storage);
    const couldAutoRefresh = !!storage.getItem(
      storageConfig.default.shared.accessToken
    );
    this.instance = couldAutoRefresh ? Date.now() : 0;
    this.shouldAutoRefresh = couldAutoRefresh;
    storage.setItem(
      storageConfig.default.shared.instance,
      this.instance.toString()
    );

    window.addEventListener("beforeunload", () => {
      this.shouldAutoRefresh = false;
      storage.setItem(storageConfig.default.shared.instance, "0");
    });

    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === storageConfig.default.shared.instance) {
        if (+newValue! > this.instance) {
          this.shouldAutoRefresh = false;
        } else {
          this.shouldAutoRefresh = true;
          storage.setItem("instance", this.instance.toString());
        }
      }
      if (key === storageConfig.default.shared.accessToken) {
        if (newValue) {
          API.defaults.headers.Authorization = `Bearer ${newValue}`;
        } else {
          this.logout();
        }
      }
    });
  }

  //todo ref: use string | []string instead of { roles }
  checkAccess({ roles }: CheckAccessOptions) {
    if (!this.isAuthenticated || !this.user) {
      return false;
    }
    if (!roles) {
      return true;
    }

    // If user has internal admin role, then it's equivalent to tenant admin role
    // If user has tenant admin role, then it's not equivalent to internal admin role
    const userRole = this.user.role;
    if (userRole === UserRole.INTERNAL_ADMIN) {
      return roles.includes(userRole) || roles.includes(UserRole.TENANT_ADMIN);
    } else {
      return roles.includes(userRole);
    }
  }
  shouldRefreshTokens() {
    return this.shouldAutoRefresh && super.shouldRefreshTokens();
  }
  async logout() {
    this.shouldAutoRefresh = false;
    this.instance = 0;
    deleteCoursesCookies();
    return super.logout();
  }
  async loginWithUserAndPassword(params: LoginWithUserAndPasswordParams) {
    await super.loginWithUserAndPassword(params);
    this.instance = Date.now();
    this.shouldAutoRefresh = true;
    this.storage.setItem(
      storageConfig.default.shared.instance,
      this.instance.toString()
    );
  }
}

const authClient = new CustomRestApiClient(
  endpointsMapping,
  window.localStorage
);

export default authClient;
