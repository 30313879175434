import * as React from "react";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";

import { Button, Header } from "components";

import useStyles from "./closing-modal.styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ClosingModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Header
          title={
            <span className={classes.modalText}>
              Are you sure you want to close the panel <br /> and lose your
              data?
            </span>
          }
          variant="section"
        />
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="success">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClosingModal;
