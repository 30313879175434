import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, typography }) => ({
  item: {
    display: "flex",
    alignItems: "center"
  },
  label: {
    ...typography.h4,
    color: palette.hint.main,
    marginRight: spacing(1)
  },
  userData: {
    ...typography.h4,
    color: palette.text.default
  }
}));
