import * as React from "react";
import { Typography } from "@material-ui/core";

import useStyles from "./empty-list-placeholder.styles";

type Props = {
  text: string;
};

const EmptyListPlaceholder: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

export default EmptyListPlaceholder;
