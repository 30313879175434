import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { useModuleState, actionTypes } from "modules/courses/reducers/history";

import CourseListHistoryView from "../views/course-list-history.view";
import CourseDetailsHistoryView from "../views/course-details-history.view";

type Props = {
  match: match;
};

const HistoryRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_HISTORY_FILTERS });
    },
    [dispatch]
  );
  return (
    <Switch>
      <Route exact path={match.path} component={CourseListHistoryView} />
      <Route
        path={`${match.path}/:courseId`}
        component={CourseDetailsHistoryView}
      />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default HistoryRouter;
