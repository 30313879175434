import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { sessionStorage } from "globalthis/implementation";
import Popover from "@material-ui/core/Popover";
import { Typography } from "@material-ui/core";

import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";
import {
  ResponsiveTableWrapper,
  DataTable,
  Button,
  AutoGrid,
  Pagination,
  LoadingPlaceholder
} from "components";
import useTableStyles from "components/data-table/data-table.styles";
import ProblemStatusChip from "components/chip/problem-status-chip";
import { IconCheck, IconClose, IconCreate } from "assets/icons";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import routes from "config/routes";

import { ProblemsListItem } from "../provider/problems";
import { Problems, ProblemTypes } from "../problems.constants";

import ProblemsListToolbar from "./problems-list-toolbar.component";
import useStyles from "./problems-list.styles";

type Props = {
  problems: ProblemsListItem[];
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
};

const ProblemsList: React.FC<Props> = ({
  problems,
  meta,
  listFilters,
  refetch,
  loading
}) => {
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const { push } = useHistory();
  const tenant = useTenant();

  const isProblemsUserDetails = tenant.isComponentAllowed(
    AllowedComponents.ProblemsUserDetails
  );

  const isProblemsTableSortable = tenant.isComponentAllowed(
    AllowedComponents.ProblemsTableSortable
  );

  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  const setProblemInStorage = (problemId: string) =>
    sessionStorage.setItem("problemId", problemId);

  const getSenderNameTable = (fullName: string) => {
    return (
      <span
        className={clsx(tableClasses.ellipsis, tableClasses.customText)}
        title={fullName}
      >
        {fullName}
      </span>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <ProblemsListToolbar listFilters={listFilters} />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <ResponsiveTableWrapper>
            <DataTable
              emptyText="No problem reports"
              data={problems}
              //selectedRow={problemForEdition}
              sortable={isProblemsTableSortable}
              listFilters={listFilters}
              columns={[
                {
                  key: "sender",
                  title: "Sender name",
                  sortable: true,
                  render: ({ id, sender, senderFirstName, senderLastName }) => {
                    const fullName = sender
                      ? `${sender.firstName} ${sender.lastName}`
                      : `${senderFirstName} ${senderLastName}`;
                    return isProblemsUserDetails ? (
                      sender ? (
                        <Button
                          onClick={() => setProblemInStorage(id)}
                          component={RouterLink}
                          to={`/users/${sender.id}`}
                          variant="text"
                        >
                          {getSenderNameTable(fullName)}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            aria-owns={
                              isOpen ? "mouse-over-popover" : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            variant="text"
                          >
                            {getSenderNameTable(fullName)}
                          </Button>

                          <Popover
                            id="mouse-over-popover"
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            className={classes.popover}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right"
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "left"
                            }}
                            disableRestoreFocus
                          >
                            <Typography className={classes.popoverContent}>
                              This problem was reported by a non-logged in user
                            </Typography>
                          </Popover>
                        </div>
                      )
                    ) : (
                      getSenderNameTable(fullName)
                    );
                  }
                },
                {
                  key: "senderEmail",
                  title: "Sender email",
                  sortable: true,
                  render: ({ sender, senderEmail }) => {
                    const email = sender?.email || senderEmail;
                    return (
                      <AutoGrid
                        spacing={2}
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Button
                          variant="text"
                          color="default"
                          href={`mailto:${email}`}
                          target="_blank"
                          title={email}
                        >
                          {email}
                        </Button>
                      </AutoGrid>
                    );
                  }
                },
                {
                  key: "senderPhone",
                  title: "Sender phone",
                  sortable: true,
                  render: ({ sender, senderPhoneNumber }) =>
                    sender ? sender.phone || "-" : senderPhoneNumber || "-"
                },
                {
                  key: "assignee",
                  title: "Assignee name",
                  sortable: true,
                  render: ({ assignee }) => {
                    const assigneeName = `${assignee?.firstName} ${assignee?.lastName}`;
                    const problemHasAssignee =
                      Boolean(assignee?.firstName) &&
                      Boolean(assignee?.lastName);

                    return (
                      <span
                        className={clsx(
                          tableClasses.ellipsis,
                          tableClasses.customText
                        )}
                      >
                        {problemHasAssignee ? assigneeName : "-"}
                      </span>
                    );
                  }
                },
                {
                  key: "type",
                  title: "Type",
                  sortable: true,
                  render: ({ type }) => ProblemTypes[type]
                },
                {
                  key: "courseId",
                  title: "Course id",
                  sortable: false,
                  render: ({
                    course,
                    courseExternalId,
                    type,
                    freeTextCourse
                  }) => (
                    <>
                      {type === Problems.UNABLE_TO_FIND_A_COURSE ? (
                        freeTextCourse ? (
                          <span className={clsx(tableClasses.customText)}>
                            {freeTextCourse}
                          </span>
                        ) : (
                          "-"
                        )
                      ) : course ? (
                        <Button
                          variant="text"
                          target={isDetailsNewTab ? "_blank" : "_self"}
                          component={RouterLink}
                          to={`/courses/${course}`}
                        >
                          <span
                            className={clsx(
                              tableClasses.ellipsis,
                              tableClasses.customText
                            )}
                          >
                            {courseExternalId || course}
                          </span>
                        </Button>
                      ) : (
                        "-"
                      )}
                    </>
                  )
                },
                {
                  key: "status",
                  title: "Status",
                  sortable: true,
                  render: ({ status }) => <ProblemStatusChip status={status} />
                },
                {
                  key: "file",
                  title: "Attached file",
                  render: ({ fileUrl }) =>
                    Boolean(fileUrl) ? (
                      <IconCheck
                        className={classes.attachedFileIcon}
                        fontSize="small"
                      />
                    ) : (
                      <IconClose color="error" fontSize="small" />
                    )
                },
                {
                  key: "createdAt",
                  title: "Report date",
                  sortable: true,
                  render: ({ createdAt }) => moment(createdAt).format("L")
                },
                {
                  key: "actions",
                  title: "Actions",
                  width: "1%",
                  render: problem => (
                    <AutoGrid spacing={2} wrap="nowrap" justify="space-between">
                      <Button
                        // onClick={() => openEditProblemDrawer(problem)}
                        onClick={() => {
                          push(
                            routes.problems.details.replace(":id", problem.id)
                          );
                        }}
                        icon={IconCreate}
                        color="secondary"
                      >
                        Edit details
                      </Button>
                    </AutoGrid>
                  )
                }
              ]}
            />
          </ResponsiveTableWrapper>
          <AutoGrid justify="flex-end" showEmpty>
            {meta && meta.total > 0 && (
              <Pagination
                totalCount={meta.total}
                onChange={listFilters.setPagination}
                rowsPerPage={listFilters.pagination.rowsPerPage}
                page={listFilters.pagination.page}
              />
            )}
          </AutoGrid>
        </>
      )}
    </>
  );
};

export default ProblemsList;
