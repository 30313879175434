import * as React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useHistory } from "react-router";

import {
  Header,
  TextControl,
  FormError,
  Button,
  Section,
  TextEditorControl,
  CheckboxControl,
  CancelConfirmButtons
} from "components";
import { createValidator } from "utils/forms";
import { parseDuration, formatDuration } from "utils/datetime";
import { removeHtmlTags } from "utils/strings";
import useToast from "utils/hooks/useToast";
import * as PythonAPI from "services/api/python";
import {
  CourseItem,
  useCourseCreate,
  useCourseUpdate
} from "modules/courses/provider/courses";
import { IconAdd, IconClear, IconInfo } from "assets/icons";
import routes, { queries } from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import CoursesSelect from "./courses-select";
import useStyles from "./course-add-or-update.styles";

type Props = {
  course?: CourseItem; // edit when exits
  onCancel: () => void;
  onSubmit: () => void;
};

type Link = {
  title: string;
  url: string;
};

const AddOrUpdateCourse: React.FC<Props> = ({ course, onCancel, onSubmit }) => {
  const { showToast } = useToast();
  const createCourse = useCourseCreate();
  const updateCourse = useCourseUpdate(course?.id || "");
  const classes = useStyles();
  const history = useHistory();

  const tenant = useTenant();
  const isCoursePrerequisites = tenant.isComponentAllowed(
    AllowedComponents.PrerequisitesCourses
  );

  const myForm = React.useCallback(
    () => (
      <Form
        onSubmit={async values => {
          const variables = {
            name: values.name,
            externalId: values.externalId,
            duration: parseDuration(values.duration),
            description: values.description,
            links: values.links.filter((l?: Link) => l && l.url && l.title),
            notes: values.notes,
            isAllowLearnerCompletions: values.isAllowLearnerCompletions,
            ...(isCoursePrerequisites && {
              prerequisiteCourses: values.prerequisiteCourses || []
            })
          };

          try {
            if (course?.id) {
              await updateCourse(variables);
              onSubmit();
            } else {
              const response = await createCourse(variables);
              showToast("The course was successfully created", "success");
              const courseId = response.data?.id;
              if (courseId) {
                history.push(
                  routes.courses.list +
                    `/${courseId}?${queries.courses.details.uploadContent}=true`
                );
              } else {
                onSubmit();
              }
            }
          } catch (ex) {
            return PythonAPI.getFormErrors(ex);
          }
        }}
        validate={validator}
        initialValues={{
          name: "",
          externalId: "",
          description: "",
          notes: "",
          isAllowLearnerCompletions: false,
          ...course,

          duration: course ? formatDuration((course.duration || 0) * 1000) : "",

          links:
            course?.links && course.links.length > 0
              ? course.links
              : [{ title: "", url: "" }],

          prerequisiteCourses: course?.prerequisiteCourses
            ? course.prerequisiteCourses?.map(c => c.id)
            : []
        }}
        mutators={{ ...arrayMutators }}
      >
        {({ handleSubmit, submitError, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <FormError>{submitError}</FormError>}
            <Section margin="dense">
              <Header
                variant="formSection"
                number="1"
                title="Enter course details"
              />

              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Field
                    name="name"
                    label="Title"
                    variant="outlined"
                    margin="normal"
                    component={TextControl}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    name="externalId"
                    label="External Course ID"
                    variant="outlined"
                    margin="normal"
                    disabled={Boolean(course)} // Cannot be editable because of storage API static folder structure
                    component={TextControl}
                  />
                </Grid>

                <Grid item sm={6}>
                  <Field
                    name="duration"
                    label="Duration (hh:mm:ss)"
                    variant="outlined"
                    margin="normal"
                    component={TextControl}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Field
                    name="description"
                    margin="normal"
                    placeholder="Description"
                    component={TextEditorControl}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section margin="dense">
              <Header
                variant="formSection"
                number="2"
                title="Add link to more information"
                hint="(optional)"
              />

              <FieldArray name="links">
                {({ fields }) =>
                  fields.map((name: string, index: number) => (
                    <Grid container spacing={3} key={name}>
                      <Grid item sm={4}>
                        <Field
                          name={`${name}.url`}
                          label="Link"
                          variant="outlined"
                          margin="normal"
                          component={TextControl}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <Field
                          name={`${name}.title`}
                          label="Link title"
                          variant="outlined"
                          margin="normal"
                          component={TextControl}
                        />
                      </Grid>

                      <Grid item sm={4} classes={{ item: classes.linkButton }}>
                        {index === fields.value.length - 1 ? (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => fields.push({})}
                            icon={IconAdd}
                          >
                            add next link
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            color="error"
                            onClick={() => fields.remove(index)}
                            icon={IconClear}
                          >
                            remove
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))
                }
              </FieldArray>
            </Section>

            <Section margin="dense">
              <Header
                variant="formSection"
                number="3"
                title="Add notes"
                hint="(optional)"
              />
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Field
                    name="notes"
                    margin="normal"
                    placeholder="Notes"
                    component={TextEditorControl}
                  />
                </Grid>

                <Grid item sm={12}>
                  <div className={classes.checkContainer}>
                    <Field
                      name="isAllowLearnerCompletions"
                      color="primary"
                      type="checkbox"
                      label={
                        <span className={classes.checkLabel}>
                          Manual completion enabled
                        </span>
                      }
                      component={CheckboxControl}
                    />

                    <Tooltip
                      title="Is user allowed to request for manual completion"
                      placement="bottom-start"
                    >
                      <div className={classes.infoIcon}>
                        <IconInfo />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </Section>

            {isCoursePrerequisites && (
              <Section margin="dense">
                <Header
                  variant="formSection"
                  number="4"
                  title="Prerequisite courses"
                />
                <CoursesSelect />
              </Section>
            )}

            <CancelConfirmButtons
              confirmButtonDisabled={submitting || hasValidationErrors}
              confirmButtonLabel={course ? "Save" : "Create"}
              onCancel={onCancel}
            />
          </form>
        )}
      </Form>
    ),
    [
      classes,
      course,
      createCourse,
      history,
      isCoursePrerequisites,
      onCancel,
      onSubmit,
      showToast,
      updateCourse
    ]
  );

  return myForm();
};

export default AddOrUpdateCourse;

const validator = createValidator({
  name: { presence: { allowEmpty: false } },
  externalId: {
    presence: true,
    format: {
      pattern: "^[A-Za-z0-9]+$",
      message: "should contain only letters and numbers"
    }
  },
  duration: {
    presence: true,
    format: {
      pattern: "^([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$",
      message: "should be valid time format"
    }
  },
  description: {
    presence: { allowEmpty: false },
    length: {
      minimum: 1,
      maximum: 2000,
      tooShort: "can't be blank",
      tokenizer: function(value: string) {
        return removeHtmlTags(value).split("");
      }
    }
  }
});
