import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  infoTooltip: {
    width: 0,
    display: "flex",
    color: palette.hint.main,
    position: "relative",
    left: spacing(1),
    top: spacing(4)
  },
  twoColumnsForm: {
    maxWidth: 600,
    [breakpoints.up("lg")]: {
      minWidth: 750
    }
  },
  form: {
    maxWidth: 600,
    [breakpoints.up("md")]: {
      minWidth: 400
    }
  },
  fullPageContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  signInButton: {
    marginRight: "auto"
  },
  samlSignInButton: {
    padding: spacing(1.5, 6)
  },
  divider: {
    margin: spacing(3, 0),
    textAlign: "center",
    fontSize: "0.875rem",
    color: palette.text.default,

    "&::before, &::after": {
      content: '""',
      display: "inline-block",
      height: 1,
      width: "45%",
      backgroundColor: palette.hint.main,
      margin: "10px 0"
    },
    "&::before": {
      float: "left"
    },
    "&::after": {
      float: "right"
    }
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  termsDisclaimer: {
    marginTop: spacing(2),
    color: palette.text.primary,
    textAlign: "justify"
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    color: "#000000",
    userSelect: "none"
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    [breakpoints.up("sm")]: {
      padding: spacing(10, 13, 0, 13)
    },
    [breakpoints.up("md")]: {
      padding: spacing(23.5, 13, 0, 13.2)
    }
  },
  flex: {
    display: "flex"
  },
  attLogo: {
    marginTop: spacing(5),
    fontSize: "2rem",
    marginRight: spacing(0.5),
    [breakpoints.up("sm")]: {
      marginTop: spacing(4.5)
    },
    [breakpoints.up("md")]: {
      marginTop: spacing(4),
      marginRight: spacing(1),
      fontSize: "2.2rem"
    }
  },
  mobileLogoContainer: {
    display: "flex",
    margin: "0 auto"
  }
}));
