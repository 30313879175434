import * as React from "react";

import { Section, AppLayout, ApiResultRenderer } from "components";
import useListFilters from "utils/hooks/useListFilters";
import { useModuleState, actionTypes } from "modules/tenants/reducers/tenants";

import { useTenantsList, useTenantsResources } from "../provider/tenants";
import TenantsListContainer from "../components/tenants-list/tenants-list-container";
import { useTenant } from "../../../services/tenant-provider";

const TenantsView: React.FC = () => {
  const [{ tenantsFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters(tenantsFilters);
  const tenant = useTenant();
  const tenantsResources = useTenantsResources({
    variables: {
      listFilters
    }
  });
  const tenantsList = useTenantsList({
    variables: {
      listFilters
    }
  });

  const refetchAll = React.useCallback(async () => {
    await tenant.init();
    tenantsList.refetch();
    tenantsResources.refetch();
  }, [tenant, tenantsResources, tenantsList]);

  const tenantsListWithConfig = React.useMemo(() => {
    return {
      data: tenantsResources.data?.map(tenant => {
        const tenantWithConfig = tenantsList.data?.find(
          ({ url }) => url === tenant.url
        );
        if (!tenantWithConfig) {
          return tenant;
        }
        return {
          ...tenant,
          config: tenantWithConfig.config
        };
      }),
      loading: tenantsList.loading || tenantsResources.loading,
      error: tenantsList.error || tenantsResources.error,
      meta: tenantsResources.meta
    };
  }, [
    tenantsResources.data,
    tenantsList.data,
    tenantsList.loading,
    tenantsResources.loading,
    tenantsList.error,
    tenantsResources.error,
    tenantsResources.meta
  ]);

  const { meta } = tenantsResources;

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_TENANTS_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  return (
    <AppLayout
      viewTitle={meta && meta.total > 1 ? `Tenants (${meta.total})` : "Tenant"}
    >
      <Section>
        <ApiResultRenderer
          apiResult={tenantsListWithConfig}
          reloadsSection={false}
        >
          {({ data = [], meta, loading }) => (
            <TenantsListContainer
              tenants={data}
              loading={loading}
              meta={meta!}
              listFilters={listFilters}
              refetch={refetchAll}
            />
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default TenantsView;
