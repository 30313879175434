import * as React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import makeMuiTenantTheme from "config/theme";

import { IProps, ITenantContext, TenantContextProps } from "../";

const TenantContext = React.createContext<TenantContextProps>(null);
const { Provider } = TenantContext;

class StatefulProvider extends React.Component<IProps, ITenantContext> {
  methods = this.prepareMethods();

  constructor(props: IProps) {
    super(props);

    this.state = {
      isInitialized: false,
      ...this.methods
    };
  }

  syncState = () => {
    const { client } = this.props;

    this.setState({
      isInitialized: client.getIsInitialized(),
      ...this.methods
    });
  };

  prepareMethods() {
    const { client } = this.props;

    return {
      init: client.init.bind(client),
      isComponentAllowed: client.isComponentAllowed.bind(client),
      getStyles: client.getStyles.bind(client),
      getIsInitialized: client.getIsInitialized.bind(client),
      getSaml: client.getSaml.bind(client),
      getText: client.getText.bind(client),
      getName: client.getName.bind(client),
      getHostname: client.getHostname.bind(client),
      getLegalDiscalimer: client.getLegalDiscalimer.bind(client)
    };
  }

  componentDidMount() {
    this.props.client.registerOnUpdateHandler(this.syncState);
  }

  render() {
    let tenantTheme: Theme;

    try {
      tenantTheme = makeMuiTenantTheme(this.state.getStyles());
    } catch (ex) {
      // ex. BE returns bad color value (not HEX/RGB(a)) in some field
      console.error("Cannot use tenant theme:", ex.message);
      tenantTheme = makeMuiTenantTheme();
    }

    if (this.state.isInitialized) {
      updateTitle(this.state.getText()?.htmlTitle ?? this.state.getName());
    }

    const { children } = this.props;

    return (
      <Provider value={this.state}>
        <ThemeProvider theme={tenantTheme}>{children}</ThemeProvider>
      </Provider>
    );
  }
}

const updateTitle = (title: string) => {
  if (Boolean(title)) {
    document.title = title;
  }
};

const useTenant = () => React.useContext(TenantContext)!;

export { useTenant, TenantContext, StatefulProvider as TenantProvider };
