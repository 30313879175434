import * as React from "react";

import routes from "config/routes";
import { Breadcrumbs, ExtendedMainHeader } from "components";
import { UserItem } from "modules/users/provider/users";

import UsersTableRowActions from "../user-list/users-list-row-actions";

type Props = {
  user: UserItem;
  refetch: () => void;
};

const UserHeader: React.FC<Props> = ({ user, refetch }) => {
  const title = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <ExtendedMainHeader
        title={title}
        hint={`(${user.username})`}
        subtitle={
          <Breadcrumbs
            routes={[
              { name: "Users", path: routes.users.list },
              { name: title }
            ]}
          />
        }
        button={<UsersTableRowActions {...{ user, refetch }} />}
      />
    </>
  );
};

export default UserHeader;
