import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: "flex",
    padding: spacing(2, 0),
    justifyContent: "stretch",

    "& > button": {
      flexGrow: 1
    },

    [breakpoints.up("sm")]: {
      justifyContent: "center",

      "& > button": {
        flexGrow: 0
      }
    },
    [breakpoints.up("md")]: {
      justifyContent: "flex-start"
    }
  }
}));
