import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: "1rem",
    [breakpoints.up("sm")]: {
      fontSize: "1.125rem"
    }
  },
  titleContainer: {
    display: "flex"
  },
  subtitleContainer: {
    margin: spacing(3, 0)
  },
  childrenContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  section: {
    marginBottom: spacing(3),

    "&$withSubtitle": {
      marginBottom: spacing(1)
    }
  },
  formSection: {
    marginBottom: spacing(3),
    lineHeight: "1.4rem"
  },
  number: {
    marginRight: spacing(2),
    lineHeight: "1.4rem"
  },
  hint: {
    marginLeft: spacing(1),
    color: palette.text.hint,
    lineHeight: "1.4rem"
  },
  withSubtitle: {}
}));
