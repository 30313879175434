import * as React from "react";

import {
  useModuleState,
  actionTypes
} from "modules/courses/reducers/submissions";
import { AppLayout, Section, ApiResultRenderer, Header } from "components";
import useListFilters from "utils/hooks/useListFilters";

import SubmissionList from "../components/submission-list/submission-list";
import SubmissionHistoryList from "../components/submission-list/submission-history-list";
import {
  useSubmissionsList,
  SubmissionsListFilters
} from "../provider/courses-submissions";

type Props = {};

const SubmissionsView: React.FC<Props> = () => {
  const [
    { pendingSubmissionsFilters, processedSubmissionsFilters },
    dispatch
  ] = useModuleState();
  const pendingSubmissionsListFilters = useListFilters<SubmissionsListFilters>(
    pendingSubmissionsFilters
  );
  const pendingSubmissionsList = useSubmissionsList({
    variables: { listFilters: pendingSubmissionsListFilters }
  });

  const processedSubmissionsListFilters = useListFilters<
    SubmissionsListFilters
  >(processedSubmissionsFilters);
  const processedSubmissionsList = useSubmissionsList({
    variables: { listFilters: processedSubmissionsListFilters }
  });

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_PENDING_SUBMISSIONS_FILTERS,
      payload: pendingSubmissionsListFilters
    });
  }, [dispatch, pendingSubmissionsListFilters]);

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_PROCESSED_SUBMISSIONS_FILTERS,
      payload: processedSubmissionsListFilters
    });
  }, [dispatch, processedSubmissionsListFilters]);

  return (
    <AppLayout viewTitle="Approval / Rejection">
      <Section>
        <ApiResultRenderer
          apiResult={pendingSubmissionsList}
          reloadsSection={false}
        >
          {({ data = [], meta, refetch, loading }) => (
            <>
              <Header
                variant="section"
                title={`Requests list (${(meta && meta.total) || 0})`}
              />
              <SubmissionList
                loading={loading}
                submissions={data!}
                meta={meta!}
                listFilters={pendingSubmissionsListFilters}
                refetchAll={() => {
                  refetch();
                  processedSubmissionsList &&
                    processedSubmissionsList.refetch &&
                    processedSubmissionsList.refetch();
                }}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>
      <Section>
        <ApiResultRenderer
          apiResult={processedSubmissionsList}
          reloadsSection={false}
        >
          {({ data = [], meta, loading }) => (
            <>
              <Header
                variant="section"
                title={`History list (${(meta && meta.total) || 0})`}
              />
              <SubmissionHistoryList
                loading={loading}
                submissions={data!}
                meta={meta!}
                listFilters={processedSubmissionsListFilters}
              />
            </>
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default SubmissionsView;
