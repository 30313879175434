import { Array } from "globalthis/implementation";

import apiConfig from "config/api";
import { AllowedComponents } from "services/tenant-provider";
import { UserRole } from "modules/users/users.constants";

type Reports =
  | "courseReport"
  | "courseOverviewReport"
  | "userReport"
  | "userOverviewReport"
  | "companyOverviewReport"
  | "overallPerformanceReport"
  | "allUsersCoursesReport";

export type ReportType = {
  type: string;
  label: string;
  roles: Array<UserRole>;
  componentAccess: Array<AllowedComponents>;
  requestPath?: (arg?: string) => string;
  buildBody?: (arg?: string | string[]) => Record<string, any>;
};

type ReportTypes = { [key in Reports]: ReportType };

export const ReportTypes: ReportTypes = {
  courseReport: {
    type: "Course Report",
    label: "Course Report",
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN, UserRole.DESIGNER],
    componentAccess: [AllowedComponents.ReportsTab]
  },
  courseOverviewReport: {
    type: "Course Overview Report",
    label: "Course Overview Report",
    roles: [UserRole.ADMIN],
    componentAccess: [AllowedComponents.ReportCourseDetails],
    requestPath: courseId =>
      apiConfig.paths.authorized.report.createForCourseDetails(
        courseId as string
      )
  },
  userReport: {
    type: "User Report",
    label: "User Report",
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN],
    componentAccess: [AllowedComponents.CourseListForStudent]
  },
  userOverviewReport: {
    type: "User Overview Report",
    label: "User Overview Report",
    roles: [UserRole.ADMIN],
    componentAccess: [AllowedComponents.ReportUserDetails],
    requestPath: () => apiConfig.paths.authorized.report.createForUserDetails(),
    buildBody: data => ({ userIds: Array.isArray(data) ? data : [data] })
  },
  companyOverviewReport: {
    type: "Company Overview Report",
    label: "Company Overview Report",
    roles: [UserRole.ADMIN, UserRole.INTERNAL_ADMIN],
    componentAccess: [AllowedComponents.ReportCompanyDetails],
    requestPath: () => apiConfig.paths.authorized.report.createForGroup(),
    buildBody: data => ({ companyIds: Array.isArray(data) ? data : [data] })
  },
  overallPerformanceReport: {
    type: "Overall Performance Report",
    label: "Overall Performance Report",
    roles: [UserRole.ADMIN],
    componentAccess: [AllowedComponents.ReportPerformance],
    requestPath: tenantId =>
      apiConfig.paths.authorized.report.createForTenant(tenantId as string)
  },
  allUsersCoursesReport: {
    type: "All Users/Courses Activity Report",
    label: "All Users/Courses Activity Report",
    roles: [UserRole.ADMIN],
    componentAccess: [AllowedComponents.AllUsersCoursesReport],
    requestPath: () =>
      apiConfig.paths.authorized.report.createForAllCoursesUsers
  }
};
