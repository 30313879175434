import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing }) => {
  const createColorStyle = colorName => ({
    "&$contained": {
      backgroundColor: palette[colorName].main,
      color: palette[colorName].contrastText,

      "&:hover": {
        backgroundColor: palette[colorName].dark
      },
      "&:disabled": {
        backgroundColor: palette.action.disabledBackground,
        color: palette.action.disabled
      }
    },
    "&$outlined": {
      backgroundColor: "transparent",
      color: palette[colorName].main,
      borderColor: palette[colorName].main,

      "&:hover": {
        backgroundColor: palette[colorName].main,
        color: palette[colorName].contrastText
      }
    },
    "&$text": {
      backgroundColor: "transparent",
      color: palette[colorName].main,

      "&:hover": {
        backgroundColor: palette[colorName].light
      }
    }
  });

  return {
    root: {
      display: "inline-flex",
      alignItems: "center"
    },
    icon: {
      fontSize: "1rem",

      "&$left": {
        order: 0,
        marginRight: spacing(1.5)
      },
      "&$right": {
        order: 1,
        marginLeft: spacing(1.5)
      }
    },
    label: {
      color: "inherit",
      fontSize: "inherit",
      display: "inline-flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      "&$underline": { textDecoration: "underline" }
    },
    underline: {},
    defaultHoverColor: {},

    // button variants
    contained: {},
    outlined: {},
    text: {
      "&:hover": {
        "&$defaultHoverColor": {
          backgroundColor: `${palette.hint.faded} !important`
        }
      }
    },

    // button colors
    success: createColorStyle("success"),
    error: createColorStyle("error"),
    hint: createColorStyle("hint"),
    default: {
      "&$icon": {
        color: palette.hint.main
      }
    },

    // icon position
    left: {},
    right: {}
  };
});
