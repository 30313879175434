import * as React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

import { AllowedComponents, useTenant } from "services/tenant-provider";
import { IconTimerOutlined } from "assets/icons";
import { formatDuration } from "utils/datetime";

import {
  MyLearningPlanItem,
  MyLearningPlanStatus
} from "../../../provider/my-learning-plans";

import useStyles from "./learning-plan-card.styles";
import LearningPlanStatusChip from "./learning-plan-status-chip";

type Props = {
  learningPlan: MyLearningPlanItem;
};

const LearningPlanCard: React.FC<Props> = ({ learningPlan }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const tenant = useTenant();
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );

  const goToDetails = React.useCallback(() => {
    push(
      isAllowedTenantAvailableCourses
        ? `/available-courses/my-learning-plan/${learningPlan.id}`
        : `/my-courses/my-learning-plan/${learningPlan.id}`
    );
  }, [push, isAllowedTenantAvailableCourses, learningPlan.id]);

  return (
    <Paper elevation={4} className={classes.root} onClick={goToDetails}>
      <div className={classes.contentContainer}>
        <Typography variant="h2" className={classes.header} paragraph>
          <span className={classes.name}>{learningPlan.name}</span>
          <span className={classes.lpId}>ID: {learningPlan.externalId}</span>
        </Typography>
        <Typography variant="body2" className={classes.textWithIcon}>
          <IconTimerOutlined className={classes.icon} />
          {learningPlan.totalDuration
            ? `Duration - ${formatDuration(
                learningPlan.totalDuration * 1000,
                "H[h] mm[min]"
              )}`
            : "-"}
        </Typography>
        <Typography className={classes.total}>
          Total courses: {learningPlan.totalCourses}
        </Typography>
        <LearningPlanStatusChip
          className={classes.status}
          status={learningPlan.status}
          label={
            learningPlan.status === MyLearningPlanStatus.IN_PROGRESS
              ? `(${learningPlan.finishedCourses}/${learningPlan.totalCourses})`
              : ""
          }
        />
      </div>
    </Paper>
  );
};

export default LearningPlanCard;
