export const checkIfIE = () => /MSIE|Trident/.test(window.navigator.userAgent);

export const disableBackspaceNavigationForFirefox = () => {
  document.onkeydown = event => {
    if (navigator?.userAgent?.toLowerCase().indexOf("firefox") === -1) {
      // Not Firefox
      return;
    }

    const finalEvent = (event || window.event) as any;
    if (
      finalEvent &&
      finalEvent.keyCode === 8 && // Is BACKSPACE key
      // Not in editable element
      (finalEvent.target || finalEvent.srcElement)?.tagName !== "TEXTAREA" &&
      (finalEvent.target || finalEvent.srcElement)?.tagName !== "INPUT" &&
      (finalEvent.target || finalEvent.srcElement)?.isContentEditable !== true
    ) {
      finalEvent.returnValue = false;
      return false;
    }
  };
};

export const deleteCoursesCookies = () => {
  const cookies = document.cookie;
  cookies
    .split("; ")
    .filter(cookie => cookie.startsWith("course_"))
    .map(cookie => cookie.split("="))
    .forEach(
      cookie =>
        (document.cookie = `${cookie[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
    );
};
