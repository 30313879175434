import * as React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { Form } from "react-final-form";

import { Button, FormError } from "components";
import * as PythonApi from "services/api/python";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import useSetShouldRefetchIndicatorsToTrue from "modules/common/hooks/useIndicatorsRefetchSetter";

import { useCourseCompletionCreate } from "../provider/courses-submissions";

import useStyles from "./course-submit-completion.styles";

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  courseId: string;
};

const SubmitCourseCompletion: React.FC<Props> = ({
  onCancel,
  onSubmit,
  courseId
}) => {
  const classes = useStyles();
  const submitCourseCompletion = useCourseCompletionCreate();
  const isMobile = useSizeCheck("xs");
  const setShouldRefetchIndicatorsToTrue = useSetShouldRefetchIndicatorsToTrue();

  return (
    <Form
      onSubmit={async () => {
        try {
          await submitCourseCompletion({ course: courseId });
          setShouldRefetchIndicatorsToTrue();
          onSubmit();
        } catch (ex) {
          return PythonApi.getFormErrors(ex);
        }
      }}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <FormError>{submitError}</FormError>}
          <Typography className={classes.typography}>
            Did you complete the course and an error occured? Send a request to
            mark the course as completed.
          </Typography>

          <Box mt={6}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify={isMobile ? "center" : "flex-end"}
            >
              <Grid item className={classes.cancelButton}>
                <Button variant={"outlined"} color="error" onClick={onCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  key="submit-course-completion"
                  color="success"
                  type="submit"
                  disabled={submitting}
                >
                  Submit manual completion
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default SubmitCourseCompletion;
