import * as React from "react";

export type SelectedStateItems<I> = Map<string, I>;
export type SelectedStateActions<I> = {
  selectOne: (item: I) => void;
  toggleOne: (item: I) => void;
  unselectOne: (item: I) => void;
  selectMultiple: (items: I[]) => void;
  setAll: (items: I[]) => void;
  unselectAll: () => void;
};

export type SelectedState<I extends Item> = {
  selected: SelectedStateItems<I>;
  actions: SelectedStateActions<I>;
};

export type Item = { [key: string]: any };

const useSelectedState = <I extends Item>(
  initialSelection: I[] = [],
  key: string = "id"
): SelectedState<I> => {
  const [selected, setSelected] = React.useState(
    () => new Map<string, I>(initialSelection.map(i => [i[key], i]))
  );

  const actions = React.useMemo(
    () => ({
      selectOne: (item: I) => {
        setSelected(s => {
          if (s.has(item[key])) {
            return s;
          }

          const newSet = new Map(s);
          newSet.set(item[key], item);
          return newSet;
        });
      },
      toggleOne: (item: I) => {
        setSelected(s => {
          const newSet = new Map(s);
          if (newSet.has(item[key])) {
            newSet.delete(item[key]);
          } else {
            newSet.set(item[key], item);
          }
          return newSet;
        });
      },
      unselectOne: (item: I) => {
        setSelected(s => {
          const newSet = new Map(s);
          newSet.delete(item[key]);
          return newSet;
        });
      },
      selectMultiple: (items: I[]) => {
        setSelected(s => {
          const newSet = new Map(s);
          for (const item of items) {
            newSet.set(item[key], item);
          }
          return newSet;
        });
      },
      setAll: (items: I[]) => {
        setSelected(new Map(items.map(i => [i[key], i])));
      },
      unselectAll: () => {
        setSelected(new Map());
      }
    }),
    [key]
  );

  return { selected, actions };
};

export default useSelectedState;
