import * as React from "react";

import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";

import FullscreenMessage from "../components/fullscreen-message.component";

type Props = {};

const SetNewPasswordExpiredView: React.FC<Props> = () => (
  <GuestLayout>
    <SidePanel variant="login" />
    <Content>
      <FullscreenMessage variant="error" title="Your token has expired" />
    </Content>
    <BottomPanel variant="login" />
  </GuestLayout>
);

export default SetNewPasswordExpiredView;
