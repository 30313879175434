import moment from "moment";

import * as PythonApi from "services/api/python";
import { useFetchMutation } from "utils/hooks/useFetch";
import API from "services/api";
import apiConfig from "config/api";

type ReportVars = {
  reportId: string;
};

export enum ReportStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE"
}

export const checkIfReportIsGenerated = (status?: string) =>
  status === ReportStatus.SUCCESS;

export const useReportStatusCallback = () => {
  type Item = {
    id: string;
    createdAt: string;
    status: ReportStatus;
  };

  return useFetchMutation(
    PythonApi.makeMutation<ReportVars, Item>(({ reportId }) => ({
      method: "GET",
      url: apiConfig.paths.authorized.report.details(reportId)
    }))
  );
};

export const useReportDownloadCallback = () => async ({
  reportId
}: {
  reportId: string;
}) =>
  await API.get<Blob>(apiConfig.paths.authorized.report.download(reportId), {
    responseType: "blob",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  });

export const usePrepareReportUrl = () => {
  type Req = {
    path: string;
    dateFrom: moment.Moment;
    dateTo: moment.Moment;
    body?: Record<string, any>;
  };

  type Resp = {
    id: string; // report
    createdAt: string;
    status: string;
  };

  return useFetchMutation(
    PythonApi.makeMutation<Req, Resp>(({ path, dateTo, dateFrom, body }) => {
      return {
        method: "POST",
        url: path,
        params: {
          timestamp__gte: moment(dateFrom).format(),
          timestamp__lte: moment(dateTo).format()
        },
        data: body
      };
    })
  );
};
