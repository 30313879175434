import * as React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";

import useStyles from "./auto-grid.styles";

const getFlatChildren = (
  children: React.ReactNode,
  skipEmpty: boolean
): React.ReactNode => {
  if (React.Children.count(children) === 0) {
    return [];
  }

  const childrenArray = Array.isArray(children) ? [...children] : [children];

  const flatChildren = childrenArray.reduce((acc, child) => {
    if (React.isValidElement(child) && child.type === React.Fragment) {
      return [
        ...acc,
        ...React.Children.toArray(child.props.children as React.ReactNode)
      ];
    }

    if (!child && skipEmpty) {
      return acc;
    }

    return [...acc, child];
  }, []);

  return flatChildren;
};

type Props = {
  className?: string;
  spacing?: GridProps["spacing"];
  justify?: GridProps["justify"];
  alignItems?: GridProps["alignItems"];
  wrap?: GridProps["wrap"];
  zeroMinWidth?: GridProps["zeroMinWidth"];
  showEmpty?: boolean;
};

const AutoGrid: React.FC<Props> = ({
  children,
  className,
  spacing = 1,
  justify = "flex-start",
  alignItems = "center",
  wrap,
  zeroMinWidth,
  showEmpty = false
}) => {
  const classes = useStyles();
  const childrenFlat = getFlatChildren(children, !showEmpty);

  return (
    <Grid
      container
      spacing={spacing}
      justify={justify}
      alignItems={alignItems}
      wrap={wrap}
      className={className}
    >
      {React.Children.map(childrenFlat, child => {
        return (
          <Grid
            item
            xs="auto"
            zeroMinWidth={zeroMinWidth}
            className={classes.gridItem}
          >
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AutoGrid;
