import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  flex: {
    display: "flex"
  },
  categoryContainer: {
    borderBottom: `1px solid ${palette.divider}`,
    padding: spacing(1, 0)
  },
  categoryTitleContainer: {
    display: "flex",
    margin: spacing(1.5, 0),
    cursor: "pointer",
    alignItems: "center"
  },
  categoryTitle: {
    marginTop: 2,
    [breakpoints.up("sm")]: {
      marginLeft: spacing(2)
    }
  },
  flagContainer: {
    marginLeft: spacing(2),
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.up("sm")]: {
      marginLeft: spacing(5)
    }
  },
  flagTitle: {
    marginTop: spacing(1)
  },
  infoIcon: {
    color: palette.info.main,
    opacity: 0.3,
    marginTop: spacing(1),
    marginRight: spacing(1.2),
    "&:hover": {
      opacity: 1
    }
  },
  noCategories: {
    marginLeft: spacing(3),
    color: palette.error.main
  },
  popper: {
    maxWidth: 350,
    fontSize: "1rem"
  }
}));
