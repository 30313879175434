import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { CourseStatus } from "../courses.constants";

export enum MyLearningPlanStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  NOT_STARTED = "not_started"
}

export type MyLearningPlanItem = {
  id: string;
  externalId: string;
  createdAt: string;
  name: string;
  status: MyLearningPlanStatus;
  totalCourses: number;
  finishedCourses: number;
  description: string;
  totalDuration: number;
};

export type MyLearningPlanDetailsItem = {
  id: string;
  externalId: string;
  name: string;
  createdAt: string;
  description: string;
  courses: {
    id: string;
    name: string;
    status: CourseStatus;
    duration: number;
  }[];
  status: MyLearningPlanStatus;
  totalCourses: number;
  finishedCourses: number;
  totalDuration: number;
};

type MyLearningPlanListVars = {
  listFilters?: ListFilters<{}>;
  fields?: string;
};

type MyLearningPlanDetailsVars = {
  learningPlanId: string;
};

export const useMyLearningPlanList = (
  options: FetchOptions<MyLearningPlanListVars>
) => {
  const {
    variables: { listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<MyLearningPlanItem>({
      method: "GET",
      url: apiConfig.paths.authorized.learningPlan.studentList,
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};

export const useMyLearningPlanDetails = (
  options: FetchOptions<MyLearningPlanDetailsVars>
) => {
  const {
    variables: { learningPlanId }
  } = options;

  return useFetch(
    PythonApi.makeRequestForOne<MyLearningPlanDetailsItem>({
      method: "GET",
      url: apiConfig.paths.authorized.learningPlan.studentDetails(
        learningPlanId
      )
    }),
    options
  );
};
