import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    fontSize: ({ size }) => size * 0.4,
    fontWeight: "bold",
    backgroundColor: palette.action.selected,
    color: palette.primary.main,
    margin: "auto",

    [breakpoints.up("sm")]: {
      margin: 0
    }
  }
}));
