import * as React from "react";
import { useParams } from "react-router";

import { useAuth } from "services/auth-provider";
import {
  ListToolbar,
  Button,
  AutoGrid,
  Drawer,
  Search,
  ClosingModal
} from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";
import {
  CompanyItem as CompanyItemType,
  useCompanyCreate,
  useCompanyThumbnailCreate
} from "modules/companies/provider/companies";
import useToast from "utils/hooks/useToast";
import { UserRole } from "modules/users/users.constants";
import { useRepeatStatusCheckUntilSuccess } from "modules/common/hooks/useRepeatStatusCheckUntilSuccess";
import { useUploadStatus } from "modules/common/hooks/useUploadStatus";
import { useUploadOptions } from "modules/common/hooks/useUploadOptions";
import { useTenant } from "services/tenant-provider";

import CompanyForm from "../company-form.component";

type Props = {
  companies: CompanyItemType[];
  listFilters: ListFiltersState;
  refetch: () => void;
};

type StatusPromise = (uploadId: string) => Promise<string>;

const ToolbarCompanies: React.FC<Props> = ({ listFilters, refetch }) => {
  const { courseId } = useParams();
  const { checkAccess } = useAuth();
  const { showToast } = useToast();
  const { getHostname } = useTenant();
  const createCompany = useCompanyCreate();
  const uploadThumbnail = useCompanyThumbnailCreate();
  const getUploadOptions = useUploadOptions();
  const checkThumbnailStatus = useUploadStatus();
  const waitForStatus = useRepeatStatusCheckUntilSuccess(checkThumbnailStatus);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    true
  );

  const isAdmin = checkAccess({ roles: [UserRole.ADMIN] });
  const handleAddSubmit = React.useCallback(
    async ({
      name,
      admins,
      companyinfo,
      thumbnail,
      externalId,
      voucherNumber,
      companyDomain
    }) => {
      let thumbnailUrl = "";
      if (thumbnail) {
        const result = await getUploadOptions({});
        if (!result.data) {
          throw new Error("Retrieving upload options failed");
        }
        const { sasToken, uploadId, storageUrl } = result.data;

        await uploadThumbnail({
          thumbnail,
          sasToken,
          uploadId,
          storageUrl,
          tenantId: getHostname()
        });

        thumbnailUrl = (await waitForStatus(uploadId, 1500)) || "";
      }

      await createCompany({
        name,
        adminsIds: admins ?? [],
        parent: undefined,
        companyinfo: {
          attSponsorId: companyinfo.attSponsorId,
          addresses: companyinfo.addresses,
          thumbnailUrl
        },
        externalId,
        voucherNumber,
        companyDomain
      });
      listFilters.setPagination({
        offset: 0,
        rowsPerPage: listFilters.pagination.rowsPerPage
      });
      refetch();
      setDrawerOpen(false);
      showToast("The company was successfully created", "success");
    },
    [
      createCompany,
      listFilters,
      refetch,
      showToast,
      uploadThumbnail,
      getHostname,
      getUploadOptions,
      waitForStatus
    ]
  );

  const left = React.useMemo(
    () => (
      <AutoGrid spacing={2}>
        <Search listFilters={listFilters} />
      </AutoGrid>
    ),
    [listFilters]
  );

  const right = React.useMemo(
    () => (
      <>
        <AutoGrid spacing={2} justify="flex-end">
          {isAdmin && !Boolean(courseId) && (
            <Button onClick={() => setDrawerOpen(true)} color="secondary">
              Add company
            </Button>
          )}
        </AutoGrid>

        <Drawer
          isOpen={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          title="Add new company"
          size="sm"
          onBackdropClick={
            isClosingModalEnabled
              ? () => setIsClosingModalOpen(true)
              : undefined
          }
        >
          <CompanyForm
            onCancel={() => setDrawerOpen(false)}
            onSubmit={handleAddSubmit}
            setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
          />
        </Drawer>

        <ClosingModal
          isOpen={isClosingModalOpen}
          onClose={() => setIsClosingModalOpen(false)}
          onConfirm={() => {
            setIsClosingModalOpen(false);
            setDrawerOpen(false);
          }}
        />
      </>
    ),
    [
      courseId,
      handleAddSubmit,
      isAdmin,
      isClosingModalEnabled,
      isClosingModalOpen,
      isDrawerOpen
    ]
  );

  return <ListToolbar right={right} left={left} />;
};

export default ToolbarCompanies;
