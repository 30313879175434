import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ breakpoints, palette, spacing, transitions, shadows }: CustomTheme) => ({
    navLinks: {
      padding: spacing(4, 0, 0),
      flexShrink: 1
    },
    noCompany: {},
    navLink: {
      borderRadius: 100,
      color: palette.text.default,
      padding: spacing(2, 2.5),
      marginBottom: spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",

      [breakpoints.only("sm")]: {
        flexDirection: "column",
        textAlign: "center",
        padding: spacing(1),
        "&:hover": {
          backgroundColor: palette.action.hover
        }
      }
    },
    navLinkActive: {
      // active sidebar link
      color: palette.primary.main,
      backgroundColor: "transparent",

      "&:hover": {
        backgroundColor: palette.primary.main,
        cursor: "default"
      },

      [breakpoints.up("sm")]: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText
      }
    },
    navLinkIcon: {
      color: "inherit",
      minWidth: spacing(5),

      [breakpoints.only("sm")]: {
        minWidth: 0
      }
    },
    navLinkText: {
      color: "inherit",
      [breakpoints.up("md")]: {
        whiteSpace: "nowrap"
      },
      [breakpoints.up("lg")]: {
        "&$narrowed": {
          display: "none"
        }
      }
    },
    rootNavTablet: {
      width: 200,
      [breakpoints.up("md")]: {
        width: 300
      }
    },
    rootNavDesktop: {
      width: 100,
      "&$expanded": {
        width: 300
      }
    },
    drawerBackdrop: {
      backgroundColor: "transparent"
    },
    drawer: {
      border: 0,
      boxShadow: shadows[3],
      height: "100%"
    },
    drawerMobile: {
      width: 280,
      height: "auto"
    },
    drawerTablet: {
      width: 200,
      [breakpoints.up("md")]: {
        width: 300
      }
    },
    drawerDesktop: {
      width: 100,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
      }),
      "&$expanded": {
        width: 300,
        transition: transitions.create("width", {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen
        })
      }
    },
    drawerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100vh",
      maxHeight: "100vh",
      width: "100%",

      [breakpoints.up("sm")]: {
        padding: 0
      }
    },
    drawerContent: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      padding: spacing(4),
      flexShrink: 1,
      flexGrow: 1
    },
    drawerContentTablet: {
      padding: spacing(4, 4)
    },
    drawerContentDesktop: {
      padding: spacing(4, 2),

      "&$expanded": {
        overflowX: "hidden",
        overscrollBehavior: "contain",
        padding: spacing(4, 4)
      }
    },
    arrowButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: spacing(3),
      [breakpoints.up("md")]: {
        "&$narrowed": {
          justifyContent: "center"
        }
      }
    },
    arrowButton: {
      color: palette.text.default
    },
    expanded: {},
    narrowed: {},
    tooltip: {
      marginLeft: spacing(4),
      padding: spacing(2)
    },
    faqButtonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    faqButton: {
      fontSize: "1rem",
      margin: spacing(3, 0),

      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  })
);
