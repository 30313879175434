import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  submitContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing(6)
  }
}));
