import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, typography }: CustomTheme) => ({
  contentText: {
    color: palette.text.default,
    fontSize: "0.9rem"
  },
  dialogActions: {
    marginTop: spacing(2),
    width: "100%",
    justifyContent: "flex-end"
  },
  dialogPaper: {
    padding: spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  company: {
    fontSize: typography.pxToRem(19),
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold
  },
  course: {
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightRegular,
    color: palette.primary.main
  }
}));
