import * as React from "react";
import { useParams } from "react-router";

import { Drawer, Button, AutoGrid, ClosingModal } from "components";
import RecordForm from "modules/users/components/user-record/user-record";
import {
  UserItem as UserItemType,
  UserCreateUpdateVars as UserCreateUpdateVarsType
} from "modules/users/provider/users";
import { useUserUpdate, useUserStatusUpdate } from "modules/users/provider";
import useToast from "utils/hooks/useToast";
import { IconCreate } from "assets/icons";
import * as PythonApi from "services/api/python";
import { useAuth } from "services/auth-provider";

type UsersTableRowActionsProps = {
  user: UserItemType;
  refetch: () => void;
};

const DirectUsersTableRowActions: React.FC<UsersTableRowActionsProps> = ({
  user,
  refetch
}) => {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const editUser = useUserUpdate({ id: user.id });
  const { showToast } = useToast();
  const { userId, courseId } = useParams();
  const isUserDetailsView = Boolean(userId);
  const isCourseDetailsView = Boolean(courseId);
  const activateUser = useUserStatusUpdate("activate");
  const deactivateUser = useUserStatusUpdate("deactivate");
  const auth = useAuth();

  const [isClosingModalOpen, setIsClosingModalOpen] = React.useState(false);
  const [isClosingModalEnabled, setIsClosingModalEnabled] = React.useState(
    false
  );

  const handleEditSubmit = React.useCallback(
    async (values: UserCreateUpdateVarsType) => {
      try {
        const updatedUserData = await editUser(values);

        if (
          updatedUserData?.data?.id &&
          updatedUserData.data.id === auth.user?.id
        ) {
          auth.updateUser(updatedUserData.data);
        }

        user.isAccepted !== values.isAccepted &&
          (await (values.isAccepted
            ? activateUser({ id: user.id })
            : deactivateUser({ id: user.id })));
        setDrawerOpen(false);
        showToast("The user was successfully edited", "success");
        refetch();
      } catch (ex) {
        const errorMessages = PythonApi.getMessageStringFromError(ex);
        showToast(errorMessages, "error");
      }
    },
    [
      activateUser,
      auth,
      deactivateUser,
      editUser,
      refetch,
      showToast,
      user.id,
      user.isAccepted
    ]
  );

  return (
    <>
      {isUserDetailsView ? (
        <Button
          style={{ marginBottom: "-7px" }}
          variant="text"
          color="secondary"
          onClick={() => setDrawerOpen(true)}
          icon={IconCreate}
        >
          Edit user
        </Button>
      ) : (
        <AutoGrid spacing={2} wrap="nowrap">
          {!isCourseDetailsView && (
            <Button
              onClick={() => setDrawerOpen(true)}
              variant="text"
              icon={IconCreate}
            >
              Edit
            </Button>
          )}
        </AutoGrid>
      )}

      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Edit user"
        size="md"
        onBackdropClick={
          isClosingModalEnabled ? () => setIsClosingModalOpen(true) : undefined
        }
      >
        <RecordForm
          user={user}
          onSubmit={handleEditSubmit}
          onCloseDrawer={() => setDrawerOpen(false)}
          setIsModalEnabled={isEnabled => setIsClosingModalEnabled(isEnabled)}
        />
      </Drawer>

      <ClosingModal
        isOpen={isClosingModalOpen}
        onClose={() => setIsClosingModalOpen(false)}
        onConfirm={() => {
          setIsClosingModalOpen(false);
          setDrawerOpen(false);
        }}
      />
    </>
  );
};

export default DirectUsersTableRowActions;
