import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ palette, typography, shadows, spacing }: CustomTheme) => ({
    container: {
      margin: 0
    },
    tabs: {
      marginBottom: spacing(4)
    },
    root: {
      borderBottom: `1px solid ${palette.success.main}`
    },
    tabIndicator: {
      display: "none"
    },
    tab: {
      height: 43,
      boxShadow: shadows[8],
      borderRadius: "10px 10px 0px 0",
      fontFamily: typography.fontFamily,
      fontWeight: typography.fontWeightRegular,
      width: "auto",
      justifyContent: "center",
      fontSize: typography.pxToRem(16),
      opacity: 1,
      margin: spacing(1, 2, 0, 0.5),
      backgroundColor: palette.background.default,
      padding: 0,
      color: palette.text.hint
    },
    active: {
      backgroundColor: palette.success.main,
      color: palette.primary.contrastText
    }
  })
);
