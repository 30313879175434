import * as React from "react";
import { Avatar } from "@material-ui/core";

import { getInitials } from "utils/strings";

import useStyles from "./user-initials.styles";

export type Props = {
  classes?: {
    root?: string;
  };
  size?: number;
  names: string;
};

const UserInitials: React.FC<Props> = ({
  classes: parentClasses,
  names,
  size = 80
}) => {
  const classes = useStyles({ size, classes: parentClasses });

  const initials = getInitials(names);

  return (
    <Avatar title={`User Initials: ${initials}`} className={classes.root}>
      {initials}
    </Avatar>
  );
};

export default UserInitials;
