import * as React from "react";
import { AxiosResponse } from "axios";

import { delay } from "utils/delay";

import {
  CheckUploadStatusVars,
  StatusItem,
  UploadStatus
} from "./useUploadStatus";

type UploadStatusChecker = (
  uploadId: string,
  delayTime: number
) => Promise<string | undefined>;

export const useRepeatStatusCheckUntilSuccess = (
  checkStatus: (
    variables: CheckUploadStatusVars
  ) => Promise<AxiosResponse<StatusItem | undefined>>
) => {
  return React.useMemo(() => {
    const waitForStatus: UploadStatusChecker = async (
      uploadId: string,
      delayTime: number
    ) => {
      try {
        const response = await checkStatus({ uploadId });

        if (!response.data || response.data.status === UploadStatus.ERROR) {
          throw new Error("Retrieving upload status failed");
        }

        if (response.data.status === UploadStatus.SUCCESS) {
          return response.data.fileUrl;
        }

        await delay(delayTime);
        return waitForStatus(uploadId, delayTime);
      } catch (e) {
        throw new Error("Retrieving upload status failed");
      }
    };

    return waitForStatus;
  }, [checkStatus]);
};
