import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { useFetch, FetchOptions, useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";
import {
  AllowedComponents,
  TenantText,
  TenantLegalDisclaimer
} from "services/tenant-provider/types";

export type TenantsListFilters = {
  name?: string;
};

type TenantsListVars = {
  listFilters?: ListFilters<TenantsListFilters>;
};

export type TenantReadVars = {
  tenantId: string;
};

export type TenantAccessDescription = {
  [key in AllowedComponents]: TenantAccessDescriptionItem;
};

export type TenantAccess = { [key in AllowedComponents]: boolean };

export type TenantAccessDescriptionItem = {
  description: string;
  category: string;
};

export type TenantConfig = {
  access?: TenantAccess;
  accessDescription?: TenantAccessDescription;
  samlUrl?: string;
};

export type TenantAPIResponse = {
  name: string;
  hostname: string;
  url?: string;
  config?: TenantConfig;
  uiConfig: {
    theme?: ThemeOptions;
    text?: TenantText;
    legalDisclaimer?: TenantLegalDisclaimer;
  };
};

export type TenantItem = {
  name: string;
  hostname: string;
  url: string;
  countStudents: number;
  countAdmins: number;
  countDesigners: number;
  countCourses: number;
  countCompanies: number;
  countProblems: number;
  config?: TenantConfig;
};

type PrimeCoursesListVars = {
  hostname: string;
  listFilters?: ListFilters;
};

export type PrimeCourseItem = {
  id: string;
  user: string;
  createdAt: string;
  status: string;
  message?: string;
};

const createTenantsResourcesConfig = ({ listFilters }: TenantsListVars) =>
  PythonApi.makeRequestForList<TenantItem>({
    method: "GET",
    url: apiConfig.paths.authorized.tenants.resources,
    params: {
      ...PythonApi.getParamsFromFilters(listFilters)
    }
  });

export const useTenantsResources = (options: FetchOptions<TenantsListVars>) =>
  useFetch(createTenantsResourcesConfig(options.variables), options);

const createTenantsListConfig = ({ listFilters }: TenantsListVars) =>
  PythonApi.makeRequestForList<TenantItem>({
    method: "GET",
    url: apiConfig.paths.authorized.tenants.list,
    params: {
      ...PythonApi.getParamsFromFilters(listFilters)
    }
  });

export const useTenantsList = (options: FetchOptions<TenantsListVars>) =>
  useFetch(createTenantsListConfig(options.variables), options);

export const usePrimeCoursesList = (
  options: FetchOptions<PrimeCoursesListVars>
) => {
  const {
    variables: { hostname, listFilters }
  } = options;

  return useFetch(
    PythonApi.makeRequestForList<PrimeCourseItem>({
      method: "GET",
      url: apiConfig.paths.authorized.course.prime(hostname),
      params: PythonApi.getParamsFromFilters(listFilters)
    }),
    options
  );
};

export const createGetTenantConfig = ({ tenantId }: TenantReadVars) =>
  PythonApi.makeRequestForOne<TenantAPIResponse>({
    method: "GET",
    url: apiConfig.paths.authorized.tenants.getTenant(tenantId)
  });

export const useGetTenant = (options: FetchOptions<TenantReadVars>) =>
  useFetch(createGetTenantConfig(options.variables), options);

export const useTenantConfigUpdate = (tenantId: string) => {
  type Vars = { [key in AllowedComponents]: boolean };

  return useFetchMutation(
    PythonApi.makeMutation<Vars>(data => ({
      method: "PUT",
      url: apiConfig.paths.authorized.tenants.getTenant(tenantId),
      data
    }))
  );
};
