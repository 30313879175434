import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ spacing }: CustomTheme) => ({
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: spacing(3),
    flex: 5
  },
  leftButton: {
    marginRight: spacing(1.5)
  }
}));
