import API from "services/api";
import * as PythonApi from "services/api/python";
import { ListFilters } from "utils/hooks/useListFilters";
import { FetchOptions, useFetch, useFetchMutation } from "utils/hooks/useFetch";
import apiConfig from "config/api";

import { CourseStatus } from "../courses.constants";

import { PrerequisiteCourse } from "./courses";

export type MyCoursesListFilters = {
  name?: string;
};

type MyCoursesListVars = {
  id: string;
  listFilters?: ListFilters<MyCoursesListFilters>;
  isNotStudent?: boolean;
  isLightList?: boolean;
};

export type MyCoursesListItem = {
  id: string;
  name: string;
  createdAt: string; // datestring
  description: string;
  notes?: string;
  duration?: number; // in secs
  externalId: string;
  plainUrl?: string;
  assigned?: boolean; // is course assigned to current user
  status?: CourseStatus;
  currentStatus?: CourseStatus;
  statusTimestamp?: string;
  thumbnailUrl?: string;
  isPublished: boolean;
  updatedAt: string; // datestring
  url?: string;
  userId: string;
  allowManualCompletion: boolean;
  links: Array<{
    url: string;
    title: string;
  }>;
  detailsId: string; // id from lrs for my course details history view
  learningPlanIndicator?: {
    name: string;
    id: string;
  }[];
  prerequisiteCourses?: PrerequisiteCourse[];
};

type MyCourseVars = {
  courseId: string;
};

type MyCourseHistoryVars = {
  courseId: string;
};

export type MyCoursesHistoryListFilters = {
  name?: string;
  status_timestamp?: string;
};

type MyCoursesHistoryListVars = {
  listFilters?: ListFilters<MyCoursesHistoryListFilters>;
};

type StartMyCourseVars = {
  id?: string;
  url?: string;
  status?: string;
};

type StartMyCourseItem = {
  url: string;
  status: string;
};

const createMyCoursesListConfig = ({ listFilters, isLightList }: MyCoursesListVars) =>
  PythonApi.makeRequestForList<MyCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.listOfCurrentUser,
    params: {
      ...PythonApi.getParamsFromFilters(listFilters, {
        name: "contains",
      }),
      ...(isLightList && {
        fields: "id,externalId,name",
      })
    }
  });

export const useMyCoursesList = (options: FetchOptions<MyCoursesListVars>) => {
  return useFetch(createMyCoursesListConfig({ ...options.variables }), options);
};

const createMyCourseReadConfig = ({ courseId }: MyCourseVars) =>
  PythonApi.makeRequestForOne<MyCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.detailsOfUser(courseId)
  });

export const useMyCourseRead = (options: FetchOptions<MyCourseVars>) =>
  useFetch(createMyCourseReadConfig(options.variables), options);

export const createMyCoursesHistoryListConfig = ({
  listFilters
}: MyCoursesHistoryListVars) =>
  PythonApi.makeRequestForList<MyCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.listOfFinished,
    params: PythonApi.getParamsFromFilters(listFilters, {
      name: "contains",
      status_timestamp: "gte"
    })
  });

export const useMyCoursesHistoryList = (
  options: FetchOptions<MyCoursesHistoryListVars>
) => useFetch(createMyCoursesHistoryListConfig(options.variables), options);

const createMyCourseHistoryReadConfig = ({ courseId }: MyCourseHistoryVars) =>
  PythonApi.makeRequestForOne<MyCoursesListItem>({
    method: "GET",
    url: apiConfig.paths.authorized.course.detailOfFinished(courseId)
  });

export const useMyCourseHistoryRead = (
  options: FetchOptions<MyCourseHistoryVars>
) => useFetch(createMyCourseHistoryReadConfig(options.variables));

export const useStartMyCourse = () =>
  useFetchMutation(
    PythonApi.makeMutation<StartMyCourseVars, StartMyCourseItem>(
      ({ id, url, status }) => ({
        method: "POST",
        url: apiConfig.paths.authorized.course.start(id || "_"),
        data: { url, status }
      })
    )
  );

type CourseTokenVars = {
  courseId: string;
};

export const useCourseToken = () => async ({ courseId }: CourseTokenVars) =>
  await API.get<string>(apiConfig.paths.authorized.course.getToken(courseId));
