import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ spacing }) => ({
  content: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing(3)
  },
  rightIcon: {
    marginLeft: spacing(1)
  },
  leftIcon: {
    marginRight: spacing(1),
    fontSize: "1rem"
  }
}));
