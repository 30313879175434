import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ breakpoints, palette, spacing, shadows }: CustomTheme) => ({
    root: {
      width: "100%",
      height: 85,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      boxShadow: shadows[1],

      [breakpoints.up("sm")]: {
        minHeight: 170
      },

      "&$filled": {
        "&$primary": {
          backgroundColor: palette.primary.main
        },
        "&$secondary": {
          backgroundColor: palette.secondary.main
        },
        "&$success": {
          backgroundColor: palette.success.main
        },
        "&$info": {
          backgroundColor: palette.info.main
        },
        "&$hint": {
          backgroundColor: palette.hint.main
        }
      }
    },
    rootCardContent: {
      flexGrow: 1,
      width: "100%",
      overflow: "hidden"
    },
    title: {
      flexWrap: "wrap",
      color: palette.text.default,
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [breakpoints.up("sm")]: {
        marginBottom: spacing(1),
        fontSize: "1.5rem"
      },

      "&$filled": {
        "&$primary": {
          color: palette.primary.contrastText
        },
        "&$secondary": {
          color: palette.secondary.contrastText
        },
        "&$success": {
          color: palette.success.contrastText
        },
        "&$info": {
          color: palette.info.contrastText
        },
        "&$hint": {
          color: palette.hint.contrastText
        }
      }
    },
    subtitle: {
      fontSize: "0.75rem",
      textTransform: "uppercase",
      color: palette.hint.main,

      [breakpoints.up("sm")]: {
        fontSize: "1rem"
      },

      "&$filled": {
        "&$primary": {
          color: palette.primary.contrastText
        },
        "&$secondary": {
          color: palette.secondary.contrastText
        },
        "&$success": {
          color: palette.success.contrastText
        },
        "&$info": {
          color: palette.info.contrastText
        },
        "&$hint": {
          color: palette.hint.contrastText
        }
      }
    },
    icon: {
      fontSize: "1.5rem",
      [breakpoints.up("sm")]: {
        fontSize: "2.5rem"
      }
    },
    number: {
      fontSize: "3rem",
      fontWeight: 700
    },
    logoText: {
      color: palette.secondary.contrastText
    },
    avatar: {
      width: 40,
      height: 40,
      borderRadius: "50%",

      [breakpoints.up("sm")]: {
        height: 80,
        width: 80
      }
    },
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "&$transparent": {
        "&$default": {
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main,
          "&$withLogo": {
            backgroundColor: "transparent"
          }
        },
        "&$primary": {
          color: palette.primary.main,
          backgroundColor: palette.primary.light
        },
        "&$secondary": {
          color: palette.secondary.main,
          backgroundColor: palette.secondary.light
        },
        "&$success": {
          color: palette.success.main,
          backgroundColor: palette.success.light
        },
        "&$info": {
          color: palette.info.main,
          backgroundColor: palette.info.light
        },
        "&$hint": {
          color: palette.hint.main,
          backgroundColor: palette.hint.light
        },
        "&$error": {
          color: palette.error.main,
          backgroundColor: palette.error.light
        }
      },
      "&$filled": {
        "&$primary": {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.contrastText + "50"
        },
        "&$secondary": {
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.contrastText + "50"
        },
        "&$success": {
          color: palette.success.contrastText,
          backgroundColor: palette.info.contrastText + "50"
        },
        "&$info": {
          color: palette.info.contrastText,
          backgroundColor: palette.info.contrastText + "50"
        },
        "&$hint": {
          color: palette.hint.contrastText,
          backgroundColor: palette.hint.contrastText + "50"
        }
      }
    },
    avatarOrder: {
      "&$left": {
        order: 0,
        marginRight: spacing(1),

        [breakpoints.up("sm")]: {
          marginRight: spacing(2)
        }
      },
      "&$right": {
        order: 1,
        marginLeft: spacing(1),

        [breakpoints.up("sm")]: {
          marginLeft: spacing(2)
        }
      }
    },
    left: {},
    right: {},

    // card variants
    transparent: {},
    filled: {},

    // color variants
    default: {},
    primary: {},
    secondary: {},
    success: {},
    info: {},
    hint: {},
    error: {},

    // buttons
    buttons: {
      marginTop: spacing(1)
    },
    textButton: {
      marginLeft: spacing(-1),
      fontSize: "1rem",

      "&$transparent": {
        color: palette.secondary.main
      },
      "&$filled": {
        color: palette.secondary.light
      }
    },
    arrowButton: {
      color: palette.hint.dark,
      padding: spacing(0.5),
      "&:last-child": {
        marginLeft: spacing(2)
      }
    },
    arrowIcon: {
      fontSize: "2rem"
    },
    withLogo: {}
  })
);
