import React, { useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import moment from "moment";

import { createValidator } from "utils/forms";
import { Button, FormError, KeyboardDatePickerControl } from "components";
import * as PythonApi from "services/api/python";
import GroupTreeControl from "modules/common/components/group-tree/group-tree-control";
import { OptionType as OptionTypeSelect } from "components/forms/select-control/select-control";
import { useLightUsersList } from "modules/users/provider";
import useListFilters from "utils/hooks/useListFilters";
import { useCompaniesListPublic } from "modules/companies/provider";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import { useGenerateReportMutation } from "modules/users/provider/users-report";
import { useGenerateCourseReportMutation } from "modules/courses/provider/course-report";
import { useLightCoursesList } from "modules/courses/provider/courses";
import { useTenantsList } from "modules/tenants/provider/tenants";
import useToast from "utils/hooks/useToast";
import { downloadBlob } from "utils/fileDownload";
import {
  checkIfReportIsGenerated,
  ReportStatus,
  usePrepareReportUrl,
  useReportDownloadCallback,
  useReportStatusCallback
} from "modules/common/provider/reports";

import {
  ReportTypes as ReportConfig,
  ReportType as ReportTypeProps
} from "../reports.constants";

import useStyles from "./report-form.styles";

export type ReportSubmitData = {
  tenantId?: string;
  userId?: string;
  groupId?: string[];
  overviewUserId?: string[];
  courseId?: string;
  overviewCourseId?: string;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
};

type Props = {
  reportType: ReportTypeProps;
};

const minimalDateFrom = new Date("2020/01/01");
const dateTo = moment(); // Today

const getFilePrefix = (
  courseId: string | undefined,
  userId: string | undefined,
  tenantId: string | undefined,
  overviewUserId: string[] | undefined,
  groupId: string[] | undefined,
  overviewCourseId: string | undefined
) => {
  if (courseId) return `Course-${courseId}`;
  else if (userId) return `User-${userId}`;
  else if (overviewUserId) {
    const andText =
      overviewUserId.length === 1
        ? ""
        : `-and-${overviewUserId.length - 1}-others-overview`;
    return `Course-${overviewUserId[0]}${andText}`;
  } else if (groupId) {
    const andText =
      groupId.length === 1 ? "" : `-and-${groupId.length - 1}-others`;
    return `Company-${groupId[0]}${andText}`;
  } else if (overviewCourseId) return `Course-${overviewCourseId}-overview`;
  else if (tenantId) return `Tenant-${tenantId}`;
  else return "";
};

const ReportForm: React.FC<Props> = ({ reportType }) => {
  const type = reportType.type;
  const initialValues = useMemo(
    () => ({
      dateFrom: moment(dateTo).subtract(1, "months"),
      dateTo: dateTo
    }),
    []
  );

  // reports without date range
  const isCourseReportType = type === ReportConfig.courseReport.type;
  const isUserReportType = type === ReportConfig.userReport.type;

  // reports with date range
  const isCourseOverviewReportType =
    type === ReportConfig.courseOverviewReport.type;
  const isUserOverviewReportType =
    type === ReportConfig.userOverviewReport.type;
  const isCompanyReportType = type === ReportConfig.companyOverviewReport.type;
  const isPerformanceReportType =
    type === ReportConfig.overallPerformanceReport.type;
  const isAllUsersCoursesReportType =
    type === ReportConfig.allUsersCoursesReport.type;

  const validator = createValidator({
    dateFrom: {
      presence: { allowEmpty: false }
    },
    dateTo: {
      presence: { allowEmpty: false }
    },
    ...(isCourseReportType && {
      courseId: {
        presence: { allowEmpty: false }
      }
    }),
    ...(isUserReportType && {
      userId: {
        presence: { allowEmpty: false }
      }
    }),
    ...(isCourseOverviewReportType && {
      overviewCourseId: {
        presence: { allowEmpty: false }
      }
    }),
    ...(isUserOverviewReportType && {
      overviewUserId: {
        presence: { allowEmpty: false }
      }
    }),
    ...(isCompanyReportType && {
      groupId: {
        presence: { allowEmpty: false }
      }
    }),
    ...(isPerformanceReportType && {
      tenantId: {
        presence: { allowEmpty: false }
      }
    })
  });

  const ListFilters = useListFilters({
    pagination: { rowsPerPage: 25 }
  });

  const { data: users, loading: userLoading } = useLightUsersList({
    variables: { listFilters: ListFilters },
    skip: !isUserOverviewReportType && !isUserReportType
  });

  const { data: tenants, loading: tenantLoading } = useTenantsList({
    variables: { listFilters: ListFilters },
    skip: !isPerformanceReportType
  });

  const { data: groups, loading: groupLoading } = useCompaniesListPublic({
    variables: {
      listFilters: ListFilters,
      onlyOwn: true
    },
    skip: !isCompanyReportType
  });

  const { data: courses, loading: courseLoading } = useLightCoursesList({
    variables: {
      listFilters: ListFilters
    },
    skip: !isCourseReportType && !isCourseOverviewReportType
  });

  const usersOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      users?.map(admin => ({
        value: admin.id,
        label: `${admin.firstName} ${admin.lastName}`,
        parent: null
      })) || [],
    [users]
  );

  const tenantsOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      tenants?.map(tenant => ({
        value: tenant.hostname,
        label: tenant.name,
        parent: null
      })) || [],
    [tenants]
  );

  const groupsOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      groups?.map(group => ({
        value: group.id,
        label: group.name,
        parent: null
      })) || [],
    [groups]
  );

  const coursesOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      courses?.map(course => ({
        value: course.id,
        label: course.name,
        parent: null
      })) || [],
    [courses]
  );

  const classes = useStyles();
  const { showToast, openPersistToast, closeToast } = useToast();
  const prepareReportUrl = usePrepareReportUrl();
  const generateReport = useGenerateReportMutation();
  const generateCourseReport = useGenerateCourseReportMutation();
  const getReportStatus = useReportStatusCallback();
  const downloadReport = useReportDownloadCallback();
  const isMobile = useSizeCheck("xs");

  const [, setReportIsBeingGenerated] = React.useState(false);
  const toastKey = React.useRef<string | number | undefined>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    return () => {
      closeToast();
    };
  }, []); // eslint-disable-line

  const onStartReportGenerating = React.useCallback(() => {
    const key = openPersistToast("Downloading report in progress…", "info");
    !toastKey.current && key !== null && (toastKey.current = key);
  }, [openPersistToast]);

  const onStopReportGenerating = React.useCallback(() => {
    closeToast(toastKey.current && toastKey.current);
    toastKey.current = undefined;
  }, [closeToast]);

  const onSuccessfulReportGeneration = React.useCallback(
    (sendReportInEmail: boolean = false) => {
      onStopReportGenerating();
      showToast(
        sendReportInEmail
          ? "The generated report will be sent to your email address once ready"
          : "Your report was prepared and it'll be downloaded now",
        "success"
      );
      componentIsMounted.current && setReportIsBeingGenerated(false);
      setReportIsBeingGenerated(false);
    },
    [showToast, onStopReportGenerating]
  );

  const onUnsuccessfulReportGeneration = React.useCallback(() => {
    showToast(
      "Something went wrong, try again in a moment or report a problem",
      "error"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
    setReportIsBeingGenerated(false);
    onStopReportGenerating();
  }, [showToast, onStopReportGenerating]);

  const downloadReportAndBlob = React.useCallback(
    async (reportId: string, filename: string) => {
      const { data: fileBlob } = await downloadReport({ reportId });
      fileBlob && downloadBlob(fileBlob, filename);
      onSuccessfulReportGeneration();
    },
    [downloadReport, onSuccessfulReportGeneration]
  );

  const downloadReportWhenReady = React.useCallback(
    async (reportId: string, filename: string) => {
      try {
        const { data: reportStatusData } = await getReportStatus({ reportId });

        if (reportStatusData?.status === ReportStatus.FAILURE) {
          onUnsuccessfulReportGeneration();
          return;
        }

        if (checkIfReportIsGenerated(reportStatusData?.status)) {
          await downloadReportAndBlob(reportId, filename);
          return;
        } else {
          setTimeout(() => downloadReportWhenReady(reportId, filename), 2000);
        }
      } catch (ex) {
        onUnsuccessfulReportGeneration();
      }
    },
    [downloadReportAndBlob, getReportStatus, onUnsuccessfulReportGeneration]
  );

  const onReportSubmit = React.useCallback(
    async ({
      dateFrom,
      dateTo,
      tenantId,
      userId,
      groupId,
      courseId,
      overviewUserId,
      overviewCourseId
    }: ReportSubmitData) => {
      try {
        onStartReportGenerating();
        setReportIsBeingGenerated(true);
        let generatedReportData: any;
        if (isUserReportType && userId) {
          const response = await generateReport({ userId });
          generatedReportData = response.data;
        } else if (isCourseReportType && courseId) {
          const response = await generateCourseReport({ courseId });
          generatedReportData = response.data;
        } else {
          if (reportType.requestPath) {
            const chosenValue =
              tenantId || overviewUserId || groupId || overviewCourseId;

            const response = await prepareReportUrl({
              path:
                chosenValue && !Array.isArray(chosenValue)
                  ? reportType.requestPath(chosenValue)
                  : reportType.requestPath(""),
              dateFrom: dateFrom,
              dateTo: dateTo,
              body: reportType.buildBody
                ? reportType.buildBody(chosenValue as string[])
                : undefined
            });
            if (isAllUsersCoursesReportType) {
              onSuccessfulReportGeneration(true);
              return;
            }
            generatedReportData = response.data;
          }
        }
        if (generatedReportData) {
          const filename = `${getFilePrefix(
            courseId,
            userId,
            tenantId,
            overviewUserId,
            groupId,
            overviewCourseId
          )}-report-${moment(generatedReportData.createdAt).format("L")}.xlsx`;

          if (checkIfReportIsGenerated(generatedReportData.status)) {
            await downloadReportAndBlob(generatedReportData.id, filename);
          } else {
            await downloadReportWhenReady(generatedReportData.id, filename);
          }
        }
      } catch (e) {
        setReportIsBeingGenerated(false);
        onStopReportGenerating();
        throw e;
      }
    },
    [
      downloadReportAndBlob,
      downloadReportWhenReady,
      generateCourseReport,
      generateReport,
      isAllUsersCoursesReportType,
      isCourseReportType,
      isUserReportType,
      onSuccessfulReportGeneration,
      prepareReportUrl,
      onStartReportGenerating,
      onStopReportGenerating,
      reportType
    ]
  );

  const handleSubmit = React.useCallback(
    async values => await onReportSubmit(values),
    [onReportSubmit]
  );

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validator}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        hasValidationErrors,
        values
      }) => (
        <form className={classes.form}>
          <Grid container spacing={3}>
            {submitError && <FormError>{submitError}</FormError>}
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.reportTitle}>
                {reportType.label}
              </Typography>
              {isCourseReportType && (
                <Grid item xs={12}>
                  <Field
                    name="courseId"
                    label="Choose Course"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={coursesOptions}
                    isLoading={courseLoading}
                    listFilters={ListFilters}
                  />
                </Grid>
              )}
              {isUserReportType && (
                <Grid item xs={12}>
                  <Field
                    name="userId"
                    label="Choose User"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={usersOptions}
                    isLoading={userLoading}
                    listFilters={ListFilters}
                  />
                </Grid>
              )}
              {isCourseOverviewReportType && (
                <Grid item xs={12}>
                  <Field
                    name="overviewCourseId"
                    label="Choose Course"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={coursesOptions}
                    isLoading={courseLoading}
                    listFilters={ListFilters}
                  />
                </Grid>
              )}
              {isUserOverviewReportType && (
                <Grid item xs={12}>
                  <Field
                    name="overviewUserId"
                    label="Choose User"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={usersOptions}
                    isLoading={userLoading}
                    listFilters={ListFilters}
                    allowMultiple
                  />
                </Grid>
              )}
              {isPerformanceReportType && (
                <Grid item xs={12}>
                  <Field
                    name="tenantId"
                    label="Choose Tenant"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={tenantsOptions}
                    isLoading={tenantLoading}
                    listFilters={ListFilters}
                  />
                </Grid>
              )}
              {isCompanyReportType && (
                <Grid item xs={12}>
                  <Field
                    name="groupId"
                    label="Choose Company"
                    component={GroupTreeControl}
                    onlyOneFromBranch
                    options={groupsOptions}
                    isLoading={groupLoading}
                    listFilters={ListFilters}
                    allowMultiple
                  />
                </Grid>
              )}
            </Grid>
            {reportType.requestPath && (
              <Grid item xs={12} sm={5}>
                <Field
                  name="dateFrom"
                  label="Date from"
                  component={KeyboardDatePickerControl}
                  inputFormat="MM/DD/YYYY"
                  startDate={minimalDateFrom}
                  endDate={values.dateTo}
                />
              </Grid>
            )}
            {!isMobile && reportType.requestPath && (
              <Grid item xs={2}>
                <div className={classes.dateSeparator}>-</div>
              </Grid>
            )}
            {reportType.requestPath && (
              <Grid item xs={12} sm={5}>
                <Field
                  name="dateTo"
                  label="Date to"
                  component={KeyboardDatePickerControl}
                  inputFormat="MM/DD/YYYY"
                  startDate={initialValues.dateFrom}
                  endDate={values.dateTo}
                />
              </Grid>
            )}
            {error && <FormError center>{error}</FormError>}
            <Grid item xs={12}>
              <Button
                disabled={submitting || hasValidationErrors}
                onClick={async () => {
                  setError(undefined);
                  try {
                    await handleSubmit();
                  } catch (ex) {
                    setError(PythonApi.getMessageStringFromError(ex));
                  }
                }}
                color="success"
                type="submit"
              >
                Generate report
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default ReportForm;
