import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { UserRole } from "modules/users/users.constants";
import { HasAccess } from "services/auth-provider";

import ProblemsReportView from "./views/problems-report.view";
import ProblemsListRouter from "./routers/problems-list";
import UserProblemsListView from "./views/user-problems-list.view";
import ProblemDetails from "./views/problem-details";

type Props = {
  match: match;
};

const ProblemsRouter: React.FC<Props> = ({ match }) => (
  <Switch>
    {/* Redirect used to differentiate public and internal report form's paths */}
    <Redirect
      exact
      from={`${match.path}/report`}
      to={`${match.path}/report/form`}
    />
    <Route exact path={`${match.path}`} component={ProblemsListRouter} />
    <Route
      exact
      path={`${match.path}/report/form`}
      component={ProblemsReportView}
    />
    <Route
      exact
      path={`${match.path}/user/:id`}
      component={UserProblemsListView}
    />
    <HasAccess roles={[UserRole.ADMIN, UserRole.TENANT_ADMIN]}>
      <Route exact path={`${match.path}/:id`} component={ProblemDetails} />
    </HasAccess>
    <Redirect to={match.path} />
  </Switch>
);

export default ProblemsRouter;
