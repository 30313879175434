import * as React from "react";

import { UserStatus } from "modules/users/users.constants";

import Chip, { ChipVariant } from "./chip";

const statusVariants: Record<UserStatus, ChipVariant> = {
  [UserStatus.ACTIVE]: "success",
  [UserStatus.DEACTIVATED]: "hint",
  [UserStatus.PENDING]: "info"
};

interface Props {
  status: UserStatus;
}

const UserStatusChip: React.FC<Props> = ({ status }) => (
  <Chip label={status} size="small" variant={statusVariants[status]} />
);

export default UserStatusChip;
