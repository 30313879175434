import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(
  ({ spacing, typography, palette, breakpoints }: CustomTheme) => ({
    wrapper: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      [breakpoints.down("md")]: {
        flexDirection: "column"
      }
    },
    columnItem: {
      display: "flex",
      flexGrow: 1,
      flexBasis: "45%",
      maxWidth: "100%",
      marginTop: spacing(3),
      [breakpoints.up("lg")]: {
        marginTop: 0,
        maxWidth: "50%"
      }
    },
    right: {
      flexDirection: "column"
    },
    left: {
      marginRight: spacing(4),
      flexDirection: "row",
      flexWrap: "wrap"
    },
    infoItem: {
      flexGrow: 1,
      flexBasis: "30%",
      padding: spacing(1, 2, 3, 0)
    },
    title: {
      color: palette.text.primary,
      marginBottom: spacing(1)
    },
    text: {
      color: palette.text.default,
      fontSize: typography.pxToRem(15)
    },
    description: {
      flexGrow: 3,
      padding: spacing(1, 1, 3, 0)
    },
    icon: {
      fontSize: typography.pxToRem(12),
      color: palette.text.primary,
      marginRight: spacing(1)
    },
    durationContainer: {
      display: "flex",
      justifyContent: "flex-start"
    },
    button: {
      flex: 5,
      justifyContent: "flex-start",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block"
    },
    duration: {
      flex: 1,
      marginRight: spacing(1),
      marginLeft: spacing(1),
      fontSize: typography.pxToRem(14)
    },
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block"
    }
  })
);
