import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";

import {
  ResponsiveTableWrapper,
  DataTable,
  TooltipList,
  Drawer,
  Button
} from "components";
import { pluralize } from "utils/strings";
import useTableStyles from "components/data-table/data-table.styles";
import { ListResultsMeta } from "services/api/shared";
import { ListFiltersState } from "utils/hooks/useListFilters";
import tenant from "services/tenant";
import { AllowedComponents } from "services/tenant-provider";

import { LearningPlanItem } from "../../../provider/learning-plan";
import LearningPlanForm from "../learning-plan-form/learning-plan-form";
import { useAuth } from "services/auth-provider";
import { UserRole } from "../../../../users/users.constants";

import LearningPlanListRowActions from "./learning-plan-list-row-actions";
import LearningPlanDeletionModal from "./learning-plan-deletion-modal";

export type InitialStateType = {
  learningPlan?: LearningPlanItem;
  isOpen: boolean;
};

const initialState = {
  learningPlan: undefined,
  isOpen: false
};

type Props = {
  learningPlans: LearningPlanItem[];
  meta?: ListResultsMeta;
  listFilters: ListFiltersState;
  refetch: () => void;
  loading: boolean;
};

const LearningPlanList: React.FC<Props> = ({
  learningPlans,
  refetch,
  loading,
  listFilters
}) => {
  const tableClasses = useTableStyles();
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );
  const auth = useAuth();
  const isDesigner = auth.checkAccess({ roles: [UserRole.DESIGNER] });

  const [learningPlanForEdition, setLearningPlanForEdition] = useState<
    InitialStateType
  >(initialState);

  const [
    learningPlanDeletionModalState,
    setLearningPlanDeletionModalState
  ] = useState<InitialStateType>(initialState);

  const onEditContentSubmit = () => {
    setLearningPlanForEdition(initialState);
    refetch();
  };

  return (
    <>
      <ResponsiveTableWrapper>
        <DataTable
          loading={loading}
          emptyText="No learning plans"
          data={learningPlans}
          sortable
          listFilters={listFilters}
          columns={[
            {
              key: "name",
              title: "Name",
              sortable: true,
              render: lp =>
                !isDesigner ? (
                  <Button
                    variant="text"
                    target={isDetailsNewTab ? "_blank" : "_self"}
                    component={RouterLink}
                    to={`/courses/learning-plan/${lp.id}`}
                  >
                    <span
                      className={clsx(
                        tableClasses.ellipsis,
                        tableClasses.customText
                      )}
                    >
                      {lp.name}
                    </span>
                  </Button>
                ) : (
                  <span
                    className={clsx(
                      tableClasses.ellipsis,
                      tableClasses.customText
                    )}
                  >
                    {lp.name}
                  </span>
                )
            },
            {
              key: "id",
              title: "Id",
              render: lp => (
                <span
                  className={clsx(
                    tableClasses.ellipsis,
                    tableClasses.customText
                  )}
                >
                  {lp.externalId || "-"}
                </span>
              )
            },
            {
              key: "addedDate",
              title: "Added date",
              render: lp => moment(lp.createdAt).format("L")
            },
            {
              key: "groups",
              title: "Groups",
              render: ({ groups }) =>
                groups instanceof Array && groups.length > 0 ? (
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <span
                      className={clsx(
                        tableClasses.ellipsis,
                        tableClasses.customText
                      )}
                    >
                      {groups[0].name}
                    </span>
                    {groups.length > 1 && (
                      <TooltipList
                        title={`${groups.length} ${pluralize(
                          "group",
                          groups.length
                        )}`}
                        list={groups.map(group => group.name)}
                      />
                    )}
                  </div>
                ) : (
                  "-"
                )
            },
            {
              key: "countCourses",
              setWhiteSpaceNormal: true,
              title: "Courses count",
              className: tableClasses.center,
              render: lp => lp.courses.length
            },
            {
              key: "actions",
              title: "Actions",
              width: "1%",
              render: lp => (
                <LearningPlanListRowActions
                  learningPlan={lp}
                  setLearningPlanForEdition={setLearningPlanForEdition}
                  setLearningPlanDeletionModalState={
                    setLearningPlanDeletionModalState
                  }
                />
              )
            }
          ]}
        />
      </ResponsiveTableWrapper>

      <Drawer
        isOpen={learningPlanForEdition.isOpen}
        onClose={() => setLearningPlanForEdition(initialState)}
        title="Edit learning plan"
        size="md"
      >
        <LearningPlanForm
          learningPlan={learningPlanForEdition.learningPlan}
          onSubmit={onEditContentSubmit}
          onCancel={() => setLearningPlanForEdition(initialState)}
        />
      </Drawer>

      {learningPlanDeletionModalState.learningPlan && (
        <LearningPlanDeletionModal
          isOpen={learningPlanDeletionModalState.isOpen}
          learningPlan={learningPlanDeletionModalState.learningPlan}
          refetch={refetch}
          onClose={() => setLearningPlanDeletionModalState(initialState)}
        />
      )}
    </>
  );
};

export default LearningPlanList;
