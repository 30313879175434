import * as React from "react";

import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";
import ReportProblem from "modules/problems/components/report-problem.component";

import useStyles from "./guest.styles";

type Props = {};

const ReportProblemView: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <GuestLayout>
      <SidePanel variant="login" />
      <Content title="Report a problem" form className={classes.twoColumnsForm}>
        <ReportProblem />
      </Content>
      <BottomPanel variant="login" />
    </GuestLayout>
  );
};

export default ReportProblemView;
