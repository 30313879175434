import React, { useState, useCallback } from "react";
import { Grid, Box } from "@material-ui/core";
import { Form } from "react-final-form";
import { DropResult } from "react-beautiful-dnd";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import { ConfirmationDialog } from "components";
import { createValidator } from "utils/forms";
import useToast from "utils/hooks/useToast";
import * as PythonAPI from "services/api/python";

import CourseChip from "../components/course-chip";
import {
  LearningPlanItem,
  useLearningPlanUpdate
} from "../../../provider/learning-plan";
import DragNDrop from "../components/drag-n-drop";

export type Child = {
  handleOpen: () => void;
};

type Props = {
  learningPlan: LearningPlanItem;
  refetch: () => void;
  children: (props: Child) => React.ReactNode;
};

const EditCoursesModal: React.FC<Props> = ({
  learningPlan,
  refetch,
  children
}) => {
  const { showToast } = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const updateLearningPlan = useLearningPlanUpdate(learningPlan.id);

  const onClose = (reset: () => void) => {
    setModalOpen(false);
    reset();
  };

  const handleSubmit = useCallback(
    async (values: { courses: { id: string; name: string }[] }) => {
      const submitValues = {
        ...learningPlan,
        groups: learningPlan.groups.map(g => g.id),
        courses: values.courses.map(c => c.id)
      };

      try {
        await updateLearningPlan(submitValues);
        showToast("Learning plan was successfully updated", "success");
        refetch();
      } catch (e) {
        showToast(e.message, "error");
        return PythonAPI.getFormErrors(e);
      }
    },
    [refetch, showToast, updateLearningPlan, learningPlan]
  );

  const onDragEnd = (fields: { move: (from: number, to: number) => void }) => (
    result: DropResult
  ) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;
    fields.move(source.index, destination.index);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ courses: learningPlan.courses }}
      mutators={{
        reset: (_, state, { changeValue }) => {
          changeValue(state, "courses", () => learningPlan.courses);
        },
        ...arrayMutators
      }}
      validate={validator}
    >
      {({
        handleSubmit,
        submitting,
        hasValidationErrors,
        values,
        pristine,
        form: mutators
      }) => (
        <form>
          {children({
            handleOpen: () => {
              setModalOpen(true);
            }
          })}
          <ConfirmationDialog
            title="Edit courses list"
            isOpen={isModalOpen}
            onClose={() => onClose(mutators.reset)}
            onSubmit={handleSubmit}
            submitDisabled={submitting || hasValidationErrors || pristine}
            submitText="Save"
            submitColor="success"
            description={
              <Box mb={1}>
                {values.courses?.length === 0 ? (
                  <CourseChip
                    label="There are no courses on the list. Please add new one"
                    empty={true}
                  />
                ) : (
                  <Grid container>
                    <FieldArray name="courses">
                      {({ fields }) => (
                        <DragNDrop
                          onDragEnd={onDragEnd(fields)}
                          items={values.courses}
                          onRemove={fields.remove}
                          maxLength="sm"
                        />
                      )}
                    </FieldArray>
                  </Grid>
                )}
              </Box>
            }
          />
        </form>
      )}
    </Form>
  );
};

export default EditCoursesModal;

const validator = createValidator({
  courses: {
    length: {
      minimum: 1,
      tooShort: "can’t be blank"
    }
  }
});
