import * as React from "react";
import clsx from "clsx";
import { SnackbarProvider } from "notistack";

import { IconDone } from "assets/icons";

import useStyles from "./toast-provider.styles.js";

type Props = {};

const ToastProvider: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      dense
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      iconVariant={{ success: <IconDone /> }}
      classes={{
        containerAnchorOriginTopRight: classes.container,
        variantSuccess: clsx(classes.toast, classes.success),
        variantError: clsx(classes.toast, classes.error),
        variantInfo: clsx(classes.toast, classes.info),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
