import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "config/theme";

type Props = {
  background?: string;
};

export default makeStyles<CustomTheme, Props>(({ spacing, palette }) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: ({ background }) =>
      background === "white"
        ? palette.background.default
        : palette.background.accent,
    borderRadius: spacing(2),
    padding: spacing(3, 3, 4, 3),
    display: "flex",
    flexDirection: "column"
  },
  submit: {
    backgroundColor: palette.success.main,
    color: palette.background.default
  },
  cancel: {
    color: palette.error.main,
    marginRight: spacing(2)
  },
  checkLabel: {
    fontSize: "0.875rem"
  },
  formContainer: {
    width: "100%",
    alignSelf: "flex-end"
  },
  list: {
    overflowX: "hidden",
    overflowY: "scroll",
    height: 350,
    marginBottom: spacing(2),

    "&::-webkit-scrollbar": {
      width: "0.2em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 3px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 3px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0.5px solid #9CA1BC"
    }
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: ({ background }) =>
      background === "white"
        ? palette.action.selected
        : palette.background.default,
    margin: spacing(0, 2, 0, 0),
    color: palette.text.avatar,
    fontSize: 15,
    fontWeight: 700
  },
  date: {
    color: palette.hint.main,
    fontSize: "0.875rem"
  },
  text: {
    marginBottom: spacing(2),
    width: "100%",
    fontSize: 15,
    wordBreak: "break-word",
    color: palette.text.default
  },
  icon: {
    fontSize: "1rem",
    margin: spacing(0, 1, -0.5, 0)
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: spacing(1)
  },
  buttons: {
    marginTop: spacing(2)
  },
  commentInputRoot: {
    "& > div": {
      backgroundColor: palette.background.default
    }
  },
  commentInputLabel: {
    color: palette.hint.main,
    backgroundColor: "transparent",

    "&.Mui-focused": {
      color: palette.hint.main
    }
  },
  chip: {
    width: 54,
    height: 18,
    fontSize: "0.625rem",
    color: palette.common.white,
    backgroundColor: palette.chip.contrastText
  }
}));
