import React from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { screen } from "globalthis/implementation";

import { Button } from "components";
import { useUserDisclaimerApprove } from "modules/users/provider";
import { useAuth } from "services/auth-provider";
import { IconCheckBox, IconCheckBoxOutlineBlank } from "assets/icons";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import { useTenant } from "services/tenant-provider";

import useStyles from "./disclaimer-modal.styles";

const DisclaimerModal = () => {
  const [checked, setCheck] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const isMobile = useSizeCheck("xs");
  const tenant = useTenant();

  const approveDisclaimer = useUserDisclaimerApprove();
  const legalDisclaimerConfig = tenant.getLegalDiscalimer();

  React.useEffect(() => {
    if (!auth.user?.isDisclaimerApproved) handleOpen();
  }, [auth.user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const sendApproveDisclaimer = async () => await approveDisclaimer({});

  const handleClose = () => {
    sendApproveDisclaimer();
    setOpen(false);
    window.location.reload();
  };

  const getCheckBoxSize = () => (isMobile ? "large" : "small");
  const isHighMobile = () => screen.height > 650;

  return (
    <div>
      <Modal open={open}>
        <div className={classes.container}>
          <Typography
            variant="h4"
            component="h3"
            color="textPrimary"
            gutterBottom
            className={classes.title}
          >
            {legalDisclaimerConfig?.title
              ? legalDisclaimerConfig?.title
              : "Verification disclaimer"}
          </Typography>

          {legalDisclaimerConfig?.body && (
            <section
              className={clsx(
                classes.articlesContainer,
                isHighMobile() && classes.articlesContainerHighMobile
              )}
            >
              {legalDisclaimerConfig?.body?.map((text, index) => (
                <Typography key={index} className={classes.disclaimerArticle}>
                  {text}
                </Typography>
              ))}
            </section>
          )}

          <FormControlLabel
            control={
              <Checkbox
                icon={<IconCheckBoxOutlineBlank fontSize={getCheckBoxSize()} />}
                checkedIcon={<IconCheckBox fontSize={getCheckBoxSize()} />}
                onChange={event => {
                  setCheck(event.target.checked);
                }}
                color="secondary"
              />
            }
            classes={{ label: classes.label }}
            label={
              legalDisclaimerConfig?.consent
                ? legalDisclaimerConfig?.consent
                : "I acknowledge and agree to the condition(s) above"
            }
          />
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleClose}
              fullWidth={isMobile}
              color="success"
              disabled={!checked}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DisclaimerModal;
