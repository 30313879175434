import { pluralize } from "utils/strings";

export interface PolicyValidator {
  isValid(value: string): boolean;
  getMessage(): string;
}
export interface SinglePolicyValidator extends PolicyValidator {}
export interface CompoundPolicyValidator extends PolicyValidator {
  getPolicies(): PolicyValidator[];
  getValidationResults(): boolean[];
  getInvalidPolicies(): PolicyValidator[];
  getValidPolicies(): PolicyValidator[];
  getMessages(): string[];
}

export class MinLength implements SinglePolicyValidator {
  constructor(protected minLength: number) {}

  isValid(value: string) {
    return value.length >= this.minLength;
  }

  getMessage() {
    return `${this.minLength} ${pluralize("character", this.minLength)}`;
  }
}

abstract class CharacterCountValidator implements SinglePolicyValidator {
  constructor(protected minCount: number, protected pattern: RegExp) {}

  isValid(value: string) {
    const matches = value.match(this.pattern);
    return (matches || []).length >= this.minCount;
  }

  abstract getMessage(): string;
}

export class LowerAlpha extends CharacterCountValidator {
  constructor(minCount: number) {
    super(minCount, /([a-z])/g);
  }

  getMessage() {
    return `${this.minCount} ${pluralize("lowercase letter", this.minCount)}`;
  }
}

export class UpperAlpha extends CharacterCountValidator {
  constructor(minCount: number) {
    super(minCount, /([A-Z])/g);
  }

  getMessage() {
    return `${this.minCount} ${pluralize("uppercase letter", this.minCount)}`;
  }
}

export class Digits extends CharacterCountValidator {
  constructor(minCount: number) {
    super(minCount, /([0-9])/g);
  }

  getMessage() {
    return `${this.minCount} ${pluralize("digit", this.minCount)}`;
  }
}

export class Special extends CharacterCountValidator {
  constructor(minCount: number) {
    super(minCount, /([~`!@#$%^&*()_+={}[\]:;'"<>?,./-])/g);
  }

  getMessage() {
    return `${this.minCount} ${pluralize("special character", this.minCount)}`;
  }
}

export class MultiPolicyValidator implements CompoundPolicyValidator {
  protected validationResults: boolean[] = [];

  constructor(
    protected minCount: number,
    protected policyValidators: PolicyValidator[]
  ) {}

  isValid(value: string) {
    this.validationResults = this.policyValidators.map(pv => pv.isValid(value));

    const validPolicies = this.validationResults.filter(r => r);
    return validPolicies.length >= this.minCount;
  }

  getMessage() {
    return `${this.minCount} ${pluralize("valid policy", this.minCount)}`;
  }

  getMessages() {
    return this.getInvalidPolicies().map(p => p.getMessage());
  }

  protected getPoliciesWithResult(expectedResult: boolean) {
    return this.validationResults
      .map((isValid, index) =>
        isValid === expectedResult ? this.policyValidators[index] : null
      )
      .filter(Boolean) as PolicyValidator[];
  }

  getInvalidPolicies() {
    return this.getPoliciesWithResult(false);
  }

  getValidPolicies() {
    return this.getPoliciesWithResult(true);
  }

  getPolicies() {
    return this.policyValidators;
  }

  getValidationResults() {
    return this.validationResults;
  }
}

export class MinValidCharGroups extends MultiPolicyValidator {
  getMessage() {
    return `${this.minCount} of the following character groups:`;
  }
}
