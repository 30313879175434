import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
  yourReports: {
    fontSize: "0.7rem"
  },
  badge: {
    fontSize: "0.7rem",
    marginLeft: spacing(2),
    color: palette.text.secondary,
    "& .MuiBadge-badge": {
      backgroundColor: palette.background.default
    }
  }
}));
