import * as React from "react";
import { Form, Field } from "react-final-form";

import { FormError, Section, Header, CancelConfirmButtons } from "components";
import * as PythonApi from "services/api/python";
import GroupTreeControl, {
  OptionType as OptionTypeTree
} from "modules/common/components/group-tree/group-tree-control";
import useListFilters from "utils/hooks/useListFilters";
import { UserRole } from "modules/users/users.constants";
import {
  useCompaniesList,
  useCompaniesListPublic
} from "modules/companies/provider";
import { useUsersList } from "modules/users/provider";
import { useCourseDirectUsersList } from "modules/users/provider/users";
import useToast from "utils/hooks/useToast";
import { OptionType as OptionTypeSelect } from "components/forms/select-control/select-control";
import { useAuth } from "services/auth-provider";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import {
  useAssignCoursesToGroups,
  useAssignCourseToUsers,
  useUnassignCourseToUsers,
  useUnassignCoursesToGroups
} from "../provider/group-courses";

export type CourseToAssign = {
  courseId: string;
};

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  course: CourseToAssign;
};

type SubmitForm = {
  groupIds: Array<string>;
  userIds: Array<string>;
};

const CourseAssign: React.FC<Props> = ({ onCancel, onSubmit, course }) => {
  const { showToast } = useToast();
  const assignCoursesToGroups = useAssignCoursesToGroups(course.courseId);
  const unassignCoursesToGroups = useUnassignCoursesToGroups(course.courseId);
  const assignCourseToUsers = useAssignCourseToUsers();
  const unssignCourseToUsers = useUnassignCourseToUsers();
  const { checkAccess } = useAuth();
  const tenant = useTenant();

  const isAllowedGroupAssign = tenant.isComponentAllowed(
    AllowedComponents.CourseCompanyAssign
  );
  const isAllowedUserAssign = tenant.isComponentAllowed(
    AllowedComponents.CourseUserAssign
  );
  const isTenantAdmin = checkAccess({
    roles: [UserRole.TENANT_ADMIN, UserRole.INTERNAL_ADMIN]
  });

  const groupsListFilters = useListFilters({
    pagination: { rowsPerPage: 0 },
    orderBy: ["name,ASC"]
  });
  const allGroups = useCompaniesListPublic({
    variables: {
      listFilters: groupsListFilters,
      onlyOwn: true
    },
    skip: !isAllowedGroupAssign
  });
  const assignedGroups = useCompaniesList({
    variables: { listFilters: groupsListFilters, courseId: course.courseId },
    skip: !isAllowedGroupAssign
  });

  const usersListFilters = useListFilters({
    pagination: { rowsPerPage: 100 },
    orderBy: ["lastName,ASC"]
  });
  const allUsers = useUsersList({
    variables: { listFilters: usersListFilters },
    skip: !isAllowedUserAssign
  });
  const assignedDirectUsers = useCourseDirectUsersList({
    variables: { listFilters: usersListFilters, id: course.courseId },
    skip: !isAllowedUserAssign
  });

  const groupOptions = React.useMemo<Array<OptionTypeTree>>(
    () =>
      allGroups.data?.map(({ id, name, parent }) => ({
        label: name,
        value: id,
        parent: isTenantAdmin
          ? allGroups.data!.some(({ id }) => id === parent)
            ? parent
            : null
          : parent
      })) || [],
    [allGroups.data, isTenantAdmin]
  );

  const userOptions = React.useMemo<Array<OptionTypeSelect>>(
    () =>
      allUsers.data?.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.username})`,
        parent: null
      })) || [],
    [allUsers.data]
  );

  const handleSubmit = async (values: SubmitForm) => {
    try {
      const onlyNewGroups = values.groupIds?.filter(
        id => !initialValues.groupIds?.includes(id)
      );
      const onlyUnassignedGroups = initialValues.groupIds?.filter(
        id => !values.groupIds?.includes(id)
      );

      const onlyNewDirectUsers = values.userIds?.filter(
        id => !initialValues.userIds?.includes(id)
      );
      const onlyUnassignedUsers = initialValues.userIds?.filter(
        id => !values.userIds?.includes(id)
      );

      if (onlyNewGroups?.length > 0) {
        await assignCoursesToGroups({
          groups: onlyNewGroups
        });

        showToast(
          onlyNewGroups?.length > 1
            ? "Groups were successfully added"
            : "Group was successfully added",
          "success"
        );
      }

      if (onlyUnassignedGroups?.length > 0) {
        await unassignCoursesToGroups({
          groups: onlyUnassignedGroups
        });

        showToast(
          onlyUnassignedGroups?.length > 1
            ? "Groups were successfully removed"
            : "Group was successfully removed",
          "success"
        );
      }

      if (onlyNewDirectUsers?.length > 0) {
        await assignCourseToUsers({
          course: course.courseId,
          users: onlyNewDirectUsers
        });

        showToast(
          onlyNewDirectUsers?.length > 1
            ? "Users were successfully added"
            : "User successfully added",
          "success"
        );
      }

      if (onlyUnassignedUsers?.length > 0) {
        await unssignCourseToUsers({
          course: course.courseId,
          users: onlyUnassignedUsers
        });

        showToast(
          onlyUnassignedUsers?.length > 1
            ? "Users were successfully removed"
            : "User successfully removed",
          "success"
        );
      }

      onSubmit();
    } catch (ex) {
      const errorMessages = PythonApi.getMessageStringFromError(ex);
      showToast(errorMessages, "error");
    }
  };

  const initialValues = React.useMemo<SubmitForm>(() => {
    if (!assignedGroups.loading || !assignedDirectUsers.loading) {
      return {
        groupIds: assignedGroups.data?.map(e => e.id) || [],
        userIds: assignedDirectUsers.data?.map(e => e.id) || []
      };
    }

    return {
      groupIds: [],
      userIds: []
    };
  }, [
    assignedGroups.data,
    assignedGroups.loading,
    assignedDirectUsers.data,
    assignedDirectUsers.loading
  ]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        hasValidationErrors,
        pristine
      }) => (
        <form onSubmit={handleSubmit}>
          {submitError && <FormError>{submitError}</FormError>}

          {isAllowedGroupAssign && (
            <Section margin="dense">
              <Header variant="formSection" number="1" title="Assign group" />
              <Field
                key="groupIds"
                name="groupIds"
                label="Assign group"
                component={GroupTreeControl}
                onlyOneFromBranch
                allowMultiple
                options={groupOptions}
                isLoading={allGroups.loading || assignedGroups.loading}
                listFilters={groupsListFilters}
              />
            </Section>
          )}

          {isAllowedUserAssign && (
            <Section margin="dense">
              <Header variant="formSection" number="2" title="Assign user" />
              <Field
                name="userIds"
                key="userIds"
                label="Assign user"
                component={GroupTreeControl}
                onlyOneFromBranch
                allowMultiple
                infiniteScroll
                options={userOptions}
                isLoading={allUsers.loading || assignedDirectUsers.loading}
                listFilters={usersListFilters}
              />
            </Section>
          )}

          <CancelConfirmButtons
            confirmButtonDisabled={
              submitting || hasValidationErrors || pristine
            }
            confirmButtonLabel="Save"
            onCancel={onCancel}
          />
        </form>
      )}
    </Form>
  );
};

export default CourseAssign;
