import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette }) => ({
  wrapper: {
    minWidth: 150,
    "& > *": {
      marginBottom: 0
    },
    "& label+*": {
      marginTop: 0
    },
    "& .react-select__control": {
      cursor: "pointer",
      minHeight: "auto"
    },
    "& .react-select__placeholder": {
      color: palette.text.default
    }
  }
}));
