import * as React from "react";
import { Form, Field } from "react-final-form";
import { History } from "history";

import routes from "config/routes";
import { createValidator } from "utils/forms";
import { Section, FormError, TextControl, Button } from "components";
import GuestLayout, {
  Content,
  SidePanel,
  BottomPanel
} from "components/layouts/guest-layout/guest-layout";
import * as PythonApi from "services/api/python";

import ButtonContainer from "../components/button-container.component";
import { useForgotPasswordMutation } from "../guest.provider";

import useStyles from "./guest.styles";

const validator = createValidator({
  email: { presence: { allowEmpty: false }, email: { message: "is invalid" } }
});

type Props = {
  history: History;
};

const ResetPasswordView: React.FC<Props> = ({ history }) => {
  const classes = useStyles();

  const forgotPassword = useForgotPasswordMutation();

  return (
    <GuestLayout>
      <SidePanel variant="login" />
      <Content
        title="Reset password"
        text="We will send you an email with your username and link to reset your password."
        form
        className={classes.form}
      >
        <Form
          onSubmit={async values => {
            try {
              await forgotPassword({ email: values.email });
              history.push(routes.auth.forgotPasswordSuccess);
            } catch (ex) {
              return PythonApi.getFormErrors(ex);
            }
          }}
          validate={validator}
          initialValues={{
            email: ""
          }}
        >
          {({
            handleSubmit,
            submitError,
            submitting,
            pristine,
            hasValidationErrors
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}

              <Section margin="dense">
                <Field
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  component={TextControl}
                  margin="normal"
                  autoComplete="on"
                  validationIndicator
                />
              </Section>
              <ButtonContainer>
                <Button
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={submitting || hasValidationErrors || pristine}
                >
                  Reset password
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Form>
      </Content>
      <BottomPanel variant="login" />
    </GuestLayout>
  );
};

export default ResetPasswordView;
