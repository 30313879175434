import * as React from "react";
import { Switch, Route, Redirect, match } from "react-router-dom";

import { HasAccess } from "services/auth-provider";
import routes from "config/routes";
import { UserRole } from "modules/users/users.constants";

import SubmissionListView from "../views/submission-list.view";
import { useModuleState, actionTypes } from "../reducers/submissions";

type Props = {
  match: match;
};

const ManualSubmissionsRouter: React.FC<Props> = ({ match }) => {
  const [, dispatch] = useModuleState();
  React.useEffect(
    () => () => {
      dispatch({ type: actionTypes.RESET_SUBMISSIONS_FILTERS });
    },
    [dispatch]
  );
  return (
    <Switch>
      <Route exact path={match.path}>
        <HasAccess roles={[UserRole.ADMIN, UserRole.TENANT_ADMIN]}>
          <SubmissionListView />
        </HasAccess>
        <HasAccess roles={[UserRole.DESIGNER]}>
          <Redirect to={routes.courses.list} />
        </HasAccess>
      </Route>
      <Redirect to={routes.courses.list} />
    </Switch>
  );
};

export default ManualSubmissionsRouter;
