import React from "react";
import { Typography } from "@material-ui/core";

import { pluralize } from "utils/strings";

import { PrerequisiteCourse } from "../../provider/courses";

import useStyles from "./prerequisite-courses.styles";

type Props = {
  prerequisiteCourses: PrerequisiteCourse[];
};

const PrerequisiteCourses: React.FC<Props> = ({ prerequisiteCourses }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph className={classes.prerequisite}>
        Prerequisite {pluralize("course", prerequisiteCourses.length)}:
      </Typography>
      <Typography variant="body2" className={classes.content}>
        {prerequisiteCourses[0].name}
        {prerequisiteCourses.length > 1 && (
          <span className={classes.more}>{`+${prerequisiteCourses.length -
            1} more`}</span>
        )}
      </Typography>
    </>
  );
};

export default PrerequisiteCourses;
