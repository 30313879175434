import * as React from "react";
import clsx from "clsx";
import { Chip as MuiChip } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip";

import { IconClear } from "assets/icons";

import useStyles from "./chip.styles";

export type ChipVariant =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "hint"
  | "info";

type Props = {
  label: React.ReactNode;
  variant: ChipVariant;
  icon?: React.ReactElement;
  onDelete?: (...args: any[]) => void;
  className?: string;
};

const Chip: React.FC<Omit<ChipProps, keyof Props> & Props> = ({
  label,
  variant,
  icon,
  onDelete,
  className,
  ...chipProps
}) => {
  const classes = useStyles();

  return (
    <MuiChip
      {...chipProps}
      icon={icon}
      label={label}
      className={clsx(classes.root, classes[variant], className)}
      classes={{
        root: clsx(classes.root, Boolean(onDelete) && classes.withDelete),
        icon: classes.icon
      }}
      onDelete={onDelete}
      deleteIcon={<IconClear />}
    />
  );
};

export default Chip;
