import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import {
  Paper,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { TypographyWithIcon } from "components";
import { IconHistoryOutlined } from "assets/icons";

import useStyles from "./notifications-list.styles";

const fakeNotifications = [
  {
    id: 3,
    date: new Date("2019-06-10T18:00:00Z"),
    title:
      "Third notification with a very long description. Third notification with a very long description. Third notification with a very long description. Third notification with a very long description. Third notification with a very long description. ",
    read: false
  },
  {
    id: 2,
    date: new Date("2019-06-02T11:17:21Z"),
    title: "Second notification",
    read: true
  },
  {
    id: 1,
    date: new Date("2019-06-01T00:00:00Z"),
    title: "First notification",
    read: true
  }
];

const NotificationsList = ({ notifications = fakeNotifications }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <List>
        {notifications.map(notification => (
          <ListItem
            key={notification.id}
            alignItems="flex-start"
            disableGutters
          >
            <ListItemIcon className={classes.item}>
              <TypographyWithIcon
                variant="body2"
                className={clsx(
                  classes.date,
                  !notification.read && classes.unread
                )}
                icon={IconHistoryOutlined}
              >
                {moment(notification.date).format("L")}
              </TypographyWithIcon>
            </ListItemIcon>
            <ListItemText className={classes.item}>
              <Typography
                variant="body1"
                className={clsx(
                  classes.title,
                  !notification.read && classes.unread
                )}
              >
                {notification.title}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default NotificationsList;
