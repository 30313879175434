import * as React from "react";
import moment from "moment";
import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";

import { downloadBlob } from "utils/fileDownload";
import {
  Button,
  DateFilter,
  ExtendedMainHeader,
  Header,
  ListToolbar,
  Search
} from "components";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import LayoutSelector, {
  LayoutType
} from "components/layout-selector/layout-selector";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { ListResultsMeta } from "services/api/shared";
import { useAuth } from "services/auth-provider";
import { UserRole } from "modules/users/users.constants";
import { AllowedComponents, useTenant } from "services/tenant-provider";
import { useAllAvailableCoursesReportMutation } from "modules/courses/provider/course-report";
import {
  useReportStatusCallback,
  useReportDownloadCallback,
  checkIfReportIsGenerated,
  ReportStatus
} from "modules/common/provider/reports";
import useToast from "utils/hooks/useToast";

import useStyles from "./course-list-my-toolbar.styles";

type Props = {
  isHistoryView: boolean;
  meta: ListResultsMeta;
  listFilters: ListFiltersState;
  activeLayout: LayoutType;
  setActiveLayout: (newLayout: LayoutType) => void;
};

const MyCoursesListToolbar: React.FC<Props> = ({
  isHistoryView,
  meta,
  listFilters,
  activeLayout,
  setActiveLayout
}) => {
  const auth = useAuth();
  const classes = useStyles();
  const isMobile = useSizeCheck("xs");
  const tenant = useTenant();
  const { showToast } = useToast();
  const isLearningPlans = tenant.isComponentAllowed(
    AllowedComponents.LearningPlanCreation
  );
  const [reportIsBeingGenerated, setReportIsBeingGenerated] = React.useState(
    false
  );
  const generateReport = useAllAvailableCoursesReportMutation();
  const getReportStatus = useReportStatusCallback();
  const downloadReport = useReportDownloadCallback();
  const isAdmin = auth.checkAccess({ roles: [UserRole.ADMIN] });
  const isInternal = auth.checkAccess({ roles: [UserRole.INTERNAL_ADMIN] });

  const left = (
    <Grid container justify="flex-start" spacing={2}>
      <Grid item xs={12} className={classes.gridItem}>
        <ExtendedMainHeader
          title={
            isMobile
              ? isHistoryView
                ? `Completed courses (${(meta && meta.total) || 0})`
                : `Available courses (${(meta && meta.total) || 0})`
              : isHistoryView
              ? "History"
              : "Courses"
          }
        />
      </Grid>
      <Grid
        item
        xs="auto"
        className={clsx(classes.gridItem, classes.gridSearch)}
      >
        <Search listFilters={listFilters} />
      </Grid>
      {isHistoryView && (
        <Hidden xsDown>
          <Grid item xs="auto" className={classes.gridItem}>
            <DateFilter
              label="From date"
              listFilters={listFilters}
              name="status_timestamp"
              maxDate={listFilters?.filters?.status_timestamp__lte}
            />
          </Grid>
          <Grid item xs="auto" className={classes.gridItem}>
            <DateFilter
              label="To date"
              listFilters={listFilters}
              name="status_timestamp__lte"
              minDate={listFilters?.filters?.status_timestamp}
              timeBoundary="dayEnd"
            />
          </Grid>
        </Hidden>
      )}
    </Grid>
  );

  const leftLearningPlans = (
    <Header
      title={
        isMobile
          ? `Available courses (${(meta && meta.total) || 0})`
          : "Courses"
      }
      variant="section"
    />
  );

  const componentIsMounted = React.useRef(false);
  React.useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const onSuccessfulReportGeneration = () => {
    showToast(
      "Your report was prepared and it'll be downloaded now",
      "success"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const onUnsuccessfulReportGeneration = () => {
    showToast(
      "Something went wrong, try again in a moment or report a problem",
      "error"
    );
    componentIsMounted.current && setReportIsBeingGenerated(false);
  };

  const downloadReportAndBlob = async (reportId: string, filename: string) => {
    const { data: fileBlob } = await downloadReport({ reportId });
    fileBlob && downloadBlob(fileBlob, filename);
    onSuccessfulReportGeneration();
  };

  // Recursion is introduced because request time is volatile
  // It's better to make another requests after earlier request is finished
  // Alternative is to use interval, but it's prone to errors
  const downloadReportWhenReady = async (
    reportId: string,
    filename: string
  ) => {
    try {
      const { data: reportStatusData } = await getReportStatus({ reportId });

      if (reportStatusData?.status === ReportStatus.FAILURE) {
        onUnsuccessfulReportGeneration();
        return;
      }

      if (checkIfReportIsGenerated(reportStatusData?.status)) {
        await downloadReportAndBlob(reportId, filename);
        return;
      } else {
        setTimeout(() => downloadReportWhenReady(reportId, filename), 2000);
      }
    } catch (ex) {
      onUnsuccessfulReportGeneration();
    }
  };

  const handleDownloadReport = async () => {
    setReportIsBeingGenerated(true);
    try {
      const { data: generateReportData } = await generateReport({});

      if (generateReportData) {
        const { status, id: reportId, createdAt } = generateReportData;
        const filename = `All-available-courses-report-${moment(
          createdAt
        ).format("L")}.xlsx`;

        if (checkIfReportIsGenerated(status)) {
          await downloadReportAndBlob(reportId, filename);
        } else {
          await downloadReportWhenReady(reportId, filename);
        }
      }
    } catch (ex) {
      onUnsuccessfulReportGeneration();
    }
  };

  return (
    <>
      {(!isLearningPlans || isHistoryView) && <ListToolbar left={left} />}
      {isLearningPlans && !isHistoryView && (
        <div className={classes.searchDiv}>
          <Search listFilters={listFilters} />
        </div>
      )}
      {isLearningPlans && isMobile && !isHistoryView && leftLearningPlans}
      <Hidden xsDown>
        <Header
          variant="section"
          title={`${
            isHistoryView ? "Completed" : "Available"
          } courses (${(meta && meta.total) || 0})`}
        >
          {isLearningPlans && !isHistoryView && (
            <div className={classes.searchHeader}>
              <Search listFilters={listFilters} />
            </div>
          )}
          <LayoutSelector
            activeLayout={activeLayout}
            setActiveLayout={setActiveLayout}
          />
        </Header>
      </Hidden>
      {(isAdmin || isInternal) && (
        <Button
          className={classes.exportButton}
          component="label"
          color="secondary"
          onClick={handleDownloadReport}
          disabled={(meta && meta.total === 0) || reportIsBeingGenerated}
        >
          {reportIsBeingGenerated ? "Preparing report..." : "Download courses"}
        </Button>
      )}
    </>
  );
};

export default MyCoursesListToolbar;
