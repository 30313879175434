import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  companyData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: spacing(2, 0),
    justifyContent: "center",

    "&$hideAvatar": {
      textAlign: "center",
      display: "inline-block"
    },
    [breakpoints.only("sm")]: {
      flexDirection: "column",
      textAlign: "center",
      marginTop: spacing(6)
    }
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: palette.secondary.main,
    color: palette.primary.main,
    marginBottom: 0,
    marginRight: spacing(2),

    [breakpoints.only("sm")]: {
      marginBottom: spacing(1.5),
      marginRight: 0
    },
    [breakpoints.up("lg")]: {
      "&$narrowed": {
        marginRight: 0
      }
    }
  },
  name: {
    [breakpoints.only("sm")]: {
      fontSize: "1rem"
    },
    "&$hideAvatar": {
      fontSize: "1.2rem"
    },
    [breakpoints.up("lg")]: {
      "&$narrowed": {
        display: "none"
      }
    }
  },
  hideAvatar: {},
  narrowed: {}
}));
