import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette }) => ({
  bottomPanelContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: palette.text.default,
    textAlign: "center"
  },
  textButton: {
    fontSize: "1rem",
    color: palette.primary.main,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline"
    }
  }
}));
