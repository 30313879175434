import { makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
  dropContainer: {
    minHeight: 180,
    backgroundColor: palette.hint.light,
    borderRadius: 5,
    border: `1px dashed ${palette.hint.main}`,
    padding: spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(2),
    "&:focus": {
      outline: "none"
    }
  },
  placeholder: {
    textAlign: "center"
  },
  uploadIcon: {
    color: palette.hint.main,
    fontSize: "3rem",
    marginBottom: spacing(1)
  },
  dropTextContainer: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    justifyContent: "center",
    minHeight: 30
  },
  browseButton: {
    fontSize: "1rem"
  },
  dropText: {
    color: palette.hint.main,
    fontWeight: 500
  },
  container: {
    display: "flex",
    marginBottom: spacing(1)
  },
  text: {
    margin: spacing(0, 1)
  },
  imagePreview: {
    maxHeight: 130,
    width: "auto",
    maxWidth: spacing(28),
    marginLeft: spacing(-4)
  },
  iconButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  badge: {
    zIndex: 0,
    borderRadius: "50%",
    height: spacing(3),
    width: spacing(3)
  },
  clearIcon: {
    transform: "scale(0.9)"
  }
}));
