import * as React from "react";
// eslint-disable-next-line import/named
import { FieldRenderProps } from "react-final-form";
import { components as reactSelectComponents } from "react-select";
import { FormControlProps } from "@material-ui/core/FormControl";

import { SelectControl, Dropdown } from "components";
import { ListFiltersState } from "utils/hooks/useListFilters";

import TreeContextProvider, {
  treeReducer,
  actionTypes,
  useSelected,
  useDispatch,
  TreeRootsContext
} from "./context";
import TreeContainer from "./tree-container";

type OptionType = { value: string; label: string };

type Props = FieldRenderProps<string[] | string, HTMLElement> & {
  label?: string;
  formControlProps?: FormControlProps;
  allowMultiple?: boolean; //false - radiocheckboxes, if true -> checkboxes
  filteredOptions?: Array<OptionType>;
  options?: Array<OptionType>;
  isLoading?: boolean;
  infiniteScroll?: boolean;
  noOptionsMessage?: string;
  onlyOneFromBranch?: boolean;
  listFilters?: ListFiltersState<Record<string, any>>;
  onFilter?: (option: any) => void;
};

const TreeControl = ({
  listFilters,
  onlyOneFromBranch,
  filteredOptions,
  onFilter,
  isLoading,
  ...fieldProps
}: Props) => {
  const dispatch = useDispatch();
  const selected = useSelected();

  const setInitial = React.useCallback(
    initial => {
      dispatch({
        type: actionTypes.SET_INITIAL,
        payload: { options: filteredOptions, initial }
      });
      if (onFilter) {
        const result = fieldProps.allowMultiple
          ? initial || []
          : [initial || ""];
        onFilter(result);
      }
    },
    [dispatch, fieldProps.allowMultiple, filteredOptions, onFilter]
  );

  React.useEffect(() => {
    setInitial(fieldProps.input.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps.input.value, filteredOptions]);

  const content = React.useMemo(
    () => (
      <TreeContainer
        options={filteredOptions!}
        infiniteScroll={fieldProps.infiniteScroll}
        isLoading={isLoading ?? false}
        key={listFilters?.search}
      />
    ),
    [fieldProps.infiniteScroll, filteredOptions, isLoading, listFilters]
  );

  return (
    <Dropdown
      onSave={() => {
        const newValue = fieldProps.allowMultiple ? selected : selected[0];
        fieldProps.input.onChange(newValue);
      }}
      onClose={() => {}}
      keepMounted
      listFilters={listFilters!}
      title={fieldProps.label || ""}
      content={content}
    >
      <div>
        <SelectTreeControl {...fieldProps} />
      </div>
    </Dropdown>
  );
};

const SelectTreeControl = ({
  onFilter,
  ...fieldProps
}: Omit<Props, "listfilters" | "filteredOptions" | "onlyOneFromBranch">) => {
  const dispatch = useDispatch();

  return (
    <SelectControl
      {...fieldProps}
      components={{
        MultiValueRemove: ({ innerProps, ...props }) => (
          <reactSelectComponents.MultiValueRemove
            {...props}
            innerProps={{
              ...innerProps,
              onClick: (e: React.MouseEvent) => {
                innerProps.onClick(e);
                dispatch({
                  type: actionTypes.SET_CHECKED,
                  payload: { value: props.data.value, checked: false }
                });
                e.stopPropagation();
              },
              onTouchStart: (e: React.TouchEvent<any>) => {
                e.stopPropagation();
              }
            }}
          />
        ),
        ClearIndicator: ({ innerProps, ...props }) => (
          <div
            onTouchStart={e => {
              e.stopPropagation();
            }}
          >
            <reactSelectComponents.ClearIndicator
              {...props}
              innerProps={{
                ...innerProps,
                onMouseDown: (e: React.MouseEvent) => {
                  dispatch({
                    type: actionTypes.SET_INITIAL,
                    payload: { options: fieldProps.options, initial: [] }
                  });
                  innerProps.onMouseDown(e);
                }
              }}
            />
          </div>
        ),
        Input: props => <reactSelectComponents.Input {...props} isHidden />
      }}
      customProps={{
        menuIsOpen: false,
        openMenuOnClick: false
      }}
    />
  );
};

const TreeProvider = (props: {
  children: React.ReactNode;
  allowMultiple?: boolean;
  infiniteScroll?: boolean;
  onlyOneFromBranch?: boolean;
  id: string;
}) => {
  const TreeContext = React.useMemo(
    () => TreeContextProvider.getTreeContext(props.id),
    [props.id]
  );
  React.useEffect(
    () => () => {
      TreeContextProvider.removeTreeContext(props.id);
    },
    [props.id]
  );
  return (
    <TreeContext.Provider
      value={React.useReducer(treeReducer, {
        allowMultiple: !!props.allowMultiple,
        onlyOneFromBranch: !!props.onlyOneFromBranch,
        checked: []
      })}
    >
      <TreeRootsContext.Provider value={props.id}>
        {props.children}
      </TreeRootsContext.Provider>
    </TreeContext.Provider>
  );
};
const TreeControlWithContext = (props: Props) => (
  <TreeProvider
    allowMultiple={props.allowMultiple}
    infiniteScroll={props.infiniteScroll}
    onlyOneFromBranch={props.onlyOneFromBranch}
    id={props.input.name}
  >
    <TreeControl {...props} />
  </TreeProvider>
);
export default TreeControlWithContext;
