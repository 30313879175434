import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { useParams } from "react-router";

import { useAuth } from "services/auth-provider";
import {
  Button,
  DataTable,
  ResponsiveTableWrapper,
  TooltipList
} from "components";
import { pluralize } from "utils/strings";
import { ListFiltersState } from "utils/hooks/useListFilters";
import { CompanyItem as CompanyItemType } from "modules/companies/provider/companies";
import { UserRole } from "modules/users/users.constants";
import useStyles from "components/data-table/data-table.styles";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import CompaniesListRowActions from "./companies-list-row-actions";
import CompaniesListStatusDropdown from "./companies-list-status-dropdown";

type Props = {
  loading: boolean;
  companies: CompanyItemType[];
  listFilters: ListFiltersState;
  refetch: () => void;
};

const CompaniesList: React.FC<Props> = ({
  loading,
  companies,
  listFilters,
  refetch
}) => {
  const { checkAccess } = useAuth();
  const isAdmin = checkAccess({ roles: [UserRole.ADMIN] });
  const classes = useStyles();
  const tenant = useTenant();

  const { courseId } = useParams();
  const isCourseDetailsView = Boolean(courseId);
  const isNoSponsorAndAdmins = tenant.isComponentAllowed(
    AllowedComponents.NoCompanySponsorAndAdmins
  );
  const isDetailsNewTab = tenant.isComponentAllowed(
    AllowedComponents.DetailsNewTab
  );

  return (
    <ResponsiveTableWrapper>
      <DataTable
        loading={loading}
        sortable
        emptyText="No companies"
        data={companies}
        listFilters={listFilters}
        columns={[
          {
            key: "name",
            title: "Company name",
            sortable: true,
            render: ({ name, id }) => (
              <Button
                component={RouterLink}
                to={`/companies/${id}`}
                target={isDetailsNewTab ? "_blank" : "_self"}
                variant="text"
              >
                <span className={clsx(classes.ellipsis, classes.customText)}>
                  {name}
                </span>
              </Button>
            )
          },
          {
            key: "externalId",
            title: "Company id",
            render: ({ externalId }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {externalId ? externalId : "-"}
              </span>
            )
          },
          {
            key: "admins",
            title: "Admins",
            render: ({ admins }) =>
              admins.length ? (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <span className={clsx(classes.ellipsis, classes.customText)}>
                    {`${admins[0].firstName} ${admins[0].lastName}`}
                  </span>
                  {admins.length > 1 && (
                    <TooltipList
                      title={`${admins.length} ${pluralize(
                        "admin",
                        admins.length
                      )}`}
                      list={admins.map(
                        ({ firstName, lastName }) => `${firstName} ${lastName}`
                      )}
                    />
                  )}
                </div>
              ) : (
                "-"
              ),
            skip: isNoSponsorAndAdmins
          },
          {
            key: "sponsor",
            title: "AT&T Sponsor",
            render: ({ companyinfo }) =>
              companyinfo && companyinfo.attSponsor ? (
                <Button
                  component={RouterLink}
                  to={`/users/${companyinfo.attSponsor.id}`}
                  variant="text"
                  color="primary"
                  defaultHoverColor
                  underline
                >
                  <span className={clsx(classes.ellipsis, classes.customText)}>
                    {companyinfo.attSponsor.email}
                  </span>
                </Button>
              ) : (
                "-"
              ),
            skip: isNoSponsorAndAdmins
          },
          {
            key: "countUsers",
            title: "Users",
            sortable: true,
            render: ({ usersCount }) => (
              <span className={clsx(classes.ellipsis, classes.customText)}>
                {usersCount > 0 ? usersCount : "-"}
              </span>
            )
          },
          {
            key: "status",
            title: "Status",
            render: company => (
              <CompaniesListStatusDropdown
                company={company}
                refetch={refetch}
              />
            ),
            skip: !tenant.isComponentAllowed(
              AllowedComponents.CompanyStatusChange
            )
          },
          {
            key: "actions",
            title: "Actions",
            width: "1%",
            skip: !isAdmin || isCourseDetailsView,
            render: company => (
              <CompaniesListRowActions {...{ company, refetch }} />
            )
          }
        ]}
      />
    </ResponsiveTableWrapper>
  );
};

export default CompaniesList;
