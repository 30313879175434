import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "config/theme";

export default makeStyles(({ breakpoints, palette }: CustomTheme) => ({
  tableContainer: {
    [breakpoints.up(1500)]: {
      width: "75%"
    },
    borderBottom: `1px solid ${palette.text.mercury}`,
    paddingBottom: 20
  },
  table: {
    [breakpoints.up(1500)]: {
      width: "80%"
    }
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [breakpoints.up(1500)]: {
      width: "75%"
    }
  },
  details: {
    width: "48%"
  },
  comments: {
    width: "48%",
    height: 560
  }
}));
