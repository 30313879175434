import React from "react";
import { Hidden, Typography } from "@material-ui/core";

import { Breadcrumbs, ExtendedMainHeader } from "components";
import routes from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

import { MyLearningPlanItem } from "../../../provider/my-learning-plans";

import useStyles from "./my-learning-plan-header.styles";

type Props = {
  learningPlan: MyLearningPlanItem;
};

const MyLearningPlanHeader: React.FC<Props> = ({ learningPlan }) => {
  const classes = useStyles();
  const tenant = useTenant();
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );
  return (
    <>
      <Hidden smDown>
        <Typography className={classes.title}>Learning plan:</Typography>
      </Hidden>
      <ExtendedMainHeader
        title={learningPlan.name}
        subtitle={
          <Breadcrumbs
            routes={[
              {
                name: "Courses",
                path: isAllowedTenantAvailableCourses
                  ? routes.myCourses.available
                  : routes.myCourses.list
              },
              {
                name: "Learning plans",
                path: isAllowedTenantAvailableCourses
                  ? routes.myCourses.learningPlans.availableList
                  : routes.myCourses.learningPlans.list
              },
              { name: learningPlan.name }
            ]}
            className={classes.breadcrumbs}
          />
        }
      />
    </>
  );
};

export default MyLearningPlanHeader;
