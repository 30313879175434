import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette }) => ({
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    fontSize: "0.875rem",
    fontWeight: "bold",
    borderRadius: "50%",
    color: palette.primary.main,
    backgroundColor: palette.primary.light
  },
  active: {
    width: 30,
    height: 30,
    fontSize: "1rem",
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main
  },
  completed: {
    color: palette.success.contrastText,
    backgroundColor: palette.success.main
  }
}));
