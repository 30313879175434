import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  infoContainer: {
    marginTop: spacing(4),
    [breakpoints.up("sm")]: {
      marginTop: spacing(10)
    }
  },
  text: {
    fontSize: "0.875rem",

    [breakpoints.up("sm")]: {
      fontSize: "1rem"
    },
    [breakpoints.up("lg")]: {
      maxWidth: "50%"
    }
  },
  linksTitle: {
    color: palette.text.default,
    margin: spacing(4, 0, 2)
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: spacing(-1)
  }
}));
