import * as React from "react";

import { AppLayout, Section, ApiResultRenderer } from "components";
import useListFilters from "utils/hooks/useListFilters";

import ProblemsList from "../components/problems-list.component";
import { useModuleState, actionTypes } from "../reducers/problems";
import { useProblemsList } from "../provider";

type Props = {};

const ProblemsListView: React.FC<Props> = () => {
  const [{ problemsFilters }, dispatch] = useModuleState();
  const listFilters = useListFilters({
    ...problemsFilters,
    filters: undefined
  });
  const problemsList = useProblemsList({ variables: { listFilters } });
  const { meta, refetch } = problemsList;

  React.useEffect(() => {
    dispatch({
      type: actionTypes.SET_PROBLEMS_FILTERS,
      payload: listFilters
    });
  }, [dispatch, listFilters]);

  return (
    <AppLayout viewTitle={`Problems (${(meta && meta.total) || 0})`}>
      <Section>
        <ApiResultRenderer apiResult={problemsList} reloadsSection={false}>
          {({ data = [], meta, loading }) => (
            <ProblemsList
              problems={data}
              meta={meta!}
              listFilters={listFilters!}
              refetch={refetch}
              loading={loading}
            />
          )}
        </ApiResultRenderer>
      </Section>
    </AppLayout>
  );
};

export default ProblemsListView;
