import { makeStyles } from "@material-ui/styles";

import { CustomTheme } from "config/theme";

export default makeStyles(({ palette, spacing, shadows }: CustomTheme) => ({
  statusButton: {
    borderRadius: 2,
    fontWeight: "bold",
    padding: spacing(0.5, 1),
    margin: spacing(0.5, 0),
    minWidth: 100,
    display: "flex",
    justifyContent: "space-between"
  },
  active: {
    color: palette.success.main,
    borderColor: palette.success.main
  },
  deactivated: {
    color: palette.hint.main,
    borderColor: palette.hint.main
  },
  expandMoreIcon: {
    "&$active": {
      color: palette.success.main
    },
    "&$deactivated": {
      color: palette.hint.main
    }
  },
  dropdown: {
    minWidth: 100,
    marginTop: 28,
    borderRadius: 2,
    boxShadow: shadows[2]
  },
  dropdownList: { padding: 0 },
  dropdownButton: {
    width: "100%",
    fontSize: "0.875rem",
    padding: spacing(0.5, 1),
    borderWidth: 1,
    borderStyle: "solid",

    "&$active": {
      borderColor: palette.success.main,
      color: palette.success.main
    },
    "&$active:hover": {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText
    },
    "&$deactivated": {
      borderColor: palette.hint.main,
      color: palette.text.default
    },
    "&$deactivated:hover": {
      backgroundColor: palette.hint.main,
      color: palette.hint.contrastText
    }
  },
  dropdownActionContainer: {
    position: "relative",
    width: 101,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  twoElementsDropdownActionContainer: {
    width: 128
  }
}));
