import * as React from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";

import { Card, CardIcon } from "components";
import { formatDuration } from "utils/datetime";
import { useSizeCheck } from "utils/hooks/useSizeCheck";
import { CourseStatus } from "modules/courses/courses.constants";
import { MyCoursesListItem } from "modules/courses/provider/my-courses";
import { IconDateRange, IconRestore } from "assets/icons";

import { CardWrapper } from "../common";

import StatusCard from "./status-card-learner";

export type LearnerCardsProps = Pick<
  MyCoursesListItem,
  "status" | "duration" | "statusTimestamp"
>;

const LearnerCards: React.FC<LearnerCardsProps> = ({
  status,
  duration,
  statusTimestamp
}) => {
  const isFinished = React.useMemo(
    () =>
      status === CourseStatus.COMPLETED ||
      status === CourseStatus.MANUALLY_COMPLETED,
    [status]
  );
  const isMobile = useSizeCheck("xs");

  return (
    <Grid container spacing={isMobile ? 1 : 3}>
      <CardWrapper>
        <StatusCard status={status} />
      </CardWrapper>
      {isFinished && (
        <CardWrapper>
          <Card
            title={moment(statusTimestamp).format("L")}
            subtitle="Last completed date"
            avatar={<CardIcon icon={IconDateRange} />}
            colorVariant="secondary"
          />
        </CardWrapper>
      )}
      {duration && (
        <CardWrapper>
          <Card
            title={formatDuration(duration * 1000, "H[h] mm[min]")}
            subtitle="Duration"
            avatar={<CardIcon icon={IconRestore} />}
            colorVariant="primary"
          />
        </CardWrapper>
      )}
    </Grid>
  );
};

export default LearnerCards;
