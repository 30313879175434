import React from "react";
import { useHistory } from "react-router";

import { Section, Tabs } from "components";
import routes from "config/routes";
import { AllowedComponents, useTenant } from "services/tenant-provider";

type Props = {
  learningPlansCount: number;
  coursesCount: number;
  activeTab: number;
  panelsSet: "admin" | "student";
};

const TabsCoursesLearningPlans: React.FC<Props> = ({
  learningPlansCount,
  coursesCount,
  activeTab,
  children,
  panelsSet
}) => {
  const history = useHistory();
  const tenant = useTenant();
  const isAllowedTenantAvailableCourses = tenant.isComponentAllowed(
    AllowedComponents.CourseListForStudent
  );
  const coursesPath =
    panelsSet === "admin"
      ? routes.courses.list
      : isAllowedTenantAvailableCourses
      ? routes.myCourses.available
      : routes.myCourses.list;

  const learningPlansPath =
    panelsSet === "admin"
      ? routes.learningPlans.list
      : isAllowedTenantAvailableCourses
      ? routes.myCourses.learningPlans.availableList
      : routes.myCourses.learningPlans.list;

  return (
    <Section>
      <Tabs
        panels={[
          {
            name: `Courses (${coursesCount || 0})`,
            path: coursesPath
          },
          {
            name: `Learning plans (${learningPlansCount || 0})`,
            path: learningPlansPath
          }
        ]}
        activeTab={activeTab}
        setActiveTab={() =>
          history.push(activeTab === 1 ? coursesPath : learningPlansPath)
        }
      />
      {children}
    </Section>
  );
};

export default TabsCoursesLearningPlans;
