import * as React from "react";

import { ProblemStatuses } from "modules/problems/problems.constants";
import { splitCamelcase } from "utils/strings";

import Chip, { ChipVariant } from "./chip";

const statusVariants: Record<ProblemStatuses, ChipVariant> = {
  [ProblemStatuses.OPENED]: "error",
  [ProblemStatuses.CLOSED]: "success",
  [ProblemStatuses.IN_PROGRESS]: "secondary",
  [ProblemStatuses.CANCELED]: "hint"
};

type Props = {
  status: ProblemStatuses;
};

const ProblemStatusChip: React.FC<Props> = ({ status }) => (
  <Chip
    label={splitCamelcase(status)}
    size="small"
    variant={statusVariants[status]}
  />
);

export default ProblemStatusChip;
