import React, { useCallback, useState } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box } from "@material-ui/core";

import {
  Header,
  TextControl,
  FormError,
  Section,
  CancelConfirmButtons
} from "components";
import { createValidator } from "utils/forms";
import {
  LearningPlanItem,
  NoAccessResp,
  useLearningPlanCreate,
  useLearningPlanUpdate
} from "modules/courses/provider/learning-plan";
import useToast from "utils/hooks/useToast";
import * as PythonAPI from "services/api/python";

import MessageModal from "../components/message-modal";

import GroupsSection from "./groups-section";
import CoursesSection from "./courses-section";

export type InitialValuesType = {
  name: string;
  groups: string[];
  description: string;
  courseIds: string[];
  courses: {
    id: string;
    name: string;
  }[];
};

type Props = {
  learningPlan?: LearningPlanItem;
  onCancel: () => void;
  onSubmit: () => void;
};

const LearningPlanForm: React.FC<Props> = ({
  learningPlan,
  onCancel,
  onSubmit
}) => {
  const { showToast } = useToast();
  const createLearningPlan = useLearningPlanCreate();
  const updateLearningPlan = useLearningPlanUpdate(learningPlan?.id || "");
  const [isMessageModal, setMessageModal] = useState<NoAccessResp>();

  const initialValues = {
    name: "",
    description: "",
    courseIds: [],
    courses: [],
    ...learningPlan,
    groups: learningPlan ? learningPlan.groups.map(g => g.id) : []
  } as InitialValuesType;

  const handleSubmit = useCallback(
    async (values: InitialValuesType) => {
      const submitValues = {
        ...values,
        courses: values.courses.map(c => c.id)
      };

      try {
        if (learningPlan?.id) {
          await updateLearningPlan(submitValues);
          showToast("Learning plan was successfully updated", "success");
          onSubmit();
        } else {
          await createLearningPlan(submitValues);
          showToast("Learning plan was successfully created", "success");
          onSubmit();
        }
      } catch (e) {
        const noAccess = e?.response?.data?.noAccess;
        if (e.request.status === 400 && noAccess) {
          noAccess && setMessageModal(noAccess);
        } else {
          showToast(e.message, "error");
          onSubmit();
        }
        return PythonAPI.getFormErrors(e);
      }
    },
    [onSubmit, createLearningPlan, updateLearningPlan, showToast, learningPlan]
  );

  const MyForm = useCallback(
    () => (
      <Form
        onSubmit={handleSubmit}
        validate={validator}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
      >
        {({ handleSubmit, submitError, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <FormError>{submitError}</FormError>}
            <Section margin="dense">
              <Header variant="formSection" number="1" title="Choose name" />
              <Field
                name="name"
                label="Name"
                variant="outlined"
                component={TextControl}
                validationIndicator
              />
            </Section>
            <Section margin="dense">
              <Header variant="formSection" number="2" title="External learning plan ID" />
              <Field
                name="externalId"
                label="ID"
                variant="outlined"
                component={TextControl}
              />
            </Section>
            <GroupsSection />
            <Section margin="dense">
              <Header variant="formSection" number="4" title="Description" />
              <Field
                name="description"
                placeholder="Short description"
                component={TextControl}
                variant="outlined"
                multiline={true}
                rows={6}
              />
            </Section>
            <CoursesSection />
            <CancelConfirmButtons
              confirmButtonDisabled={submitting || hasValidationErrors}
              confirmButtonLabel={learningPlan ? "Save" : "Create"}
              onCancel={onCancel}
            />
          </form>
        )}
      </Form>
    ),
[]); // eslint-disable-line

  return (
    <Box mt={1}>
      <MyForm />
      {isMessageModal && (
        <MessageModal
          isOpen={!!isMessageModal}
          data={isMessageModal}
          onClose={() => setMessageModal(undefined)}
        />
      )}
    </Box>
  );
};

export default LearningPlanForm;

const validator = createValidator({
  name: { presence: { allowEmpty: false } },
  externalId: {
    presence: { allowEmpty: false },
    format: {
      pattern: /\S*/,
      message: ` can't contain whitespaces`
    }
  },
  description: {
    presence: { allowEmpty: false },
    length: { maximum: 300 },
  },
  groups: {
    length: {
      minimum: 1,
      tooShort: "can’t be blank"
    }
  },
  courses: {
    length: {
      minimum: 1,
      tooShort: "can’t be blank"
    },
  }
});
