import * as React from "react";
import { useHistory } from "react-router";

import { Button } from "components";

import useStyles from "./back-button.styles";

type Props = {};

const BackButton: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => history.goBack();

  return (
    <>
      {history.length > 1 && (
        <Button
          color="default"
          className={classes.button}
          variant="text"
          onClick={goBack}
        >
          &#8592; Go back
        </Button>
      )}
    </>
  );
};

export default BackButton;
