import { makeStyles } from "@material-ui/styles";

export default makeStyles(({ breakpoints, spacing, shadows }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [breakpoints.up("lg")]: {
      flexDirection: "row",
      width: "80%"
    }
  },
  reportsSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(3),
    paddingRight: spacing(2),
    [breakpoints.up("lg")]: {
      marginRight: spacing(6)
    }
  },
  reportFormSection: {
    width: "100%",
    padding: spacing(4),
    boxShadow: shadows[5]
  }
}));
