import * as React from "react";

import { SubmissionStatus } from "modules/courses/courses.constants";
import { IconClose, IconDone } from "assets/icons";

import Chip, { ChipVariant } from "./chip";

type StatusVariants = {
  [key in SubmissionStatus]: {
    variant: ChipVariant;
    icon?: React.ReactElement;
    label: string;
  };
};

const statusVariants: StatusVariants = {
  [SubmissionStatus.PENDING]: {
    variant: "info",
    label: "Pending"
  },
  [SubmissionStatus.ACCEPTED]: {
    variant: "success",
    icon: <IconDone />,
    label: "Approved"
  },
  [SubmissionStatus.REJECTED]: {
    variant: "error",
    icon: <IconClose />,
    label: "Rejected"
  }
};

interface Props {
  status: SubmissionStatus;
}

const CourseStatusChip: React.FC<Props> = ({ status }) => {
  const { label, variant, icon } = statusVariants[status];
  return <Chip {...{ variant, icon, label }} size="small" />;
};

export default CourseStatusChip;
