import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import * as PythonApi from "services/api/python";
import { Button, Header, FormError } from "components";
import useToast from "utils/hooks/useToast";

import {
  LearningPlanItem,
  useLearningPlanDelete
} from "../../../provider/learning-plan";

import useStyles from "./learning-plan-deletion-modal.styles";

type Props = {
  isOpen: boolean;
  learningPlan: LearningPlanItem;
  onClose: () => void;
  refetch: () => void;
};

const LearningPlanDeletionModal: React.FC<Props> = ({
  isOpen,
  learningPlan,
  onClose,
  refetch
}) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<
    string | string[] | PythonApi.ApiFieldError
  >();

  const deleteCourse = useLearningPlanDelete();

  const onDelete = async () => {
    setSubmitting(true);
    try {
      await deleteCourse({ id: learningPlan.id });
      onClose();
      showToast(
        `"${learningPlan.name}" has been successfully deleted`,
        "success"
      );
      refetch();
    } catch (ex) {
      setError(PythonApi.getMessageFromError(ex));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Header
          title={`Are you sure you want to delete "${learningPlan.name}" learning plan?`}
          variant="section"
        />
      </DialogTitle>
      <DialogContent>
        {error && <FormError center>{error}</FormError>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onDelete} color="error" disabled={submitting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LearningPlanDeletionModal;
